/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';
import { ApolloError, gql, MutationHookOptions, useMutation as useMutationI } from '@apollo/client';
import { toast } from 'react-toastify';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { routesPath, slugRoutesPaths } from '../constants/routes';
import { ERRORS_TYPE } from '../constants/errors';
import { GraphQLTypes, InputType, ValueTypes, Zeus } from '../zeus';
import { ErrorForToast } from '../types';
import { lastUrlStorage } from '../utilities/helpers';

const callToast = (
  error: ApolloError | ApolloError['graphQLErrors'][0] | boolean,
  history: any,
  successText?: any,
) => {
  if (error) {
    if (
      (error as ApolloError['graphQLErrors'][0])?.extensions?.code === ERRORS_TYPE.UNPROCESSABLE
    ) {
      const details = (error as ApolloError['graphQLErrors'][0])?.extensions?.details;
      const message = Object.values(details)
        .flatMap((v) => v)
        .join(', ');

      toast.error(message, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });

      return;
    }
    const message =
      (error as ApolloError)?.graphQLErrors?.[0]?.message ||
      (error as ApolloError['graphQLErrors'][0])?.message ||
      'Error';
    toast.error(message, {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
    if ((error as ApolloError)?.graphQLErrors?.[0]?.message === 'Not authorized') {
      lastUrlStorage.save();
      history.replace(routesPath.login);
    }
  } else {
    toast.success(successText, {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }
};

interface MutationParams<O extends 'Mutation', Z>
  extends MutationHookOptions<InputType<GraphQLTypes[O], Z>> {
  onUIError?: (errors: ErrorForToast[]) => void;
}

export function useMutation<Z extends ValueTypes[O], O extends 'Mutation'>(
  mut: Z | ValueTypes[O],
  additional?: {
    successText?: string;
    operationName?: string;
  },
  params?: MutationParams<O, Z>,
) {
  const history = useHistory();
  const match = useRouteMatch<any>(slugRoutesPaths);
  const clinicSlug = match?.params.refId || localStorage.getItem('hospital-refId');
  const [mutation, { data, error, loading }] = useMutationI<InputType<GraphQLTypes[O], Z>>(
    gql(Zeus('mutation', mut, additional?.operationName)),
    {
      context: {
        headers: clinicSlug
          ? {
              'X-Current-Organization-Id': clinicSlug,
            }
          : {},
        ...params?.context,
      },
      onError: (apolloError: any) => {
        if (apolloError?.graphQLErrors[0]) {
          const { code, extra, details } = apolloError?.graphQLErrors[0]?.extensions;
          if (code === ERRORS_TYPE.FORCE_RESET_PASSWORD) {
            localStorage.setItem('resetToken', extra.resetToken);
            history.push(routesPath.resetPassword);
          }
          if (code === ERRORS_TYPE.UNPROCESSABLE) {
            const errorMessage = Object.keys(details).map((key: any) => ({
              name: key,
              errors: [details[key]],
            }));
            params?.onUIError?.(errorMessage);
          }
        }
        params?.onError?.(apolloError);
        callToast(apolloError, history);
      },
      refetchQueries: [...(params?.refetchQueries ? (params?.refetchQueries as Array<any>) : [])],
    },
  );
  const mutationWrapper = useCallback(
    async (dataValue: any) => {
      try {
        const res = await mutation(dataValue);

        if (res.errors) {
          if (params?.onError) params?.onError(res.errors[0] as unknown as ApolloError);
          callToast(res.errors[0], history);
          return;
        }
        if (params?.onCompleted) params?.onCompleted(res.data as InputType<GraphQLTypes[O], Z>);
        callToast(false, history, additional?.successText);
      } catch (ex) {
        /* eslint-disable no-console */
        console.error('There was error on your request', ex);
        params?.onError?.({} as any);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation, params, history],
  );

  return { mutation: mutationWrapper, data, loading, error };
}
