import { FC } from 'react';
import classNames from 'clsx';
import { useHistory } from '../../hooks';
import { historyPushState } from '../../utilities/helpers';

type AnalyticsWidgetsProps = {
  icon: JSX.Element;
  className?: string;
  pattern?: JSX.Element;
  title: string;
  url: string;
};
export const AnalyticsWidgets: FC<AnalyticsWidgetsProps> = ({
  icon,
  pattern,
  title,
  className,
  url,
}) => {
  const history = useHistory();
  const goTo = () => {
    historyPushState(url, history);
  };

  return (
    <div
      className={classNames(
        'flex items-center overflow-hidden justify-center transition-all cursor-pointer rounded-main bg-white w-[36rem] h-[22.7rem] relative group border-2 border-[#f0f0f0] hover:border-persianBlue hover:border-2',
        className,
      )}
      onClick={goTo}
    >
      <div className="absolute w-full h-full flex flex-col items-center justify-between py-[5.2rem]">
        <div className="">{icon}</div>
        <div className="text-2430 font-bold font-lato group-hover:text-persianBlue">{title}</div>
      </div>
      <div className="">{pattern}</div>
    </div>
  );
};
