import { FC } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import type { EventProps } from 'react-big-calendar';

import EventDelay from '../EventDelay';
import clockSvg from '../../assets/clock-small.svg';
import { ProcedureTypeBadge } from '../ProcedureTypeBadge';
import type { IRoomEvent } from './types';

const formatTime = (time?: Date) => {
  if (!time) return '';

  return format(time, 'HH:mm');
};

const RoomEvent: FC<EventProps<IRoomEvent>> = ({ event }) => {
  const timeString = `${formatTime(event?.start)} - ${formatTime(event?.end)}`;

  return (
    <div className="event-content pt-4 pb-4 px-4">
      <div className="flex justify-between items-start">
        <div className="text-text-black font-extrabold text-1220">{event.surgeryName}</div>
        <ProcedureTypeBadge procedureType={event.procedureType} />
      </div>
      <div className="text-text-black font-extrabold text-1220 opacity-70">{event.surgeonName}</div>
      <div className="ml-2">
        <EventDelay delay={event.delay} />
      </div>
      <div
        className={clsx(
          'text-text-black flex justify-between items-center -ml-1',
          !event.delay && 'mt-4',
        )}
      >
        <span className="text-1220 font-extrabold inline-flex">
          <img className="mr-2" src={clockSvg} alt="clock-icon" />
          {timeString}
        </span>
      </div>
    </div>
  );
};

export default RoomEvent;
