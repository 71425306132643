export const colors = {
  neutralBlack: '#25282B',
  neutralGray: '#a0a4a8',
  neutralGrayDark: '#6F7375',
  blue: '#336cfb',
  chartColorRobotic: '#CACAFC',
  chartColorOpen: '#CAEAFC',
  chartColorLap: '#FCD9CA',
  darkBlue: '#232d42',
  silverBlue: '#8A92A6',
  white: '#fff',
  orange: '#FAC032',
  link: '#707070',
  grey2: '#ebeff2',
  grey: '#25282b',
  error: '#ff4d4f',
  darkGrey: '#585858',
  active: '#488048',
  good: '#27AE60',
  bad: '#C61414',
  bgActive: '#E2F5E2',
  bgInactive: '#FBEBEB',
  inactive: '#BD4A4A',
  chartTextColorRobotic: '#0047F6',
  chartTextColorOpen: '#F43F5E',
  chartTextColorLap: '#B45309',
  caseStageTurnover: '#FAC0324D',
  caseStageTurnoverText: '#C27E00',
  caseStagePrep: '#88A2E380',
  caseStagePrepText: '#0047F6',
  caseStageSurgery: '#E6B37A80',
  caseStageSurgeryText: '#B45309',
  caseStagePost: '#DAA6AE80',
  caseStagePostText: '#F43F5E',
  caseStageTurnoverSelected: '#FEEDC2',
  caseStagePrepSelected: '#C3D0F1',
  caseStageSurgerySelected: '#F2D9BC',
  caseStagePostSelected: '#E8CED2',
  green: 'green',
  errorAuth: '#eb5757',
  menuIconColor: '#9A9AB0',
  menuActiveIconColor: '#fff',
};

export const chartColorsDarker = [
  '#5E2F7D',
  '#3356A3',
  '#0074B0',
  '#0088A8',
  '#38979C',
  '#72AB9A',
  '#4E8DBA',
  '#5EB5C4',
  '#4FA16F',
  '#C96767',
];
export const chartColors = [
  '#8444B1',
  '#4273D7',
  '#0096E3',
  '#00B2DC',
  '#4BC9CF',
  '#95DDC8',

  '#63B3ED',
  '#76E4F7',
  '#68D391',
  '#FC8181',
  '#F6E05E',
  '#F6AD55',
  '#4FD1C5',
  '#B794F4',

  '#4299E1',
  '#0BC5EA',
  '#48BB78',
  '#F56565',
  '#ECC94B',
  '#ED8936',
  '#38B2AC',
  '#9F7AEA',

  '#3182CE',
  '#00B5D8',
  '#38A169',
  '#E53E3E',
  '#D69E2E',
  '#DD6B20',
  '#319795',
  '#805AD5',

  '#2B6CB0',
  '#00A3C4',
  '#2F855A',
  '#C53030',
  '#B7791F',
  '#C05621',
  '#2C7A7B',
  '#6B46C1',

  '#2C5282',
  '#0987A0',
  '#276749',
  '#9B2C2C',
  '#9C4221',
  '#975A16',
  '#285E61',
  '#553C9A',

  '#90CDF4',
  '#9DECF9',
  '#9AE6B4',
  '#FEB2B2',
  '#FAF089',
  '#FBD38D',
  '#81E6D9',
  '#D6BCFA',
];
