import { FC, useEffect, useState } from 'react';

import clsx from 'clsx';
import { getInitDate, oneDayOnMillisecond, today } from '../../utilities/getQuery';
import { useDateRangeFilter } from '../../hooks/useCalendarFilter';
import { DateRangePicker, DateRangeSkeleton } from '../DateRangePicker/DateRangePicker';

type DateFilterProps = {
  onSelect?: (val: { from: string; to: string }) => void;
  loading?: boolean;
};

export const DateRangeSwitch: FC<DateFilterProps> = ({ onSelect, loading }) => {
  const buttons = [
    { value: 'all', label: 'All' },
    { value: '365', label: '1 Year' },
    { value: '180', label: '6 Months' },
    { value: '30', label: '1 Month' },
  ];

  const {
    isInited,
    variables,
    selectType,
    limitRange,
    setVariables,
    setRangeDate,
    updateQuery,
    setSelectType,
  } = useDateRangeFilter();
  const [active, setActive] = useState(buttons[0]);

  const handleChange = (option: { label: string; value: string }) => {
    setActive(option);
    setSelectType(option.value as string);

    const currentDate = new Date().getTime();

    if (option.value === 'all') {
      const value = limitRange || getInitDate();

      onSelect && onSelect(value);
      updateQuery(value);

      return;
    }

    if (option.value === '365') {
      const currentYear = new Date().getFullYear();

      const value = {
        from: `${currentYear}-01-01`,
        to: today,
      };

      onSelect && onSelect(value);
      updateQuery(value);

      return;
    }

    const newDate: string | number = Number(option.value ?? 0) * oneDayOnMillisecond;
    const newQuery = {
      from: new Date(currentDate - newDate).toISOString().substr(0, 10),
      to: today,
    };

    onSelect && onSelect(newQuery);
    updateQuery(newQuery);
  };

  const handleChangeDateRange = (range: [string, string]) => {
    setSelectType('custom');
    const rangeDate = { from: range[0] || '', to: range[1] || '' };
    setRangeDate(rangeDate);
    setVariables(rangeDate);
    onSelect && onSelect(rangeDate);
  };

  useEffect(() => {
    const item = buttons.find((item) => item.value === selectType);
    setActive(item || { label: '', value: '' });
  }, [selectType]);

  return (
    <div
      className={clsx('flex', {
        'opacity-40': loading || !limitRange,
      })}
    >
      <div className="flex bg-white h-12 mr-4 content-center rounded-[0.8rem]">
        {buttons.map((btn, i) => (
          <div
            key={btn.label}
            onClick={() => !loading && limitRange && handleChange(btn)}
            className={clsx(
              ' w-[9.4rem] flex font-bold font-lato text-1214 justify-center content-center rounded-[0.8rem]',
              {
                'bg-black text-white': active.value === btn.value,
                'cursor-pointer': !loading && limitRange,
              },
            )}
          >
            <span
              className={clsx('w-full text-center py-1 h-fit self-center', {
                'border-l': active.value !== btn.value && i > 0,
              })}
            >
              {btn.label}
            </span>
          </div>
        ))}
      </div>
      {(!limitRange?.from || !limitRange?.to) && <DateRangeSkeleton selectType={selectType} />}
      {limitRange?.from && limitRange?.to && (
        <DateRangePicker
          dates={[variables.from, variables.to]}
          selectType={selectType}
          minDate={limitRange?.from}
          maxDate={limitRange?.to}
          onApply={handleChangeDateRange}
        />
      )}
    </div>
  );
};
