import { Input as AInput, InputProps } from 'antd';
import clsx from 'clsx';
import { FC, SyntheticEvent } from 'react';

export const Input: FC<InputProps> = (props) => {
  return (
    <div className="p-4">
      <AInput
        className={`
        bg-white
          rounded-[0.8rem]
          border-[0.1rem]
          border-solid
        border-grey-border
          w-full
          pl-4
          text-[1.2rem]
          mt-[0.6]
          mb-0`}
        style={{ width: 'calc(100% - 1rem)' }}
        {...props}
      />
    </div>
  );
};

export const DropdownContainer: FC<{ stretch?: boolean }> = ({ children, stretch }) => {
  return (
    <div
      className={`
        m-0
        p-2
        pt-0
        mt-3
        border-[0.1rem]
        border-solid
        border-grey-border
        z-[1000]
        absolute
        bg-white
        rounded-t-main
        rounded-b-main
        top-full
        ${stretch ? 'w-full' : ''}
        left-0]`}
    >
      {children}
    </div>
  );
};

export const List: FC<{ stretch?: boolean }> = ({ children, stretch }) => {
  return (
    <ul
      className={clsx(
        'overflow-y-auto list-none m-0',
        stretch ? 'w-full' : 'w-[20rem] md:w-[26.4rem]',
      )}
      style={{
        maxHeight: 'clamp(200px, 55vh, 600px)',
      }}
    >
      {children}
    </ul>
  );
};

export const ListItem: FC<{
  $isActive?: boolean;
  isBigText?: boolean;
  onClick?: (e: SyntheticEvent<HTMLLIElement, MouseEvent>) => void;
}> = ({ $isActive, onClick, children, isBigText }) => {
  return (
    <li
      onClick={onClick}
      className={`
       ${isBigText ? 'isBigText' : ''}
       ${$isActive ? 'bg-perano bg-opacity-20' : 'hover:bg-grey-hover'}
        mt-2
        md:w-auto
        lato
        text-black
        font-semibold
        leading-8
        not-italic
        text-[1.2rem]
        md:text-[1.2rem]
        lg:text-[1.2rem]
        relative
        py-[0.6rem]
        px-[1.4rem]
        rounded-[0.4rem]
        cursor-pointer`}
    >
      {children}
    </li>
  );
};
