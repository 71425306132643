/* eslint-disable no-octal-escape */
import { FC } from 'react';
import { Option } from '../Selector';
type SelectedFilterItemType = {
  option: Option;
  onDelete?: (option: Option) => void;
};
export const SelectedFilterItem: FC<SelectedFilterItemType> = ({ option, onDelete }) => {
  return (
    <div
      className={`
        flex
        justify-between
        md:text-[1.6rem]
        h-[2.8rem]
        md:h-[3rem]
        text-center

        border
        border-[#A5BEF5]
        rounded-[2.4rem]
        items-center
        relative
        px-[1.2rem]
        mr-6
        my-3
      `}
    >
      <div className="text-1214 tracking-wider font-lato font-[500] text-persianBlue">
        {option.label}
      </div>
      {onDelete && (
        <div
          onClick={() => onDelete(option)}
          className="text-black opacity-70 pl-2 cursor-pointer text-[2rem] relative bottom-[1px] ml-2 after:content-['\00d7']"
        ></div>
      )}
    </div>
  );
};
