import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { GET_CASE } from '../../graphql/query/constants';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';
import { makeLogData } from '../../utilities/helpers';

const logData = makeLogData();

export type useQueryCaseResponse = {
  data: Record<
    ActivityGroupKeys,
    {
      label: string;
      end_time: string;
      start_time: string;
    }[]
  >;
  meta: {
    OR: string;
    date: string;
    modality: string;
    case_number: string;
    specialty: string;
    weekDay: string;
    surgeon: string;
    surgery_name: string;
    scheduled_time: string;
  };
  activity_group: AnalyticsGenericResponse<string>['activity_group'];
  all_filters: AnalyticsGenericResponse<string>['all_filters'];
};

export type CaseArgs = {
  date: string;
  rooms?: string[] | null;
};

const makeQueryCase = async (caseId: string, params: Record<string, any>) => {
  const { data } = await RESTClient.get<useQueryCaseResponse>(`/calendar/case/${caseId}`, {
    params,
  });

  return data;
};

export const useQueryCase = (caseId: string, isActive?: boolean) => {
  const organization = useSLUGName();
  const params = useMemo(
    () => ({
      ...(organization ? { organization } : {}),
    }),
    [],
  );

  const processor = useCallback(() => makeQueryCase(caseId, params), [caseId, params]);
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery([GET_CASE, caseId, params], processor, {
    enabled: isActive || isActive === undefined,
  });

  logData(`/calendar/case/${caseId}(${JSON.stringify(params)})'`, data);

  const result = data?.data || ({} as useQueryCaseResponse['data']);
  const meta = data?.meta || ({} as useQueryCaseResponse['meta']);

  return { data: result, rawData: data, meta, loading, refetch };
};
