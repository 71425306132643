import { BrowserRouter as Router } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { AppContextProvider } from './context';
import { Routes } from './routes';
import 'react-toastify/dist/ReactToastify.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retryDelay: 3000,
    },
  },
});

const App = () => (
  <Router>
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <ToastContainer />
        <Routes />
      </AppContextProvider>
    </QueryClientProvider>
  </Router>
);

export default App;
