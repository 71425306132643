type UserFormContainerProps = {
  label?: React.ReactNode;
  actions?: React.ReactNode;
};

export const UserFormContainer: React.FC<UserFormContainerProps> = ({
  label,
  actions,
  children,
}) => {
  return (
    <div className="flex flex-col h-full">
      <div className="text-text-black text-1820 font-bold text-center">{label}</div>
      <div className="mt-12 overflow-y-auto -mr-8 pr-8">{children}</div>
      <div className="mt-auto flex gap-x-6">{actions}</div>
    </div>
  );
};
