import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { GET_PEOPLE_COUNT } from '../../graphql/query/constants';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';
import { transformAllFilters } from '../../utilities/transformAllFilters';
import { makeLogData } from '../../utilities/helpers';

const logData = makeLogData();
function transformData<K extends string>(
  data: AnalyticsGenericResponse<K>,
): AnalyticsGenericDataFormat<K>[] {
  const counts = data.counts || {};
  const means = data.means || {};
  const sds = data.sds || {};
  const maxs = data.maxs || {};
  const mins = data.mins || {};

  const df_outliers = data.df_outliers || {};
  // const dataPoints = data.dataPoints || {};
  const significance = data.significance || {};
  const bubbleChart = data.bubble_charts || {};

  const phases: K[] = [];
  const groups: string[] = [];
  Object.keys(means).forEach((key) => {
    const phaseName = key as K;
    if (!phases.includes(phaseName)) phases.push(phaseName);
    Object.keys(means[phaseName] || {}).forEach((groupName) => {
      if (!groups.includes(groupName)) groups.push(groupName);
    });
  });
  const result = groups.map((group, gg) => {
    const context = phases.map((phase) => {
      const averageDuration = means?.[phase]?.[group] as number;
      const activitiesAmount = counts?.[phase]?.[group] as number;
      const standardDeviation = sds?.[phase]?.[group] as number;
      const min = mins?.[phase]?.[group] as number;
      const max = maxs?.[phase]?.[group] as number;

      const significanceData = significance?.[phase];
      let radius = 0;
      const bubbleData = (bubbleChart?.[phase] || {}) as BubbleChartResponse;
      const bubblePadding = (bubbleChart as any)?.padding || 0;

      const maybeIndex = bubbleData?.cens?.findIndex(
        (item: any) => item[0] === averageDuration && item[1] === standardDeviation,
      );
      if (maybeIndex !== -1) {
        const rad = bubbleData?.radii?.[maybeIndex] || 0;
        const newRadius = rad - bubblePadding;
        radius = newRadius <= 0 ? rad : newRadius;
      }

      const xLimits = bubbleData?.xlim || [0, 0];
      const yLimits = bubbleData?.ylim || [0, 0];

      return {
        name: phase,
        averageDuration,
        activitiesAmount,
        standardDeviation,
        max,
        min,
        radius,
        xLimits,
        yLimits,
        dataPoints: [],
        FStatistic: significanceData?.['F-statistic'] || 0,
        pValue: significanceData?.['p-value'] || 0,
        significant: !!significanceData?.significant,
        raw: data,
      };
    });

    return { name: group === '0' ? 'all' : group, context };
  });

  return result;
}

async function makeQueryPeopleCount<K extends string>(params: Record<string, any>) {
  const { data } = await RESTClient.get<AnalyticsGenericResponse<K>>('/people-count', { params });

  return Object.keys(data || {}).reduce((acc, key) => {
    if (['means', 'maxs', 'mins', 'counts', 'sds', 'significance', 'bubble_charts'].includes(key)) {
      const obj = data[key as keyof AnalyticsGenericResponse<K>];
      const x = Object.keys(obj || {}).reduce((phases, phaseName) => {
        return { ...phases, [phaseName.replace(/_mean|_max|_min/g, '')]: (obj as any)[phaseName] };
      }, {});

      return { ...acc, [key]: x };
    }

    return { ...acc, [key]: data[key as keyof AnalyticsGenericResponse<K>] };
  }, {}) as AnalyticsGenericResponse<K>;
}

export function useQueryPeopleCount<K extends string>(
  variables: QueryAnalyticsArgs & { aspect_ratio?: number },
  pcView?: string | null,
  keepPreviousData?: boolean,
) {
  const organization = useSLUGName();
  const params = useMemo(
    () => ({
      from: variables.from,
      to: variables.to,
      ...(organization ? { organization } : {}),
      ...(variables.aspect_ratio ? { aspect_ratio: variables.aspect_ratio } : {}),
      split: variables.split || undefined,
      ...(pcView ? { activity_group: pcView } : {}),
      ...(variables.filters || {}),
    }),
    [variables],
  );

  const processor = useCallback(() => makeQueryPeopleCount(params), [params]);
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery([GET_PEOPLE_COUNT, params], processor, {
    keepPreviousData,
  });

  logData('/people-count(' + JSON.stringify(params) + ')', data);

  const result = useMemo(() => {
    const res = transformData<K>((data || {}) as AnalyticsGenericResponse<K>);
    const arr = [...res];
    const restIndex = arr.findIndex((item) => /rest/i.test(item.name));
    const restItem = arr.splice(restIndex, 1);
    arr.push(...restItem);

    return arr;
  }, [data]);

  const allFilters = useMemo(() => transformAllFilters(data?.all_filters), [data?.all_filters]);

  return { data: result, rawData: data, allFilters, loading, refetch };
}
