import { Plugin, Scale } from 'chart.js';

const getLabelHitboxes = (scales: { [key: string]: Scale }) =>
  Object.values(scales).map((s: any) => ({
    scaleId: s.id,
    labels: s._labelItems.map((e: any, i: number) => ({
      x: e.translation[0] - s._labelSizes.widths[i],
      x2: e.translation[0] + s._labelSizes.widths[i] / 2,
      y: e.translation[1] - s._labelSizes.heights[i] / 2,
      y2: e.translation[1] + s._labelSizes.heights[i] / 2,
      label: e.label,
      index: i,
    })),
  }));

export const CornerTitlePlugin: Plugin<'bar'> = {
  id: 'cornerTitle',

  afterDraw(chartInstance, chartEvent, args) {
    const options = chartInstance?.config?.options?.plugins?.cornerTitle;
    const ctx = chartInstance.ctx;
    if (!ctx) return;
    const scales = getLabelHitboxes(chartInstance.scales || {});
    const xScale = scales.find((s) => s.scaleId === (options?.scale || 'y'));
    const x = Math.min(...xScale?.labels.map((l: any) => l.x));
    const y = Math.min(...xScale?.labels.map((l: any) => l.y));

    ctx.font = `${options?.size || 0}px ${options?.family || 'Arial'}`;
    ctx.fillStyle = options?.color || '#1C1C1F';

    const xOffset = options?.xOffset || 0;
    const yOffset = options?.yOffset || 0;
    if (options?.text) {
      ctx.fillText(options?.text, x + xOffset, y + yOffset);
    }
  },
};
