export const GET_CLINICS = 'CLINICS';
export const GET_PRESETS = 'PRESETS';
export const GET_ME = 'ME';
export const GET_MONTH_CASES = 'MONTH_CASES';
export const GET_ROOMS_CASES = 'ROOMS_CASES';
export const GET_GENESIS_METRICS = 'GENESIS_METRICS';
export const GET_PEOPLE_COUNT = 'PEOPLE_COUNT';
export const GET_GENESIS_METRICS_BY_ROOM = 'GENESIS_METRICS_BY_ROOM';
export const GET_GENESIS_METRICS_BY_SURGEON = 'GENESIS_METRICS_BY_SURGEON';
export const GET_GENESIS_METRICS_BY_CASE_TYPE = 'GENESIS_METRICS_BY_CASE_TYPE';
export const GET_GENESIS_METRICS_BY_WEEK_DAY = 'GENESIS_METRICS_BY_WEEK_DAY';
export const GET_CASE = 'CASE';
export const GET_ROOMS = 'ROOMS';
export const GET_SURGEONS = 'SURGEONS';
export const GET_SURGERIES = 'SURGERIES';
export const GET_USERS = 'USERS';
export const GET_USER = 'USER';
export const GET_DASHBOARD = 'DASHBOARD';
export const GET_DASHBOARD_NOM = 'DASHBOARD_NON_OPERATIVE_METRICS';
export const GET_DASHBOARD_CASE_MIX = 'DASHBOARD_CASE_MIX';
export const GET_DASHBOARD_CASE_HISTORY = 'DASHBOARD_CASE_HISTORY';
export const GET_ADMINISTRATION = 'ADMINISTRATION';
