import clsx from 'clsx';

type RoleInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  options: { label: string; value: string }[];
};

export const RoleInput: React.FC<RoleInputProps> = ({ value, options, onChange }) => {
  return (
    <div className="grid grid-cols-2 gap-x-6 gap-y-4">
      {options?.map((option) => {
        const isSelected = option.value === value;

        return (
          <div
            key={option.value}
            onClick={() => onChange?.(option.value)}
            className={clsx(
              'inline-flex items-center rounded-3xl border border-solid h-[3.1rem] pl-5 cursor-pointer',
              isSelected ? 'border-perano' : '',
            )}
          >
            <div
              className={clsx(
                'h-5 w-5 rounded-full border-solid mr-4',
                isSelected ? 'border-4 border-persianBlue' : 'border border-text-black',
              )}
            />
            <span className="text-1214 font-semibold">{option.label}</span>
          </div>
        );
      })}
    </div>
  );
};
