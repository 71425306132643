export const genesisLabels = {
  sterileprep_patientin: 'case-open to patient-in',
  patientin_firstcut: 'patient-in to skin-cut',
  patientclose_patientout: 'skin-close to patient-out',
  patientout_sterileprep: 'patient-out to case-open',
  patientout_patientin: 'wheels-out to wheels-in',
  surgery_surgery: 'non-operative time',
};

export const TemporalActivitiesLabels: { [x in TemporalActivitiesKeys]: string } = {
  turnover: 'Turnover',
  setup: 'Setup',
  sterileprep: 'Sterile Prep',
  robotdraping: 'Robot Draping',
  patientin: 'Patient In',
  walkin: 'Walk In',
  patientprep: 'Patient Prep',
  intubate: 'Intubate',
  patientdraping: 'Patient Draping',
  imaging: 'Imaging',
  firstcut: 'First Cut',
  portplacement: 'Port Placement',
  roomprep: 'Room Prep',
  intervention: 'Intervention',
  rollup: 'Rollup',
  docking: 'Docking',
  surgery: 'Surgery',
  undocking: 'Undocking',
  rollback: 'Rollback',
  opensurgery: 'Open Surgery',
  lapsurgery: 'Lap Surgery',
  patientclose: 'Patient Close',
  robotundraping: 'Robot Undraping',
  patientundraping: 'Patient Undraping',
  patientout: 'Patient Out',
  clean: 'Clean',
  idle: 'Idle',
};
