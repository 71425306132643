import React, { FC } from 'react';
import clsx from 'clsx';

import { Skeleton } from 'antd';

export type LayoutProps = {
  withMargin?: boolean;
  children?: React.ReactNode;
};

export type LayoutContentProps = LayoutProps & {
  loading?: boolean;
  loadingComponent?: React.ReactNode;
  actions?: React.ReactNode;
  actionsAlign?: 'left' | 'center';
};

export type LayoutType = FC<LayoutProps> & {
  Filters: typeof LayoutFilters;
  Content: typeof LayoutContent;
};

export const LayoutFilters: FC<LayoutProps> = ({ children, withMargin = true }) => {
  return <div className={clsx('flex flex-row gap-x-4', !!withMargin && 'mt-12')}>{children}</div>;
};

const LayoutContentContainer: FC<LayoutProps> = ({ children, withMargin = true }) => {
  return (
    <div
      className={clsx('flex flex-col gap-y-12 bg-white rounded-xl p-8', !!withMargin && 'mt-12')}
    >
      {children}
    </div>
  );
};

export const LayoutContent: FC<LayoutContentProps> = ({
  actions,
  loading,
  children,
  actionsAlign = 'left',
  loadingComponent = <Skeleton active round />,
}) => {
  if (loading) {
    return <LayoutContentContainer>{loadingComponent}</LayoutContentContainer>;
  }

  return (
    <LayoutContentContainer>
      {!!actions && (
        <div className={clsx('flex', actionsAlign === 'left' ? 'justify-start' : 'justify-center')}>
          {actions}
        </div>
      )}
      {children}
    </LayoutContentContainer>
  );
};

export const CalendarLayout: FC<LayoutProps> = ({ children }) => {
  const array = React.Children.toArray(children).filter(React.isValidElement);

  const filters = array.find((c) => c.type === LayoutFilters);
  const content = array.find((c) => c.type === LayoutContent);

  return (
    <>
      {filters}
      {content}
    </>
  );
};

const CalendarLayoutComponent: LayoutType = CalendarLayout as unknown as LayoutType;
CalendarLayoutComponent.Filters = LayoutFilters;
CalendarLayoutComponent.Content = LayoutContent;

export default CalendarLayoutComponent;
