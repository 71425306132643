import { addMinutes, set } from 'date-fns';

import type { ICaseEvent } from './types';
import { CaseStageType } from '../../types';

const generateEvents = (
  startTime: Date,
  endTime: Date,
  stage: CaseStageType,
  resourceId: string,
) => {
  const events: (ICaseEvent & { resourceId?: string })[] = [];

  for (
    let currentTime = startTime;
    currentTime < endTime;
    currentTime = addMinutes(currentTime, Math.random() * 50 + 10)
  ) {
    const start = set(currentTime, { minutes: Math.random() * 30 });
    events.push({
      resourceId,
      stage,
      start: start,
      end: addMinutes(start, Math.random() * 30),
    });
  }

  return events;
};

export const generateDummyData = (date: Date) => {
  const truncatedDate = set(date, {
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const events = [
    ...generateEvents(
      set(truncatedDate, { hours: 7 }),
      set(truncatedDate, { hours: 9 }),
      'turnover',
      'turnover1',
    ),
    ...generateEvents(
      set(truncatedDate, { hours: 9 }),
      set(truncatedDate, { hours: 10 }),
      'patient-prep',
      'pre_surgery1',
    ),
    ...generateEvents(
      set(truncatedDate, { hours: 10 }),
      set(truncatedDate, { hours: 11 }),
      'main',
      'surgery1',
    ),
    ...generateEvents(
      set(truncatedDate, { hours: 11 }),
      set(truncatedDate, { hours: 12 }),
      'robotic',
      'post_surgery1',
    ),
    ...generateEvents(
      set(truncatedDate, { hours: 13 }),
      set(truncatedDate, { hours: 9 }),
      'turnover',
      'turnover2',
    ),
    ...generateEvents(
      set(truncatedDate, { hours: 9 }),
      set(truncatedDate, { hours: 10 }),
      'patient-prep',
      'pre_surgery2',
    ),
    ...generateEvents(
      set(truncatedDate, { hours: 10 }),
      set(truncatedDate, { hours: 11 }),
      'main',
      'surgery2',
    ),
    ...generateEvents(
      set(truncatedDate, { hours: 11 }),
      set(truncatedDate, { hours: 12 }),
      'robotic',
      'post_surgery2',
    ),
  ];

  return events;
};
