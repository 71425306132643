import { useEffect, useState } from 'react';

const PROPORTIONS = {
  min: [400, 200],
  '1024': [600, 300],
  '1280': [800, 400],
  '1440': [1000, 500],
  '1920': [1200, 600],
};

export const useBubbleChartProportions = () => {
  const [proportions, setProportions] = useState(PROPORTIONS['1440']);

  function getProportions() {
    let proportion = PROPORTIONS['1440'];
    if (window.innerWidth > 1920) {
      proportion = PROPORTIONS['1920'];
    }
    if (window.innerWidth < 1920 && window.innerWidth >= 1440) {
      proportion = PROPORTIONS['1440'];
    }
    if (window.innerWidth < 1440 && window.innerWidth >= 1280) {
      proportion = PROPORTIONS['1280'];
    }
    if (window.innerWidth < 1280 && window.innerWidth >= 1024) {
      proportion = PROPORTIONS['1024'];
    }

    if (window.innerWidth < 1024) {
      proportion = PROPORTIONS['min'];
    }
    setProportions(proportion);
  }

  useEffect(() => {
    window.addEventListener('resize', getProportions);

    return () => {
      window.removeEventListener('resize', getProportions);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    getProportions();
  }, []);

  return proportions;
};
