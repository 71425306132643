import { AxisLabelPoint, Plugin, Scale } from 'chart.js';
type LabelHitbox = {
  left: number;
  right: number;
  top: number;
  bottom: number;
  label: string | number;
  item: any;
  index: number;
};

let changedPoints: Record<string, AxisLabelPoint> = {};

const findLabel = (labels: any, evt: any) => {
  let found = false;
  let labelInfo = null;

  labels?.forEach((l: any) => {
    l?.labels?.forEach((label: LabelHitbox, index: number) => {
      if (
        evt.x > label.left - 30 &&
        evt.x < label.right + (l.scaleId === 'y' ? 5 : 30) &&
        evt.y > label.top - 15 &&
        evt.y < label.bottom
      ) {
        labelInfo = {
          label: label.label,
          scaleId: l.scaleId,
          item: label.item,
          boundaries: {
            x: label.left,
            y: label.top,
            left: label.left,
            right: label.right,
            top: label.top,
            bottom: label.bottom,
          },
          index,
        };
        found = true;
      }
    });
  });

  return { found, labelInfo } as {
    found: boolean;
    labelInfo: AxisLabelPoint;
  };
};

const getLabelHitboxes = (scales: { [key: string]: Scale }) =>
  Object.values(scales)?.map((s: any) => ({
    scaleId: s.id,
    labels: s._labelItems?.map((e: any, i: number) => {
      return {
        left: e.translation[0] - s._labelSizes.widths[i] / 2,
        right: e.translation[0] + s._labelSizes.widths[i] / 2,
        top: e.translation[1],
        bottom: e.translation[1] + s._labelSizes.heights[i],
        label: e.label,
        item: e,
        index: i,
      } as LabelHitbox;
    }),
  }));

const hasActiveZones = (scales: { [key: string]: Scale }, evt: any) => {
  const x = evt.x || 0;
  const y = evt.y || 0;

  const { x: xScale, y: yScale } = scales;

  const insideXScale = xScale.right < x || xScale.left > x || xScale.bottom < y || xScale.top > y;
  const insideYScale = yScale.right < x || yScale.left > x || yScale.bottom < y || yScale.top > y;

  const isXScale = !insideXScale && insideYScale;
  const isYScale = !insideYScale && insideXScale;

  return isXScale || isYScale;
};

export const ClickOnTickPlugin: Plugin<'bar'> = {
  id: 'clickOnTick',

  afterEvent(chartInstance, chartEvent) {
    const evt = chartEvent.event;
    const plugin = chartInstance?.config?.options?.plugins?.clickOnTick;
    const x = plugin?.x;
    const y = plugin?.y;

    const isActive = hasActiveZones(chartInstance.scales, evt);

    if (isActive) {
      if (Object.keys(changedPoints).length) {
        Object.values(changedPoints).forEach((point) => {
          if (point) {
            point.item.color = '#000';
          }
        });

        chartInstance.canvas.style.cursor = 'default';
        changedPoints = {};
        chartEvent.changed = true;
      }

      const { found, labelInfo } = findLabel(getLabelHitboxes(chartInstance.scales), evt) || {};

      if (found && labelInfo) {
        const datasets = chartInstance?.config?.data?.datasets || [];
        const xShouldHover = x?.shouldHover || (() => true);
        const yShouldHover = y?.shouldHover || (() => true);

        const shouldHover =
          (x?.enabled && labelInfo.scaleId === 'x' && xShouldHover(labelInfo)) ||
          (y?.enabled && labelInfo.scaleId === 'y' && yShouldHover(labelInfo));

        if (shouldHover) {
          chartInstance.canvas.style.cursor = 'pointer';
          labelInfo.item.color = '#1422BD';
          changedPoints[labelInfo.label] = labelInfo;
          chartEvent.changed = true;
        }

        if (evt.type === 'click') {
          if (x?.enabled && x?.onClick && labelInfo.scaleId === 'x') {
            x.onClick(labelInfo, chartInstance, datasets);
          }
          if (y?.enabled && y?.onClick && labelInfo.scaleId === 'y') {
            y.onClick(labelInfo, chartInstance, datasets);
          }
        }

        if (evt.type === 'mousemove') {
          if (x?.enabled && x?.onMouseEnter && labelInfo.scaleId === 'x') {
            x.onMouseEnter(labelInfo, chartInstance, datasets);
          }
          if (y?.enabled && y?.onMouseEnter && labelInfo.scaleId === 'y') {
            y.onMouseEnter(labelInfo, chartInstance, datasets);
          }
        }
      } else {
        if (x?.enabled && x?.onMouseLeave) {
          x.onMouseLeave();
        }
        if (y?.enabled && y?.onMouseLeave) {
          y.onMouseLeave();
        }
      }
    } else {
      if (x?.enabled && x?.onMouseLeave) {
        x.onMouseLeave();
      }
      if (y?.enabled && y?.onMouseLeave) {
        y.onMouseLeave();
      }
    }
  },
};
