import './DateRangePicker.scss';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-menu.svg';
import { FC, useCallback, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { humanDate } from '../../utilities/dateTime';
import {
  changeVisibility,
  handleClickOutside,
  renderDPContainer,
  renderJqDatePicker,
  format,
} from './hooks';
import { FilterOverlay } from '../FilterOverlay';
import { LSStorage } from './../../hooks/useCalendarFilter';

type DateRangePickerProps = {
  onCancel?: () => void;
  onApply: (dates: [string, string]) => void;
  dates: [string, string];
  selectType?: string;
  maxDays?: number;
  minDays?: number;
  loading?: boolean;
  yearsRange?: number;
  minDate?: string | Date;
  maxDate?: string | Date;
  startOfWeek?: 'monday' | 'sunday';
};

export const DateRangePicker: FC<DateRangePickerProps> = ({
  dates,
  loading,
  onApply,
  onCancel,
  minDays,
  maxDays,
  minDate,
  maxDate,
  yearsRange,
  startOfWeek,
  selectType = 'custom',
}) => {
  const pickerRef = useRef<any>(null);
  const pickerButtonRef = useRef<any>(null);
  const [isOpen, setOpen] = useState(false);
  const [currentDates, setCurrentDates] = useState(dates);
  const from = LSStorage.getParsed('from');
  const to = LSStorage.getParsed('to');

  const calendarStartDateHandler = useCallback(
    (date: string, silent?: boolean) => {
      pickerRef.current?.datePicker?.setStart?.(date, silent);
    },
    [pickerRef, isOpen],
  );

  const calendarEndDateHandler = useCallback(
    (date: string, silent?: boolean) => {
      pickerRef.current?.datePicker?.setEnd?.(date, silent);
    },
    [pickerRef, isOpen],
  );
  const applyHandler = useCallback(([startDate, endDate]: [string, string]) => {
    onApply([startDate, endDate]);
    setOpen(false);
  }, []);

  const cancelHandler = useCallback(() => {
    setOpen(false);

    calendarStartDateHandler(dates[0]);
    calendarEndDateHandler(dates[1]);
    onCancel?.();
  }, [dates]);

  useEffect(() => {
    return handleClickOutside(() => {
      setOpen(false);
      calendarStartDateHandler(dates[0]);
      calendarEndDateHandler(dates[1]);
    }, pickerButtonRef.current);
  }, [pickerButtonRef, isOpen, dates]);

  useEffect(() => {
    (async function () {
      await renderDPContainer(pickerButtonRef.current);

      pickerRef.current = await renderJqDatePicker({
        onSetStart: (s) => setCurrentDates([s, currentDates[1]]),
        onSetEnd: (e) => setCurrentDates([currentDates[0], e]),
        onApply: applyHandler,
        onCancel: cancelHandler,
        maxDate,
        minDate,
        maxDays,
        minDays,
        yearsRange,
        startOfWeek,
      });

      setCurrentDates(dates);
      calendarStartDateHandler(dates[0], true);
      calendarEndDateHandler(dates[1], true);
    })();

    return () => {
      pickerRef.current?.onDestroy?.();
      pickerRef.current.datePicker.destroy?.();
    };
  }, []);

  useEffect(() => {
    if (!pickerRef.current?.datePicker) return;
    const [startDate, endDate] = dates;
    if (startDate) {
      calendarStartDateHandler(startDate, true);
    }
    if (endDate) {
      calendarEndDateHandler(endDate, true);
    }
  }, [pickerRef, dates]);

  useEffect(() => {
    if (pickerButtonRef.current && pickerRef.current?.datePicker) {
      renderDPContainer(pickerButtonRef.current);
    }
  }, [pickerButtonRef, pickerRef, dates]);

  useEffect(() => {
    changeVisibility(isOpen);
  }, [isOpen]);

  const shouldShowDates = dates[0] && selectType === 'custom';

  const label = shouldShowDates
    ? `${humanDate(dates[0])} - ${humanDate(dates[1] || '')}`
    : 'Select dates';

  if (loading) {
    return (
      <div>
        <div
          className={clsx('flex h-[3.2rem] content-center rounded-[0.8rem]', {
            'bg-white text-black opacity-70 hover:bg-perano hover:bg-opacity-20':
              selectType !== 'custom',
            'bg-black text-white': selectType === 'custom',
          })}
        >
          <div className="min-w-[13.2rem] justify-center flex font-bold font-lato text-1214 pt-[3px]">
            <div className="pl-3 pr-4">
              <CalendarIcon
                className={clsx({
                  'fill-white': selectType === 'custom',
                  'fill-black': selectType !== 'custom',
                })}
              />
            </div>
            <div className="py-2 pr-6 w-full">
              {humanDate(from)} - {humanDate(to)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {isOpen && <FilterOverlay />}
      <div
        onClick={() => setOpen(true)}
        ref={pickerButtonRef}
        className={clsx('cursor-pointer flex h-[3.2rem] content-center rounded-[0.8rem]', {
          'bg-white text-black opacity-70 hover:bg-perano hover:bg-opacity-20':
            !isOpen && selectType !== 'custom',
          'bg-black text-white': !isOpen && selectType === 'custom',
          'bg-persianBlue text-white': isOpen,
        })}
      >
        <div className="min-w-[13.2rem] justify-center flex font-bold font-lato text-1214 pt-[3px]">
          <div className="pl-3 pr-4">
            <CalendarIcon
              className={clsx({
                'fill-white': selectType === 'custom' || isOpen,
                'fill-black': !isOpen && selectType !== 'custom',
              })}
            />
          </div>
          <div className="py-2 pr-6 w-full">{label}</div>
        </div>
      </div>
    </div>
  );
};

export const DateRangeSkeleton = ({ selectType }: { selectType?: string }) => {
  const shouldShowDates = selectType === 'custom';

  const label = shouldShowDates
    ? `${humanDate('2019-01-01')} - ${humanDate('2023-01-01')}`
    : 'Select dates';
  return (
    <div className="cursor-pointer opacity-70 flex h-[3.2rem] content-center rounded-[0.8rem] bg-black text-white">
      <div className="min-w-[13.2rem] justify-center flex font-bold font-lato text-1214 pt-[3px]">
        <div className="pl-3 pr-4">
          <CalendarIcon
            className={clsx({
              'fill-white': selectType === 'custom',
              'fill-black': selectType !== 'custom',
            })}
          />
        </div>
        <div className="py-2 pr-6 w-full">{label}</div>
      </div>
    </div>
  );
};
