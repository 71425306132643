import { FC } from 'react';
import { ReactComponent as OptionsIcon } from '../../assets/options.svg';

type FiltersPanelHeaderProps = {
  onResetFilters: () => void;
};

export const FiltersPanelHeader: FC<FiltersPanelHeaderProps> = ({ onResetFilters }) => {
  return (
    <div className="flex items-center mt-1">
      <div>
        <OptionsIcon />
      </div>
      <div className="text-text-black text-1820 font-bold ml-4">Filters</div>
      <button
        onClick={onResetFilters}
        className="text-text-black text-1014 font-bold ml-auto mr-16 cursor-pointer hover:text-persianBlue"
      >
        Clear All
      </button>
    </div>
  );
};
