/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
import underSvg from '../../assets/under.svg';

// eslint-disable-next-line no-shadow
export enum ButtonTypes {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SUBMIT = 'submit',
}

export interface ButtonProps {
  $type: ButtonTypes;
  $width?: string;
  $height?: string;
}

export const Under = () => <img src={underSvg} alt="under" />;
