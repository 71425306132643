import { startOfMonth, endOfMonth, addDays, subDays, isAfter } from 'date-fns';

import { convertDateToString, convertStringToDate } from '../../utilities/dateTime';

import type { IBubbleEvent } from '../../components/BubbleCalendar';
import { useQueryCalendarMonthResponse } from '../../rest/query/useQueryCalendarMonth';

export const convertDataToEvents = (
  cases: useQueryCalendarMonthResponse['data'],
  applySmartSizing: boolean,
): IBubbleEvent[] | null => {
  const events = Object.keys(cases || {}).map((date) => {
    const dataForDay = cases[date];
    const res: IBubbleEvent = {
      count: dataForDay.count,
      end: convertStringToDate(date),
      start: convertStringToDate(date),
      cases: {
        Lap: dataForDay.modalities.some((c) => c === 'Lap'),
        Open: dataForDay.modalities.some((c) => c === 'Open'),
        Robotic: dataForDay.modalities.some((c) => c === 'Robotic'),
      },
    };

    return res;
  });

  return applySmartSizing ? performSmartSizing(events) : events;
};

const divideAsPercent = (enumerator: number, denominator: number) => {
  return ((enumerator ?? 0) * 100) / denominator;
};

const performSmartSizing = (events: IBubbleEvent[] | null): IBubbleEvent[] | null => {
  if (!events) return events;

  const maxAmount = Math.max(...events.map((e) => e.count ?? 0));
  if (!maxAmount) return events;

  return events.map((e) => ({ ...e, size: divideAsPercent(e.count ?? 0, maxAmount) }));
};

export const getBounds = (date: Date) => {
  return {
    from: convertDateToString(subDays(startOfMonth(date), 7)),
    to: convertDateToString(addDays(endOfMonth(date), 7)),
  };
};

export const getLastDataMonth = (data: IBubbleEvent[] | null) => {
  const today = convertDateToString(new Date());
  if (!data) return today;
  let maxDate: Date | undefined = undefined;
  data.forEach((item) => {
    if (!maxDate) {
      maxDate = item.start;
    } else if (isAfter(new Date(item.start || ''), new Date(maxDate || ''))) {
      maxDate = item.start;
    }
  });
  return maxDate ? convertDateToString(maxDate) : today;
};
