import { useMemo } from 'react';

import { convertStringToDate } from '../../utilities/dateTime';
import { convertDataToEvents } from './convertDataToEvents';
import type { CalendarProps } from './types';
import { useQueryCase } from '../../rest/query/useQueryCase';

export const useCaseCalendar = (caseId: string): CalendarProps => {
  const { data, loading, meta } = useQueryCase(caseId);

  const _events = useMemo(() => convertDataToEvents(data), [data]);

  const { start, end, events } = useMemo(() => {
    const sorted = [..._events].sort(
      (a, b) => (a as any)?.start.getTime() - (b as any)?.start.getTime(),
    );
    const start = sorted[0]?.start ?? null;
    const end = sorted[sorted.length - 1]?.end ?? null;

    return { start, end, events: sorted };
  }, [_events]);

  return {
    events,
    loading,
    meta,
    case: data,
    start,
    end,
    date: convertStringToDate(data.main?.[0].start_time ?? ''),
  };
};
