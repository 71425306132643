import { ModalityType } from '../../types';
import './style.scss';

export const getOperationTypeClassName = (type?: ModalityType, loading?: boolean): string => {
  if (loading) return 'loading-room-event pointer-events-none';

  switch (type) {
    case 'Lap':
      return 'lapCase';
    case 'Open':
      return 'openCase';
    case 'Robotic':
      return 'roboticCase';
    default:
      return 'roboticCase';
  }
};
