import { FC, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import FullCalendar from '@fullcalendar/react';
import { CalendarProps } from 'react-big-calendar';
import { ResourceInput } from '@fullcalendar/resource-common';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import format from 'date-fns/format';
import subMinutes from 'date-fns/subMinutes';
import addMinutes from 'date-fns/addMinutes';

import { getBounds } from './getBounds';
import { CaseEvent } from './CaseEvent';
import { generateDummyData } from './generateDummyData';
import { getStageClassName, getResourceClassName, getResourceColor } from './getStageClassName';
import type { ICaseEvent } from './types';

import './style.scss';
import { calculateZoom } from './zoom';

type CaseTimelineProps = Pick<CalendarProps, 'onNavigate'> & {
  date?: Date;
  loading?: boolean;
  zoom: number;
  onZoomChange: (fn: (z: number) => number) => void;
  events: ICaseEvent[];
};

const caseResources: ResourceInput[] = [
  {
    id: 'turnover',
    title: 'Turnover',
    extendedProps: {},
    order: 1,
  },
  {
    id: 'main',
    title: 'Main',
    order: 2,
  },
  {
    id: 'patient-prep',
    title: 'Patient-Prep',
    order: 3,
  },
  {
    id: 'robotic',
    title: 'Robotic',
    order: 4,
  },
];

const CaseTimeline: FC<CaseTimelineProps> = ({ events, date, zoom, onZoomChange, loading }) => {
  const timelineRef = useRef<HTMLDivElement | null>(null);

  const caseEvents = useMemo(() => {
    if (loading) {
      return generateDummyData(date ?? new Date()).map((e, i) => ({
        ...e,
        number: i + 1,
        borderColor: '#fff0',
        resourceId: e.stage,
      }));
    }

    return events.map((e, i) => ({
      ...e,
      number: i + 1,
      classNames: [getStageClassName(e.stage)],
      borderColor: '#fff0',
      resourceId: e.stage,
    }));
  }, [events, date, loading]);

  const { from, to } = getBounds(caseEvents, date);

  useEffect(() => {
    const func = (e: WheelEvent) => {
      e.stopPropagation();

      if (!e.ctrlKey) {
        return;
      }
      e.preventDefault();

      onZoomChange(calculateZoom(e.deltaY < 0 ? 'in' : 'out'));
    };
    timelineRef?.current?.addEventListener('wheel', func);

    return () => timelineRef?.current?.removeEventListener('wheel', func);
  }, [zoom]);

  return (
    <div ref={timelineRef} className={clsx('caseTimeline')}>
      <FullCalendar
        height="parent"
        key={`${loading}`}
        headerToolbar={{
          center: '',
          start: '',
          end: '',
        }}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
          hour12: false,
        }}
        resourceOrder="order"
        slotMinTime={format(subMinutes(from, 10), 'HH:mm:ss')}
        slotMaxTime={format(addMinutes(to, 10), 'HH:mm:ss')}
        slotLabelInterval={{ minute: zoom }}
        slotMinWidth={150}
        contentHeight="auto"
        plugins={[resourceTimelinePlugin]}
        initialDate={date}
        resourceLabelContent={(props: any) => {
          return (
            <div className="inline-flex items-center justify-center gap-x-3">
              <div
                className={clsx(
                  'rounded-full w-6 h-6 border border-solid border-grey-border',
                  getResourceColor(props.fieldValue),
                )}
              />
              <div className="text-1214 font-extrabold uppercase inline-flex items-center justify-center">
                {props.fieldValue}
              </div>
            </div>
          );
        }}
        eventContent={(ctx) => {
          return <CaseEvent zoom={zoom} event={ctx.event as ICaseEvent} loading={loading} />;
        }}
        initialView="resourceTimeline"
        eventMinWidth={20}
        resourceAreaWidth="15%"
        resourceLabelClassNames={getResourceClassName()}
        resourceAreaColumns={[
          {
            field: 'title',
            headerContent: '',
            width: 10,
          },
        ]}
        events={caseEvents}
        resources={caseResources}
      />
    </div>
  );
};

export default CaseTimeline;
export type { ICaseEvent };
