import { RoleEnum } from '../zeus';

export const userRoleList = {
  [RoleEnum.org_admin]: {
    label: 'Admin',
    value: 'org_admin',
  },
  [RoleEnum.surgical_chair]: {
    label: 'Surgical Chair',
    value: 'surgical_chair',
  },
  [RoleEnum.surgeon]: {
    label: 'Surgeon',
    value: 'surgeon',
  },
  [RoleEnum.superadmin]: {
    label: 'Super Admin',
    value: 'superadmin',
  },
};
