const zoomScale = [60, 30, 10, 5, 4, 3, 2];

export const zoomMin = zoomScale[zoomScale.length - 1];
export const zoomMax = zoomScale[0];

export const calculateZoom = (direction: 'in' | 'out') => (zoom: number) => {
  const currentIndex = zoomScale.indexOf(zoom);
  if (direction === 'in') {
    const val = zoomScale[currentIndex + 1] || zoomMin;
    if (val >= zoomMin) {
      return val;
    }
    return zoomMin;
  }
  if (direction === 'out') {
    const val = zoomScale[currentIndex - 1] || zoomMax;
    if (val <= zoomMax) {
      return val;
    }
    return zoomMax;
  }
  return zoom;
};
