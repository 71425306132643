import { useCallback, useEffect, useState } from 'react';
import { ValueTypes } from '../zeus';

import { useDebounce } from './useDebounce';
import { useQuery } from './useQuery';

const DEBOUNCE_DELAY = 250;

export function usePaginationItems<Z extends ValueTypes[O], O extends 'Query'>({
  isNoCache = false,
  query,
  entity,
  operationName,
  perPage,
}: {
  isNoCache?: boolean;
  operationName?: string;
  entity: keyof ValueTypes[O];
  query: Z | ValueTypes[O];
  perPage?: number;
}) {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const debouncedUserInput = useDebounce(searchQuery, DEBOUNCE_DELAY);
  const { data, loading, fetchMore, refetch } = useQuery(
    query,
    {
      operationName,
    },
    {
      fetchPolicy: isNoCache ? 'no-cache' : 'cache-first',
      variables: {
        search: debouncedUserInput,
        pagination: {
          page: 1,
          perPage,
        },
      },
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res = data?.[entity] as any;
  const pagination = res?.pagination;
  const items = res?.nodes || [];

  const handleOnSearch = useCallback((search: string) => {
    setSearchQuery(search);
  }, []);

  const handleOnScroll = useCallback(
    (variables?: any) => {
      fetchMore({
        variables: variables || {
          search: searchQuery,
          pagination: {
            page: pagination?.nextPage,
            perPage: pagination?.perPage,
          },
        },
      });
    },
    [pagination],
  );

  useEffect(() => {
    refetch({
      search: debouncedUserInput,
      pagination: {
        page: 1,
      },
    });
  }, [debouncedUserInput]);

  const refetchItems = async (variables?: any) => {
    const resp = await refetch(variables);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res = resp?.data?.[entity] as any;
    const newPagination = res?.pagination;
    const items = res?.nodes || [];

    return { items, pagination: newPagination, loading: resp?.loading };
  };

  return {
    loading,
    searchQuery,
    items,
    pagination,
    setSearchQuery,
    refetchItems,
    onScroll: handleOnScroll,
    onSearch: handleOnSearch,
  };
}
