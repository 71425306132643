import clsx from 'clsx';

type StatusProps = {
  status?: 'active' | 'inactive';
};

const classNames: { [x: string]: string } = {
  active: 'bg-activeBg text-activeText',
  inactive: 'bg-inactiveBg text-inactiveText',
};

export const Status: React.FC<StatusProps> = ({ status }) => {
  return (
    <span
      className={clsx(
        'rounded-3xl px-5 py-1 uppercase text-1012 font-bold',
        status ? classNames[status] : '',
      )}
    >
      {status}
    </span>
  );
};
