import { FC } from 'react';
import { Selector } from '../Selector';
import { Option, Value } from '../Selector/types';

type PhaseSelectboxProps = {
  options: Option[];
  phase: string;
  onSelectPhase: (o: GenesisKeys) => void;
};
export const PhaseSelectbox: FC<PhaseSelectboxProps> = ({ options, phase, onSelectPhase }) => {
  return (
    <div className="mr-4">
      <div className="font-bold font-lato text-1214 ml-2 mb-4">Non-Operative Metric</div>
      <Selector
        onSelect={(v) => onSelectPhase(v.value as GenesisKeys)}
        options={options}
        notFoundLabel="Phase"
        pickedData={phase}
      />
    </div>
  );
};
