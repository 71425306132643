import { Plugin } from 'chart.js';

export const DoughnutCenterPlugin: Plugin<'doughnut'> = {
  id: 'doughnutCenter',
  beforeDatasetDraw(chartInstance) {
    const { ctx } = chartInstance;

    ctx.save();
    const options = chartInstance?.config?.options?.plugins?.doughnutCenter;
    const x = chartInstance.getDatasetMeta(0).data[0].x;
    const y = chartInstance.getDatasetMeta(0).data[0].y;

    if (options?.text) {
      ctx.font = options.font || 'bold 32px Lato';
      ctx.fillStyle = options?.fontStyle || '#1C1C1F';
      ctx.textAlign = 'center';
      ctx.fillText(options?.text, x, y + 10);
      ctx.save();
    }
  },
};
