import { compareOptions } from '../MultiFilter/mocks';
import * as XLSX from 'xlsx';
import { CompareValues } from '../MultiFilter/MultiFilters.types';

import { ConfigType, Table } from '../Table';
import { convertDatetimeToString, getHumanTimeFromSeconds } from '../../utilities/dateTime';
import { generateArray, getEntityName } from '../../utilities/helpers';

type GenesisTableProps<K extends string> = {
  tableId: string;
  unit?: string;
  showSize?: boolean;
  showMin?: boolean;
  showMax?: boolean;
  data?: AnalyticsGenericDataFormat<K>[];
  transformKeys?: (val: K, significant: boolean | null) => string;
  allFilters: AllFilters;
  loading: boolean;
  compareBy: CompareValues;
};

const LoadingPlaceholder: AnalyticsGenericDataFormat<any>[] = generateArray(3).map((_, i) => ({
  context: [
    {
      name: String(i) as any,
      averageDuration: i,
      activitiesAmount: i,
      standardDeviation: i,
      radius: i,
      yLimits: [i, i],
      xLimits: [i, i],
      dataPoints: [],
      FStatistic: i,
      pValue: i,
      significant: false,
      raw: [] as any,
    },
  ],
  name: String(i),
}));

const showSeconds = (seconds?: number) => {
  if (seconds === undefined || seconds === null) return 'N/A';

  return getHumanTimeFromSeconds(Number(seconds) * 60, true);
};

type LabelProps = {
  amount?: number;
  sds?: number;
  showSize?: boolean;
  size?: number;
  min?: number;
  max?: number;
  unit?: string;
};

function to2Dig(num: number | string) {
  return Number(num).toFixed(1);
}

const showTimeLabel = ({ amount, sds, size, showSize, min, max }: LabelProps) => {
  if (amount === undefined || amount === null)
    return <div className="text-[1.6rem] font-bold">N/A</div>;
  return (
    <div className="">
      <div className="text-[1.6rem] font-bold">
        {`${showSeconds(amount)}${
          sds === undefined || sds === null ? '' : ' ± ' + showSeconds(Number(sds))
        }`}
      </div>
      {showSize && <div className="text-1417">Sample size: {size}</div>}
      {min !== undefined && <div className="text-1417">Minimum: {showSeconds(min)}</div>}
      {max !== undefined && <div className="text-1417">Maximum: {showSeconds(max)}</div>}
    </div>
  );
};
const showLabel = ({ amount, sds, size, showSize, min, max, unit }: LabelProps) => {
  if (amount === undefined || amount === null)
    return <div className="text-[1.6rem] font-bold">N/A</div>;

  return (
    <div className="">
      <div className="text-[1.6rem] font-bold">
        {`${to2Dig(amount)}${sds === undefined || sds === null ? '' : ' ± ' + to2Dig(Number(sds))}`}
      </div>
      {showSize && <div className="text-1417">Sample size: {size}</div>}
      {min !== undefined && <div className="text-1417">Minimum: {to2Dig(min)}</div>}
      {max !== undefined && <div className="text-1417">Maximum: {to2Dig(max)}</div>}
    </div>
  );
};

export function generateXLSX<K extends string>({
  sourceData,
  allFilters,
  compareBy,
  tableId,
  shouldHaveMinMax,
  labels,
}: {
  sourceData: AnalyticsGenericDataFormat<K>[];
  allFilters: AllFilters;
  compareBy: CompareValues;
  tableId: string;
  shouldHaveMinMax?: boolean;
  labels: { [key in K]: string };
}) {
  const groupByHeader =
    compareOptions.find((option) => option.value === compareBy)?.label || 'COMPARE';

  const headers = (sourceData?.[0]?.context || []).map((gen) => {
    return (
      labels[gen.name]?.toUpperCase() +
      (gen.significant !== null ? ` (${gen.significant ? 'SIG' : 'INSIG'})` : '')
    );
  });

  if (compareBy) {
    headers.unshift(groupByHeader !== 'None' ? groupByHeader?.toUpperCase() : 'COMPARE');
  }

  const makeData = (
    val: 'averageDuration' | 'standardDeviation' | 'activitiesAmount' | 'min' | 'max',
  ) =>
    sourceData.map((item) => {
      const vals = item.context.map((gen) => {
        return gen[val] + '';
      });

      if (compareBy) {
        vals.unshift(getEntityName({ value: item.name, allFilters, compareBy }));
      }

      return vals;
    });

  const meanData = [headers, ...makeData('averageDuration')];
  const stdData = [headers, ...makeData('standardDeviation')];
  const sampleSizeData = [headers, ...makeData('activitiesAmount')];
  const minValueData = [headers, ...makeData('min')];
  const maxValueData = [headers, ...makeData('max')];

  const meanWs = XLSX.utils.aoa_to_sheet(meanData);
  const stdWS = XLSX.utils.aoa_to_sheet(stdData);
  const sampleSizeWS = XLSX.utils.aoa_to_sheet(sampleSizeData);
  const minValueWS = XLSX.utils.aoa_to_sheet(minValueData);
  const maxValueWS = XLSX.utils.aoa_to_sheet(maxValueData);

  /* Create workbook */
  const excelFile = XLSX.utils.book_new();

  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(excelFile, meanWs, 'Mean');
  XLSX.utils.book_append_sheet(excelFile, stdWS, `Standard Deviation`);
  XLSX.utils.book_append_sheet(excelFile, sampleSizeWS, `Sample Size`);

  if (shouldHaveMinMax) {
    XLSX.utils.book_append_sheet(excelFile, minValueWS, `Min`);
  }
  if (shouldHaveMinMax) {
    XLSX.utils.book_append_sheet(excelFile, maxValueWS, `Max`);
  }

  XLSX.writeFile(
    excelFile,
    `${tableId}--by-${compareBy}${convertDatetimeToString(new Date())}.xlsx`,
  );
}

export function GenesisTable<K extends string>({
  unit,
  loading,
  allFilters,
  showSize,
  showMin,
  showMax,
  tableId,
  transformKeys = (val: K) => val,
  data: initialData,
  compareBy,
}: GenesisTableProps<K>) {
  const hasData = !!initialData?.length;

  const groupByHeader =
    compareOptions.find((option) => option.value === compareBy)?.label || 'Compare';

  if (!hasData && !loading) {
    return (
      <div className="p-10 flex justify-start uppercase text-silver text-[1.5rem] md:text-[2rem] self-center md:self-start">
        NO DATA
      </div>
    );
  }

  const columns: ConfigType<AnalyticsGenericDataFormat<K>>['columns'] = (
    initialData?.[0]?.context || []
  ).map((gen) => {
    return {
      header: () => transformKeys(gen.name, gen.significant),
      getValue: (el: AnalyticsGenericDataFormat<K>) => {
        const item = el.context.find((r) => r.name === gen.name);

        const label = unit
          ? showLabel({
              amount: item?.averageDuration,
              sds: item?.standardDeviation,
              size: item?.activitiesAmount,
              showSize,
              min: showMin ? item?.min : undefined,
              max: showMax ? item?.max : undefined,
              unit,
            })
          : showTimeLabel({
              showSize,
              amount: item?.averageDuration,
              sds: item?.standardDeviation,
              size: item?.activitiesAmount,
              min: showMin ? item?.min : undefined,
              max: showMax ? item?.max : undefined,
            });

        return label;
      },
    };
  });

  if (compareBy) {
    columns.unshift({
      header: () => (groupByHeader !== 'None' ? groupByHeader : 'Compare'),
      headerClassName: 'sticky left-0',
      cellClassName: 'sticky left-0 font-bold',
      getValue: (el: AnalyticsGenericDataFormat<K>) => {
        return (
          <div className="max-w-[40rem]">
            {getEntityName({ value: el.name, allFilters, compareBy })}
          </div>
        );
      },
    });
  }
  return (
    <Table<AnalyticsGenericDataFormat<K>>
      id={tableId}
      alignToRight
      withBorder={false}
      withRounded={false}
      config={{ columns }}
      loading={loading}
      data={loading ? LoadingPlaceholder : initialData || []}
    />
  );
}
