import { Skeleton } from 'antd';
import { FC } from 'react';
import { Container, Wrapper } from './styles';

type ChartSkeletonProps = {
  id?: string;
  title: JSX.Element | string;
  footer?: JSX.Element | string;
  loading?: boolean;
};

export const ChartLoading = () => {
  return (
    <div className="relative">
      <Wrapper>
        <div className="relative">
          <Skeleton.Input active />
        </div>
        <Container>
          <Skeleton active />
        </Container>
      </Wrapper>
    </div>
  );
};

export const ChartSkeleton: FC<ChartSkeletonProps> = ({ title, children, footer, loading, id }) => {
  return (
    <div className="relative w-full">
      <Wrapper>
        <div className="relative">{title}</div>
        <div id={id} className="">
          <Container>{loading ? <Skeleton active /> : children}</Container>
          <div className="footer">{footer}</div>
        </div>
      </Wrapper>
    </div>
  );
};
