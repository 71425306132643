import { useState, useEffect } from 'react';

export const useDebouncedValue = <T>(value?: T): T | undefined => {
  const [debouncedValue, setDebouncedValue] = useState<T | undefined>(value);

  useEffect(() => {
    const thisValue = value;
    // make this configurable, id don't care right now
    const timedOut = setTimeout(() => setDebouncedValue(thisValue), 250);

    return () => {
      clearTimeout(timedOut);
    };
  }, [value]);

  return debouncedValue;
};
