import clsx from 'clsx';

export const FilterArrow: React.FC<{ open?: boolean; topClass?: string; disabled?: boolean }> = ({
  open,
  disabled,
  topClass = 'top-[1rem] md:top-[1.4rem]',
}) => {
  return (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(
        topClass,
        open ? 'rotate-180' : '',
        'arrow absolute right-[0.8rem] md:right-[1rem] lg:right-[1.6rem]',
        {
          'cursor-pointer': !disabled,
          'cursor-default': disabled,
        },
      )}
    >
      <path
        d="M1.04927 0.5L4.04927 3.5L6.95117 0.598365"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
