import { forwardRef } from 'react';

import { IconCalendar } from './IconCalendar';
import { FilterContainer } from '../FilterContainer';

type TriggerProps = React.HTMLProps<HTMLImageElement> & {
  label: string;
  open?: boolean;
  active?: boolean;
};

// eslint-disable-next-line
const Trigger = forwardRef<HTMLImageElement, TriggerProps>(
  // eslint-disable-next-line
  ({ open, active, onClick, label = 'Calendar' }, ref) => {
    const labelElement = (
      <div className="inline-flex flex-row flex-grow justify-between">
        <IconCalendar /> <span className="ml-1">{label}</span>
      </div>
    );

    return (
      <FilterContainer
        ref={ref}
        onClick={onClick}
        open={open}
        active={active}
        withArrow={false}
        label={labelElement}
      />
    );
  },
);

export default Trigger;
