import { Chart, ChartEvent, ChartOptions, ClickOnTickLabelClick } from 'chart.js';
import {
  commonOptions,
  compLabels,
  displayDataLabels,
  displayScaleInMinutes,
} from '../../utilities/commonOptions';
import { cloneDeep, merge } from 'lodash';
import { DataSet } from './types';
import { barChartOptions } from '../../utilities/chartUtils';
import { makeTooltipStyles } from '../ChartTooltip/ChartTooltip';
import { CompareValues } from '../MultiFilter/MultiFilters.types';
import { getEntityName } from '../../utilities/helpers';

export const makeBarOptions = ({
  chart,
  compareBy,
  tooltipRef,
  allFilters,
  unit = 'minutes',
  showDataLabels,
  extendOptions,
}: {
  chart: { data: DataSet[] };
  onDataPointClick?: (point: ChartDataPoint, chart: Chart, evt: ChartEvent) => void;
  compareBy: CompareValues;
  tooltipRef: React.MutableRefObject<HTMLDivElement>;
  allFilters: AllFilters;
  showDataLabels?: boolean;
  unit?: string;
  extendOptions?: ChartOptions<'bar'>;
}) => {
  const l = chart.data.map((d) => d.data).reduce((a, b) => b.concat(a), []);
  const maxValue = Math.max(...l);

  const options = cloneDeep({ ...barChartOptions, ...commonOptions });

  return merge(
    merge(
      options,
      {
        scales: {
          y: {
            ticks: {
              stepSize: maxValue < 60 ? 15 : 30,
              callback: displayScaleInMinutes,
            },
            ...(maxValue ? { suggestedMax: maxValue + maxValue / 10 } : {}),
          },
          x: {
            ticks: {
              autoSkip: true,
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'nearest',
            callbacks: {
              label() {
                return '';
              },
              beforeBody(i) {
                const dataIndex = i[0].dataIndex;
                const datasetIndex = i[0].datasetIndex;
                // // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const general = (i[0].dataset as any)?.general;

                const l = Object.values(general)?.length || 0;

                const vIndex = datasetIndex % l;

                const set = Object.values(general)?.[vIndex] as any;

                const metricLabel = compLabels[compareBy as keyof typeof compLabels];

                const useDash = compareBy === 'national_statistics';

                return [
                  {
                    label: metricLabel,
                    value: /rest/i.test(set?.name)
                      ? 'Rest'
                      : getEntityName({
                          value: set?.name,
                          allFilters,
                          compareBy,
                        }),
                    isHead: true,
                  },
                  ...(set?.maxs.length > 0
                    ? [
                        {
                          label: 'Maximum',
                          value:
                            useDash && !set?.maxs?.[dataIndex]
                              ? '—'
                              : (set?.maxs?.[dataIndex] || 0).toFixed(1) + ' ' + unit,
                        },
                      ]
                    : []),
                  {
                    label: 'Mean',
                    value:
                      useDash && !set?.data?.[dataIndex]
                        ? '—'
                        : (set?.data?.[dataIndex] || 0).toFixed(1) + ' ' + unit,
                  },
                  ...(set?.mins.length > 0
                    ? [
                        {
                          label: 'Minimum',
                          value:
                            useDash && !set?.mins?.[dataIndex]
                              ? '—'
                              : (set?.mins?.[dataIndex] || 0).toFixed(1) + ' ' + unit,
                        },
                      ]
                    : []),
                  {
                    label: 'Standard Deviation',
                    value:
                      useDash && !set?.standardDeviation?.[dataIndex]
                        ? '—'
                        : (set?.standardDeviation?.[dataIndex] || 0).toFixed(1) + ' ' + unit,
                  },
                  {
                    label: 'Sample Size',
                    value:
                      useDash && set?.name !== 'this hospital' && !set?.datalabels?.[dataIndex]
                        ? '—'
                        : Math.floor(set?.datalabels?.[dataIndex] || 0) + ' cases',
                  },
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ] as any;
              },
            },
            enabled: false,
            external: makeTooltipStyles({
              tooltipRef,
              isCalendarIcon: false,
            }),
          },
          datalabels: {
            display: displayDataLabels(!!showDataLabels),
          },
        },
      } as ChartOptions<'bar'>,
      extendOptions,
    ),
  ) as ChartOptions<'bar'>;
};
