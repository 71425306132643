/* eslint-disable max-len */
import clsx from 'clsx';
import { FC } from 'react';

import { IconProps } from './types';

export const UserSvg: FC<IconProps> = ({ isActive }) => {
  // const lavander = clsx({ 'stroke-persianBlue opacity-50': isActive, 'stroke-white': !isActive });
  const blue = clsx({ 'fill-persianBlue': isActive, 'fill-white': !isActive });

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={blue}
        d="M9.88451 14.7673C11.3211 14.6441 12.7655 14.6441 14.202 14.7673C14.9876 14.82 15.7679 14.9347 16.5356 15.1105C18.1962 15.4448 19.2799 16.1048 19.7344 17.0726C20.0975 17.8768 20.0879 18.8016 19.7082 19.5979C19.245 20.5658 18.1612 21.2257 16.4744 21.5689C15.7093 21.7411 14.932 21.8529 14.1496 21.9032C13.2843 22 12.5502 22 11.8685 22H11.5975C11.2113 21.9581 10.9186 21.63 10.9186 21.2389C10.9186 20.8478 11.2113 20.5197 11.5975 20.4778L12.1955 20.4777C12.7949 20.4706 13.398 20.4426 14.001 20.3898C14.7124 20.3432 15.4194 20.2432 16.1161 20.0906C17.3134 19.8267 18.0651 19.4483 18.301 18.9468C18.4846 18.5631 18.4846 18.1162 18.301 17.7325C18.0651 17.2222 17.3134 16.8263 16.1423 16.5887C15.4348 16.4289 14.7159 16.3259 13.9923 16.2807C12.6403 16.1575 11.2801 16.1575 9.92821 16.2807C9.21388 16.3273 8.50399 16.4273 7.80441 16.5799C6.60704 16.8439 5.86414 17.2222 5.61943 17.7237C5.53272 17.9142 5.488 18.1213 5.48833 18.3309C5.48784 18.5432 5.53254 18.7533 5.61943 18.9468C6.03541 19.5188 6.66832 19.8915 7.36741 19.9763L7.46883 20.0038C7.69883 20.0837 7.87937 20.2709 7.9497 20.5099C8.03008 20.7829 7.95322 21.0784 7.7502 21.2767C7.54718 21.4751 7.25157 21.5435 6.98285 21.4545C5.85607 21.2695 4.86781 20.5934 4.28222 19.6067C3.90593 18.8076 3.90593 17.8806 4.28222 17.0814C4.74544 16.0872 5.82919 15.4448 7.49851 15.1017C8.28445 14.9305 9.08201 14.8188 9.88451 14.7673ZM9.96471 2.40721C11.9547 1.57833 14.2447 2.0384 15.7663 3.57275C17.2879 5.1071 17.7411 7.41333 16.9145 9.41539C16.0879 11.4174 14.1444 12.7207 11.9908 12.7172C9.05464 12.7123 6.67696 10.3147 6.67696 7.35867L6.6819 7.12723C6.77054 5.05238 8.04579 3.20648 9.96471 2.40721ZM11.9908 3.53114C10.981 3.52882 10.0117 3.93104 9.29685 4.64909C8.58197 5.36714 8.18021 6.34202 8.18021 7.35867C8.17669 8.91111 9.10286 10.3127 10.5265 10.9092C11.9501 11.5058 13.5906 11.1797 14.6822 10.0833C15.7739 8.98678 16.1015 7.33602 15.5122 5.90141C14.9229 4.4668 13.5329 3.53114 11.9908 3.53114Z"
        fill="#1422BD"
      />
    </svg>
  );
};
