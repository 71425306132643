import { FC } from 'react';

export const ClinicItemSkeleton: FC = () => {
  return (
    <div className="p-2">
      <div className="flex animate-pulse pl-1">
        <div className="w-[3.2rem] h-[3.2rem] bg-ghost rounded-main mr-[.8rem]"></div>
        <div className="flex flex-col justify-center">
          <div className="bg-ghost w-[13.4rem] h-[1.8rem] rounded-[.4rem]"></div>
        </div>
      </div>
    </div>
  );
};
