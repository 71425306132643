import { FC } from 'react';

import { ViewSwitch, ViewSwitchItem } from '../ViewSwitch';
import { ReactComponent as BubbleViewIcon } from '../../assets/bubbleChart.svg';
import { ReactComponent as ListViewIcon } from '../../assets/list-view.svg';
import { ReactComponent as BarChartIcon } from '../../assets/bar-chart.svg';
import { ReactComponent as PieChartIcon } from '../../assets/pie-chart.svg';
import { ChartViewName } from '../MultiFilter/MultiFilters.types';

export const chartViewOptions: ViewSwitchItem<ChartViewName>[] = [
  {
    Icon: BarChartIcon,
    label: 'Bar View',
    style: 'p-[0.7rem]',
    styleActive: 'fill-white',
    styleInactive: 'fill-black',
  },

  {
    Icon: BubbleViewIcon,
    label: 'Bubble View',
    style: 'p-[0.7rem]',
    styleActive: 'fill-white',
    styleInactive: 'fill-black',
  },
  {
    Icon: PieChartIcon,
    label: 'Pie View',
    style: 'p-[0.7rem]',
    styleActive: 'stroke-white',
    styleInactive: 'stroke-black',
  },
  {
    Icon: BarChartIcon,
    label: 'Horizontal Bar View',
    style: 'p-[0.7rem] rotate-90',
    styleActive: 'fill-white',
    styleInactive: 'fill-black',
  },
  {
    label: 'List View',
    Icon: ListViewIcon,
    style: 'p-[0.5rem]',
    styleActive: 'stroke-white fill-white',
    styleInactive: 'stroke-black fill-black',
  },
];

type ChartViewTabsProps = {
  enabledList?: typeof chartViewOptions[0]['label'][];
  disabledOptions?: typeof chartViewOptions[0]['label'][];
  className?: string;
  defaultItem?: ChartViewName;
  onChange: (name: ChartViewName) => void;
};

export const ChartViewTabs: FC<ChartViewTabsProps> = ({
  className,
  enabledList,
  defaultItem,
  disabledOptions,
  onChange,
}) => {
  const options = enabledList
    ? chartViewOptions.filter((item) => enabledList.includes(item.label))
    : chartViewOptions;

  return (
    <ViewSwitch
      defaultItem={defaultItem}
      items={options.map((item) => ({ ...item, disabled: disabledOptions?.includes(item.label) }))}
      onChange={onChange}
      className={className}
    />
  );
};
