import { FC } from 'react';
import { MultiFilterStateContextType } from '../MultiFilter/MultiFilters.types';

import { FiltersPanelSection, PanelFilter } from './FiltersPanelSection';
import { OptionsKeys } from './types';

export type FiltersPanelAllFiltersProps = {
  filtersList: Record<OptionsKeys, PanelFilter[]>;
  updateFilters: (name: keyof MultiFilterStateContextType) => (arr: PanelFilter[]) => void;
  onAdditionalFiltersChange: (arr: PanelFilter[]) => void;
  additionalFilters: PanelFilter[];
};

export const FiltersPanelAllFilters: FC<FiltersPanelAllFiltersProps> = ({
  filtersList,
  updateFilters,
  onAdditionalFiltersChange,
  additionalFilters,
}) => {
  return (
    <div className="overflow-y-auto -mr-8 pr-8">
      <div className="pt-8">
        <FiltersPanelSection
          title=""
          hideSelectAll
          onChange={(data) => onAdditionalFiltersChange(data)}
          filters={additionalFilters}
        />
      </div>
      <div className="border-t pt-8">
        <FiltersPanelSection
          title="Week Days"
          onChange={updateFilters('weekDay')}
          filters={filtersList.weekOptions}
        />
      </div>
      <div className="border-t pt-8">
        <FiltersPanelSection
          title="Rooms"
          onChange={updateFilters('rooms')}
          filters={filtersList.roomsOptions}
        />
      </div>

      <div className="border-t pt-8">
        <FiltersPanelSection
          title="Modalities"
          onChange={updateFilters('modalities')}
          filters={filtersList.modalitiesOptions}
        />
      </div>
      <div className="border-t pt-8">
        <FiltersPanelSection
          title="Case Numbers"
          onChange={updateFilters('cases')}
          filters={filtersList.casesOptions}
        />
      </div>
      <div className="border-t pt-8">
        <FiltersPanelSection
          title="Specialties"
          onChange={updateFilters('specialties')}
          filters={filtersList.specialtiesOptions}
        />
      </div>
      <div className="border-t pt-8">
        <FiltersPanelSection
          title="Surgeons"
          onChange={updateFilters('surgeons')}
          filters={filtersList.surgeonsOptions}
        />
      </div>
      <div className="border-t pt-8">
        <FiltersPanelSection
          title="Procedures"
          onChange={updateFilters('procedures')}
          filters={filtersList.proceduresOptions}
        />
      </div>
      <div className="border-t pt-8">
        <FiltersPanelSection
          title="Case Counts per Day"
          onChange={updateFilters('casesPerDay')}
          filters={filtersList.casesPerDayOptions}
        />
      </div>
      <div className="border-t pt-8">
        <FiltersPanelSection
          title="Same Surgeon Turnovers"
          radio
          onChange={updateFilters('sameSurgeon')}
          filters={filtersList.sameSurgeonOptions}
        />
      </div>
      <div className="border-t pt-8">
        <FiltersPanelSection
          title="All Robotic Days"
          radio
          onChange={updateFilters('allRoboticDay')}
          filters={filtersList.allRoboticDayOptions}
        />
      </div>
    </div>
  );
};
