export const HelpDescription = () => {
  return (
    <div className="w-[50rem] h-[50rem] overflow-auto">
      <h3 className="font-bold text-1820 font-lato mb-4">
        How are the nonoperative metrics defined?
      </h3>
      <div className="text-cornflowerBlue text-1620">
        Please source the definition for each metric from{' '}
        <span className="font-bold text-1419">nonop_metrics</span> table in SQL metadata file. For
        example:
      </div>
      <div className="italic ml-8 mb-8 mt-4">
        Case-open to patient-in is defined as the duration from start of sterile-prep activity to
        start of patient-in activity.
      </div>
      <h3 className="font-bold text-1820 font-lato mb-4">
        What is the difference between the options in Filters panel and Compare drop-down menu?
      </h3>
      <div className="text-cornflowerBlue text-1620 mb-6">
        Filters are used to restrict the statistics to desired cases. Compare drop-down menu
        however, is used to split cases according to the selected metadata. For example, if{' '}
        <span className="font-bold text-1419">Wed</span> is selected from{' '}
        <span className="font-bold text-1419">Week Day</span> filter and{' '}
        <span className="font-bold text-1419">OR</span> is selected from{' '}
        <span className="font-bold text-1419">Compare</span> drop-down menu, cases operated on
        Wednesday will be compared for all ORs.
      </div>
      <h3 className="font-bold text-1820 font-lato mb-4">
        How can I use the options in Suggested Views drop-down menu?
      </h3>
      <div className="text-cornflowerBlue text-1620 mb-6">
        The options in this menu are generated based on the analysis of metadata and provide easy
        access to investigate the most important questions without directly working with the filters
        and menus. For instance, the first option enables the user to compare top surgeons that have
        operated the most common procedures in the database, without having to manually find the top
        surgeons that share common procedures.
      </div>
      <h3 className="font-bold text-1820 font-lato mb-4">How are the outliers identified?</h3>
      <div className="text-cornflowerBlue text-1620 mb-6">
        Outliers are detected by clustering cases according to their nonoperative metrics. Cases
        that are far from centers of all clusters are categorized as outliers.
      </div>
      <h3 className="font-bold text-1820 font-lato mb-4">
        What does statistically significant mean?
      </h3>
      <div className="text-cornflowerBlue text-1620 mb-6">
        The difference between nonoperative metrics, when split based on a desired metadata from{' '}
        <span className="font-bold text-1419">Compare</span> drop-down menu, is statistically
        significant if they are significantly different. The underlying analysis takes into account
        the means, standard deviations, and sample sizes. For instance, when comparing nonoperative
        metrics for ORs, the means might look considerably different but if the standard deviations
        are high, the difference might be unreliable and insignificant.
      </div>
    </div>
  );
};
