import { FC } from 'react';
import { format } from 'date-fns';

import { routesPath } from '../../constants/routes';
import { PageTitle } from '../../components/PageTitle';
import type { CalendarProps } from './types';
import { useLocation } from 'react-router-dom';
import { useQueryCaseResponse } from '../../rest/query/useQueryCase';

type HeaderProps = {
  date?: Date;
  loading?: boolean;
  case: useQueryCaseResponse['meta'];
};

export const Header: FC<HeaderProps> = ({ case: caseData, date, loading }) => {
  const location = useLocation<{ name?: string }>();

  const name = location.state?.name;

  return (
    <PageTitle
      header={caseData?.surgery_name ?? 'Unknown Surgery'}
      crumbs={[
        {
          title: 'Calendar',
          url: routesPath.calendar.replace('/:refId', ''),
        },
        ...(date
          ? [
              {
                title: format(new Date(date!), 'EEEE, MMMM d, yyyy'),
                url: routesPath.dataCalendar.replace('/:refId', ''),
              },
            ]
          : []),
      ]}
    />
  );
};
