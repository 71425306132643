import clsx from 'clsx';

import { procedureColors } from '../../utilities/procedureColors';
import type { ModalityType } from '../../types';

type ProcedureTypeBadgeProps = {
  procedureType?: ModalityType;
};

export const ProcedureTypeBadge: React.FC<ProcedureTypeBadgeProps> = ({ procedureType }) => {
  return (
    <div
      className={clsx(
        'text-text-black text-1012 font-extrabold h-6 w-20 rounded-lg text-center mr-1 inline-flex items-center justify-center',
        procedureColors[procedureType!],
      )}
    >
      {procedureType}
    </div>
  );
};
