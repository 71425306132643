/* eslint-disable max-len */
import clsx from 'clsx';
export const DataPointsSvg = ({ classNames }: { classNames?: string[] }) => {
  return (
    <svg width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 13.0001C6 11.343 4.65691 10 2.99986 10C1.34302 10 0 11.3431 0 13.0001C0 14.657 1.34309 16 2.99986 16C4.65698 16 6 14.6569 6 13.0001Z"
        className={classNames?.[0]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 6C5 4.89559 4.10464 4 3 4C1.89559 4 1 4.89555 1 6C1 7.10459 1.89555 8 3 8C4.10459 8 5 7.10464 5 6Z"
        className={classNames?.[1]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0.999954C4 0.447672 3.5523 0 2.99995 0C2.44767 0 2 0.447695 2 0.999954C2 1.55221 2.4477 2 2.99995 2C3.55233 2 4 1.55221 4 0.999954Z"
        className={classNames?.[2]}
      />
    </svg>
  );
};
