import { FC } from 'react';
import clsx from 'clsx';
import { Skeleton } from 'antd';
import { format } from 'date-fns';
import { EventProps } from 'react-big-calendar';

import { procedureColors } from '../../utilities/procedureColors';

import type { IBubbleEvent } from './types';
import type { ModalityType } from '../../types';

const ProcedureTypeCircle: FC<{ type: ModalityType }> = ({ type }) => {
  return <div className={clsx('w-[0.6rem] h-[0.6rem] rounded-full', procedureColors[type])}></div>;
};

const BubbleEvent: FC<EventProps<IBubbleEvent>> = ({ event, ...rest }) => {
  const cases = event?.cases ?? {};
  const onClick = (rest as any).onClick;

  return (
    <div
      onClick={event.empty ? undefined : () => onClick?.(event)}
      className={clsx(
        'flex flex-col justify-center items-center w-full h-full relative',
        event.offRange ? 'rbc-off-range' : '',
        event.empty ? '' : 'cursor-pointer hover:bg-peranoHover',
      )}
    >
      <div className="rbc-date-cell show text-neutralBlack absolute right-0 top-0">
        {format(event.start ?? new Date(), 'd')}
      </div>
      {!event.empty && (
        <div className="flex flex-col gap-y-4 mt-4">
          <div className="bg-persianBlue rounded-full w-16 h-16 inline-flex items-center justify-center text-1820 font-semibold">
            {event?.count ?? 0}
          </div>
          <div className="flex gap-x-2 items-center justify-center">
            {!!cases.Lap && <ProcedureTypeCircle type="Lap" />}
            {!!cases.Robotic && <ProcedureTypeCircle type="Robotic" />}
            {!!cases.Open && <ProcedureTypeCircle type="Open" />}
          </div>
        </div>
      )}
    </div>
  );
};

export default BubbleEvent as any;
