import clsx from 'clsx';
import { useState, useCallback, ChangeEvent, forwardRef } from 'react';

type InputFieldArgs = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  classNameWrapper?: string;
  changeOnKeyUp?: boolean;
  onChange: (e: string, evt: ChangeEvent<HTMLInputElement>) => void;
  onEnter?: (e: string, evt: ChangeEvent<HTMLInputElement>) => void;
};

export const InputField = forwardRef<HTMLInputElement, InputFieldArgs>(
  ({ classNameWrapper, onChange, onEnter, changeOnKeyUp, ...rest }, ref) => {
    const [focus, setFocus] = useState(false);
    const enterHandler = useCallback(
      (e) => {
        if (e.key === 'Enter') {
          onEnter?.(e.target.value, e);
          return;
        }

        if (changeOnKeyUp) {
          onChange?.(e.target.value, e);
        }
      },
      [onChange],
    );

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      onChange?.(value, e);
    }, []);

    return (
      <div
        className={clsx(
          'border rounded-main border-ghost bg-opacity-30 bg-white px-[.8rem] py-[.3rem] w-full',
          classNameWrapper,
          {
            'border-grey bg-ghostBorder': rest.disabled,
            'border-persianBlue bg-white': focus,
          },
        )}
      >
        <input
          ref={ref}
          className={clsx(
            ' bg-white border-ghost w-full bg-opacity-30 placeholder:font-[600] placeholder:text-1214 placeholder:tracking-wider',
            {
              'border-persianBlue bg-white': focus,
              'border-ghost': !focus,
            },
            rest.className,
          )}
          {...rest}
          onChange={handleChange}
          onKeyUp={enterHandler}
          onFocus={(e) => {
            setFocus(true);
            rest.onFocus?.(e);
          }}
          onBlur={(e) => {
            setFocus(false);
            rest.onBlur?.(e);
          }}
        />
      </div>
    );
  },
);
