// eslint-disable-next-line no-shadow
export enum WidgetsType {
  OPEN_CASES = 'OPEN_CASES',
  LAP_CASES = 'LAP_CASES',
  IDLE_TIME = 'IDLE_TIME',
  ROBOTIC_CASES = 'ROBOTIC_CASES',
  CLEANING_TIME = 'CLEANING_TIME',
  OPEN_AVERAGE_CLEANING_TIME = 'OPEN_AVERAGE_CLEANING_TIME',
  LAP_AVERAGE_CLEANING_TIME = 'LAP_AVERAGE_CLEANING_TIME',
  ROBOTIC_AVERAGE_CLEANING_TIME = 'ROBOTIC_AVERAGE_CLEANING_TIME',
  TOTAL_CASES = 'TOTAL_CASES',
  OPEN_AVERAGE_DELAY_TIME = 'OPEN_AVERAGE_DELAY_TIME',
  ROBOTIC_AVERAGE_DELAY_TIME = 'ROBOTIC_AVERAGE_DELAY_TIME',
  TOTAL_AVERAGE_DELAY_TIME = 'TOTAL_AVERAGE_DELAY_TIME',
}

export enum SurgeryType {
  OPEN = 'Open Case',
  LAPAROSCOPIC = 'Lap case',
  ROBOTIC = 'Robotic Case',
  OVERAL = 'Overal',
}

export interface DashboardWidgetProps {
  loading?: boolean;
  title: string;
  value: string | number;
  prevValue: string | number;
  type: WidgetsType;
  surgeryType: SurgeryType;
  rangeType: string | null;
  analyticsIsUp: boolean | null;
  isTime: boolean;
  valueContent?: React.ReactNode;
  previousPeriodContent?: React.ReactNode;
}

export const averageWidgets = [
  WidgetsType.OPEN_AVERAGE_CLEANING_TIME,
  WidgetsType.LAP_AVERAGE_CLEANING_TIME,
  WidgetsType.ROBOTIC_AVERAGE_CLEANING_TIME,
  WidgetsType.OPEN_CASES,
  WidgetsType.OPEN_AVERAGE_DELAY_TIME,
  WidgetsType.ROBOTIC_AVERAGE_DELAY_TIME,
  WidgetsType.TOTAL_AVERAGE_DELAY_TIME,
];
