import { useCallback, useMemo, useRef } from 'react';
import { BarWithErrorBarsController } from 'chartjs-chart-error-bars';
import { chartColors, chartColorsDarker, colors } from '../../constants/colors';
import { secondsToMinutes } from 'date-fns';
import { Set, DataSet } from './types';

import { getEntityName, transposeArray } from '../../utilities/helpers';
import { makeBarOptions } from './barOptions';
import { CompareValues } from '../MultiFilter/MultiFilters.types';
import { DisplayOptionValue } from '../../page/genesis-analytics/useControls';
import { sortItems } from '../../utilities/helpers';
import { useCreateChartTooltip } from '../ChartTooltip/ChartTooltip';
import { ChartOptions } from 'chart.js';
import { capitalize } from 'lodash';

const barOptions = {
  fill: true,
  borderColor: 'white',
  borderRadius: 6,
  borderWidth: 1,
  tension: 0.3,
  groupPadding: 2,
  pointRadius: 0,
  errorBarWhiskerRatio: 0.1,
};

export type TransformKeys<K extends string> = (key: K, significant: boolean | null) => string;

export const fillDataSet =
  <K extends string>(
    set: Set[string],
    transformKeys: TransformKeys<K>,
    shouldSkipEmptyValues = true,
  ) =>
  (gen: Partial<AnalyticsTransformResult>) => {
    const duration = Number(gen?.averageDuration);

    if (!duration && shouldSkipEmptyValues) return;
    const amount = Number(gen.activitiesAmount);
    const minutes = secondsToMinutes(gen?.averageDuration || 0);
    set.significant = gen?.significant !== undefined ? gen?.significant : null;

    set.standardDeviation.push(gen?.standardDeviation || 0);

    set.dataPoints.push(gen?.dataPoints || []);

    if (gen?.min !== undefined) {
      set.mins?.push(gen?.min);
    }
    if (gen?.max !== undefined) {
      set.maxs?.push(gen?.max);
    }

    if (gen?.name) {
      const label = transformKeys(gen.name as K, set.significant) ?? 'unknown type';

      set.phaseNames.push(gen.name);

      set.labels.push(
        label, //+ (set.significant !== null && showSignificant ? ` (${set.significant ? 'sig' : 'insig'})` : ''),
      );
    }

    set.data.push(duration);

    set.datalabels.push(amount);
  };

export function buildScatters(input: number[][][], set: Set) {
  const setsNames = Object.keys(set);

  const scatters = transposeArray(input);
  const scatterChart: any[] = [];

  scatters.forEach((point, i) => {
    point.forEach((p, j) => {
      const k = setsNames[j];
      scatterChart.push({
        id: i + j,
        label: set[k].name + j + i,
        data: p.map((d, o) => ({
          x: o,
          y: d,
        })) as any,
        dataPoints: set[k].dataPoints,
        standardDeviation: set[k].standardDeviation,
        general: set,
        significant: set[k].significant,
        color: set[k].color,
        textColor: set[k].textColor,
      });
    });
  });

  return scatterChart;
}

type BarChartEngineProps<K extends string> = {
  sourceData: AnalyticsGenericDataFormat<K>[];
  compareBy: CompareValues;
  shouldSkipEmptyValues?: boolean;
  transformKeys: TransformKeys<K>;
  displayOptionList: DisplayOptionValue[];
  allFilters: AllFilters;
  unit?: string;
  extendOptions?: ChartOptions<'bar'>;
};

export function useBarChartEngine<K extends string>({
  sourceData,
  compareBy,
  transformKeys,
  shouldSkipEmptyValues = true,
  displayOptionList,
  allFilters,
  extendOptions,
  unit = 'minutes',
}: BarChartEngineProps<K>) {
  const tooltipRef = useRef(document.createElement('div'));

  useCreateChartTooltip(tooltipRef);

  const chart = useMemo(() => {
    const set: Set = {};

    sourceData.forEach(({ name, context }, i) => {
      set[name] = {
        name,
        phaseNames: [],
        mins: [],
        maxs: [],
        color: /rest/i.test(name) ? colors.neutralGray : [...chartColors][i],
        darkColor: /rest/i.test(name) ? colors.neutralGrayDark : [...chartColorsDarker][i],
        textColor: [...chartColors][i],
        dataPoints: [],
        standardDeviation: [],
        significant: null,
        data: [],
        labels: [],
        datalabels: [],
      };
      context.forEach(fillDataSet(set[name], transformKeys, shouldSkipEmptyValues));
    });

    const setsNames = Object.keys(set);

    const chartData: DataSet[] = setsNames.map((k, i) => ({
      id: i,
      label: set[k].name.split(' ').map(capitalize).join(' '),
      data: set[k].data,
      phaseNames: set[k].phaseNames,
      dataPoints: set[k].dataPoints,
      datalabels: set[k].datalabels,
      labels: set[k].labels,
      darkColor: set[k].darkColor,
      mins: set[k].mins,
      maxs: set[k].maxs,
      tooltipLabel: getEntityName({
        value: set[k].name,
        allFilters,
        compareBy,
      }),

      standardDeviation: set[k].standardDeviation,
      general: set,
      significant: set[k].significant,
      color: set[k].color,
      textColor: set[k].textColor,
    }));

    const dataItems = sortItems(chartData, compareBy);

    return { data: dataItems };
  }, [sourceData, compareBy, displayOptionList]);

  const getData = useCallback(() => {
    return {
      datasets: chart.data.map((item) => ({
        ...item,
        dataPoints: [],
        backgroundColor: sourceData.length > 1 ? item.color : chartColors[0],
        data: item.data.map((d, j) => ({
          y: d,
          x: item.labels[j],
        })),
        ...barOptions,
      })),
    };
  }, [chart]);

  const getDataPointsData = useCallback(() => {
    return {
      datasets: chart.data.map((item, l) => ({
        ...item,
        backgroundColor: sourceData.length > 1 ? item.color : chartColors[0],
        data: item.data.map((d, j) => ({
          y: d,
          x: item.labels[j],
        })),
        ...barOptions,
      })),
    };
  }, [chart]);

  const getSDSData = useCallback(() => {
    return {
      datasets: chart.data.map((item, i) => ({
        ...item,
        dataPoints: [],
        data: item.data.map((d, j) => ({
          y: d,
          yMin: d - item?.standardDeviation?.[j],
          yMax: d + item?.standardDeviation?.[j],
          sds: item?.standardDeviation?.[j],
          x: item.labels[j],
        })),
        type: BarWithErrorBarsController.id,
        backgroundColor: sourceData.length > 1 ? item.color : chartColors[0],
        ...barOptions,
      })),
    };
  }, [chart]);

  const getBothData = useCallback(() => {
    return {
      datasets: [
        ...chart.data.map((item, i) => ({
          ...item,
          stack: item.label,
          id: item.label + ' mean',
          label: item.label + ' mean',
          data: item.data.map((d, j) => ({
            y: d,
            yMin: d - item?.standardDeviation?.[j],
            yMax: d + item?.standardDeviation?.[j],
            x: item.labels[j],
          })),
          type: BarWithErrorBarsController.id,
          backgroundColor: sourceData.length > 1 ? item.color : chartColors[0],
          ...barOptions,
        })),
        ...chart.data.map((item) => ({
          ...item,
          dataPoints: [],
          stack: item.label,
          id: item.label + ' max',
          label: item.label + ' max',
          data: item.maxs,
          backgroundColor: sourceData.length > 1 ? item.color + '90' : chartColors[0],
          ...barOptions,
        })),
      ],
    };
  }, [chart]);

  const addValues = (min: boolean, max: boolean, datasets: Record<string, any>[]) => {
    return [
      ...(!min
        ? []
        : datasets.map((item) => ({
            ...item,
            stack: item.label,
            datalabels: [],
            label: item.label + ' min',
            // grouped: false,
            backgroundColor: `${item.darkColor}`,
            data: item.mins,
          }))),
      ...datasets.map((item) => ({
        ...item,
        stack: item.label,
        // grouped: false,
        label: item.label + ' mean',
      })),
      ...(!max
        ? []
        : datasets.map((item) => ({
            ...item,
            stack: item.label,
            datalabels: [],
            label: item.label + ' max',
            // grouped: false,
            backgroundColor: item.backgroundColor + 'AA',
            data: item.maxs,
          }))),
    ];
  };

  const _getBarChartData = () => {
    if (displayOptionList.includes('deviation') && displayOptionList.includes('dataPoints')) {
      return getBothData();
    }
    if (displayOptionList.includes('deviation')) {
      return getSDSData();
    }
    if (displayOptionList.includes('dataPoints')) {
      return getDataPointsData();
    }

    return getData();
  };
  const getBarChartData = () => {
    const data = _getBarChartData();
    return {
      ...data,
      datasets: addValues(
        displayOptionList.includes('min'),
        displayOptionList.includes('max'),
        data.datasets,
      ),
    };
  };

  const barChartOptions = makeBarOptions({
    chart,
    compareBy,
    unit,
    tooltipRef,
    allFilters,
    showDataLabels: displayOptionList.includes('sampleSize'),
    extendOptions,
  });

  return { getBarChartData, barChartOptions };
}
