import { $ } from '../../zeus';
import { GET_CLINICS } from '../../graphql/query/constants';
import { usePaginationItems } from '../../hooks/usePaginationItems';

export const useClinics = () => {
  const {
    loading,
    pagination,
    searchQuery,
    items: clinics,
    onScroll,
    onSearch,
    refetchItems,
    setSearchQuery,
  } = usePaginationItems({
    query: {
      organizations: [
        {
          search: $`search`,
          pagination: $`pagination`,
        },
        {
          nodes: {
            logoUrl: true,
            refId: true,
            name: true,
            parent: {
              refId: true,
            },
          },
          pagination: {
            currentPage: true,
            lastPage: true,
            nextPage: true,
            perPage: true,
            totalNodes: true,
            totalPages: true,
          },
        },
      ],
    },
    operationName: GET_CLINICS,
    entity: 'organizations',
    perPage: 5,
  });

  return {
    loading,
    pagination,
    searchQuery,
    items: clinics,
    onScroll,
    onSearch,
    refetchItems,
    setSearchQuery,
  };
};
