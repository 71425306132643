import clsx from 'clsx';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { generateArray } from '../../utilities/helpers';

export const BarChartDashboardSkeleton = () => {
  return (
    <div className="bg-white h-[67.9rem] rounded-main mt-2 flex flex-col animate-pulse p-4">
      <div className="flex px-2 py-4 w-full items-center justify-between">
        <div className="flex px-2 w-full">
          <div className="bg-ghost h-[1.5rem] w-[9.4rem] rounded-[.4rem] mr-[1.8rem]"></div>
          <div className="bg-ghost h-[1.5rem] w-[18.4rem] rounded-[.4rem] mr-[1.8rem]"></div>
        </div>
        <div className="bg-ghost h-[2.6rem] w-[14.4rem] rounded-[.4rem] self-end mr-[1.8rem]"></div>
      </div>
      <div className="mt-[2.5rem] relative">
        <div className="absolute -ml-[1rem] left-1/2 top-[20%] z-[3]">
          <div className="flex flex-col">
            <div className="mb-8">
              <LoadingSpinner />
            </div>
            <div className="text-1419 font-bold">Loading...</div>
          </div>
        </div>
        {generateArray(5).map((_, i, r) => (
          <div key={'genesisAnalytics-row' + i} className="row flex">
            {generateArray(12).map((_, j, c) => (
              <div
                key={'genesisAnalytics-cell' + i + '' + j}
                className={clsx('cell h-[6.2rem] relative ', {
                  'w-[3.9rem] border-r': j === 0,
                  'border-t border-dashed': i === 0 && j !== 0,
                  'w-1/5 border-t border-r border-dashed':
                    j > 0 && j < c.length - 1 && i !== r.length - 1,
                  'w-1/5 border-r border-dashed': j > 0 && j < c.length - 1,
                  'w-1/5 border-t border-dashed': j === c.length - 1,
                  'w-1/5 border-t border-solid': j > 0 && i === r.length - 1,
                })}
              >
                {j === 0 && (
                  <div className="w-[3.4rem] h-[1.5rem] bg-ghost rounded-md absolute -left-1 -top-[.7rem]"></div>
                )}
                {j > 0 && i === r.length - 1 && (
                  <div className="absolute bottom-0 w-full flex justify-center items-end">
                    <div className="w-[4.5rem] h-[20rem] bg-ghost rounded-t-md mr-4"></div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
        <div className="row flex">
          {generateArray(12).map((_, j, c) => (
            <div
              key={j}
              className={clsx(
                'flex justify-center items-center cell h-[4.2rem] relative border-ghost',
                {
                  'w-[3.9rem]': j === 0,
                  'w-1/5': j > 0,
                  'border-t': j > 0,
                },
              )}
            >
              {j === 0 && (
                <div className="w-[3.4rem] h-[1.5rem] bg-ghost rounded-md absolute -left-1 -top-[.7rem]"></div>
              )}
              {j > 0 && <div className="w-[8rem] h-[1.5rem] rounded-[.4rem] bg-ghost"></div>}
            </div>
          ))}
        </div>
        <div className="row flex justify-center mt-4">
          <div className="w-[9.5rem] h-[1.8rem] rounded-[.4rem] bg-ghost"></div>
          <div className="w-[4.5rem] h-[1.8rem]"></div>
          <div className="w-[9.5rem] h-[1.8rem] rounded-[.4rem] bg-ghost"></div>
          <div className="w-[4.5rem] h-[1.8rem]"></div>
          <div className="w-[9.5rem] h-[1.8rem] rounded-[.4rem] bg-ghost"></div>
        </div>
      </div>
    </div>
  );
};
