import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import * as XLSX from 'xlsx';
import { PieChart } from '../../components/PieChart';
import { PermissionCheck } from '../../components/PermissionCheck';
import { AnalyticsChartContainer } from '../../components/AnalyticsChartContainer';
import { routesPath } from '../../constants/routes';
import { PageTitle } from '../../components/PageTitle';
import { useHistory } from 'react-router-dom';
import { getQuery } from '../../utilities/getQuery';
import { ChartViewName, CompareValues } from '../../components/MultiFilter/MultiFilters.types';
import { MultiFilter } from '../../components/MultiFilter/MultiFilter';
import { useQueryCasesMetrics } from '../../rest/query/useQueryCasesMetrics';
import { chartColors, colors } from '../../constants/colors';
import { FilterSlot, NoData } from '../../components/ChartSkeleton/styles';
import clsx from 'clsx';
import { makeDownloadChartHandler } from '../../utilities/makeDownloadChartHandler';
import { DownloadButton } from '../../components/DownloadButton';
import { ChartSkeleton } from '../../components/ChartSkeleton/ChartSkeleton';
import { capitalize, set } from 'lodash';
import { useMultiFilterStore } from '../../components/MultiFilter/MultiFilter.store';
import { getEntityName, historyPushState, makeQuery } from '../../utilities/helpers';
import { makeDoughnutOptions } from './options';
import { useCreateChartTooltip } from '../../components/ChartTooltip/ChartTooltip';
import { useSuggestedView } from '../../rest/query/useQuerySuggestedViews';
import { SuggestedViewsSelectbox } from '../../components/SuggestedViewsSelectbox/SuggestedViewsSelectbox';
import { ChartViewTabs } from '../../components/ChartViewTabs';
import { CasesTable } from '../../components/CasesTable/CasesTable';
import { convertDatetimeToString } from '../../utilities/dateTime';
import { compareOptions } from '../../components/MultiFilter/mocks';

const chartId = 'cases-analytics-chart';
const tableId = 'cases-analytics-sheet';

const days = {
  Mon: 0,
  Tue: 1,
  Wed: 2,
  Thu: 3,
  Fri: 4,
};

const addDefaultOptions = (data: number[], labels: string[]) => {
  const restDataIndex = labels.findIndex((l) => /rest/i.test(l));

  const bgColors = chartColors.slice(0, labels.length);
  bgColors[restDataIndex] = colors.neutralGray;
  return {
    labels,
    datasets: [
      {
        label: 'My First Dataset',
        data,
        labels,
        general: {
          data,
          labels,
        },
        backgroundColor: bgColors,
        spacing: 6,
        hoverOffset: 2,
        borderRadius: 8,
        rotation: -100,
      },
    ],
  };
};

const groupByNames = {
  OR: 'Room',
  surgeon_id: 'Surgeon',
  procedure: 'Modality',
  weekday: 'Week Day',
};

const REPORT_NAME = 'cases';

export const CasesAnalytics = () => {
  const history = useHistory();
  const dateRanges = getQuery(history);
  const multiFilterStore = useMultiFilterStore();
  const filtersState = multiFilterStore[REPORT_NAME];
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [view, setView] = useState<ChartViewName | null>(null);
  const [compareBy, setCompareBy] = useState<CompareValues>(null);

  const tooltipRef = useRef(document.createElement('div'));

  const onViewChange = useCallback(
    (v: ChartViewName) => {
      setView(v);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('chartView', v);
      historyPushState(`${window.location.pathname}?${urlParams}`);
    },
    [window.location.search],
  );
  useCreateChartTooltip(tooltipRef);

  const currentQuery = useMemo(
    () => makeQuery(dateRanges, filtersState),
    [dateRanges, filtersState],
  );

  const { data, loading, allFilters } = useQueryCasesMetrics(currentQuery);

  const rooms = allFilters.rooms;
  const surgeons = allFilters.surgeons;
  const procedures = allFilters.procedures;
  const specialties = allFilters.specialties;

  const { setSuggestedView, suggestedViews, suggestedView } = useSuggestedView(
    REPORT_NAME,
    view,
    procedures,
    specialties,
  );

  const { labels, values, sum } = useMemo(() => {
    const obj = data?.frequencies || ({} as Record<string, number>);

    const dataPairs = Object.keys(obj)
      .sort((ka, kb) => obj[kb as keyof typeof obj] - obj[ka as keyof typeof obj])
      .map((k) => ({
        label: /rest/i.test(k)
          ? capitalize(k)
          : getEntityName({
              value: k,
              allFilters,
              compareBy,
            }),
        value: obj[k as keyof typeof obj],
      }));

    const arr = [...dataPairs];
    const restIndex = arr.findIndex((item) => /rest/i.test(item.label));
    const restItem = arr.splice(restIndex, 1);
    arr.push(...restItem);

    const labels = arr.map((p) => p.label);
    const values = arr.map((p) => p.value);

    const sum = values.reduce((acc, val) => acc + val, 0);
    return { labels, values, sum };
  }, [surgeons, data?.frequencies, compareBy]);

  const downloadXLSX = useCallback(() => {
    const caseMixData = labels.map((label, index) => ({
      label,
      value: values?.[index],
    }));

    const total = caseMixData.reduce((acc, { value }) => acc + value, 0);
    const groupByHeader =
      compareOptions.find((option) => option.value === compareBy)?.label || 'COMPARE';

    const headers = [groupByHeader?.toUpperCase(), 'COUNT', 'PERCENTAGE'];

    const meanData = [
      headers,
      ...caseMixData.map(({ label, value }) => [label, value, ((value / total) * 100).toFixed(0)]),
    ];
    const meanWs = XLSX.utils.aoa_to_sheet(meanData);

    const excelFile = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(excelFile, meanWs, 'Sheet1');

    XLSX.writeFile(
      excelFile,
      `${tableId}--by-${compareBy}${convertDatetimeToString(new Date())}.xlsx`,
    );
  }, [values, labels, compareBy]);

  const downloadChart = useCallback(makeDownloadChartHandler(chartId), []);

  const { filtersBar, panel, selectedFilters } = MultiFilter({
    reportName: REPORT_NAME,
    drawerOpen,
    allFilters,
    compareTitle: 'Metadata',
    isLoading: loading,
    availableFilters: data?.available_filters,
    leftFiltersBarSlot: (
      <SuggestedViewsSelectbox
        loading={loading}
        options={suggestedViews}
        view={suggestedView}
        onSelectView={setSuggestedView}
      />
    ),
    rightFiltersBarSlot: (
      <ChartViewTabs
        enabledList={['Pie View', 'List View']}
        defaultItem={view || 'Pie View'}
        onChange={onViewChange}
      />
    ),
    alwaysGroupBy: true,
    hideFilters: ['excludeOutliers'],
    onOpenPanel: () => setDrawerOpen(true),
    onClosePanel: () => setDrawerOpen(false),
  });

  useEffect(() => {
    setCompareBy(filtersState.compare);
  }, [filtersState.compare]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const maybeChartView = urlParams.get('chartView') || '';

    if (maybeChartView) {
      setView(maybeChartView as ChartViewName);
    } else {
      setView('Pie View');
    }
  }, [window.location.search]);

  const labelsFooterData = useMemo(() => {
    const colorsBG = chartColors.slice(0, labels.length);
    const pairs = labels.map((label, index) => ({
      label,
      color: /rest/i.test(label) ? colors.neutralGray : colorsBG[index],
    }));
    return pairs.map(({ color, label }, i) => {
      return (
        <div key={label} className="flex mx-12 mb-4">
          <div
            style={{
              backgroundColor: color,
            }}
            className="w-12 h-[1.6rem] rounded-[.4rem] mr-4"
          ></div>
          <span className="text-[1.2rem] leading-6 font-lato font-bold opacity-70 align-top">
            {/rest/i.test(label) ? capitalize(label) : label}
          </span>
        </div>
      );
    });
  }, [data, allFilters, compareBy]);

  const filterSlot = (
    <div data-testid="filter-slot" className="flex flex-col-reverse xl:flex-row justify-between">
      <div className="w-full inline-flex items-center">{selectedFilters}</div>
      <div className="w-fit my-2 xl:mb-2">
        <div className="flex justify-between">
          <DownloadButton
            title={view === 'Pie View' ? 'Download Chart' : 'Download Excel'}
            onClick={view === 'Pie View' ? downloadChart : downloadXLSX}
          />
        </div>
      </div>
    </div>
  );

  const renderChart = () => {
    return (
      <div id={chartId} className="flex w-full flex-col items-center justify-center">
        <AnalyticsChartContainer
          loading={loading}
          centerContent={sum}
          footer={groupByNames[(compareBy || '') as keyof typeof groupByNames]}
        >
          <PieChart
            id="chart"
            data={addDefaultOptions(values, labels)}
            options={makeDoughnutOptions({
              compareBy,
              tooltipRef,
              sum,
            })}
          />
        </AnalyticsChartContainer>
        <div
          data-testid="chart-footer"
          className={clsx('mt-8 w-[100%]', {
            'grid grid-cols-4': labelsFooterData.length > 10,
            'flex flex-wrap justify-center content-center': labelsFooterData.length <= 10,
          })}
        >
          {labelsFooterData}
        </div>
      </div>
    );
  };

  const renderList = () => (
    <div className="w-full flex justify-center">
      <div className="w-full">
        <CasesTable
          tableId={tableId}
          loading={loading}
          labels={labels}
          data={values}
          compareBy={compareBy}
          allFilters={allFilters}
        />
      </div>
    </div>
  );

  return (
    <PermissionCheck check="readAnalytics" fallback={<div />}>
      <PageTitle
        header="Case Mix"
        crumbs={[{ title: 'Analytics', url: routesPath.analytics.replace('/:refId', '') }]}
      />
      <div className="relative">
        {panel}
        {filtersBar}
        <div className="mt-10 flex"></div>
        <div className="bg-white shadow-sm">
          <ChartSkeleton
            loading={loading}
            title={
              <>
                <FilterSlot>{filterSlot}</FilterSlot>
              </>
            }
          >
            {!sum && <NoData>No Data</NoData>}
            {!!sum && (view === 'List View' ? renderList() : renderChart())}
          </ChartSkeleton>
        </div>
      </div>
    </PermissionCheck>
  );
};
