import { useCallback, useEffect, useState } from 'react';
import { makeDownloadChartHandler } from '../../utilities/makeDownloadChartHandler';
import { ChartViewName } from '../../components/MultiFilter/MultiFilters.types';
import { useMultiFilterStore } from '../../components/MultiFilter/MultiFilter.store';
import { makeSelectOptionWithURLParam } from '../../utilities/helpers';
import { DisplayOptionValue, displayOptions } from '../genesis-analytics/useControls';

export const chartId = 'people-count-chart';
export const tableId = 'people-count-sheet';

export const useControls = () => {
  const nonOperativeMetrics = useMultiFilterStore((s) => s.nonOperativeMetrics);
  const [currentPage, setCurrentPage] = useState(1);
  const [view, setView] = useState<ChartViewName | null>(null);
  const [currentPhase, selectPhase] = useState<GenesisKeys>('sterileprep_patientin');

  const onViewChange = useCallback(
    makeSelectOptionWithURLParam<ChartViewName | null>('chartView', setView),
    [window.location.search],
  );

  const onPhaseChange = useCallback(
    makeSelectOptionWithURLParam<GenesisKeys>('phase', selectPhase),
    [window.location.search],
  );

  const compareBy = nonOperativeMetrics.compare;

  const [displayOptionList, setDisplayOptionList] = useState<DisplayOptionValue[]>([]);
  const downloadChart = useCallback(makeDownloadChartHandler(chartId), []);

  const switchChartViews = (btn: typeof displayOptions[0]) => () => {
    setDisplayOptionList((prev) =>
      prev.includes(btn.value) ? prev.filter((f) => f !== btn.value) : [...prev, btn.value],
    );
  };
  const resetChartViews = useCallback(() => {
    setDisplayOptionList([]);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const maybeChartView = urlParams.get('chartView') || '';
    const maybePhase = urlParams.get('phase') || '';

    if (maybeChartView) {
      setView(maybeChartView as ChartViewName);
    }
    if (maybePhase) {
      selectPhase(maybePhase as GenesisKeys);
    }
  }, [window.location.search]);

  return {
    compareBy,
    displayOptionList,
    view,
    currentPhase,
    downloadChart,
    switchChartViews,
    resetChartViews,
    onViewChange,
    onPhaseChange,
    currentPage,
    setCurrentPage,
  };
};
