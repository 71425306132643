import clsx from 'clsx';

import { FormLabel } from '../FormLabel';
import { ColoredSlug } from '../ColoredSlug';
import { useDebouncedValue } from '../../hooks';

type FormSlugProps = {
  label: string;
  value?: string;
};

export const FormSlug: React.FC<FormSlugProps> = ({ value, label }) => {
  const thisValue = useDebouncedValue(value);

  return (
    <>
      {!!label && <FormLabel marginClassName="ml-10">{label}</FormLabel>}
      <div
        className={clsx(
          thisValue ? '' : 'border border-dashed hover:bg-peranoHover',
          'w-[23rem] h-[23rem] bg-athensGrey bg-opacity-30 rounded-[3.2rem] border-ghost cursor-pointer flex items-center justify-center overflow-hidden',
        )}
      >
        {thisValue ? (
          <ColoredSlug name={thisValue} withName={false} size="fill" className="text-4064" />
        ) : null}
      </div>
    </>
  );
};
