/* eslint-disable no-else-return */
import * as H from 'history';
export interface InitDateProps {
  from: string;
  to: string;
}

export const today = new Date().toISOString().substr(0, 10);

export const oneDayOnMillisecond = 24 * 60 * 60 * 1000;

export const getInitDate = () => {
  return {
    from: '2000-01-01',
    to: today,
  };
};

export const getQuery = (history: H.History) => {
  const queryFromStorage = localStorage.getItem('params');
  const params = new URLSearchParams(history.location.search || queryFromStorage || '');
  const from = params.get('from');
  const to = params.get('to');

  if (to && from) {
    const query = { from, to };
    return query;
  }

  return getInitDate();
};

export const getOldQuery = ({ from, to }: { from: string; to: string }) => {
  const dateFrom = new Date(from); // 2020-01-11
  const dateTo = new Date(to); // 2020-01-21
  const differenceDays = dateTo.getTime() - dateFrom.getTime(); // 10
  const prevFrom = new Date(dateFrom.getTime() - differenceDays); // 2020-01-01
  if (differenceDays === 0) {
    prevFrom.setDate(prevFrom.getDate() - 1);
    const newDateFrom = prevFrom.toISOString().substr(0, 10);

    return {
      from: newDateFrom,
      to: newDateFrom,
    };
  } else {
    prevFrom.setDate(prevFrom.getDate() + 1);
  }

  const newDateFrom = prevFrom.toISOString().substr(0, 10);
  const newDateTo = dateFrom.toISOString().substr(0, 10);

  return {
    from: newDateFrom,
    to: newDateTo,
  };
};
