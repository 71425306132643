import { FC } from 'react';

import { Arrow } from '../Arrow';
import MonthLabel from '../MonthLabel';

export type MonthHeaderHeaderProps = {
  date?: Date | null;
  decrease: () => void;
  increase: () => void;
  prevButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
};

const MonthHeader: FC<MonthHeaderHeaderProps> = ({ date, decrease, increase }) => {
  return (
    <div className="w-[17rem] inline-flex justify-between items-center">
      <Arrow direction="left" onClick={decrease} />
      <MonthLabel date={date} />
      <Arrow direction="right" onClick={increase} />
    </div>
  );
};

export default MonthHeader;
