import { Drawer } from '../Drawer';
import { AddUser } from './AddUser';
import { EditUser } from './EditUser';
import { ColoredSlug } from '../ColoredSlug';

type UserDrawerProps = {
  data: any;
  open?: boolean;
  isEdit?: boolean;
  stop: () => void;
  onSuccess: () => void;
  componentKey?: string | number;
};

export const UserDrawer: React.FC<UserDrawerProps> = ({
  data,
  open,
  isEdit,
  stop,
  onSuccess,
  componentKey,
}) => {
  const iconProps = isEdit
    ? { name: data?.fullName }
    : { overrideName: 'New', overrideColor: '#C2EFF4' };

  const icon = <ColoredSlug size="big" withName={false} className="shadow-md" {...iconProps} />;
  const Component = isEdit ? EditUser : AddUser;

  return (
    <Drawer icon={icon} visible={open} onClose={stop} isEdit={isEdit}>
      <Component data={data} onClose={stop} key={componentKey} onSuccess={onSuccess} />
    </Drawer>
  );
};
