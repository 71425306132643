import {
  ChartType,
  CoreChartOptions,
  DatasetChartOptions,
  DoughnutControllerChartOptions,
  ElementChartOptions,
  PluginOptionsByType,
  ScaleChartOptions,
  TooltipItem,
} from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';

export interface CustomPluginOptionsByType<TType extends ChartType>
  extends PluginOptionsByType<TType> {
  canvasBackgroundColor: {
    color: string;
  };
}
export interface PluginChartOptions<TType extends ChartType> {
  plugins: CustomPluginOptionsByType<TType>;
}

type ChartOptions<T extends ChartType> =
  | _DeepPartialObject<
      CoreChartOptions<T> &
        ElementChartOptions<T> &
        PluginChartOptions<T> &
        DatasetChartOptions<T> &
        ScaleChartOptions<T> &
        DoughnutControllerChartOptions
    >
  | undefined;

export const calculatePercents = (dataset: number[], value: number, fx = 0) => {
  const total = dataset.reduce((acc, curr) => acc + curr, 0);
  return ((value * 100) / total).toFixed(fx);
};

export const makeSimplePieChartTooltipLabel = (context: TooltipItem<'doughnut'>) => {
  const label = (context?.dataset as any)?.labels[context?.dataIndex] as string;

  return `${label || ''} (${context.parsed})`;
};
export const makeSimpleBubbleChartTooltipLabel = (context: TooltipItem<'scalableBubble'>) => {
  const label = (context?.dataset as any)?.labels[context?.dataIndex] as string;

  return `${label || ''} (${context.parsed})`;
};

export const makeBarChartTooltipLabel = (context: TooltipItem<'bar'>) => {
  const percents = calculatePercents(context.dataset.data, context.raw as number, 1);
  return `${context.raw} / ${percents}%`;
};

export const pieChartOptions: ChartOptions<'doughnut'> = {
  responsive: true,
  interaction: {
    mode: 'index',
  },
  plugins: {
    datalabels: {
      display: true,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      intersect: true,
      displayColors: false,
      callbacks: {
        label: makeSimplePieChartTooltipLabel,
      },
    },
  },
};

export const barChartOptions: ChartOptions<'bar'> = {
  interaction: {
    mode: 'nearest',
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    canvasBackgroundColor: {
      color: '#ffffff',
    },
    tooltip: {
      callbacks: {
        label: makeBarChartTooltipLabel,
      },
    },
  },
  scales: {
    y: {
      display: true,
      title: {
        display: true,
        text: 'Minutes',
        font: {
          size: 12,
          family: 'Lato',
        },
        color: '#9A9AB0',
      },
    },
  },
};
export const bubbleChartOptions: ChartOptions<'scalableBubble'> = {
  interaction: {
    mode: 'nearest',
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    canvasBackgroundColor: {
      color: '#ffffff',
    },
    tooltip: {
      callbacks: {
        label: makeSimpleBubbleChartTooltipLabel,
      },
    },
  },
  scales: {
    y: {
      display: true,
      title: {
        display: true,
        text: 'Minutes',
        font: {
          size: 12,
          family: 'Lato',
        },
        color: '#9A9AB0',
      },
    },
  },
};
