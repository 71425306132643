import { FC } from 'react';
import classNames from 'clsx';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { Modal } from '../Modal';

type ConfirmationModalType = {
  onClose: () => void;
  onConfirm: () => void;
  className?: string;
  confirmButtonName?: string;
  cancelButtonName?: string;
};

export const ConfirmationModal: FC<ConfirmationModalType> = ({
  onClose,
  onConfirm,
  children,
  className = 'h-[25rem]',
  cancelButtonName = 'Cancel',
  confirmButtonName = 'Confirm',
}) => {
  return (
    <Modal onCancel={onClose}>
      <div className={classNames('flex flex-col justify-between w-[32rem]', className)}>
        <div className="flex flex-col justify-between">
          <div onClick={onClose} className="close  cursor-pointer self-end">
            <CloseIcon />
          </div>
          <div className="text-[1.5rem] w-full flex flex-col justify-center items-center mt-8">
            {children}
          </div>
        </div>
        <div>
          <div className="bg-galery h-px mb-10"></div>
          <div className="flex justify-around">
            <button
              className="w-[14rem] h-[3.2rem] text-1214 tracking-wider font-bold bg-white text-black rounded-main py-2 border border-galery"
              onClick={onClose}
            >
              {cancelButtonName}
            </button>
            <button
              className="w-[14rem] h-[3.2rem] text-1214 tracking-wider font-bold bg-black text-white rounded-main py-2"
              onClick={onConfirm}
            >
              {confirmButtonName}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
