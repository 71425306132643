import { useCallback, useEffect, useState } from 'react';
import { makeDownloadChartHandler } from '../../utilities/makeDownloadChartHandler';
import { CaseViewModeValue, CompareValues } from '../../components/MultiFilter/MultiFilters.types';
import { useMultiFilterStore } from '../../components/MultiFilter/MultiFilter.store';

export const chartId = 'non-operative-metrics-chart';
export const tableId = 'non-operative-metrics-sheet';

export type ViewOptionType = { label: string; value: CaseViewModeValue };

export const viewsOptions: ViewOptionType[] = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'Inliers',
    value: 'inliers',
  },
  {
    label: 'Outliers',
    value: 'outliers',
  },
];

export const useControls = () => {
  const [caseViewMode, setFilters] = useMultiFilterStore((s) => [
    s.caseView.caseViewMode,
    s.setFilters,
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [compareBy] = useState<CompareValues>(null);

  const view = caseViewMode;
  const setView = useCallback(
    (v: CaseViewModeValue) => {
      setFilters('caseView', 'caseViewMode', v);
      setFilters('caseView', 'excludeOutliers', !!v);
    },
    [setFilters],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [view]);

  const downloadChart = useCallback(makeDownloadChartHandler(chartId), []);

  return {
    compareBy,
    downloadChart,
    currentPage,
    view,
    setView,
    setCurrentPage,
  };
};
