import { useCalendar } from './useCalendar';
import CalendarComponent from './CalendarComponent';
import { PageTitle } from '../../components/PageTitle';

export const MonthCalendar = () => {
  //TOTO: get rooms and  surgeons from API
  const surgeons = [] as any[];

  const {
    loading,
    events,
    date,
    rooms,
    onDateChange,
    room,
    onRoomChange,
    surgeon,
    onSurgeonChange,
    onSelectEvent,
  } = useCalendar({
    applySmartSizing: false,
    surgeons,
  });

  return (
    <div>
      <PageTitle header="Calendar" subHeader={false} />
      <CalendarComponent
        loading={loading}
        roomsLoading={false}
        surgeonsLoading={false}
        rooms={rooms}
        surgeons={surgeons}
        events={events}
        date={date}
        onDateChange={onDateChange}
        room={room}
        onRoomChange={onRoomChange}
        surgeon={surgeon}
        onSurgeonChange={onSurgeonChange}
        onSelectEvent={onSelectEvent}
      />
    </div>
  );
};
