import { useCallback, useMemo, useRef } from 'react';
import { chartColors, colors } from '../../constants/colors';
import { genesisLabels } from '../../constants/analyticsLabels';
import { makeBubbleOptions } from './bubbleOptions';
import { CompareValues } from '../MultiFilter/MultiFilters.types';
import { useCreateChartTooltip } from '../ChartTooltip/ChartTooltip';
import { DisplayOptionValue } from '../../page/genesis-analytics/useControls';
import { getEntityName, sortItems, transposeArray } from '../../utilities/helpers';
import { secondsToMinutes } from 'date-fns';
import { Set, DataSet } from './types';
import { ChartOptions } from 'chart.js';

export const fillDataSet =
  (labels: string[], set: Set[string]) => (gen: Partial<AnalyticsTransformResult>) => {
    const duration = Number(gen?.averageDuration);
    if (!duration) return;
    const amount = Number(gen.activitiesAmount);
    const minutes = secondsToMinutes(gen?.averageDuration || 0);
    set.significant = gen?.significant !== undefined ? gen?.significant : null;

    if (gen?.standardDeviation) {
      set.standardDeviation.push(gen?.standardDeviation);
    }
    if (gen?.dataPoints) {
      set.dataPoints.push(gen?.dataPoints || []);
    }

    if (gen?.name) {
      const label = genesisLabels[gen.name as GenesisKeys] ?? 'unknown type';

      set.phaseNames.push(gen.name);

      set.radiusList.push(gen.radius || 0);
      set.xLimits = gen.xLimits || [0, 1];
      set.yLimits = gen.yLimits || [0, 1];

      labels.push(
        label + (set.significant !== null ? ` (${set.significant ? 'sig' : 'insig'})` : ''),
      );
    }

    if (duration) {
      set.data.push(duration);
    }

    if (amount) {
      set.datalabels.push(amount);
    }
  };

type BubbleChartEngineProps<K extends string> = {
  unit?: string;
  isVerySmallDistance?: boolean;
  currentPhase: string;
  sourceData: AnalyticsGenericDataFormat<K>[];
  compareBy: CompareValues;
  displayOptionList: DisplayOptionValue[];
  extendOptions?: ChartOptions<'scalableBubble'>;
  allFilters: AllFilters;
};

export const useBubbleChartEngine = <K extends string>({
  unit,
  isVerySmallDistance,
  currentPhase,
  sourceData,
  extendOptions,
  compareBy,
  displayOptionList,
  allFilters,
}: BubbleChartEngineProps<K>) => {
  const tooltipRef = useRef(document.createElement('div'));

  useCreateChartTooltip(tooltipRef);

  const chart = useMemo(() => {
    const labels: string[] = [];
    const set: Set = {};

    sourceData.forEach(({ name, context }, i) => {
      const gen = context.filter((g) => g.name === currentPhase);
      set[name] = {
        name,
        phaseNames: [],
        radiusList: [],
        xLimits: [],
        yLimits: [],
        color: /rest/i.test(name) ? colors.neutralGray : [...chartColors][i],
        textColor: [...chartColors][i],
        dataPoints: [],
        standardDeviation: [],
        significant: null,
        data: [],
        datalabels: [],
      };
      gen.forEach(fillDataSet(labels, set[name]));
    });

    const setsNames = Object.keys(set);

    const chartData: DataSet[] = setsNames.map((k, i) => ({
      id: i,
      label: set[k].name,
      data: set[k].data,
      radiusList: set[k].radiusList,
      phaseNames: set[k].phaseNames,
      dataPoints: set[k].dataPoints,
      datalabels: set[k].datalabels,
      xLimits: set[k].xLimits,
      yLimits: set[k].yLimits,
      tooltipLabel: getEntityName({
        value: set[k].name,
        allFilters,
        compareBy,
      }),

      standardDeviation: set[k].standardDeviation,
      general: set,
      significant: set[k].significant,
      color: set[k].color,
      textColor: set[k].textColor,
    }));

    const dataItems = sortItems(chartData, compareBy);

    return { labels, data: dataItems };
  }, [sourceData, compareBy, currentPhase]);

  const { xLimits, yLimits, multiplier } = useMemo(() => {
    const chartAreaWidth = window.innerWidth / 2 + window.innerWidth / 2 / 3;

    const { xLimits, yLimits } = (chart.data || []).filter((el) => el.data.length)?.[0] || {
      xLimits: [0, 1],
      yLimits: [0, 1],
    };
    const xLen = (xLimits?.[1] || 1) - (xLimits?.[0] || 0);

    const multiplier = chartAreaWidth / xLen;

    return {
      multiplier,
      xLimits,
      yLimits,
    };
  }, [chart]);

  const getBubbleData = useCallback(() => {
    const x = {
      datasets: [
        ...chart.data.map((item, i) => {
          return {
            ...item,
            phaseNames: item.phaseNames[0],
            dataPoints: item.dataPoints[0],
            borderWidth: 2,
            order: item.datalabels[0] || 0,
            data: item.data.map((d) => ({
              y: item?.standardDeviation?.[0] || 0,
              x: d || 0,
              radius: item.radiusList[0] || 0,
              r: item.radiusList[0] || 0,
              size: item.datalabels[0] || 0,
            })),
            hoverBackgroundColor: item.color,
            borderColor: item.color,
            backgroundColor: sourceData.length > 1 ? item.color + '88' : item.color + '88',
          };
        }),
      ],
    };

    return x;
  }, [chart, currentPhase, multiplier]);

  const bubbleChartOptions = useMemo(
    () =>
      makeBubbleOptions({
        unit,
        xLimits,
        yLimits,
        compareBy,
        tooltipRef,
        allFilters,
        showDataLabels: displayOptionList.includes('sampleSize'),
        extendOptions,
      }),
    [allFilters, compareBy, displayOptionList, extendOptions, unit, xLimits, yLimits],
  );

  return { getBubbleData, bubbleChartOptions };
};
