import React, { FC } from 'react';
import classNames from 'clsx';
import loadingSVG from '../../assets/loading.svg';
import { format } from 'date-fns';
import { convertStringToDatetime } from '../../utilities/dateTime';

export interface CaseTooltipContentProps {
  loading?: boolean;
  caseId: string;
  caseData: {
    OR: string;
    date: string;
    modality: string;
    case_number: string;
    specialty: string;
    weekDay: string;
    surgeon: string;
    surgery_name: string;
    scheduled_time: string;
  };
}

export const CaseTooltipContent: FC<CaseTooltipContentProps> = ({ caseData, caseId, loading }) => {
  const dataList = {
    Date: caseData?.date ? format(convertStringToDatetime(caseData?.date), 'MM/dd/yyyy') : '',
    'Week Day': caseData?.weekDay,
    'Operating Room': caseData?.OR,
    'Case Number': caseData?.case_number,
    Procedure: caseData?.surgery_name,
    Specialty: caseData?.specialty,
    Surgeon: caseData?.surgeon,
    Modality: caseData?.modality,
    'Scheduled Time': caseData?.scheduled_time,
  };

  /*


  */
  return (
    <ul className="tooltip_list h-full">
      <div>
        <li className="">
          <div className="flex content-center w-full my-2">
            <div className="flex w-full px-2 justify-between">
              <div className="text-[1.2rem] leading-6 font-semibold mr-2">Case ID</div>
              <div className="text-[1.2rem] leading-6 font-semibold ml-2">{caseId}</div>
            </div>
          </div>
        </li>
      </div>
      <div className="h-full">
        {loading && (
          <div className="">
            <img
              className="w-[5rem] h-[5rem] absolute left-0 top-0 bottom-0 right-0 m-auto"
              src={loadingSVG}
              alt={'loading'}
            />
            <div className="border-t border-galery h-px w-full"></div>
          </div>
        )}
        {!loading &&
          Object.keys(dataList).map((key, i) => {
            return (
              <li key={key} className={classNames({ 'border-t border-galery': i === 0 })}>
                <div className="flex content-center w-full my-2">
                  <div className="flex w-full px-2 justify-between">
                    <div className="text-[1.2rem] leading-6 font-semibold mr-2">{key}</div>
                    <div className="text-[1.2rem] leading-6 font-semibold ml-2">
                      {dataList[key as keyof typeof dataList] || '—'}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
      </div>
    </ul>
  );
};
