import clsx from 'clsx';
import { Children, FC, useState } from 'react';

export const FiltersPanelTabsButtons = ['All Filters', 'Saved Filters'];

type FiltersPanelTabsProps = {
  tabs: string[];
  activeIndex: number;
  onChange: (index: number) => void;
};

export const FiltersPanelTabs: FC<FiltersPanelTabsProps> = ({
  tabs,
  activeIndex,
  onChange,
  children,
}) => {
  const childArray = Children.toArray(children);

  const handleChange = (tabIndex: number) => {
    onChange(tabIndex);
  };
  return (
    <>
      <div className="py-[2.1rem]">
        <div className="flex bg-[#dbdbdb] h-12 content-center rounded-[0.8rem]">
          {tabs.map((btnName, i) => (
            <button
              key={btnName}
              onClick={() => handleChange(i)}
              className={clsx(
                'cursor-pointer w-1/2 flex font-bold font-lato text-1214 justify-center content-center rounded-[0.8rem]',
                { 'bg-black text-white': activeIndex === i },
              )}
            >
              <span
                className={clsx('w-full text-center py-1 h-fit self-center', {
                  'border-l': activeIndex !== i && i > 0,
                })}
              >
                {btnName}
              </span>
            </button>
          ))}
        </div>
      </div>
      {childArray[activeIndex]}
    </>
  );
};
