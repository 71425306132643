import { parse, format, isValid, parseISO, addMinutes, formatDistance } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';

import humanizeDuration from 'humanize-duration';

export const convertDateToString = (date?: Date) => {
  return date && isValid(date) ? format(date, 'yyyy-MM-dd') : '';
};

export const convertStringToDate = (string: string) => {
  const date = parse(string, 'yyyy-MM-dd', new Date());

  return isValid(date) ? date : new Date();
};

export const humanDate = (date: Date | string) => {
  const d = typeof date === 'string' ? convertStringToDate(date) : date;
  return format(d, 'dd MMM yyyy');
};

export const getBrowserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const convertStringToDateTZ = (string: string) => parseISO(string);

export const secondsToHumanDuration = (s: number) =>
  formatDistance(0, s * 1000, { includeSeconds: true });

export const formatToUserTimeZone = (date?: Date) => {
  return date && isValid(date)
    ? formatToTimeZone(date, 'YYYY/MM/DD HH:mm:ss', { timeZone: getBrowserTimezone() })
    : '';
};

export const convertDatetimeToString = (date?: Date) => {
  return date && isValid(date) ? format(date, 'yyyy/MM/dd HH:mm:ss') : '';
};

export const shortEnglishHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'm',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'm',
    },
  },
});

export const getHumanTimeFromSeconds = (seconds: number, withSeconds?: boolean) => {
  const units = ['w', 'd', 'h', 'm', 's'] as any;
  if (!withSeconds) {
    units.pop();
  }
  return shortEnglishHumanizer(seconds * 1000, {
    round: true,
    units,
    spacer: '',
  })
    .split(',')
    .join('');
};

// if we got string in bad format - revert to parsing at least something
const convertWithFallback = (string: string) => {
  const date = parseISO(string);

  return isValid(date) ? addMinutes(date, date.getTimezoneOffset()) : new Date();
};

export const convertStringToDatetime = (string: string) => {
  const date = parseISO(string);

  return isValid(date) ? date : convertWithFallback(string);
};
