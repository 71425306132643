import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';

import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import { client } from './graphql/client';
import reportWebVitals from './reportWebVitals';

import './index.css';
import configJson from './auth_config.json';

const redirectUri = `${window.location.origin}/login`;
ReactDOM.render(
  <Auth0Provider
    domain={configJson.domain}
    clientId={configJson.clientId}
    audience={configJson.audience}
    redirectUri={redirectUri}
  >
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
