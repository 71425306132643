import { Skeleton } from 'antd';

type AnalyticsChartContainerProps = {
  loading?: boolean;
  footer?: React.ReactNode;
  centerContent?: React.ReactNode;
};

const AnalyticsChartLoading = () => {
  return (
    <div className="relative">
      <Skeleton active></Skeleton>
    </div>
  );
};

export const AnalyticsChartContainer: React.FC<AnalyticsChartContainerProps> = ({
  loading,
  footer,
  children,
  centerContent,
}) => {
  return (
    <div className="inline-flex items-center justify-center">
      <div className="relative">{loading ? <AnalyticsChartLoading /> : children}</div>
    </div>
  );
};
