export const LOGIN = 'LOGIN';
export const UMA_LOGIN = 'UMA_LOGIN';
export const SEND_EMAIL_FOR_RESET_PASSWORD = 'SEND_EMAIL_FOR_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const NEW_HOSPITAL = 'NEW_HOSPITAL';
export const CREATE_USER = 'CREATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const CREATE_PRESET = 'CREATE_PRESET';
export const REMOVE_PRESET = 'REMOVE_PRESET';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_HOSPITAL = 'UPDATE_HOSPITAL';
export const RESYNC = 'RESYNC';
