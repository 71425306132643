import clsx from 'clsx';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

export type PanelFilter = {
  label: string;
  value: boolean | string;
  disabled?: boolean;
  checked: boolean;
};

export type FiltersPanelSectionProps = {
  title: string;
  radio?: boolean;
  hideSelectAll?: boolean;
  limit?: number;
  onError?: (error: string) => void;
  filters: PanelFilter[];
  onChange: (values: PanelFilter[]) => void;
};

const itemsToShow = 7;

export const FiltersPanelSection: FC<FiltersPanelSectionProps> = ({
  title,
  filters,
  hideSelectAll,
  limit,
  radio,
  onError,
  onChange,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(filters);
  const oneChecked = useMemo(() => (currentFilters || []).some((f) => f.checked), [currentFilters]);

  const toggleAll = useCallback(() => {
    const newFilters = currentFilters
      .filter((v) => !v.disabled)
      .map((filter) => ({ ...filter, checked: !oneChecked }));
    setCurrentFilters(newFilters);
    onChange(newFilters);
  }, [currentFilters, oneChecked, onChange]);

  const toggleExpand = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const changeFilter = useCallback(
    (f: PanelFilter) => {
      const newFilters = currentFilters.map((filter) => {
        if (filter.label === f.label) {
          return { ...filter, checked: !filter.checked };
        }
        if (radio) {
          filter.checked = false;
        }
        return filter;
      });

      if (limit && newFilters.filter((f) => f.checked).length > limit) {
        onError && onError(`You can select only ${limit} options`);
        return;
      }

      setCurrentFilters(newFilters);
      onChange(newFilters);
    },
    [currentFilters],
  );

  useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);

  return (
    <div className="">
      <div className="flex justify-between">
        <div className="text-1214 font-bold flex items-center">{title}</div>
        {!hideSelectAll && ((radio && oneChecked) || !radio) && (
          <button
            onClick={toggleAll}
            className={clsx(
              'rounded-main  text-1214 px-[1.1rem] py-[.7rem] tracking-wider border border-ghostBorder font-[500]',
              {
                'bg-persianBlue text-white hover:bg-opacity-80': oneChecked,
                'bg-white text-black hover:bg-opacity-20 hover:border-perano': !oneChecked,
              },
            )}
          >
            {oneChecked ? (radio ? 'Reset' : 'Reset') : 'Select All'}
          </button>
        )}
      </div>
      <div className="flex flex-wrap py-8">
        {currentFilters?.slice(0, expanded ? currentFilters.length : itemsToShow).map((f) => (
          <label
            key={f.label}
            className={clsx(
              'group mr-6 mb-6 min-w-[7.1rem] flex items-center border w-fit rounded-[2.4rem] h-[3.1rem] bg-white px-[1.2rem]',
              {
                'border-persianBlue text-persianBlue': f.checked,
                'opacity-40 cursor-default': f.disabled,
                'cursor-pointer hover:bg-perano hover:bg-opacity-20 hover:border-perano':
                  !f.disabled,
                'border-ghostBorder': !f.checked,
              },
            )}
          >
            <div className="">
              <input
                onChange={() => !f.disabled && changeFilter(f)}
                onClick={() => radio && !f.disabled && changeFilter(f)}
                checked={f.checked}
                className={clsx(
                  'checked:bg-persianBlue mb-1  checked:border-persianBlue  w-[1.2rem] h-[1.2rem] ring-0 border-black bg-transparent',
                  {
                    'rounded-[.2rem]': !radio,
                    'cursor-pointer': !f.disabled,
                    'cursor-default': f.disabled,
                  },
                )}
                type={radio ? 'radio' : 'checkbox'}
              />
            </div>
            <div className="text-1214 ml-[1.2rem] font-[500]">{f.label}</div>
          </label>
        ))}
        {currentFilters.length > itemsToShow && (
          <label
            onClick={toggleExpand}
            className={clsx(
              'group mr-6 mb-6 min-w-[7.1rem] flex items-center border w-fit cursor-pointer hover:bg-perano hover:bg-opacity-20 hover:border-perano rounded-main h-[3.1rem] bg-white px-[1.2rem]',
            )}
          >
            <div className="text-1214 font-[500]">{expanded ? 'Show Less' : 'Show More'}</div>
          </label>
        )}
      </div>
    </div>
  );
};
