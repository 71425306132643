import loadingSvg from '../../assets/loading.svg';

const Loading = () => {
  return (
    <div>
      <img src={loadingSvg} alt="Loading..." />
    </div>
  );
};

export default Loading;
