import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';

import { ClinicItem } from './ClinicItem';
import { PermissionCheck } from '../PermissionCheck';
import { SearchInput } from '../SearchInput/SearchInput';
import { ClinicItemSkeleton } from './ClinicItemSkeleton';
import type { GraphQLTypes } from '../../zeus';

type ClinicsDropDownArgs = {
  searchQuery: string;
  clinics: Partial<GraphQLTypes['Organization']>[];
  currentClinic: Partial<GraphQLTypes['Organization']>;
  hasMorePages: boolean;
  addHospital: () => void;
  handleChangeClinic: (clinic?: string) => void;
  setSearchQuery: (q: string) => void;
  onScroll: () => void;
};

export const ClinicsDropDown: FC<ClinicsDropDownArgs> = ({
  clinics,
  currentClinic,
  searchQuery,
  addHospital,
  hasMorePages,
  handleChangeClinic,
  setSearchQuery,
  onScroll,
}) => {
  return (
    <div className="flex flex-col items-center bg-white w-[23rem] border border-solid rounded-xl border-galery">
      <div className="search p-6 w-full">
        <SearchInput value={searchQuery} onChange={setSearchQuery} />
      </div>
      <PermissionCheck check="createOrganizations">
        <div className="w-full">
          <div onClick={addHospital} className={clsx('flex items-center pb-6 pr-6 pl-[1.4rem]')}>
            <div className="flex cursor-pointer hover:bg-perano hover:bg-opacity-20 justify-center items-center w-[20rem] h-[3.2rem] border border-galery rounded-main font-bold text-1214 text-center">
              Add New Hospital
            </div>
          </div>
        </div>
      </PermissionCheck>
      <div className="divider w-[20rem] bg-cornflowerBlue h-px opacity-10"></div>
      <ul id="scrollable-clinics-list" className="ml-4 mt-2 w-full h-[23rem] overflow-y-scroll">
        <InfiniteScroll
          hasMore={hasMorePages}
          dataLength={clinics.length}
          scrollableTarget="scrollable-clinics-list"
          loader={new Array(5).fill(0).map((_, i) => (
            <div key={i} className="flex items-center py-2">
              <ClinicItemSkeleton />
            </div>
          ))}
          next={onScroll}
        >
          {clinics?.map((clinic) => (
            <li
              className="flex items-center py-2"
              key={`${clinic.name}__${clinic.refId}`}
              onClick={() => clinic.refId && handleChangeClinic(clinic.refId)}
            >
              <ClinicItem
                active={clinic.name === currentClinic.name}
                disabled={!clinic.refId}
                clinic={clinic}
              />
            </li>
          ))}
        </InfiniteScroll>
      </ul>
    </div>
  );
};
