import { FC, useMemo } from 'react';
import { ApplyPreset, FiltersPresets } from '../FilterPresets/FilterPresets';
import { AddButton } from '../MultiFilter/AddButton';
import {
  MultiFilterReportName,
  MultiFilterStateContextType,
  SelectedFilterKeys,
} from '../MultiFilter/MultiFilters.types';
import { DeleteSelectedFilter, SelectedFilters } from '../SelectedFilters/SelectedfFilters';
import { Option } from '../Selector';
import { PanelFilter } from './FiltersPanelSection';
import { OptionsKeys } from './types';

export const optionsKeys = {
  dateRange: 'dateRange',
  roomsOptions: 'rooms',
  surgeonsOptions: 'surgeons',
  activitiesOptions: 'activities',
  casesOptions: 'cases',
  compareOptions: 'compare',
  specialtiesOptions: 'specialties',
  modalitiesOptions: 'modalities',
  proceduresOptions: 'procedures',
  casesPerDayOptions: 'casesPerDay',
  allRoboticDayOptions: 'allRoboticDay',
  sameSurgeonOptions: 'sameSurgeon',
  frequencyCutoffOptions: 'frequencyCutoff',
  weekOptions: 'weekDay',
};

type FiltersPanelSavedFiltersType = {
  reportName: MultiFilterReportName;
  shouldHaveCompare?: boolean;
  filtersList: Record<OptionsKeys, PanelFilter[]>;
  additionalFilters: PanelFilter[];
  onDeleteSelectedFilter: DeleteSelectedFilter;
  onApplyPreset: ApplyPreset;
  onAddClick: () => void;
};

const toOption = (f: PanelFilter): Option => ({
  label: f.label,
  value: f.value as string,
});

export const FiltersPanelSavedFilters: FC<FiltersPanelSavedFiltersType> = ({
  reportName,
  additionalFilters,
  filtersList,
  shouldHaveCompare,
  onDeleteSelectedFilter,
  onApplyPreset,
  onAddClick,
}) => {
  const filtersOptions = Object.keys(filtersList).reduce((r, key) => {
    const k = key as OptionsKeys;
    const filterList = filtersList[k];
    const selected = filterList.filter((f) => f.checked);
    return { ...r, [key]: selected.map(toOption) };
  }, {}) as Record<OptionsKeys, Option[]>;

  const selectedFilters = useMemo(
    () =>
      Object.keys(filtersOptions).reduce((r, key) => {
        const k = key as OptionsKeys;
        const stateKey = optionsKeys[key as OptionsKeys] as SelectedFilterKeys;
        const filterList = filtersOptions[k];
        const selected = filterList.map((f) => f.value);
        return { ...r, [stateKey]: selected };
      }, {}) as MultiFilterStateContextType,
    [filtersOptions],
  );

  const state = useMemo(
    () =>
      additionalFilters.reduce((acc, f) => {
        return { ...acc, [f.value as keyof MultiFilterStateContextType]: f.checked };
      }, selectedFilters),
    [additionalFilters, selectedFilters],
  );

  const hasAtLeastOneFilter = Object.keys(state).some((key) => {
    const k = key as keyof MultiFilterStateContextType;
    const v = state[k];
    return Array.isArray(v) ? v.length > 0 : v !== false;
  });

  return (
    <div className="overflow-y-auto -mr-8 pr-8">
      <div className="pt-8">
        <div className="flex justify-between">
          <div className="text-1214 font-bold flex items-center">Current Filters</div>
        </div>
        <div className="flex flex-wrap py-8">
          {!hasAtLeastOneFilter && <AddButton onClick={onAddClick} />}
          {hasAtLeastOneFilter && (
            <SelectedFilters
              notdeleteableOptions={shouldHaveCompare ? ['compare', 'dateRange'] : ['dateRange']}
              filtersState={state}
              onDeleteSelectedFilter={onDeleteSelectedFilter}
              options={filtersOptions}
            />
          )}
        </div>
      </div>
      <FiltersPresets
        reportName={reportName}
        hasFilters={hasAtLeastOneFilter}
        selectedFilters={state}
        onApplyPreset={onApplyPreset}
      />
    </div>
  );
};
