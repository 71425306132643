/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import { ButtonTypes } from '../common';
import { SContainerForm } from './styled';
import loadingSvg from '../../assets/loading.svg';

export type FormErrors = {
  name: string;
  errors: string[];
};

interface CreateFormProps {
  children: JSX.Element;
  onSubmit?: any;
  validateTrigger?: string[];
  initialValues?: any;
  buttonType?: ButtonTypes;
  buttonText?: string;
  loading?: boolean;
  errors?: FormErrors[];
  additionValidationHandler?: () => void;
}

export const CreateForm: FC<CreateFormProps> = ({
  children,
  validateTrigger = ['onSubmit', 'onChange'],
  onSubmit,
  buttonType = ButtonTypes.PRIMARY,
  buttonText = '',
  loading,
  initialValues,
  errors,
  additionValidationHandler,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const cancel = () => {
    history.goBack();
  };
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (errors) form.setFields(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <SContainerForm
      form={form}
      initialValues={initialValues}
      validateTrigger={validateTrigger}
      onFinish={onSubmit}
    >
      {children}
      <div>
        <button type="submit" onClick={additionValidationHandler}>
          {loading ? <img src={loadingSvg} alt="Loading..." /> : buttonText}
        </button>
        <div onClick={cancel}>Cancel</div>
      </div>
    </SContainerForm>
  );
};
