import { FC, useMemo } from 'react';
import { Skeleton } from 'antd';

import { Selector } from '../../components/Selector';
import CalendarLayout from '../../components/CalendarLayout';
import BubbleCalendar from '../../components/BubbleCalendar';
import { PermissionCheck } from '../../components/PermissionCheck';
import CalendarDatePicker from '../../components/CalendarDatePicker';
import CalendarDateSlider from '../../components/CalendarDateSlider';

import { convertRoomsToOptions, convertSurgeonsToOptions } from '../../utilities/optionsConvertors';

import type { CalendarProps } from './types';

const skeleton = <Skeleton.Input className="skeleton" active />;

const CalendarComponent: FC<CalendarProps> = ({
  loading,
  roomsLoading,
  surgeonsLoading,
  events,
  date,
  onDateChange,
  room,
  rooms,
  onRoomChange,
  surgeon,
  surgeons,
  onSurgeonChange,
  onSelectEvent,
}) => {
  const roomOptions = useMemo(() => convertRoomsToOptions(rooms), [rooms]);
  const surgeonsOptions = useMemo(() => convertSurgeonsToOptions(surgeons), [surgeons]);
  const showSurgeonsFilter = !!surgeons.length;

  return (
    <>
      <CalendarLayout>
        <CalendarLayout.Filters>
          <CalendarDatePicker date={date} onChange={onDateChange} mode="month" />
          <PermissionCheck check="readRooms">
            {roomsLoading ? (
              skeleton
            ) : (
              <Selector
                pickedData={room}
                options={roomOptions}
                onSelect={(newRoom) => onRoomChange?.(newRoom?.value)}
              />
            )}
          </PermissionCheck>
          {surgeonsLoading
            ? skeleton
            : showSurgeonsFilter && (
                <Selector
                  withSearch
                  pickedData={surgeon}
                  options={surgeonsOptions}
                  onSelect={(newSurgeon) => onSurgeonChange?.(newSurgeon?.value)}
                />
              )}
        </CalendarLayout.Filters>
        <CalendarLayout.Content
          actions={<CalendarDateSlider date={date} onChange={onDateChange} mode="month" />}
        >
          <BubbleCalendar
            events={events ?? []}
            date={date}
            loading={loading}
            onNavigate={() => 1}
            onSelectEvent={onSelectEvent}
          />
        </CalendarLayout.Content>
      </CalendarLayout>
    </>
  );
};

export default CalendarComponent;
