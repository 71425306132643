import { capitalize, debounce, last } from 'lodash';
import H from 'history';
import {
  CompareValues,
  MultiFilterStateContextType,
} from '../components/MultiFilter/MultiFilters.types';

export const stringToBoolean = (value: any): any => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
};
export function convertURLParamsToObject(params: URLSearchParams) {
  return Array.from(params.entries()).reduce((res, [key, value]) => {
    const existValue = res[key];

    if (existValue) {
      if (Array.isArray(existValue)) {
        return { ...res, [key]: [...existValue, stringToBoolean(value)] };
      }
      return { ...res, [key]: [existValue, stringToBoolean(value)] };
    }
    return { ...res, [key]: stringToBoolean(value) };
  }, {} as Record<string, string | boolean | (string | boolean)[]>);
}

export function adjustUrlState<T = Record<string, any>>(
  searchParams: URLSearchParams,
  state: T,
  excludeParams: string[] = [],
) {
  Object.entries(state).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      searchParams.delete(key);
      if (value.length > 0) {
        value.forEach((item) => searchParams.append(key, item));
      }
    } else {
      if (value === null || value === false) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
    }
  });

  excludeParams.forEach((param) => searchParams.delete(param));

  return searchParams.toString();
}

export const generateArray = (length: number) => {
  return Array.from(Array(length).keys());
};

const week = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const sortByDayName = (dayName: string) => {
  return week[dayName as keyof typeof week] || 0;
};

export const makeBarTitle = (title: string, compare: CompareValues) => {
  if (compare === 'weekday') {
    return capitalize(title).substring(0, 3);
  }
  return title;
};
export const makeLegendTitle = (title: string, compare: CompareValues) => {
  if (compare === 'weekday') {
    return capitalize(title);
  }
  return title;
};
export const makeRotation = (datasetLength: number, compare: CompareValues) => {
  if (compare === 'surgeon_id') {
    return datasetLength > 3 ? datasetLength * 5 : 0;
  }
  return 0;
};

export const makeXLegendYOffset = (datasetLength: number, compare: CompareValues) => {
  if (!compare) {
    return 0;
  }
  if (compare === 'surgeon_id') {
    return datasetLength > 3 ? datasetLength * 15 : 20;
  }
  return 20;
};

export const sortItems = <DataSet extends Record<string, any>>(
  items: DataSet[],
  compare: CompareValues,
) => {
  if (compare === 'weekday') {
    return [...items].sort((a, b) => {
      return sortByDayName(a.label) - sortByDayName(b.label);
    });
  }
  return items;
};

type GetEntityNameProps = {
  value: string;
  allFilters?: AllFilters;
  compareBy?: CompareValues;
};

export const getEntityName = ({ value, allFilters, compareBy }: GetEntityNameProps) => {
  const table = {
    national_statistics: [
      {
        code: 'top 20%',
        name: 'Top 20%',
      },
      {
        code: 'average',
        name: 'Average',
      },
      {
        code: 'this hospital',
        name: 'This Hospital',
      },
    ],
    same_surgeon: [
      {
        code: 'true',
        name: 'A-A Turnovers',
      },
      {
        code: 'false',
        name: 'A-B Turnovers',
      },
    ],
    surgeon_id: allFilters?.surgeons,
    procedure: allFilters?.procedures,
    specialty_code: allFilters?.specialties,
  };

  const arr = table[compareBy as keyof typeof table];

  if (!arr) {
    return value;
  }

  return arr.find((s) => s.code === value)?.name || value;
};

export const makeQuery = (
  dates: { from: string; to: string },
  filters: MultiFilterStateContextType,
): {
  from: string;
  to: string;
  split?: CompareValues;
  filters?: AnalyticsFilters;
} => {
  return {
    ...dates,
    split: filters.compare,
    filters: {
      ...(filters.rooms?.length ? { OR: filters.rooms } : {}),
      ...(filters.surgeons?.length ? { surgeon_id: filters.surgeons } : {}),
      ...(['surgeon_id', 'procedure', 'specialty_code'].includes(filters.compare as string)
        ? { with_rest: true }
        : {}),
      ...(filters.specialties?.length ? { specialty_code: filters.specialties } : {}),
      ...(filters.modalities?.length ? { modality: filters.modalities } : {}),
      ...(filters.procedures?.length ? { procedure_code: filters.procedures } : {}),
      ...(filters.weekDay?.length ? { weekday: filters.weekDay } : {}),
      ...(filters.multiCases ? { multi_cases: false } : {}),
      ...(filters.activities ? { activity_group: filters.activities } : {}),
      ...(filters.cases ? { case_number: filters.cases } : {}),
      ...(filters.excludeOutliers ? { exclude_outliers: filters.excludeOutliers } : {}),
      ...(filters.vrest ? { vrest: filters.vrest } : {}),
      ...(filters.allRoboticDay?.length
        ? { all_robotic: filters.allRoboticDay.includes('include') }
        : {}),
      ...(filters.casesPerDay?.length ? { cases_per_day: filters.casesPerDay } : {}),
      ...(filters.sameSurgeon?.length
        ? { same_surgeon: filters.sameSurgeon.includes('include') }
        : {}),
    },
  };
};

export const makeLogData = () =>
  debounce((name: string, data: any) => {
    if (data) {
      // eslint-disable-next-line no-console
      console.group(name);
      // eslint-disable-next-line no-console
      console.log(data);

      // eslint-disable-next-line no-console
      console.groupEnd();
    }
  }, 1000);

export function transposeArray(input: number[][][]) {
  const maxCols = input.length;
  let maxRows = 0;
  let maxItems = 0;
  input.forEach((row) => {
    maxItems = Math.max(maxItems, row.length);
    row.forEach((col) => {
      maxRows = Math.max(maxRows, col.length);
    });
  });

  const output = new Array(maxRows).fill(0).map((c, i) => {
    return new Array(maxCols).fill(0).map((r, j) => {
      return new Array(maxItems).fill(0).map((m, k) => {
        return input[j]?.[k]?.[i] || 0;
      });
    });
  });

  return output;
}

export function makeSelectOptionWithURLParam<V extends string | null>(
  urlName: string,
  selectFunction: (p: V) => void,
) {
  return (p: V) => {
    selectFunction(p);
    const urlParams = new URLSearchParams(window.location.search);
    if (p) {
      urlParams.set(urlName, p);
    } else {
      urlParams.delete(urlName);
    }
    historyPushState(`${window.location.pathname}?${urlParams}`);
  };
}

const state = {
  prevUrls: [] as string[],
  countCalls: 0,
};

const removeSkipFilters = debounce(() => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete('skipFilters');

  window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
}, 100);

const pushState = (
  data: any,
  unused: string,
  url?: string | URL | null | undefined,
  history?: H.History,
) => {
  const isSkipFilters = /skipFilters/i.test(url + '');

  const sameUrl = last(state.prevUrls) === (url + '').split('?')[0];
  if (isSkipFilters || (sameUrl && state.countCalls > 2)) {
    if (history) {
      history.replace(url as string);
    } else {
      window.history.replaceState(data, unused, url);
    }

    if (isSkipFilters) {
      removeSkipFilters();
    }
  } else {
    if (history) {
      history.push(url as string);
    } else {
      window.history.pushState(data, unused, url);
    }
  }
};

export function historyPushState(url?: string | URL | null | undefined, history?: H.History) {
  const path = (url + '').split('?')[0];

  if (last(state.prevUrls) !== path) {
    state.prevUrls.push(path);
    if (state.prevUrls.length > 10) {
      state.prevUrls.shift();
    }
  }
  state.countCalls += 1;

  setTimeout(() => {
    state.countCalls = 0;
  }, 500);

  pushState({}, '', url, history);
}

export const lastUrlStorage = {
  save() {
    localStorage.setItem('returnUrl', window.location.pathname + window.location.search);
  },
  remove() {
    localStorage.removeItem('returnUrl');
  },
  get() {
    return localStorage.getItem('returnUrl');
  },
};
