import { FC } from 'react';
import { ChartViewName } from '../MultiFilter/MultiFilters.types';

type ChartViewSwitchProps = {
  skeleton: JSX.Element;
  view: ChartViewName | null;
  defaultView: ChartViewName;
  loading?: boolean;
  bubble?: () => JSX.Element;
  pie?: () => JSX.Element;
  bar?: () => JSX.Element;
  horizontalBar?: () => JSX.Element;
  list?: () => JSX.Element;
};

export const ChartViewSwitch: FC<ChartViewSwitchProps> = ({
  skeleton,
  horizontalBar,
  bar,
  bubble,
  pie,
  list,
  view,
  defaultView,
  loading,
}) => {
  const table = {
    'Bubble View': bubble,
    'Horizontal Bar View': horizontalBar,
    'Bar View': bar,
    'List View': list,
    'Pie View': pie,
  };

  const viewToRender = table[view || defaultView] || table[defaultView];

  return (
    <>
      {loading && skeleton}
      {!loading && viewToRender?.()}
    </>
  );
};
