import { FC } from 'react';
import clsx from 'classnames';
import { chartColors, colors } from '../../constants/colors';

import { CompareValues } from '../MultiFilter/MultiFilters.types';
import { getEntityName } from '../../utilities/helpers';

type ChartReportFooterLabelsProps = {
  sourceData: { name: string }[];
  allFilters?: AllFilters;
  compareBy?: CompareValues;
  colorsPalette?: string[];
};

export const ChartReportFooterLabels: FC<ChartReportFooterLabelsProps> = ({
  compareBy,
  sourceData,
  allFilters,
  colorsPalette = chartColors,
}) => {
  const labelsFooterData = sourceData
    .filter((d) => d.name !== 'all')
    .map((item, i) => {
      const label = getEntityName({ value: item.name, allFilters, compareBy });

      return (
        <div key={item.name} className="flex mx-12 mb-4">
          <div
            style={{
              backgroundColor: /rest/i.test(item.name) ? colors.neutralGray : colorsPalette[i],
            }}
            className="w-12 h-[1.6rem] rounded-[.4rem] mr-4"
          ></div>
          <span className="text-[1.2rem] leading-6 font-lato2 font-bold opacity-70 align-top">
            {label === 'rest' ? 'Rest' : label}
          </span>
        </div>
      );
    });

  return (
    <div
      data-testid="chart-footer"
      className={clsx('mt-4 w-full', {
        'grid grid-cols-4': labelsFooterData.length > 10,
        'flex flex-wrap justify-center items-center content-center': labelsFooterData.length <= 10,
      })}
    >
      {labelsFooterData}
    </div>
  );
};
