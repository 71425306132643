import { FC } from 'react';
import { addDays, addMonths } from 'date-fns';

import DateHeader from './DateHeader';
import MonthHeader from './MonthHeader';
import { FilterContainer } from '../FilterContainer';

export type CalendarDateSliderProps = {
  date?: Date | null;
  mode?: 'date' | 'month';
  onChange?: (date: Date) => void;
};

const CalendarDateSlider: FC<CalendarDateSliderProps> = ({ date, onChange, mode = 'date' }) => {
  const thisDate = date ?? new Date();
  const isMonthMode = mode === 'month';
  const HeaderComponent = isMonthMode ? MonthHeader : DateHeader;

  const increase = isMonthMode
    ? () => onChange?.(addMonths(thisDate, 1))
    : () => onChange?.(addDays(thisDate, 1));
  const decrease = isMonthMode
    ? () => onChange?.(addMonths(thisDate, -1))
    : () => onChange?.(addDays(thisDate, -1));

  return (
    <FilterContainer
      withArrow={false}
      className="max-w-[17.5rem]"
      label={<HeaderComponent date={thisDate} increase={increase} decrease={decrease} />}
    ></FilterContainer>
  );
};

export default CalendarDateSlider;
