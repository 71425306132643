import { min, max, endOfDay, startOfDay, addMinutes, set, isValid } from 'date-fns';
import type { ICaseEvent } from './types';

// function rounds date to ignore last digit of minutes
// examples:
// roundMinutes(17:26, 'down') => 17:20
// roundMinutes(16:20, 'down') => 17:10
// roundMinutes(15:32, 'up') => 15:40
// roundMinutes(12:20, 'up') => 12:30
const roundMinutes = (date: Date, how: 'up' | 'down') => {
  const minutes = date.getMinutes();
  const lastDigit = minutes % 10;

  if (how === 'up') {
    return addMinutes(date, 10 - lastDigit);
  }

  return addMinutes(date, lastDigit === 0 ? -10 : -lastDigit);
};

const getLowerBound = (events: ICaseEvent[], currentDate?: Date | string) => {
  const thisDate = currentDate ? new Date(currentDate) : new Date();

  const todayEventsTimes = events
    .map((e) => roundMinutes(e.start as Date, 'down'))
    .filter((d) => isValid(d));

  const minEventTime = todayEventsTimes?.length
    ? min(todayEventsTimes)
    : set(thisDate, {
        hours: 12,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
  // in case operation was done between days
  const startOfThisDay = startOfDay(thisDate);

  return max([minEventTime, startOfThisDay]);
};

const getUpperBound = (events: ICaseEvent[], currentDate?: Date | string) => {
  const thisDate = currentDate ? new Date(currentDate) : new Date();

  const todayEventsTimes = events
    .map((e) => roundMinutes(e.end as Date, 'up'))
    .filter((d) => isValid(d));

  const maxEventTime = todayEventsTimes?.length
    ? max(todayEventsTimes)
    : set(thisDate, {
        hours: 13,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
  // in case operation was done between days
  const endOfThisDay = endOfDay(thisDate);

  return min([maxEventTime, endOfThisDay]);
};

export const getBounds = (events: ICaseEvent[], currentDate?: Date | string) => {
  return {
    from: getLowerBound(events, currentDate),
    to: getUpperBound(events, currentDate),
  };
};
