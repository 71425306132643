import React from 'react';
import clsx from 'clsx';

const generateHash = (input: string): number =>
  Array.from(input).reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);

const generateColor = (input: string) => {
  const hash = generateHash(input ?? '');

  // use hsl here so we rely on one color + don't have very light colors
  return `hsl(${hash % 360}, 50%, 60%)`;
};

const abbreviate = (string: string) => {
  return (
    String(string)
      // by commas, dots, dashes and spaces
      .split(/[\s,.-]+/)
      .map((s) => s.trim())
      .filter((s) => !!s)
      .map((s) => s?.[0]?.toLocaleUpperCase())
      .filter((s, index) => !!s && index <= 1)
      .join('')
  );
};

const sizes = {
  small: 'w-10 h-10',
  medium: 'w-[3.2rem] h-[3.2rem] text-1420',
  big: 'w-24 h-24 text-2020',
  fill: 'w-full h-full',
};

type ColoredSlugProps = {
  name?: string;
  uid?: string;
  rounded?: boolean;
  withName?: boolean;
  className?: string;
  overrideName?: string;
  overrideColor?: string;
  size?: 'small' | 'medium' | 'big' | 'fill';
};

export const ColoredSlug: React.FC<ColoredSlugProps> = ({
  name,
  uid,
  className,
  size = 'small',
  rounded = true,
  withName = true,
  overrideName,
  overrideColor,
}) => {
  const displayName = overrideName ?? abbreviate(name ?? '');
  const colorGuid = String(uid ?? name ?? overrideName);

  return (
    <>
      {!!displayName && (
        <div
          className={clsx(
            'text-text-black inline-flex items-center justify-center capitalize shrink-0',
            className,
            rounded ? (size !== 'big' ? 'rounded-main' : 'rounded-[1.2rem]') : '',
            sizes[size] ?? size,
          )}
          style={{ background: overrideColor ?? generateColor(colorGuid) }}
        >
          {displayName}
        </div>
      )}
      {!!withName && !!name && <span className={clsx('ml-6', className)}>{name}</span>}
    </>
  );
};
