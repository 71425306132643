import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { GET_DASHBOARD_NOM } from '../../graphql/query/constants';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';
import { ANALYTICS_API_BASE_URL } from '../../env';
import { makeLogData } from '../../utilities/helpers';

const logData = makeLogData();
export type DashboardNonOperativeMetricsResponse = {
  activities_metrics: Record<GenesisKeys, Record<'means' | 'sds' | 'counts', number[]>>;
  non_operative_metrics: Record<
    GenesisKeys,
    Record<'means' | 'sds' | 'counts', Record<SpecialtyCode, number>>
  > & {
    ora_score: number;
    national_statistic: Record<
      GenesisKeys,
      Record<'top 20%' | 'average' | 'this hospital', number>
    >;
  };
};

export type QueryDashboardNonOperativeMetricsArgs = {
  from: string;
  to: string;
};

const makeQueryDashboardNonOperativeMetrics = async (params: Record<string, any>) => {
  const { data } = await RESTClient.request<DashboardNonOperativeMetricsResponse>({
    baseURL: ANALYTICS_API_BASE_URL + '/dashboard/v1/non_operative_metrics',
    params,
  });

  return data;
};

export const useQueryDashboardNonOperativeMetrics = (
  variables: QueryDashboardNonOperativeMetricsArgs,
) => {
  const organization = useSLUGName();
  const params = useMemo(
    () => ({
      from: variables.from,
      to: variables.to,
      ...(organization ? { organization } : {}),
    }),
    [variables],
  );

  const processor = useCallback(() => makeQueryDashboardNonOperativeMetrics(params), [params]);
  const { data, isLoading: loading, refetch } = useQuery([GET_DASHBOARD_NOM, params], processor);
  logData('/dashboard-nom(' + JSON.stringify(params) + ')', data);

  const nom =
    data?.non_operative_metrics ||
    ({} as DashboardNonOperativeMetricsResponse['non_operative_metrics']);
  const activity =
    data?.activities_metrics || ({} as DashboardNonOperativeMetricsResponse['activities_metrics']);

  return { nom, activity, loading, refetch };
};
