import { useCallback, useEffect, useState } from 'react';
import { makeDownloadChartHandler } from '../../utilities/makeDownloadChartHandler';
import { ChartViewName, CompareValues } from '../../components/MultiFilter/MultiFilters.types';
import { DisplayOptionValue, displayOptions } from '../genesis-analytics/useControls';
import { historyPushState } from '../../utilities/helpers';

export const chartId = 'temporal-activities-chart';
export const tableId = 'temporal-activities-sheet';

export const useControls = () => {
  const [compareBy, setCompareBy] = useState<CompareValues>(null);
  const [view, setView] = useState<ChartViewName | null>(null);
  const onViewChange = useCallback(
    (v: ChartViewName) => {
      setView(v);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('chartView', v);
      historyPushState(`${window.location.pathname}?${urlParams}`);
    },
    [window.location.search],
  );

  const [currentPhase, selectPhase] = useState('');
  const [displayOptionList, setDisplayOptionList] = useState<DisplayOptionValue[]>([]);
  const downloadChart = useCallback(makeDownloadChartHandler(chartId), []);

  const switchChartViews = (btn: typeof displayOptions[0]) => () => {
    setDisplayOptionList((prev) =>
      prev.includes(btn.value) ? prev.filter((f) => f !== btn.value) : [...prev, btn.value],
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const maybeChartView = urlParams.get('chartView') || '';

    if (maybeChartView) {
      setView(maybeChartView as ChartViewName);
    }
  }, [window.location.search]);

  return {
    compareBy,
    displayOptionList,
    view,
    currentPhase,
    setCompareBy,
    downloadChart,
    switchChartViews,
    onViewChange,
    selectPhase,
  };
};
