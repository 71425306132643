import { Plugin } from 'chart.js';

let changedPoints: Record<number, ChartDataPoint> = {};
export const DataPointsPlugin: Plugin<'bar'> = {
  id: 'dataPoints',
  beforeDatasetDraw(chart, args) {
    // const ctx = chart.ctx;
    const { y: yScale } = chart.scales;

    const ds = (args.meta as any)._dataset || ({} as any);
    const $allPoints = ((chart as any).$allPoints || {}) as Record<number, ChartDataPoint[]>;

    const $points: ChartDataPoint[] = [];

    const { dataPoints } = ds as any;

    (dataPoints || []).forEach((points: { value: number; case_id: number }[], i: number) => {
      const x = args.meta?.data?.[i]?.x;

      points.forEach((point: { value: number; case_id: number }, k: number) => {
        if (!point.value) return;
        const yValue = yScale.getPixelForValue(point.value);

        $points.push({
          x,
          y: yValue,
          radius: 2,
          color: '#000',
          case_id: point.case_id,
          value: point.value,
        });
      });
    });

    $allPoints[args.index] = $points;

    (chart as any).$allPoints = $allPoints;
    (chart as any).$points = $points;
  },
  afterDatasetDraw(chart) {
    const ctx = chart.ctx;

    const $points = (chart as any).$points as ChartDataPoint[];

    ($points || []).forEach((point, k: number) => {
      if (!point.value) return;

      ctx.beginPath();
      ctx.strokeStyle = point.color;
      ctx.fillStyle = point.color;
      ctx.arc(point.x, point.y, point.radius, 0, Math.PI * 2);
      ctx.fill();
      ctx.stroke();
      ctx.closePath();
    });

    ctx.save();
  },
  afterEvent(chart, evt) {
    const $allPoints = ((chart as any).$allPoints || {}) as Record<number, ChartDataPoint[]>;

    const { x: mouseX = 0, y: mouseY = 0 } = evt.event;

    const $points = Object.values($allPoints).flat();

    if (!$points || !mouseX || !mouseY) return;

    const foundPoint = ($points || [])?.find((point: { x: number; y: number }) => {
      const { x, y } = point;

      return mouseX >= x - 5 && mouseX <= x + 5 && mouseY >= y - 5 && mouseY <= y + 5;
    });

    const onClick = chart?.config?.options?.plugins?.dataPoints?.onClick;
    const onMouseEnter = chart?.config?.options?.plugins?.dataPoints?.onMouseEnter;
    const onMouseLeave = chart?.config?.options?.plugins?.dataPoints?.onMouseLeave;

    if (Object.keys(changedPoints).length) {
      Object.values(changedPoints).forEach((point) => {
        point.radius = 2;
        point.color = '#000';
      });

      chart.canvas.style.cursor = 'default';

      changedPoints = {};
      evt.changed = true;
    }

    if (foundPoint) {
      foundPoint.radius = 3;
      foundPoint.color = '#1422BD';
      chart.canvas.style.cursor = 'pointer';
      changedPoints[foundPoint.case_id] = foundPoint;
      evt.changed = true;

      if (onClick && evt.event.type === 'click') {
        onClick(foundPoint, chart, evt.event);
      }
      if (onMouseEnter && evt.event.type === 'mousemove') {
        onMouseEnter(foundPoint, chart, evt.event);
      }
    } else {
      onMouseLeave?.();
    }
    // console.log({ options });

    // calculate position of point and show tooltip
  },
};
