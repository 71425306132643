import {
  BubbleDataPoint,
  Chart,
  ChartType,
  ChartTypeRegistry,
  ScatterDataPoint,
  TooltipModel,
} from 'chart.js';
import { MutableRefObject, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-menu.svg';
import triangleSvg from '../../assets/triangle.svg';
import './styles.scss';
import { camelCase, groupBy, chunk } from 'lodash';
import clsx from 'clsx';

export type TooltipEl = {
  isHead?: boolean;
  label: string;
  value: number | string;
  color: string;
  groupBy?: string;
};

type ChartTooltipProps = {
  tooltipData: TooltipEl[];
  title: string;
  view?: 'list' | 'table';
  colName?: string;
  itemsPerColumn?: number;
  currentSelection?: string;
  isCalendarIcon?: boolean;
};

const TooltipListItemDashboard = ({
  label,
  value,
  color,
  i,
  withBorder = true,
}: TooltipEl & { i: number; withBorder?: boolean }) => {
  return (
    <li
      className={clsx({
        'border-t border-galery': withBorder && i === 0,
        'pt-2': color && i === 0,
      })}
    >
      <div className="flex content-center w-full my-2">
        {color && (
          <div
            style={{ backgroundColor: color }}
            className="w-8 h-[1.6rem] px-4 rounded-[.4rem] mx-2"
          ></div>
        )}
        <div className="flex w-full px-2 justify-between">
          <div className="text-[1.2rem] leading-6 font-semibold mr-2">{label}</div>
          <div className="text-[1.2rem] text-right leading-6 font-semibold ml-2 max-w-[20rem]">
            {value}
          </div>
        </div>
      </div>
    </li>
  );
};

const makeTooltipList = (tooltipData: TooltipEl[], currentSelection?: string) => {
  const groupByData = groupBy(tooltipData, 'groupBy');
  const shouldShowGroup = tooltipData.some((d) => !!d?.groupBy);
  const groupByElements: JSX.Element[] = [];
  Object.keys(groupByData).forEach((key, j) => {
    const data = groupByData[key];
    groupByElements.push(
      <li key={key + j + 'tooltip'} className="tooltip_list_item">
        <span>{key}</span>
      </li>,
    );
    data.forEach(({ label, color, value }, k) => {
      groupByElements.push(
        <li
          key={label + value + +k + 'tooltip'}
          className={`tooltip_list_item ${
            camelCase(currentSelection) === camelCase(label) ? 'font-bold' : ''
          }`}
        >
          {color && (
            <div style={{ backgroundColor: color }} className="tooltip_list_item-line"></div>
          )}
          <span>
            {label} - {value}
          </span>
        </li>,
      );
    });
  });
  return (
    <ul className="tooltip_list">
      {shouldShowGroup && groupByElements}
      {!shouldShowGroup &&
        tooltipData
          .filter((el) => el.value && el.isHead)
          .map((item, i) => {
            return (
              item.value && (
                <div key={item.label + 'TooltipListItemDashboard'}>
                  <TooltipListItemDashboard {...item} i={i} withBorder={false} />
                </div>
              )
            );
          })
          .filter(Boolean)}
      {!shouldShowGroup &&
        tooltipData
          .filter((el) => el.value && !el.isHead)
          .map((item, i) => {
            return (
              item.value && (
                <div key={item.label + item.value + 'TooltipListItemDashboard'}>
                  <TooltipListItemDashboard {...item} i={i} />
                </div>
              )
            );
          })
          .filter(Boolean)}
    </ul>
  );
};

function rotateMatrix<T = unknown>(matrix: T[][]) {
  const newMatrix: T[][] = [];
  for (let i = 0; i < matrix.length; i++) {
    for (let j = 0; j < matrix[i].length; j++) {
      if (newMatrix[j] instanceof Array) {
        newMatrix[j].push(matrix[i][j]);
      } else {
        newMatrix.push([matrix[i][j]]);
      }
    }
  }

  return newMatrix;
}
const makeMatrix = (groupData: Record<string, TooltipEl[]>, colName = '#') => {
  const keys = Object.keys(groupData);
  const matrix: { val: string; color: string }[][] = [
    [{ val: colName, color: '' }, ...keys.map((k) => ({ val: k, color: '' }))],
  ];
  keys.forEach((k, row) => {
    const key = k as keyof typeof groupData;
    const data = groupData[key];
    data.forEach((d, col) => {
      if (row === 0) {
        (matrix || []).push([{ val: d.label, color: d.color }]);
      }
      (matrix[col + 1] || []).push({ val: d.value + '', color: d.color });
    });
  });

  return rotateMatrix(matrix);
};

const makeTooltipTable = (
  tooltipData: TooltipEl[],
  colName?: string,
  currentSelection?: string,
) => {
  const groupByData = groupBy(tooltipData, 'groupBy');
  const matrix = makeMatrix(groupByData, colName);

  return (
    <table className="tooltip_table my-4">
      {matrix.map((rows, i) => {
        const hl = rows[0].val === currentSelection ? 'font-bold text-2xl bg-grey-border' : '';
        return (
          <tr>
            {rows.map((col, j) => (
              <td
                style={{ color: col.color }}
                className={`px-3 py-1
              ${i === 0 ? 'font-bold border-b-2 border-b-grey-border uppercase pb-2' : ''}
              ${i === 0 && j > 0 ? 'text-center' : ''}
              ${hl}
              ${j > 0 ? 'border-l-2 border-l-grey-border' : 'text-left'}
              ${j > 0 && i > 0 ? 'text-center' : ''}
                `}
              >
                {col.val}
              </td>
            ))}
          </tr>
        );
      })}
    </table>
  );
};

export const ChartTooltip = ({
  tooltipData,
  title,
  currentSelection,
  view = 'list',
  itemsPerColumn = 15,
  colName = '#',
  isCalendarIcon = true,
}: ChartTooltipProps) => {
  const shouldShowGroup = tooltipData.some((d) => !!d?.groupBy);
  const shouldShowTable = view === 'table' && shouldShowGroup;

  return (
    <div className="w-full shadow-[0_15px_15px_#5252523c] bg-white border border-galery rounded-main relative  p-4 -left-1/2">
      <div className="flex text-1214 tracking-wider font-semibold font-lato mb-2">
        {isCalendarIcon && <CalendarIcon className="self-center mr-2" />}
        <span className="self-center">{title}</span>
      </div>
      {shouldShowTable && makeTooltipTable(tooltipData, colName, currentSelection)}

      {!shouldShowTable &&
        tooltipData.length < itemsPerColumn &&
        makeTooltipList(tooltipData, currentSelection)}

      {!shouldShowTable && tooltipData.length > itemsPerColumn && (
        <div className="flex">
          {chunk(tooltipData, itemsPerColumn).map((ch, i) => (
            <div key={'chunkTooltip' + i} className={i > 0 ? 'border-l' : ''}>
              {makeTooltipList(ch, currentSelection)}
            </div>
          ))}
        </div>
      )}

      <img
        className="absolute bottom-[-8px] z-[11] m-auto left-0 right-0 w-4 h-4"
        alt="triangle"
        src={triangleSvg}
      ></img>
    </div>
  );
};

export const useCreateChartTooltip = (tooltipRef: MutableRefObject<HTMLDivElement | null>) => {
  useLayoutEffect(() => {
    const maybeTooltip = document.getElementById('chartjs-tooltip');

    if (maybeTooltip) {
      tooltipRef.current = maybeTooltip as HTMLDivElement;
      return;
    }
    const tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<div class="tooltip"></div>';
    document.body.appendChild(tooltipEl);

    tooltipRef.current = tooltipEl;

    return () => {
      document.body.removeChild(tooltipEl);
      tooltipRef.current = null;
    };
  });
};

type ChartExternalProps<T extends ChartType> = {
  chart: Chart<
    keyof ChartTypeRegistry,
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  >;
  tooltip: TooltipModel<T>;
};

type makeTooltipStylesProps = {
  tooltipRef: MutableRefObject<HTMLDivElement>;
  isCalendarIcon?: boolean;
  view?: 'list' | 'table';
  colName?: string;
  leftOffset?: number;
  topOffset?: number;
};

export function makeTooltipStyles<T extends ChartType>({
  tooltipRef,
  isCalendarIcon = true,
  view = 'list',
  colName = '#',
  leftOffset,
  topOffset,
}: makeTooltipStylesProps) {
  return (context: ChartExternalProps<T>) => {
    const tooltipData = context.tooltip.beforeBody as never as TooltipEl[];
    const title = context.tooltip.title as never as string;
    const { stack, label } = (context.tooltip?.dataPoints?.[0]?.dataset || {}) as Record<
      string,
      string
    >;

    const tooltipEl = tooltipRef.current;
    if (!tooltipEl) return;

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    if (tooltipModel.body) {
      const tableRoot = tooltipEl.querySelector('.tooltip');
      if (tableRoot) {
        ReactDOM.render(
          <ChartTooltip
            tooltipData={tooltipData}
            title={title}
            view={view}
            colName={colName}
            currentSelection={stack || label}
            isCalendarIcon={isCalendarIcon}
          />,
          tableRoot,
        );
      }
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    const position = context.chart.canvas.getBoundingClientRect();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tm = tooltipModel as any;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = `${
      position.left + window.pageXOffset + tm.caretX + (leftOffset || 0)
    }px`;
    tooltipEl.style.top = `${
      position.top +
      window.pageYOffset +
      tm.caretY -
      tm.height * 2 +
      tm.height / 2 +
      (topOffset || 0)
    }px`;
    tooltipEl.style.padding = `${tm.padding}px ${tm.padding}px`;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.transition = '0.4s';
  };
}
