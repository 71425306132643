import { useMutation } from '../../hooks/useMutation';
import { GQLArgs } from '../../types';
import { $ } from '../../zeus';
import { UPDATE_HOSPITAL } from './constants';

export const useMutationUpdateOrganization = (args?: GQLArgs) => {
  return useMutation(
    {
      updateOrganization: [
        {
          slug: $`organizationSlug`,
          input: {
            active: $`organizationActive`!,
          },
        },
        {
          slug: true,
          active: true,
        },
      ],
    },
    { operationName: UPDATE_HOSPITAL, successText: 'Clinic has been updated' },
    args,
  );
};
