import clsx from 'clsx';
import { FC } from 'react';

type MenuItemArgs = {
  icon: JSX.Element;
  title?: string;
  active?: boolean;
  setCurrentTab: () => void;
};

export const MenuItem: FC<MenuItemArgs> = ({ icon, title, setCurrentTab, active }) => {
  return (
    <div
      onClick={() => setCurrentTab()}
      className={clsx(
        'flex px-12 mb-6 rounded-xl w-full h-[4rem] font-nunito cursor-pointer hover:bg-white hover:bg-opacity-20',
        {
          'bg-white': active,
          'hover:bg-opacity-100': active,
        },
      )}
    >
      <div className="flex items-center transition-all">
        <div className="icon mr-[2.2rem]">{icon}</div>
        {!!title && (
          <div
            className={clsx('text-1419 font-bold', { 'text-white': !active, 'text-black': active })}
          >
            {title}
          </div>
        )}
      </div>
    </div>
  );
};
