import { FC, useEffect, useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';

import { convertStringToDate } from '../../utilities/dateTime';
import { useCreateChartTooltip, makeTooltipStyles } from '../ChartTooltip/ChartTooltip';
import { ChartSkeleton } from '../ChartSkeleton/ChartSkeleton';

import { format } from 'date-fns';

import { chartColors } from '../../constants/colors';
import { useHistory } from 'react-router-dom';
import { getQuery } from '../../utilities/getQuery';
import { ModalityType } from '../../types';
import { ChartDatesViews, SelectChartDatesView } from './SelectChartDatesView';
import { ChartOptions } from 'chart.js';
import { ChartReportFooterLabels } from '../ChartReportFooterLabels/ChartReportFooterLabels';

type TotalCasesChartProps = {
  data: { date: string; count: number; modality: Record<ModalityType, number> }[];
  view: Periods;
  loading?: boolean;
  onChangeView: (value: Periods) => void;
};

export const TotalCasesChart: FC<TotalCasesChartProps> = ({
  data,
  onChangeView,
  view,
  loading,
}) => {
  const tooltipRef = useRef(document.createElement('div'));
  const history = useHistory();
  const { from, to } = getQuery(history);

  const label = `${format(convertStringToDate(from), 'MMM dd, yyyy')} - ${format(
    convertStringToDate(to),
    'dd MMM yyyy',
  )}`;

  useCreateChartTooltip(tooltipRef);

  const { options, datasets, labels, totalCases } = useMemo(() => {
    const dateFormat = view === 'months' ? 'MMM yyyy' : 'wo - yyyy';

    const casesData = data.filter((item) => item.count > 0);
    const totalCases = Math.max(0, ...casesData.map((item) => item.count));
    const labels = casesData.map((item) => format(convertStringToDate(item.date), dateFormat));

    const chartSet: Record<ModalityType, { data: number[]; color: string }> = {
      Robotic: { data: [], color: chartColors[0] },
      Open: { data: [], color: chartColors[3] },
      Lap: { data: [], color: chartColors[5] },
    };

    casesData.forEach((item) => {
      Object.keys(chartSet).forEach((modality) => {
        if (chartSet[modality as ModalityType]) {
          chartSet[modality as ModalityType].data.push(
            item.modality[modality as ModalityType] || 0,
          );
        }
      });
    });

    const datasets = Object.keys(chartSet).map((modality, i) => ({
      label: modality,
      data: chartSet[modality as ModalityType].data,
      backgroundColor: chartSet[modality as ModalityType].color,
      borderRadius: 6,
    }));

    const options: ChartOptions<'bar'> = {
      interaction: {
        mode: 'index',
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            color: '#1C1C1FB3',
            pointStyle: 'rectRounded',
            font: {
              size: 12,
              weight: 'bold',
              family: 'Lato',
              lineHeight: '20px',
            },
          },
        },
        tooltip: {
          mode: 'nearest',
          callbacks: {
            label() {
              return '';
            },
            beforeBody(i) {
              const dataIndex = i[0]?.dataIndex;
              // eslint-disable-next-line @typescript-eslint/no-explicit-any

              const datasetsTooltip = [
                {
                  label: 'Total',
                  data: casesData.map((item) => item.count),
                  backgroundColor: null,
                },
              ];
              const datasets = i[0]?.chart?.data?.datasets || [];

              return [
                ...datasetsTooltip.map((d) => ({
                  label: d.label,
                  value: d.data[dataIndex],
                })),
                ...datasets.map((d) => ({
                  label: d.label,
                  value: d.data[dataIndex],
                  color: d.backgroundColor,
                })),
              ] as any;
            },
          },
          enabled: false,
          external: makeTooltipStyles({
            tooltipRef,
          }),
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 12,
              lineHeight: '20px',
              family: 'Lato',
            },
            color: '#1C1C1F',
          },
        },
        y: {
          stacked: true,
          suggestedMax: totalCases > 0 ? undefined : 60,
          grid: {
            display: true,
            color: '#F0F0F0',
            borderDash: [5, 5],
          },
        },
      },
    };

    return {
      options,
      labels,
      datasets,
      totalCases,
    };
  }, [data, view]);

  return (
    <ChartSkeleton
      title={
        <div className="flex justify-between">
          <div className="text-1620 mb-8">
            <span className="font-lato2 font-bold align-baseline">Total Cases ({totalCases})</span>
            <span className="font-lato2 text-1220 font-semibold opacity-70 ml-4 align-top">
              {label}
            </span>
          </div>
          <div className="">
            <SelectChartDatesView value={view} onChange={onChangeView} />
          </div>
        </div>
      }
      loading={loading}
      footer={
        <ChartReportFooterLabels
          colorsPalette={[chartColors[0], chartColors[3], chartColors[5]]}
          sourceData={datasets.map((d) => ({ name: d.label }))}
        />
      }
    >
      <Bar
        options={options}
        data={{
          labels,
          datasets,
        }}
        height={70}
      />
    </ChartSkeleton>
  );
};
