import React from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { Title } from '../HeaderTitle';
import { Link } from '../../components/Link';
import { BreadcrumbsContent } from '../Breadcrumbs/Breadcrumbs';

type PageTitleCrumb = {
  title?: React.ReactNode;
  url: string;
};

type PageTitleProps = {
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  crumbs?: PageTitleCrumb[];
};

const useCrumbs = ({ header, subHeader, crumbs }: PageTitleProps) => {
  const location = useLocation();
  const currentPath = `${location.pathname}${location.search}`;

  const result = [...(crumbs ?? [])];
  subHeader && result.push({ title: subHeader, url: '' });
  !subHeader && subHeader !== false && header && result.push({ title: header, url: currentPath });

  return result;
};

const BreadcrumbArrow: React.FC<{ last: boolean }> = ({ last }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('mt-1', last ? 'opacity-100' : 'opacity-50')}
    >
      <path
        d="M12.5 10.0493L15.5 13.0493L12.5984 15.9512"
        stroke="#1C1C1F"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Breadcrumbs: React.FC<{ crumbs: PageTitleCrumb[] }> = ({ crumbs }) => {
  return (
    <div className="flex items-center gap-x-1 h-11">
      {crumbs.map((crumb, index) => {
        const last = index === crumbs.length - 1;

        return (
          <React.Fragment key={crumb.url}>
            {index !== 0 && <BreadcrumbArrow last={last} />}
            {crumb.url ? (
              <Link
                exact={last}
                path={crumb.url}
                text={crumb.title}
                className={clsx('cursor-pointer', last ? 'opacity-100' : 'opacity-50')}
              />
            ) : (
              crumb.title
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export const PageTitle: React.FC<PageTitleProps> = ({ header, subHeader, crumbs }) => {
  const theseCrumbs = useCrumbs({ header, subHeader, crumbs });

  return (
    <>
      {!!header && <Title pageTitle={header} />}
      <BreadcrumbsContent>
        <Breadcrumbs crumbs={theseCrumbs} />
      </BreadcrumbsContent>
    </>
  );
};
