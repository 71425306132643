import { FC } from 'react';

import { Arrow } from '../Arrow';
import YearLabel from '../YearLabel';

export type MonthHeaderHeaderProps = {
  date: Date;
  decreaseYear: () => void;
  increaseYear: () => void;
  prevYearButtonDisabled?: boolean;
  nextYearButtonDisabled?: boolean;
};

/* eslint-disable max-len */
const MonthHeader: FC<MonthHeaderHeaderProps> = ({ date, decreaseYear, increaseYear }) => {
  return (
    <div className="react-datepicker-custom-header w-full px-4">
      <div className="react-datepicker-custom-header-actions flex items-center justify-between w-full">
        <Arrow direction="left" onClick={decreaseYear} />
        <YearLabel date={date} className="text-persianBlue text-1620 font-semibold" />
        <Arrow direction="right" onClick={increaseYear} />
      </div>
    </div>
  );
};

export default MonthHeader;
