/* eslint-disable */

import { AllTypesProps, ReturnTypes } from './const';
type ZEUS_INTERFACES = never
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["AvailableData"]: AliasType<{
	/** From date */
	from?:boolean,
	/** To date */
	to?:boolean,
		__typename?: boolean
}>;
	/** User attributes */
["CreateUserInput"]: {
	/** Full name */
	fullName:string,
	/** Speciality name */
	speciality?:string | null,
	/** Activate account */
	active?:boolean | null,
	/** Remote data source identification */
	refId?:string | null,
	/** Force user to change current password on next login */
	forceResetPassword?:boolean | null,
	/** Unique email */
	email:string
};
	["Current"]: AliasType<{
	/** Current organization based on permitted X-Current-Organization-Id header */
	organization?:ValueTypes["Organization"],
	permissions?:boolean,
		__typename?: boolean
}>;
	/** An ISO 8601-encoded datetime */
["ISO8601DateTime"]:unknown;
	["Me"]: AliasType<{
	/** Active account */
	active?:boolean,
	/** Current state */
	current?:ValueTypes["Current"],
	/** Email address */
	email?:boolean,
	/** User must to change current password */
	forceResetPassword?:boolean,
	/** Full user name */
	fullName?:boolean,
	/** Remote data source identification */
	refId?:boolean,
	/** Current organization relaed role */
	role?:boolean,
	/** Speciality name */
	speciality?:boolean,
		__typename?: boolean
}>;
	["Mutation"]: AliasType<{
createOrganization?: [{	input:ValueTypes["OrganizationInput"]},ValueTypes["Organization"]],
createPreset?: [{	input:ValueTypes["PresetInput"]},ValueTypes["Preset"]],
createSessionWithPassword?: [{	email:string,	password:string},ValueTypes["Session"]],
createSessionWithUmaToken?: [{	umaToken:string},ValueTypes["Session"]],
createUser?: [{	input:ValueTypes["CreateUserInput"],	role:ValueTypes["RoleEnum"]},ValueTypes["User"]],
removePreset?: [{	name:string},ValueTypes["Preset"]],
	/** Remove current session. */
	removeSession?:ValueTypes["Result"],
removeUser?: [{	email:string},ValueTypes["Result"]],
resetPassword?: [{	baseUrl:string,	email:string},ValueTypes["Result"]],
resyncAll?: [{	organizationSlug?:string | null},ValueTypes["Result"]],
updateOrganization?: [{	slug:string,	input:ValueTypes["OrganizationInput"]},ValueTypes["Organization"]],
updatePassword?: [{	resetToken:string,	newPassword:string},ValueTypes["Result"]],
updateUser?: [{	email:string,	input?:ValueTypes["UpdateUserInput"] | null,	role?:ValueTypes["RoleEnum"] | null,	setDefaultPassword?:boolean | null},ValueTypes["User"]],
		__typename?: boolean
}>;
	/** A valid naive date string like 2021-09-30 */
["NaiveDate"]:unknown;
	["Organization"]: AliasType<{
	/** Only active organizations available to re-sync */
	active?:boolean,
	/** Data available range */
	availableData?:ValueTypes["AvailableData"],
	/** Public logo image URL */
	logoUrl?:boolean,
	/** Official name */
	name?:boolean,
	/** Parent organization */
	parent?:ValueTypes["Organization"],
	/** Remote data source identification */
	refId?:boolean,
	/** Unique slug. Can be used for identification */
	slug?:boolean,
	/** Working time zone */
	timeZone?:boolean,
		__typename?: boolean
}>;
	/** Organization attributes */
["OrganizationInput"]: {
	/** Formal name */
	name?:string | null,
	/** Parent organization slug */
	parentOrganizationSlug?:string | null,
	/** Remote data source identification */
	refId?:string | null,
	/** Public image URL */
	logoUrl?:string | null,
	/** Active or not */
	active?:boolean | null
};
	["OrganizationsList"]: AliasType<{
	nodes?:ValueTypes["Organization"],
	pagination?:ValueTypes["PaginationPayload"],
		__typename?: boolean
}>;
	/** Paginate data collection */
["PaginationInput"]: {
	/** Page number. Defaults to 1) */
	page?:number | null,
	/** Nodes per page. Defaults to 20.                Max to 100 */
	perPage?:number | null
};
	["PaginationPayload"]: AliasType<{
	/** Current page number */
	currentPage?:boolean,
	/** Last page number */
	lastPage?:boolean,
	/** Next page number */
	nextPage?:boolean,
	/** Nodes per page */
	perPage?:boolean,
	/** Total nodes amount */
	totalNodes?:boolean,
	/** Total pages amount */
	totalPages?:boolean,
		__typename?: boolean
}>;
	["Preset"]: AliasType<{
	content?:boolean,
	name?:boolean,
		__typename?: boolean
}>;
	/** Preset attributes */
["PresetInput"]: {
	name:string,
	content:string
};
	["PresetsList"]: AliasType<{
	nodes?:ValueTypes["Preset"],
	pagination?:ValueTypes["PaginationPayload"],
		__typename?: boolean
}>;
	["Query"]: AliasType<{
	/** Current user info */
	me?:ValueTypes["Me"],
organizations?: [{	pagination?:ValueTypes["PaginationInput"] | null,	search?:string | null},ValueTypes["OrganizationsList"]],
presets?: [{	pagination?:ValueTypes["PaginationInput"] | null},ValueTypes["PresetsList"]],
user?: [{	email:string},ValueTypes["User"]],
users?: [{	pagination?:ValueTypes["PaginationInput"] | null,	search?:string | null},ValueTypes["UsersList"]],
		__typename?: boolean
}>;
	["Result"]: AliasType<{
	/** Action result message */
	message?:boolean,
		__typename?: boolean
}>;
	["RoleEnum"]:RoleEnum;
	["Session"]: AliasType<{
	/** Session expiration */
	expiresAt?:boolean,
	/** Session token */
	token?:boolean,
		__typename?: boolean
}>;
	/** User attributes */
["UpdateUserInput"]: {
	/** Full name */
	fullName:string,
	/** Speciality name */
	speciality?:string | null,
	/** Activate account */
	active?:boolean | null,
	/** Remote data source identification */
	refId?:string | null,
	/** Force user to change current password on next login */
	forceResetPassword?:boolean | null
};
	["User"]: AliasType<{
	/** Active account */
	active?:boolean,
	/** Email address */
	email?:boolean,
	/** User must to change current password */
	forceResetPassword?:boolean,
	/** Full user name */
	fullName?:boolean,
	/** Remote data source identification */
	refId?:boolean,
	/** Current organization relaed role */
	role?:boolean,
	/** Speciality name */
	speciality?:boolean,
		__typename?: boolean
}>;
	["UsersList"]: AliasType<{
	nodes?:ValueTypes["User"],
	pagination?:ValueTypes["PaginationPayload"],
		__typename?: boolean
}>
  }

export type ModelTypes = {
    ["AvailableData"]: {
		/** From date */
	from?:ModelTypes["NaiveDate"],
	/** To date */
	to?:ModelTypes["NaiveDate"]
};
	/** User attributes */
["CreateUserInput"]: GraphQLTypes["CreateUserInput"];
	["Current"]: {
		/** Current organization based on permitted X-Current-Organization-Id header */
	organization?:ModelTypes["Organization"],
	permissions?:string[]
};
	/** An ISO 8601-encoded datetime */
["ISO8601DateTime"]:any;
	["Me"]: {
		/** Active account */
	active?:boolean,
	/** Current state */
	current?:ModelTypes["Current"],
	/** Email address */
	email?:string,
	/** User must to change current password */
	forceResetPassword:boolean,
	/** Full user name */
	fullName:string,
	/** Remote data source identification */
	refId?:string,
	/** Current organization relaed role */
	role?:ModelTypes["RoleEnum"],
	/** Speciality name */
	speciality?:string
};
	["Mutation"]: {
		/** Create new organization

**Permissions required: createOrganizations** */
	createOrganization:ModelTypes["Organization"],
	/** Create new preset */
	createPreset:ModelTypes["Preset"],
	/** Create session with known email and password */
	createSessionWithPassword:ModelTypes["Session"],
	/** Create session with known email and password */
	createSessionWithUmaToken:ModelTypes["Session"],
	/** Create new organization user

**Permissions required: createUsers** */
	createUser:ModelTypes["User"],
	/** Create new preset */
	removePreset:ModelTypes["Preset"],
	/** Remove current session. */
	removeSession:ModelTypes["Result"],
	/** Remove organization user

**Permissions required: removeUser** */
	removeUser:ModelTypes["Result"],
	/** Send reset password token for the email */
	resetPassword:ModelTypes["Result"],
	/** Resync all data

**Permissions required: updateIndex** */
	resyncAll:ModelTypes["Result"],
	/** Update organization

**Permissions required: updateOrganizations** */
	updateOrganization:ModelTypes["Organization"],
	/** Update password for given reset token */
	updatePassword:ModelTypes["Result"],
	/** Update existing user

**Permissions required: updateUsers** */
	updateUser:ModelTypes["User"]
};
	/** A valid naive date string like 2021-09-30 */
["NaiveDate"]:any;
	["Organization"]: {
		/** Only active organizations available to re-sync */
	active:boolean,
	/** Data available range */
	availableData:ModelTypes["AvailableData"],
	/** Public logo image URL */
	logoUrl?:string,
	/** Official name */
	name:string,
	/** Parent organization */
	parent?:ModelTypes["Organization"],
	/** Remote data source identification */
	refId?:string,
	/** Unique slug. Can be used for identification */
	slug:string,
	/** Working time zone */
	timeZone:string
};
	/** Organization attributes */
["OrganizationInput"]: GraphQLTypes["OrganizationInput"];
	["OrganizationsList"]: {
		nodes:ModelTypes["Organization"][],
	pagination:ModelTypes["PaginationPayload"]
};
	/** Paginate data collection */
["PaginationInput"]: GraphQLTypes["PaginationInput"];
	["PaginationPayload"]: {
		/** Current page number */
	currentPage:number,
	/** Last page number */
	lastPage:number,
	/** Next page number */
	nextPage?:number,
	/** Nodes per page */
	perPage:number,
	/** Total nodes amount */
	totalNodes:number,
	/** Total pages amount */
	totalPages:number
};
	["Preset"]: {
		content:string,
	name:string
};
	/** Preset attributes */
["PresetInput"]: GraphQLTypes["PresetInput"];
	["PresetsList"]: {
		nodes:ModelTypes["Preset"][],
	pagination:ModelTypes["PaginationPayload"]
};
	["Query"]: {
		/** Current user info */
	me:ModelTypes["Me"],
	/** List of the current user available organizations to use */
	organizations:ModelTypes["OrganizationsList"],
	/** Current user presets */
	presets:ModelTypes["PresetsList"],
	/** Get one user by email

**Permissions required: readUsers** */
	user:ModelTypes["User"],
	/** List of the current organization users

**Permissions required: readUsers** */
	users:ModelTypes["UsersList"]
};
	["Result"]: {
		/** Action result message */
	message:string
};
	["RoleEnum"]: GraphQLTypes["RoleEnum"];
	["Session"]: {
		/** Session expiration */
	expiresAt:ModelTypes["ISO8601DateTime"],
	/** Session token */
	token:string
};
	/** User attributes */
["UpdateUserInput"]: GraphQLTypes["UpdateUserInput"];
	["User"]: {
		/** Active account */
	active?:boolean,
	/** Email address */
	email?:string,
	/** User must to change current password */
	forceResetPassword:boolean,
	/** Full user name */
	fullName:string,
	/** Remote data source identification */
	refId?:string,
	/** Current organization relaed role */
	role?:ModelTypes["RoleEnum"],
	/** Speciality name */
	speciality?:string
};
	["UsersList"]: {
		nodes:ModelTypes["User"][],
	pagination:ModelTypes["PaginationPayload"]
}
    }

export type GraphQLTypes = {
    ["AvailableData"]: {
	__typename: "AvailableData",
	/** From date */
	from?: GraphQLTypes["NaiveDate"],
	/** To date */
	to?: GraphQLTypes["NaiveDate"]
};
	/** User attributes */
["CreateUserInput"]: {
		/** Full name */
	fullName: string,
	/** Speciality name */
	speciality?: string,
	/** Activate account */
	active?: boolean,
	/** Remote data source identification */
	refId?: string,
	/** Force user to change current password on next login */
	forceResetPassword?: boolean,
	/** Unique email */
	email: string
};
	["Current"]: {
	__typename: "Current",
	/** Current organization based on permitted X-Current-Organization-Id header */
	organization?: GraphQLTypes["Organization"],
	permissions?: Array<string>
};
	/** An ISO 8601-encoded datetime */
["ISO8601DateTime"]:any;
	["Me"]: {
	__typename: "Me",
	/** Active account */
	active?: boolean,
	/** Current state */
	current?: GraphQLTypes["Current"],
	/** Email address */
	email?: string,
	/** User must to change current password */
	forceResetPassword: boolean,
	/** Full user name */
	fullName: string,
	/** Remote data source identification */
	refId?: string,
	/** Current organization relaed role */
	role?: GraphQLTypes["RoleEnum"],
	/** Speciality name */
	speciality?: string
};
	["Mutation"]: {
	__typename: "Mutation",
	/** Create new organization

**Permissions required: createOrganizations** */
	createOrganization: GraphQLTypes["Organization"],
	/** Create new preset */
	createPreset: GraphQLTypes["Preset"],
	/** Create session with known email and password */
	createSessionWithPassword: GraphQLTypes["Session"],
	/** Create session with known email and password */
	createSessionWithUmaToken: GraphQLTypes["Session"],
	/** Create new organization user

**Permissions required: createUsers** */
	createUser: GraphQLTypes["User"],
	/** Create new preset */
	removePreset: GraphQLTypes["Preset"],
	/** Remove current session. */
	removeSession: GraphQLTypes["Result"],
	/** Remove organization user

**Permissions required: removeUser** */
	removeUser: GraphQLTypes["Result"],
	/** Send reset password token for the email */
	resetPassword: GraphQLTypes["Result"],
	/** Resync all data

**Permissions required: updateIndex** */
	resyncAll: GraphQLTypes["Result"],
	/** Update organization

**Permissions required: updateOrganizations** */
	updateOrganization: GraphQLTypes["Organization"],
	/** Update password for given reset token */
	updatePassword: GraphQLTypes["Result"],
	/** Update existing user

**Permissions required: updateUsers** */
	updateUser: GraphQLTypes["User"]
};
	/** A valid naive date string like 2021-09-30 */
["NaiveDate"]:any;
	["Organization"]: {
	__typename: "Organization",
	/** Only active organizations available to re-sync */
	active: boolean,
	/** Data available range */
	availableData: GraphQLTypes["AvailableData"],
	/** Public logo image URL */
	logoUrl?: string,
	/** Official name */
	name: string,
	/** Parent organization */
	parent?: GraphQLTypes["Organization"],
	/** Remote data source identification */
	refId?: string,
	/** Unique slug. Can be used for identification */
	slug: string,
	/** Working time zone */
	timeZone: string
};
	/** Organization attributes */
["OrganizationInput"]: {
		/** Formal name */
	name?: string,
	/** Parent organization slug */
	parentOrganizationSlug?: string,
	/** Remote data source identification */
	refId?: string,
	/** Public image URL */
	logoUrl?: string,
	/** Active or not */
	active?: boolean
};
	["OrganizationsList"]: {
	__typename: "OrganizationsList",
	nodes: Array<GraphQLTypes["Organization"]>,
	pagination: GraphQLTypes["PaginationPayload"]
};
	/** Paginate data collection */
["PaginationInput"]: {
		/** Page number. Defaults to 1) */
	page?: number,
	/** Nodes per page. Defaults to 20.                Max to 100 */
	perPage?: number
};
	["PaginationPayload"]: {
	__typename: "PaginationPayload",
	/** Current page number */
	currentPage: number,
	/** Last page number */
	lastPage: number,
	/** Next page number */
	nextPage?: number,
	/** Nodes per page */
	perPage: number,
	/** Total nodes amount */
	totalNodes: number,
	/** Total pages amount */
	totalPages: number
};
	["Preset"]: {
	__typename: "Preset",
	content: string,
	name: string
};
	/** Preset attributes */
["PresetInput"]: {
		name: string,
	content: string
};
	["PresetsList"]: {
	__typename: "PresetsList",
	nodes: Array<GraphQLTypes["Preset"]>,
	pagination: GraphQLTypes["PaginationPayload"]
};
	["Query"]: {
	__typename: "Query",
	/** Current user info */
	me: GraphQLTypes["Me"],
	/** List of the current user available organizations to use */
	organizations: GraphQLTypes["OrganizationsList"],
	/** Current user presets */
	presets: GraphQLTypes["PresetsList"],
	/** Get one user by email

**Permissions required: readUsers** */
	user: GraphQLTypes["User"],
	/** List of the current organization users

**Permissions required: readUsers** */
	users: GraphQLTypes["UsersList"]
};
	["Result"]: {
	__typename: "Result",
	/** Action result message */
	message: string
};
	["RoleEnum"]: RoleEnum;
	["Session"]: {
	__typename: "Session",
	/** Session expiration */
	expiresAt: GraphQLTypes["ISO8601DateTime"],
	/** Session token */
	token: string
};
	/** User attributes */
["UpdateUserInput"]: {
		/** Full name */
	fullName: string,
	/** Speciality name */
	speciality?: string,
	/** Activate account */
	active?: boolean,
	/** Remote data source identification */
	refId?: string,
	/** Force user to change current password on next login */
	forceResetPassword?: boolean
};
	["User"]: {
	__typename: "User",
	/** Active account */
	active?: boolean,
	/** Email address */
	email?: string,
	/** User must to change current password */
	forceResetPassword: boolean,
	/** Full user name */
	fullName: string,
	/** Remote data source identification */
	refId?: string,
	/** Current organization relaed role */
	role?: GraphQLTypes["RoleEnum"],
	/** Speciality name */
	speciality?: string
};
	["UsersList"]: {
	__typename: "UsersList",
	nodes: Array<GraphQLTypes["User"]>,
	pagination: GraphQLTypes["PaginationPayload"]
}
    }
export const enum RoleEnum {
	superadmin = "superadmin",
	org_admin = "org_admin",
	surgical_chair = "surgical_chair",
	surgeon = "surgeon"
}
export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: boolean;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : {}
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & {websocket?: websocketOptions}]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .forEach((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).forEach((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


export const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string, operationName?: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${operationName ? ' ' + operationName : ''}${inspectVariables(buildQuery(tName, o))}`;
  

export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  options?: OperationOptions,
) => fn(queryConstruct(t, tName, options?.operationName)(o), options?.variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (
  t: 'query' | 'mutation' | 'subscription',
  tName: string,
) => (o: Record<any, any>, options?: OperationOptions) =>
  fn(queryConstruct(t, tName, options?.operationName)(o));


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


export const resolverFor = <
  X,
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any,source?: any) => any;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  

export const apiSubscription = (options: chainOptions) => (
    query: string,
  ) => {
    try {
      const queryString = options[0] + '?query=' + encodeURIComponent(query);
      const wsString = queryString.replace('http', 'ws');
      const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
      const webSocketOptions = options[1]?.websocket || [host];
      const ws = new WebSocket(...webSocketOptions);
      return {
        ws,
        on: (e: (args: any) => void) => {
          ws.onmessage = (event:any) => {
            if(event.data){
              const parsed = JSON.parse(event.data)
              const data = parsed.data
              if (data) {
                seekForAliases(data);
              }
              return e(data);
            }
          };
        },
        off: (e: (args: any) => void) => {
          ws.onclose = e;
        },
        error: (e: (args: any) => void) => {
          ws.onerror = e;
        },
        open: (e: () => void) => {
          ws.onopen = e;
        },
      };
    } catch {
      throw new Error('No websockets implemented');
    }
  };



const allOperations = {
    "query": "Query",
    "mutation": "Mutation"
}

export type GenericOperation<O> = O extends 'query'
  ? "Query"
  : O extends 'mutation'
  ? "Mutation"
  : never

export const Thunder = (fn: FetchFunction) => <
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation, allOperations[operation])(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));  
  
export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(
  o: Z | ValueTypes[R],
  ops?: OperationOptions
)=>
  fullSubscriptionConstruct(fn)(operation, allOperations[operation])(
    o as any,
    ops,
  ) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends 'query' | 'mutation',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  operationName?: string,
) => queryConstruct(operation, allOperations[operation], operationName)(o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();
  

export const Gql = Chain('https://api.ora-stage.ora-sandbox.com/api/graphql')