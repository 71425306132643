import { ChartOptions, ClickOnTickLabelClick } from 'chart.js';
import { genesisLabels } from '../../constants/analyticsLabels';
import {
  commonOptions,
  compLabels,
  displayDataLabels,
  displayScaleInMinutes,
  scaleTitleStyle,
} from '../../utilities/commonOptions';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { makeTooltipStyles } from '../ChartTooltip/ChartTooltip';
import { barChartOptions } from '../../utilities/chartUtils';
import { DataSet } from './types';

export const makeHorizontalBarOptions = ({
  tooltipRef,
  chart,
  onAxisLabelClick,
  extendOptions,
  from,
  to,
  showDataLabels,
}: {
  tooltipRef: React.MutableRefObject<HTMLDivElement>;
  chart: { labels: string[]; data: DataSet[] };
  showDataLabels?: boolean;
  onAxisLabelClick?: ClickOnTickLabelClick;
  extendOptions?: ChartOptions<'bar'>;
  from: number;
  to: number;
}) => {
  const l = chart.data.map((d) => d.data.slice(from, to)).reduce((a, b) => b.concat(a), []);
  const maxValue = Math.max(...l);

  const options = cloneDeep({ ...barChartOptions, ...commonOptions });

  return merge(
    merge(
      options,
      {
        indexAxis: 'y',
        scales: {
          x: {
            stacked: true,
            title: {
              ...scaleTitleStyle,
              text: 'Duration',
            },
            ticks: {
              stepSize: maxValue < 60 ? 15 : 30,
              callback: displayScaleInMinutes,
            },
            ...(maxValue ? { suggestedMax: maxValue + maxValue / 10 } : {}),
          },
          y: {
            stacked: true,
            title: {
              display: false,
            },
          },
        },
        plugins: {
          clickOnTick: {
            y: {
              enabled: !!onAxisLabelClick,
              onClick: onAxisLabelClick,
            },
            x: {
              enabled: !!onAxisLabelClick,
              onClick: onAxisLabelClick,
            },
          },
          tooltip: {
            mode: 'nearest',
            callbacks: {
              label() {
                return '';
              },
              beforeBody(i) {
                const dataIndex = i[0].dataIndex > 0 ? i[0].dataIndex - 1 : 0;
                const datasetIndex = i[0].datasetIndex;
                // // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const general = (i[0].dataset as any)?.general;

                const set = Object.values(general)?.[datasetIndex] as any;

                const entityLabel =
                  compLabels[set?.entities?.[dataIndex]?.key as keyof typeof compLabels];

                return [
                  {
                    value: ' ',
                    label: /rest/i.test(set?.name)
                      ? 'Rest'
                      : genesisLabels[set?.name as GenesisKeys],
                    isHead: true,
                  },
                  {
                    value: ' ',
                    label:
                      (i[0].dataIndex === 0
                        ? set?.averageDuration || 0
                        : set?.data?.slice(from, to)?.[dataIndex] || 0
                      ).toFixed(1) + ' minutes',
                  },
                  {
                    label: entityLabel,
                    value: 0, //(set?.entities?.[dataIndex]?.value || 0).toFixed(1),
                  },
                  {
                    label: 'Sample Size',
                    value: Math.floor(set?.datalabels?.[dataIndex] || 0),
                  },
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ] as any;
              },
            },
            enabled: false,
            external: makeTooltipStyles({
              tooltipRef,
              isCalendarIcon: false,
              topOffset: 35,
              leftOffset: -10,
            }),
          },
          datalabels: {
            display: displayDataLabels(!!showDataLabels),
          },
        },
      } as ChartOptions<'bar'>,
      extendOptions,
    ),
  ) as ChartOptions<'bar'>;
};
