import { FC, useRef, useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button } from '../Button';
import { InputField } from '../InputField/InputField';
import { FiltersPresetsItem } from './FilterPresetsItem';
import { useQueryPresets } from '../../graphql/query/getPresets';
import { GraphQLTypes } from '../../zeus';
import { useMutationDeletePreset } from '../../graphql/mutation/deletePreset';
import { useMutationCreatePreset } from '../../graphql/mutation/createPreset';
import {
  MultiFilterReportName,
  MultiFilterStateContextType,
} from '../MultiFilter/MultiFilters.types';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { useMultiFilterStore } from '../MultiFilter/MultiFilter.store';

const perPage = 10;

const FilterPresetLoading = () => (
  <li className="w-full mb-8 flex justify-center items-center animate-pulse border rounded-[1.6rem] px-[1.3rem] py-[.3rem] min-h-[3.1rem]">
    <div className="w-full rounded-[1.6rem] bg-ghost h-[1.5rem]"></div>
  </li>
);

export type ApplyPreset = (filters: MultiFilterStateContextType) => void;

export type FiltersPresetsProps = {
  reportName: MultiFilterReportName;
  hasFilters?: boolean;
  onApplyPreset: ApplyPreset;
  selectedFilters: MultiFilterStateContextType;
};
export const FiltersPresets: FC<FiltersPresetsProps> = ({
  reportName,
  onApplyPreset,
  selectedFilters,
  hasFilters,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [newPresetName, setNewPresetName] = useState('');
  const [presetToDelete, setPresetToDelete] = useState<string | null>(null);
  const [isOpenModal, setModalOpen] = useState(false);
  const [errorPresetName, setErrorPresetName] = useState('');
  const multiFilterStore = useMultiFilterStore();
  const filtersState = multiFilterStore[reportName];
  const { items, onScroll, pagination, refetchItems, loading } = useQueryPresets(perPage);
  const { mutation: createPreset } = useMutationCreatePreset({
    onCompleted: (res) => {
      setNewPresetName('');
      inputRef.current?.value && (inputRef.current.value = '');

      if (items.length % perPage === 0) {
        onScroll();
      } else {
        refetchItems();
      }
    },
  });
  const { mutation: deletePreset } = useMutationDeletePreset({
    onCompleted: () => {
      refetchItems();
    },
  });
  const [currentPreset, setCurrentPreset] = useState<GraphQLTypes['Preset'] | null>(null);

  const newFiltersContent = JSON.stringify({ ...filtersState, ...selectedFilters });

  const existedPreset = items.find((item) => item.content === newFiltersContent);

  const hasSameName = items.some((item) => item.name === newPresetName);

  const onCreatePreset = () => {
    if (hasSameName) {
      setErrorPresetName(newPresetName);
      toast.error('Preset with the same name already exist', {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }
    if (existedPreset) {
      setErrorPresetName(existedPreset.name);
      toast.error(`Preset "${existedPreset.name}" with the same combination already exist`, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      return;
    }
    createPreset({
      variables: {
        name: newPresetName,
        content: newFiltersContent,
      },
    });
  };

  const onSelectPreset = useCallback(
    (preset: GraphQLTypes['Preset']) => {
      setCurrentPreset(preset);
      setErrorPresetName('');
      const filters = JSON.parse(preset.content);
      onApplyPreset(filters);
    },
    [inputRef],
  );

  const loadMore = () => {
    onScroll();
  };

  const openConfirmationModal = (name: string) => {
    setPresetToDelete(name);
    setModalOpen(true);
  };

  const removePreset = () => {
    setModalOpen(false);
    presetToDelete && deletePreset({ variables: { name: presetToDelete } });
  };

  useEffect(() => {
    setErrorPresetName('');
  }, [selectedFilters]);

  return (
    <div>
      <div className="border-t pt-8">
        <div className="flex justify-between">
          <div className="text-1214 font-bold flex items-center">Save Current Filters as:</div>
        </div>
        <div className="flex py-8">
          <InputField
            ref={inputRef}
            disabled={!hasFilters}
            placeholder="Name Filters"
            onEnter={() => onCreatePreset()}
            changeOnKeyUp={true}
            onChange={(value) => {
              setErrorPresetName('');
              setNewPresetName(value as string);
            }}
          />
          <Button
            type={newPresetName ? 'primary' : 'disabled'}
            onClick={onCreatePreset}
            className="ml-8"
          >
            Save
          </Button>
        </div>
      </div>
      <div className="border-t pt-8">
        <div className="flex justify-between py-4">
          <div className="text-1214 font-bold flex items-center">Your Saved Filters</div>
        </div>

        <ul id="scrollable-presets-list" className="w-full h-[34rem] overflow-y-auto">
          <InfiniteScroll
            hasMore={!!pagination?.nextPage}
            dataLength={items.length}
            scrollableTarget="scrollable-presets-list"
            loader={<FilterPresetLoading />}
            next={loadMore}
          >
            {loading &&
              new Array(6)
                .fill(0)
                .map((_, i) => <FilterPresetLoading key={'filterPresetsLoading' + i} />)}
            {!loading &&
              items?.map((preset) => (
                <li key={preset.name}>
                  <FiltersPresetsItem
                    name={preset.name}
                    error={preset.name === errorPresetName}
                    checked={preset.name === currentPreset?.name}
                    onSelect={() => onSelectPreset(preset)}
                    onDelete={() => openConfirmationModal(preset.name)}
                  />
                </li>
              ))}
          </InfiniteScroll>
        </ul>
        {isOpenModal && (
          <ConfirmationModal onClose={() => setModalOpen(false)} onConfirm={removePreset}>
            <div className="w-[17rem] text-center text-1624 text-black font-lato font-bold mt-8 mb-4">
              Are you sure
              <br /> you want to remove "{presetToDelete}" preset?
            </div>
          </ConfirmationModal>
        )}
      </div>
    </div>
  );
};
