import { FormSeparator } from '../../components/FormSeparator';

type FormContainerProps = {
  left?: React.ReactNode;
  actions?: React.ReactNode;
};

export const FormContainer: React.FC<FormContainerProps> = ({ actions, left, children }) => {
  return (
    <div className="mt-12 flex flex-col bg-white p-16">
      <div className="flex gap-x-16">
        {left}
        <div className="flex flex-col flex-grow">{children}</div>
      </div>
      <FormSeparator className="mt-8 mb-12" />
      <div className="flex justify-end gap-x-6">{actions ?? 'Actions'}</div>
    </div>
  );
};
