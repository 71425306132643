import { useHistory } from 'react-router-dom';
import { Form } from 'antd';

import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { Link } from '../../components/Link';
import { routesPath } from '../../constants/routes';
import { validatePassword } from '../../utilities/validatePassword';
import { useMutationResetPassword } from '../../graphql/mutation/resetPassword';

export const ResetPassword = () => {
  const { mutation: resetPass, error } = useMutationResetPassword();

  const history = useHistory();

  const onSubmit = (form: { password: string }) => {
    const resetToken = localStorage.getItem('resetToken');
    resetPass({
      variables: { newPassword: form.password, resetToken },
    }).then((res) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((res as any)?.errors) {
        return;
      }
      history.push('/login');
    });
  };

  return (
    <>
      <div className="title title_black">Reset Password</div>
      <div className="error">
        Should be between 6 and 32 characters, have upper and lower case letters and symbols
      </div>
      <Form
        validateTrigger="onSubmit"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        autoComplete="off"
      >
        {window.navigator.userAgent.indexOf('Firefox') > -1 && (
          <>
            <input type="text" style={{ display: 'none' }} />
            <input type="password" style={{ display: 'none' }} />
          </>
        )}
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please input your password!' },
            () => ({
              validator(_, value) {
                const validationResult = validatePassword(value);
                if (validationResult.status === 'valid') {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(validationResult.error));
              },
            }),
          ]}
        >
          <Input type="password" placeholder="New password" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          hasFeedback
          rules={[
            { required: true, message: 'Please confirm your password!' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
        >
          <Input type="password" placeholder="Confirm password" />
        </Form.Item>
        <Button htmlType="submit">Reset password</Button>
        <div className="security">
          To make sure your account is secure, you’ll be logged <br />
          out from other devices once you set the new password
        </div>
        {error && <div className="error">ERROR</div>}
      </Form>
      <Link
        path={routesPath.login}
        text="Back to Login"
        className="contact-support cursor-pointer"
      />
    </>
  );
};
