import { useQuery } from '../../hooks/useQuery';
import { GET_ME } from './constants';

export const useQueryMe = () => {
  const { loading, data } = useQuery(
    {
      me: {
        active: true,
        current: {
          organization: {
            name: true,
            refId: true,
            availableData: {
              from: true,
              to: true,
            },
          },
          permissions: true,
        },
        email: true,
        fullName: true,
        refId: true,
        role: true,
        speciality: true,
      },
    },
    { operationName: GET_ME },
    { fetchPolicy: 'network-only' },
  );

  return { loading, data };
};
