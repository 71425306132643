import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'clsx';
import { useHistory } from 'react-router-dom';
import { getQuery } from '../../utilities/getQuery';
import { BarChart } from '../../components/BarChart/BarChart';
import { PermissionCheck } from '../../components/PermissionCheck';
import { MultiFilter } from '../../components/MultiFilter/MultiFilter';

import { routesPath } from '../../constants/routes';
import { PageTitle } from '../../components/PageTitle';
import { GenesisTable, generateXLSX } from '../../components/GenesisTable/GenesisTable';
import { DownloadButton } from '../../components/DownloadButton';
import { BubbleChart } from '../../components/BubbleChart/BubbleChart';
import { chartId, useControls, tableId } from './useControls';
import { useBarChartEngine } from '../../components/BarChart/useBarChartEngine';
import { ChartReportFooterLabels } from '../../components/ChartReportFooterLabels/ChartReportFooterLabels';
import { ReportFilterSlot } from '../../components/ReportFilterSlot/ReportFilterSlot';
import { ChartViewSwitch } from '../../components/ChartViewSwitch/ChartViewSwitch';
import { useBubbleChartEngine } from '../../components/BubbleChart/useBubbleChartEngine';
import { ChartViewTabs } from '../../components/ChartViewTabs';
import { genesisLabels, TemporalActivitiesLabels } from '../../constants/analyticsLabels';
import { useMultiFilterStore } from '../../components/MultiFilter/MultiFilter.store';
import { BarChartReportSkeleton } from '../../components/BarChartReportSkeleton/BarChartReportSkeleton';
import { makeQuery, makeSelectOptionWithURLParam } from '../../utilities/helpers';
import { DataPointTooltip, DataPoint } from '../../components/DataPointTooltip/DataPointTooltip';
import { createPortal } from 'react-dom';

import { ChartViewButtons } from '../../components/ChartViewButtons/ChartViewButtons';
import { useQueryPeopleCount } from '../../rest/query/useQueryPeopleCount';
import { PhaseSelectbox } from '../../components/PhaseSelectbox/PhaseSelectbox';
import { capitalize } from 'lodash';
import { peopleCountDisplayOptions } from '../genesis-analytics/useControls';
import {
  FullMenuOption,
  MultiLevelSelectbox,
  makeMenu,
} from '../../components/MultiLevelSelectbox/MultiLevelSelectbox';
import { useAppContext } from '../../context/appContext';
import { useAspectRatioForBubbles } from '../../hooks/useAspectRatioForBubbles';

const REPORT_NAME = 'peopleCount';

export const PeopleCount: FC = () => {
  const history = useHistory();
  const dateRanges = getQuery(history);
  const filtersState = useMultiFilterStore((s) => s[REPORT_NAME]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const aspect_ratio = useAspectRatioForBubbles();
  const [pcView, selectPCView] = useState<FullMenuOption>();
  const { sidebarCollapsed } = useAppContext();
  const [activityGroups, setActivityGroups] = useState<
    Record<ActivityGroupKeys, string[]> | undefined
  >();
  const [openedDataPoints, setOpenedDataPoints] = useState<DataPoint[]>([]);

  const compareBy = filtersState.compare;

  const {
    displayOptionList,
    switchChartViews,
    resetChartViews,
    downloadChart,
    view,
    onViewChange,
    onPhaseChange,
    currentPhase,
  } = useControls();

  const currentQuery = useMemo(
    () => makeQuery(dateRanges, filtersState),
    [dateRanges, filtersState],
  );

  const {
    data: sourceData,
    rawData,
    allFilters,
    loading,
  } = useQueryPeopleCount<GenesisKeys>({ ...currentQuery, aspect_ratio }, pcView?.value);

  const bubble_sourceData =
    view === 'Bubble View'
      ? sourceData.filter((d) => d.context.filter((c) => c.name === currentPhase)[0].radius > 0)
      : sourceData;

  const activityGroupOptions: FullMenuOption[] = useMemo(() => {
    return makeMenu([
      {
        label: 'Non-Operative Metrics',

        value: '',
      },
      {
        label: 'Temporal Activities',
        disabled: view === 'Bubble View',
        nested: Object.keys(activityGroups || {}).map((key) => ({
          label: key.split('-').map(capitalize).join(' '),
          value: key,
        })),
        value: 'activities',
      },
    ]);
  }, [activityGroups, view]);

  const onSelectPCView = useCallback(
    (val: FullMenuOption | undefined) => {
      makeSelectOptionWithURLParam<string | null>('pcView', () => {
        selectPCView(val);
      })(val?.id || null);
    },
    [window.location.search, activityGroupOptions],
  );

  const metricLabels = useMemo(() => {
    if (pcView?.category === 'Temporal Activities') {
      return TemporalActivitiesLabels as any;
    }
    return genesisLabels as any;
  }, [pcView]);

  const phaseOptions = useMemo(() => {
    return sourceData?.[0]?.context?.map((s) => ({
      label: capitalize(metricLabels[s.name]),
      value: s.name,
    }));
  }, [sourceData]);

  useEffect(() => {
    if (pcView || activityGroupOptions[1].nested?.length === 0) {
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const pc = urlParams.get('pcView') || '';

    let pcViewPhase: FullMenuOption | undefined = activityGroupOptions[0];
    activityGroupOptions.forEach((o) => {
      if (o.id === pc) {
        pcViewPhase = o;
      }
      pcViewPhase = o.nested?.find((n) => n.id === pc);
    });

    if (pcViewPhase) {
      selectPCView(pcViewPhase);
    } else {
      selectPCView(activityGroupOptions[0]);
    }
  }, [window.location.search, activityGroupOptions, pcView]);

  useEffect(() => {
    if (activityGroups || !rawData?.activity_group) {
      return;
    }
    setActivityGroups(rawData.activity_group);
  }, [rawData?.activity_group, activityGroups]);

  const { filtersBar, panel, selectedFilters } = MultiFilter({
    reportName: REPORT_NAME,
    drawerOpen,
    isLoading: loading,
    allFilters,
    availableFilters: rawData?.available_filters,
    leftFiltersBarSlot: (
      <div className="flex">
        <MultiLevelSelectbox
          header="View"
          shouldHaveNotNestedItems
          loading={loading}
          onSelectOption={onSelectPCView}
          selectedItem={pcView}
          options={activityGroupOptions}
        />
        {view !== 'Bubble View' ? undefined : (
          <PhaseSelectbox
            options={phaseOptions}
            phase={currentPhase}
            onSelectPhase={onPhaseChange}
          />
        )}
      </div>
    ),
    rightFiltersBarSlot: (
      <ChartViewTabs
        enabledList={['Bar View', 'Bubble View', 'List View']}
        disabledOptions={pcView?.category === 'Temporal Activities' ? ['Bubble View'] : []}
        defaultItem={view || 'Bar View'}
        onChange={onViewChange}
      />
    ),
    alwaysGroupBy: view === 'Bubble View',
    onOpenPanel: () => setDrawerOpen(true),
    onClosePanel: () => setDrawerOpen(false),
  });

  const downloadXLSX = useCallback(() => {
    generateXLSX({
      compareBy,
      allFilters,
      tableId,
      sourceData,
      labels: metricLabels,
      shouldHaveMinMax: true,
    });
  }, [sourceData, allFilters, compareBy]);

  const { barChartOptions, getBarChartData } = useBarChartEngine({
    unit: 'people',
    transformKeys: (val, significant) =>
      metricLabels[val] +
      (significant !== null && displayOptionList.includes('statisticalSignificance')
        ? ` (${significant ? 'sig' : 'insig'})`
        : ''),
    compareBy,
    displayOptionList,
    sourceData,
    allFilters,
    extendOptions: {
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: false,
          beginAtZero: true,
          title: {
            text: 'Average Count',
          },
          ticks: {
            stepSize: 1,
            callback: (v) => v,
          },
        },
      },
      plugins: {
        clickOnTick: {
          x: {
            enabled: true,
            onClick: (label, b, datasets: any[]) => {
              const dataset = datasets?.[0];

              const x = dataset?.phaseNames?.[label?.index as number];
              if (label?.scaleId === 'x' && x) {
                onViewChange('Bubble View');
                onPhaseChange(x);
              }
            },
          },
          y: {
            enabled: false,
          },
        },
      },
    },
  });

  const { bubbleChartOptions, getBubbleData } = useBubbleChartEngine({
    unit: 'people',
    currentPhase,
    compareBy,
    displayOptionList,
    isVerySmallDistance: true,
    sourceData: bubble_sourceData,
    allFilters,
    extendOptions: {
      scales: {
        x: {
          ticks: {
            stepSize: null as any,
            callback: (v) => `${Number(v).toFixed(1)}`,
          },
        },
        y: {
          ticks: {
            stepSize: null as any,
            callback: (v) => `${Number(v).toFixed(1)}`,
          },
        },
      },
    },
  });

  const closeDataPoint = useCallback((point: ChartDataPoint) => {
    setOpenedDataPoints((prev) => prev.filter((p) => p.point.case_id !== point.case_id));
  }, []);

  useEffect(() => {
    if (view === 'Bubble View' || view === 'Horizontal Bar View') {
      resetChartViews();
    }
  }, [view]);

  const footer = (
    <ChartReportFooterLabels
      compareBy={compareBy}
      sourceData={bubble_sourceData}
      allFilters={allFilters}
    />
  );

  const renderBubbleChart = () => (
    <BubbleChart
      id={chartId}
      data={getBubbleData() as any}
      filterSlot={
        <ReportFilterSlot
          displayOptions={peopleCountDisplayOptions}
          hideOptions
          onSwitchChartViews={switchChartViews}
          displayOptionList={displayOptionList}
          selectedFilters={selectedFilters}
          onDownloadChart={downloadChart}
        />
      }
      options={bubbleChartOptions}
      footer={footer}
    />
  );

  const renderBarChart = () => (
    <BarChart
      id={chartId}
      data={getBarChartData() as any}
      filterSlot={
        <ReportFilterSlot
          displayOptions={peopleCountDisplayOptions.map((d) => ({
            ...d,
            disabled: d.value === 'statisticalSignificance' && !compareBy,
          }))}
          hideOptions={compareBy === 'national_statistics'}
          onSwitchChartViews={switchChartViews}
          displayOptionList={displayOptionList}
          selectedFilters={selectedFilters}
          onDownloadChart={downloadChart}
        />
      }
      options={barChartOptions}
      footer={footer}
    />
  );

  const renderList = () => (
    <div>
      <div className="flex justify-between items-center">
        <div className="p-10">{selectedFilters}</div>
        <div className={classNames('mr-10')}>
          <div className="flex justify-between">
            <ChartViewButtons
              displayOptionList={displayOptionList}
              displayOptions={peopleCountDisplayOptions
                .map((d) => ({
                  ...d,
                  disabled: d.value === 'statisticalSignificance' && !compareBy,
                }))
                .filter((d) =>
                  ['statisticalSignificance', 'min', 'max', 'sampleSize'].includes(d.value),
                )}
              onSwitchChartViews={switchChartViews}
            />
            <DownloadButton title="Download Excel" onClick={downloadXLSX} />
          </div>
        </div>
      </div>
      <div
        className={classNames('whitespace-nowrap overflow-auto transition-all', {
          'w-[calc(100vw-200px)]': sidebarCollapsed,
          'w-[calc(100vw-320px)]': !sidebarCollapsed,
        })}
      >
        <GenesisTable<GenesisKeys>
          tableId={tableId}
          unit="p"
          showSize={displayOptionList.includes('sampleSize')}
          showMin={displayOptionList.includes('min')}
          showMax={displayOptionList.includes('max')}
          loading={loading}
          transformKeys={(val, significant) =>
            metricLabels[val] +
            (significant !== null && displayOptionList.includes('statisticalSignificance')
              ? ` (${significant ? 'sig' : 'insig'})`
              : '')
          }
          data={sourceData}
          compareBy={compareBy}
          allFilters={allFilters}
        />
      </div>
    </div>
  );
  return (
    <PermissionCheck check="readAnalytics" fallback={<div />}>
      <PageTitle
        header="People Count"
        crumbs={[{ title: 'Analytics', url: routesPath.analytics.replace('/:refId', '') }]}
      />

      <div className="relative">
        {panel}
        {filtersBar}
        <div className="mt-10 flex"></div>

        <div className="bg-white shadow-sm mt-10">
          <ChartViewSwitch
            skeleton={<BarChartReportSkeleton />}
            view={view}
            defaultView="Bar View"
            loading={loading}
            bubble={renderBubbleChart}
            bar={renderBarChart}
            list={renderList}
          />
        </div>
        {createPortal(
          <div className="">
            {openedDataPoints.map((dataPoint) => (
              <DataPointTooltip
                key={dataPoint.point.case_id}
                onClose={closeDataPoint}
                {...dataPoint}
              />
            ))}
          </div>,
          document.body,
        )}
      </div>
    </PermissionCheck>
  );
};
