import { Tooltip } from 'antd';
import classNames from 'clsx';
import { differenceInSeconds, format } from 'date-fns';

import type { ICaseEvent } from './types';

export const CaseEvent: React.FC<{
  event: ICaseEvent;
  zoom: number;
  loading?: boolean;
}> = ({ event, zoom, loading }) => {
  const diff = differenceInSeconds(new Date(event.end || ''), new Date(event.start || ''));

  if (loading) {
    return (
      <div className="truncate animate-pulse px-3 h-14 bg-athensGrey flex items-center justify-between w-full">
        <div className="bg-white w-3/5 h-6 rounded-md"></div>
        <div className="bg-white w-6 h-6 rounded-md"></div>
      </div>
    );
  }

  const isMicroTime = diff <= 60;
  const isSmallTime = diff <= 300;
  const isMiddleTime = diff > 300 && diff < 600;
  const isBigTime = diff >= 600;
  const isSmallText = Number(event.title?.length) < 150;

  const showBig =
    (isBigTime && !isSmallText) ||
    (isMiddleTime && isSmallText && zoom < 30) ||
    (isSmallTime && zoom < 5 && !isMicroTime) ||
    isBigTime;
  return (
    <Tooltip
      placement="top"
      title={`${(event as any).extendedProps.number} - ${event.title} (${
        event.start ? format(new Date(event.start || ''), 'HH:mm') : ''
      } - ${event.end ? format(new Date(event.end || ''), 'HH:mm') : ''})`}
    >
      <div className="w-full h-full">
        <div
          className={classNames(
            'text-ellipsis w-full h-full overflow-hidden text-text-black text-1220 font-bold py-1',
            {
              'pl-4': !isSmallTime,
              'pl-0 text-center': isSmallTime,
            },
          )}
        >
          {showBig && `${(event as any).extendedProps.number}. ${event.title}`}
          {!showBig && `${(event as any).extendedProps.number}`}
        </div>
      </div>
    </Tooltip>
  );
};
