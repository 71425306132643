import { addDays } from 'date-fns';

import { isOffRange } from './isOffRange';
import type { IBubbleEvent } from './types';

export const generateDummyData = (date: Date): IBubbleEvent[] => {
  const startDate = addDays(date, -40);
  const endDate = addDays(date, 40);

  const res: IBubbleEvent[] = [];
  for (let currentDate = startDate; currentDate < endDate; currentDate = addDays(currentDate, 1)) {
    res.push({
      count: 0,
      end: currentDate,
      start: currentDate,
      offRange: isOffRange(currentDate, date),
    });
  }

  return res;
};
