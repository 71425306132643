import React, { FC, useContext } from 'react';

import { useMe } from './useMe';
import type { UseMeValueType } from './useMe';

const UserContext = React.createContext<UseMeValueType>({
  isInited: false,
  currentOrg: null,
  data: { me: {} as UseMeValueType['data']['me'] },
  can: () => false,
  onOrgChange: () => void 0,
});

export const useUserContext = () => useContext<UseMeValueType>(UserContext);

const UserContextProviderComponent: FC = ({ children }) => {
  const value = useMe();

  return <UserContext.Provider value={value as UseMeValueType}>{children}</UserContext.Provider>;
};

export const UserContextProvider = React.memo(
  UserContextProviderComponent,
) as typeof UserContextProviderComponent;
