import clsx from 'clsx';
import { FC, useState } from 'react';

import { ReactComponent as MagnifierIcon } from '../../assets/magnifier.svg';

type SearchInputArgs = {
  value: string;
  onChange: (e: string) => void;
};

export const SearchInput: FC<SearchInputArgs> = ({ value, onChange }) => {
  const [focus, setFocus] = useState(false);
  return (
    <div
      className={clsx(
        'flex items-center border border-solid border-ghost w-full rounded-[0.6rem] h-[32px]',
        { 'border-perano': focus },
      )}
    >
      <div className="icon p-[0.9rem]">
        <MagnifierIcon className="fill-regentGrey" />
      </div>
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        type="text"
        placeholder="Search Clinics..."
        className="border-none w-full tracking-wider font-semibold  text-1214 font-lato placeholder:text-regentGrey placeholder:text-1214 placeholder:font-lato placeholder:tracking-wider placeholder:font-semibold px-0 focus:ring-0"
      />
    </div>
  );
};
