import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { $ } from '../../zeus';
import { CREATE_USER } from './constants';

export const useMutationCreateUser = (args?: GQLArgs<CreateMutationResponse<'createUser'>>) => {
  return useMutation(
    {
      createUser: [
        {
          input: {
            fullName: $`fullName`,
            email: $`email`,
            speciality: $`speciality`,
            refId: $`refId`,
            active: $`active`,
          },
          role: $`role`,
        },
        {
          fullName: true,
          email: true,
          speciality: true,
          refId: true,
          role: true,
          active: true,
        },
      ],
    },
    {
      operationName: CREATE_USER,
      successText: 'User has been created',
    },
    args,
  );
};
