import dateFormat from 'date-fns/format';

const DateRangePickerID = '#DateRangePickerID';

const w = window as any;
const $ = w.$;

type DateRangePickerChangeArgs = {
  date1: Date;
  date2: Date;
  value: string;
};

type initJqDatePickerProps = {
  maxDays?: number;
  minDays?: number;
  yearsRange?: number;
  minDate?: string | Date;
  maxDate?: string | Date;
  startOfWeek?: 'monday' | 'sunday';
  onCancel: () => void;
  onApply: (dates: [string, string]) => void;
  onSetStart: (date: string) => void;
  onSetEnd: (date: string) => void;
};

export const format = (date?: string | Date) => {
  try {
    return dateFormat(new Date(date || ''), 'yyyy-MM-dd');
  } catch (e) {
    return dateFormat(new Date(), 'yyyy-MM-dd');
  }
};

export const renderJqDatePicker = ({
  minDays,
  maxDays,
  minDate,
  maxDate,
  yearsRange,
  startOfWeek,
  onCancel,
  onApply,
  onSetStart,
  onSetEnd,
}: initJqDatePickerProps): Promise<{
  datePicker: any;
  onDestroy: () => void;
}> => {
  function datepickerFirstDateSelected(e: any, obj: DateRangePickerChangeArgs) {
    onSetEnd(format(obj.date1));
  }

  function datepickerChange(e: any, obj: DateRangePickerChangeArgs) {
    onSetStart(format(obj.date2));
  }
  function datepickerApply(e: any, obj: DateRangePickerChangeArgs) {
    onApply([format(obj.date1), format(obj.date2)]);
  }

  return new Promise((resolve, reject) => {
    const maybeDatePicker = document.querySelector<HTMLDivElement>('.date-picker-wrapper');
    function onDestroy() {
      $(DateRangePickerID)
        .unbind('datepicker-first-date-selected', datepickerFirstDateSelected)
        .unbind('datepicker-change', datepickerChange)
        .unbind('datepicker-apply', datepickerApply)
        .unbind('datepicker-cancel', onCancel);
    }
    if (maybeDatePicker) {
      resolve({ datePicker: $(DateRangePickerID).data('dateRangePicker'), onDestroy });
      return;
    }

    $(DateRangePickerID)
      .dateRangePicker({
        inline: true,
        container: DateRangePickerID,
        alwaysOpen: true,
        monthSelect: true,
        yearSelect: true,
        yearsRange,
        minDays,
        maxDays,
        startDate: minDate,
        endDate: maxDate,
        startOfWeek,
      })
      .bind('datepicker-first-date-selected', datepickerFirstDateSelected)
      .bind('datepicker-change', datepickerChange)
      .bind('datepicker-apply', datepickerApply)
      .bind('datepicker-cancel', onCancel)
      .bind('datepicker-rendered', () =>
        resolve({ datePicker: $(DateRangePickerID).data('dateRangePicker'), onDestroy }),
      );
  });
};

export const renderDPContainer = (pickerButtonRef: HTMLDivElement | null) => {
  return new Promise((resolve) => {
    let datePickerContainer = document.querySelector<HTMLDivElement>(DateRangePickerID);

    if (!datePickerContainer) {
      datePickerContainer = document.createElement('div');
      datePickerContainer.id = DateRangePickerID.replace('#', '');
      document.body.appendChild(datePickerContainer);
    }

    const x = pickerButtonRef?.getBoundingClientRect().x || 0;
    const y = pickerButtonRef?.getBoundingClientRect().y || 0;
    const height = pickerButtonRef?.getBoundingClientRect().height || 0;

    datePickerContainer.style.position = 'absolute';
    datePickerContainer.style.top = `${y + height + 10}px`;
    datePickerContainer.style.left = `${x}px`;
    datePickerContainer.style.zIndex = '1000';
    datePickerContainer.classList.add('transition-all');
    resolve(datePickerContainer);
  });
};

export const changeVisibility = (isOpen: boolean) => {
  const maybeDatePickerContainer = document.querySelector(DateRangePickerID);

  if (maybeDatePickerContainer) {
    maybeDatePickerContainer.classList.remove(isOpen ? 'hidden' : 'block');
    maybeDatePickerContainer.classList.add(isOpen ? 'block' : 'hidden');
  }
};

export const handleClickOutside = (callback: () => void, button?: HTMLDivElement) => {
  const onClickOutside = (e: any) => {
    const datePickerContainer = document.querySelector<HTMLDivElement>(DateRangePickerID);
    if (
      datePickerContainer &&
      !(datePickerContainer.contains(e.target) || button?.contains(e.target))
    ) {
      callback();
    }
  };

  document.addEventListener('click', onClickOutside);

  return () => {
    document.removeEventListener('click', onClickOutside);
  };
};
