import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { $ } from '../../zeus';
import { UPDATE_USER } from './constants';

export const useMutationUpdateUser = (args?: GQLArgs<CreateMutationResponse<'updateUser'>>) => {
  return useMutation(
    {
      updateUser: [
        {
          email: $`email`,
          input: {
            fullName: $`fullName`,
            speciality: $`speciality`,
            refId: $`refId`,
            active: $`active`,
          },
          role: $`role`,
          setDefaultPassword: $`setDefaultPassword`,
        },
        {
          fullName: true,
          email: true,
          speciality: true,
          refId: true,
          active: true,
          forceResetPassword: true,
        },
      ],
    },
    { successText: 'User has been updated', operationName: UPDATE_USER },
    args,
  );
};
