export const SampleSizeSvg = ({ classNames }: { classNames?: string[] }) => {
  return (
    <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0221 0.97197C16.1446 0.576279 15.9231 0.156218 15.5274 0.0337376C15.1317 -0.0887426 14.7117 0.132739 14.5892 0.52843L13.4727 4.1356H7.98727L8.96646 0.97217C9.08894 0.576478 8.86746 0.156418 8.47177 0.0339374C8.07608 -0.0885428 7.65602 0.132938 7.53354 0.528629L6.41706 4.1356H2.25C1.83579 4.1356 1.5 4.47138 1.5 4.8856C1.5 5.29981 1.83579 5.6356 2.25 5.6356H5.95275L4.29213 11.0005H0.75C0.335786 11.0005 0 11.3363 0 11.7505C0 12.1647 0.335786 12.5005 0.75 12.5005H3.82783L2.97763 15.2472C2.85515 15.6429 3.07663 16.0629 3.47232 16.1854C3.86801 16.3079 4.28807 16.0864 4.41055 15.6907L5.39805 12.5005H10.8834L10.0333 15.247C9.91081 15.6427 10.1323 16.0627 10.528 16.1852C10.9237 16.3077 11.3437 16.0862 11.4662 15.6905L12.4536 12.5005H16.25C16.6642 12.5005 17 12.1647 17 11.7505C17 11.3363 16.6642 11.0005 16.25 11.0005H12.9179L14.5786 5.6356H17.75C18.1642 5.6356 18.5 5.29981 18.5 4.8856C18.5 4.47138 18.1642 4.1356 17.75 4.1356H15.0429L16.0221 0.97197ZM11.3477 11.0005L13.0084 5.6356H7.52297L5.86235 11.0005H11.3477Z"
        className={classNames?.[0]}
      />
    </svg>
  );
};
