import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { Calendar, dateFnsLocalizer, CalendarProps } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import { compareAsc } from 'date-fns';

import RoomEvent from './RoomEvent';
import SkeletonEvent from './SkeletonEvent';
import { generateDummyData } from './generateDummyData';
import { getLowerBound, getUpperBound } from './getBounds';
import { getOperationTypeClassName } from './getOperationTypeClassName';
import './style.scss';
import type { IRoomEvent } from './types';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type RoomsCalendarProps = Pick<CalendarProps, 'onNavigate'> & {
  date?: Date;
  loading: boolean;
  events: IRoomEvent[];
  rooms?: string[];
  singleColumn?: boolean;
  onSelectEvent?: (event: IRoomEvent) => void;
};

const RoomsCalendar: FC<RoomsCalendarProps> = ({
  date,
  loading,
  onNavigate,
  onSelectEvent,
  events,
  rooms,
  singleColumn = false,
}) => {
  const resources = useMemo(
    () => rooms?.map((r) => ({ resourceId: r, resourceTitle: r })),
    [rooms],
  );

  const minDate = getLowerBound(events, date);
  const maxDate = getUpperBound(events, date);

  minDate.setMinutes(0, 0);

  const roomsEvents = useMemo(
    () => {
      return events.map((event) => ({
        ...event,
        resourceId: event.roomSlug,
        // this way we show operation that was done across days
        end: event.end && compareAsc(event.end, maxDate) === 1 ? maxDate : event.end,
        start: event.start && compareAsc(event.start, minDate) === -1 ? minDate : event.start,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [events, date],
  );

  const dummyData = useMemo(
    () => generateDummyData(date ?? new Date(), resources?.length),
    [date, resources?.length],
  );

  return (
    <div className={clsx('roomsCalendar', singleColumn ? 'displayEventsInOneColumn' : '')}>
      <Calendar
        step={15}
        min={minDate}
        max={maxDate}
        formats={{
          timeGutterFormat: 'HH:mm',
        }}
        defaultView="day"
        localizer={localizer}
        views={{ day: true }}
        style={{ height: '100%', width: '100%' }}
        date={date}
        onNavigate={onNavigate}
        eventPropGetter={(event) => ({
          className: getOperationTypeClassName(event.procedureType, loading),
        })}
        onSelectEvent={loading ? undefined : onSelectEvent}
        components={{
          event: loading ? SkeletonEvent : RoomEvent,
        }}
        events={loading ? dummyData.events : roomsEvents}
        resources={loading ? dummyData.rooms : resources}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceTitle"
      />
    </div>
  );
};

export default RoomsCalendar;
export type { IRoomEvent };
