/* eslint-disable max-len */
import clsx from 'clsx';
export const StdDevSvg = ({ classNames }: { classNames?: string[] }) => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.40599 3.81547L5.41179 1.14835L5.41154 1.1486C5.41229 0.935883 5.32846 0.731722 5.17818 0.581436C5.02764 0.430899 4.82348 0.347067 4.61076 0.347822C4.17249 0.349085 3.81728 0.704287 3.81577 1.14282L3.81577 3.81574L1.12596 3.79886C0.687433 3.79987 0.332231 4.15507 0.330968 4.59385C0.330214 4.80656 0.414044 5.01072 0.56433 5.16101C0.714867 5.31155 0.919027 5.39538 1.13174 5.39462L3.79886 5.38883L3.81575 8.07863C3.81701 8.51741 4.17221 8.87261 4.611 8.87388C5.04953 8.87237 5.40473 8.51717 5.406 8.07889L5.41179 5.41177L8.07892 5.40597C8.51745 5.40445 8.87265 5.04925 8.87391 4.61097C8.87265 4.17219 8.51745 3.81699 8.07866 3.81572L5.40599 3.81547Z"
        className={classNames?.[0]}
      />
      <path
        d="M18.1018 15.4265C18.5466 15.4298 18.9096 15.0718 18.9127 14.6267C18.9157 14.1822 18.5577 13.8191 18.1132 13.8161L13.1323 13.8217C12.6887 13.8232 12.3307 14.1837 12.3325 14.627C12.334 15.07 12.6945 15.428 13.1376 15.4265L18.1018 15.4265Z"
        className={classNames?.[1]}
      />
      <rect
        x="16.5332"
        y="2.30029"
        width="1.52104"
        height="20"
        rx="0.760521"
        transform="rotate(45 16.5332 2.30029)"
        className={classNames?.[2]}
      />
    </svg>
  );
};
