import { format } from 'date-fns';

import clockSvg from '../../assets/clock-small.svg';
import { convertStringToDatetime } from '../../utilities/dateTime';
import { ProcedureTypeBadge } from '../../components/ProcedureTypeBadge';
import { ReactComponent as CalendarSvg } from '../../assets/calendar-menu.svg';
import { ReactComponent as ZoomIn } from '../../assets/zoom-in.svg';
import { ReactComponent as ZoomOut } from '../../assets/zoom-out.svg';
import type { ModalityType } from '../../types';
import { zoomMax, zoomMin } from '../../components/CaseTimeline/zoom';
import { calculateZoom } from './../../components/CaseTimeline/zoom';
import clsx from 'clsx';
import { useQueryCaseResponse } from '../../rest/query/useQueryCase';

type TopPanelProps = {
  start?: Date | null;
  end?: Date | null;
  zoom: number;
  caseId: string;
  meta: useQueryCaseResponse['meta'];
  onZoomChange: (fn: (z: number) => number) => void;
  loading?: boolean;
  surgeonName?: string;
  procedureType?: ModalityType;
};

export const TopPanel: React.FC<TopPanelProps> = ({
  start,
  end,
  caseId,
  zoom,
  meta,
  onZoomChange,
  loading,
}) => {
  const zoomOut = () => onZoomChange(calculateZoom('out'));
  const zoomIn = () => onZoomChange(calculateZoom('in'));
  return (
    <div className="w-full flex justify-between h-8">
      <div className="font-bold text-1620 text-text-black flex items-center gap-x-4">
        {!loading && (
          <div className="flex items-center">
            <div>Case {caseId}</div>
            <div className="bg-black bg-opacity-20 h-8 mx-2 w-px"></div>
            <div>{meta.OR}</div>
            <div className="bg-black bg-opacity-20 h-8 mx-2 w-px"></div>
            <div>{meta.surgeon ?? 'Unknown Surgeon'}</div>
            <div className="bg-black bg-opacity-20 h-8 mx-2 w-px"></div>
            <div>Scheduled at {meta.scheduled_time}</div>
            <div className=" h-8 mx-2 w-px"></div>
            <ProcedureTypeBadge procedureType={meta.modality as ModalityType} />
          </div>
        )}
      </div>
      {!loading && (
        <div className="flex gap-x-5">
          <div
            onClick={zoomIn}
            className={clsx('flex items-center text-1220 font-semibold', {
              'cursor-pointer hover:fill-persianBlue hover:text-persianBlue': zoom > zoomMin,
              'fill-ghost text-ghost': zoom === zoomMin,
            })}
          >
            <ZoomIn className="opacity-70 w-[1.6rem] h-[1.7rem] mr-2" />
            Zoom In
          </div>
          <div
            onClick={zoomOut}
            className={clsx('flex items-center text-1220 font-semibold', {
              'cursor-pointer hover:fill-persianBlue hover:text-persianBlue': zoom < zoomMax,
              'fill-ghost text-ghost': zoom === zoomMax,
            })}
          >
            <ZoomOut className="opacity-70 w-[1.6rem] h-[1.7rem] mr-2" />
            Zoom Out
          </div>

          <div className="flex items-center text-1220 font-semibold">
            <CalendarSvg className="opacity-70 w-[1.6rem] h-[1.7rem] mr-2" />
            {start && format(start, 'MMM d, yyyy')}
          </div>
          <div className="flex items-center text-1220 font-semibold">
            <img className="mr-2" src={clockSvg} alt="clock-icon" />
            {start && format(start, 'HH:mm')} - {end && format(end, 'HH:mm')}
          </div>
        </div>
      )}
    </div>
  );
};
