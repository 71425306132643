import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Status } from './Status';
import { useUsers } from './useUsers';
import editIcon from '../../assets/edit.svg';
// import { RoleSelector } from './RoleSelector';
import Loading from '../../components/Loading';
import { Table } from '../../components/Table';
import deleteIcon from '../../assets/delete.svg';
import { Button } from '../../components/Button';
import { SearchBar } from '../../components/SearchBar';
import { UserDrawer } from '../../components/UserDrawer';
import { useAddEditUser } from './useAddEditUser';
import { ActionContainer } from './ActionContainer';
import { userRoleList } from '../../constants/userRole';
import { ColoredSlug } from '../../components/ColoredSlug';
import type { RoleEnum } from '../../zeus';
import { ConfirmationModal } from '../../components/ConfirmationModal/ConfirmationModal';
import { useMutationRemoveUser } from '../../graphql/mutation/removeUser';

const translations = {
  'users.button.add-new-user': 'Add New User',
  'users.table.column.role': 'Role',
  'users.table.column.name': 'Name',
  'users.table.column.email': 'Email',
  'users.table.column.status': 'Status',
  'users.table.column.actions': 'Actions',
};

export const Users = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [userToDelete, setUserToDelete] = useState<any>();

  const { open, isEdit, key, data, startAdd, startEdit, stop } = useAddEditUser();

  const { loading, usersList, searchQuery, pagination, onScroll, onSearch, refetchItems } =
    useUsers();

  const { mutation: removeUser } = useMutationRemoveUser({
    onCompleted: () => {
      refetchItems();
    },
  });

  return (
    <>
      <div className="pt-12">
        <div className="flex flex-row items-center justify-between mb-12">
          <div className="flex gap-x-8 w-1/2">
            {/* <RoleSelector /> */}
            <SearchBar value={searchQuery} onChange={onSearch} placeholder="Search Users..." />
          </div>
          <div className="ml-4">
            <Button type="dark" onClick={startAdd}>
              {translations['users.button.add-new-user']}
            </Button>
          </div>
        </div>
        <InfiniteScroll
          hasMore={!!pagination?.nextPage}
          dataLength={usersList.length}
          style={{ display: 'flex', flexDirection: 'column' }}
          loader={
            <div>
              <Loading />
            </div>
          }
          next={onScroll}
        >
          <Table<any>
            data={usersList}
            loading={loading}
            config={{
              columns: [
                {
                  headerStyle: { width: '25.6%' },
                  header: () => translations['users.table.column.name'],
                  getValue: ({ fullName }) => (
                    <ColoredSlug name={fullName} size="medium" className="font-bold" />
                  ),
                },
                {
                  headerStyle: { width: '15.9%' },
                  header: () => translations['users.table.column.role'],
                  getValue: ({ role }) => userRoleList[role as RoleEnum]?.label,
                },
                {
                  header: () => translations['users.table.column.email'],
                  getValue: ({ email }) => email,
                },
                {
                  headerStyle: { width: '14.6%' },
                  header: () => translations['users.table.column.status'],
                  getValue: ({ active }) => <Status status={active ? 'active' : 'inactive'} />,
                },
                {
                  header: () => translations['users.table.column.actions'],
                  headerStyle: { width: 140 },
                  getValue: (user) => (
                    <div className="flex gap-x-4">
                      <ActionContainer
                        title="Edit"
                        icon={editIcon}
                        onClick={() => startEdit(user)}
                      />
                      <ActionContainer
                        icon={deleteIcon}
                        title="Delete"
                        onClick={() => setUserToDelete(user)}
                      />
                    </div>
                  ),
                },
              ],
            }}
          />
        </InfiniteScroll>
      </div>
      {userToDelete && (
        <ConfirmationModal
          confirmButtonName="Delete"
          onConfirm={() => {
            removeUser({ variables: { email: userToDelete.email } });
          }}
          onClose={() => setUserToDelete(null)}
        >
          <div className="w-[17rem] text-center text-1624 text-black font-lato font-bold mt-8 mb-4">
            Are you sure you want to delete{' '}
            {userToDelete.fullName || userToDelete.email || 'this user'}?
          </div>
        </ConfirmationModal>
      )}
      <UserDrawer
        data={data}
        open={open}
        stop={stop}
        isEdit={isEdit}
        componentKey={key}
        onSuccess={refetchItems}
      />
    </>
  );
};
