import { useCallback, useMemo, useRef } from 'react';
import { chartColors, chartColorsDarker, colors } from '../../constants/colors';
import { Set, DataSet } from './types';
import { makeHorizontalBarOptions } from './horizontalBarOptions';
import { GenesisSeqData } from '../../rest/query/useQueryGenesisMetrics';
import { CompareValues } from '../MultiFilter/MultiFilters.types';
import { DisplayOptionValue } from '../../page/genesis-analytics/useControls';
import { getEntityName, sortItems } from '../../utilities/helpers';
import { useCreateChartTooltip } from '../ChartTooltip/ChartTooltip';
import { ChartOptions } from 'chart.js';

const barOptions = {
  fill: true,
  borderColor: 'white',
  borderRadius: 6,
  borderWidth: 1,
  tension: 0.3,
  groupPadding: 2,
  pointRadius: 0,
  errorBarWhiskerRatio: 0.1,
};

type BarChartEngineProps = {
  sourceData: GenesisSeqData;
  compareBy: CompareValues;
  displayOptionList: DisplayOptionValue[];
  allFilters: AllFilters;
  itemsPerPage: number;
  page: number;
  extendOptions?: ChartOptions<'bar'>;
};

export const fillDataSet =
  (labels: string[], set: Set[string], allFilters: AllFilters, compareBy: CompareValues) =>
  (gen: Partial<GenesisSeqData[0]['plot'][0]>) => {
    const duration = Number(gen?.data_point);

    if (gen?.case_id) {
      const ent = getEntityName({
        value: '',
        allFilters,
        compareBy,
      });
      const label = gen.case_id;

      set.entities?.push({
        key: '',
        value: ent,
      });

      set.phaseNames.push(gen.case_id);

      if (!labels.includes(label)) {
        labels.push(label);
      }

      set.data.push(duration);
    }
  };

export const useHorizontalBarChartEngine = ({
  sourceData,
  compareBy,
  page,
  itemsPerPage,
  displayOptionList,
  allFilters,
  extendOptions,
}: BarChartEngineProps) => {
  const tooltipRef = useRef(document.createElement('div'));

  useCreateChartTooltip(tooltipRef);

  const from = (page - 1) * itemsPerPage;
  const to = page * itemsPerPage;
  const chart = useMemo(() => {
    const _labels: string[] = [];
    const set: Set = {};

    sourceData.forEach(({ name, plot, averageDuration }, i) => {
      set[name] = {
        name,
        phaseNames: [],
        labels: [],
        entities: [],
        color: /rest/i.test(name) ? colors.neutralGray : [...chartColors][i],
        darkColor: /rest/i.test(name) ? colors.neutralGrayDark : [...chartColorsDarker][i],
        textColor: [...chartColors][i],
        dataPoints: [],
        standardDeviation: [],
        averageDuration,
        significant: null,
        data: [],
        datalabels: [],
      };
      plot.forEach(fillDataSet(_labels, set[name], allFilters, compareBy));
    });

    const setsNames = Object.keys(set);

    const pairs = _labels
      .map((label, i) => ({
        label,
        index: i,
      }))
      .sort((a, b) => Number(a.label) - Number(b.label));

    setsNames.forEach((k) => {
      const setItem = set[k];
      const newData: number[] = [];
      pairs.forEach(({ index }) => {
        newData.push(setItem.data[index]);
      });
      setItem.data = newData;
    });

    const labels = pairs
      .map(({ label }) => `Case ${label}`)
      .filter((l, i) => setsNames.some((k) => !!set[k].data[i]));

    const excludedLabelsIndexes = pairs
      .filter((l, i) => !setsNames.some((k) => !!set[k].data[i]))
      .map(({ index }) => index);

    setsNames.forEach((k) => {
      const setItem = set[k];
      setItem.data = setItem.data.filter((_, i) => !excludedLabelsIndexes.includes(i));
    });

    const chartData: DataSet[] = setsNames.map((k, i) => ({
      id: i,
      labels: set[k].labels,
      label: set[k].name,
      data: set[k].data,
      phaseNames: set[k].phaseNames,
      dataPoints: set[k].dataPoints,
      datalabels: set[k].datalabels,
      darkColor: set[k].darkColor,
      averageDuration: set[k].averageDuration,
      tooltipLabel: getEntityName({
        value: set[k].name,
        allFilters,
        compareBy,
      }),

      standardDeviation: set[k].standardDeviation,
      general: set,
      significant: set[k].significant,
      color: set[k].color,
      textColor: set[k].textColor,
    }));

    const dataItems = sortItems(chartData, compareBy);

    return { labels, data: dataItems };
  }, [sourceData, compareBy, page]);

  const getHorizontalBarChartData = useCallback(() => {
    const slicedLabels = chart.labels.slice(from, to);
    let arr: (number | null)[] = [];

    const datasets = chart.data.map((item) => {
      const slicedData = item.data.slice(from, to);
      if (!arr.length || arr.length < slicedData.length) {
        arr = slicedData;
      }
      return {
        ...item,
        data: [item.averageDuration, ...slicedData],
        dataPoints: [],
        backgroundColor: sourceData.length > 1 ? item.color : chartColors[4],
        ...barOptions,
      };
    });

    if (slicedLabels.length < itemsPerPage) {
      slicedLabels.push(...new Array(itemsPerPage - slicedLabels.length).fill(''));
    }

    return {
      labels: ['Inliers Mean', ...slicedLabels],
      datasets,
    };
  }, [chart, from, to]);

  const getTotalItems = useCallback(() => {
    let maxTotal = 0;
    chart.data.forEach((item) => {
      const total = item.data.length;
      if (total > maxTotal) {
        maxTotal = total;
      }
    });

    return maxTotal;
  }, [chart]);

  const horizontalBarChartOptions = makeHorizontalBarOptions({
    tooltipRef,
    chart,
    from,
    to,
    extendOptions,
    showDataLabels: displayOptionList.includes('sampleSize'),
  });

  return { getHorizontalBarChartData, getTotalItems, horizontalBarChartOptions };
};
