import { FC } from 'react';

import { Arrow } from '../Arrow';
import MonthLabel from '../MonthLabel';

export type DateHeaderProps = {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled?: boolean;
  nextMonthButtonDisabled?: boolean;
};

/* eslint-disable max-len */
const DateHeader: FC<DateHeaderProps> = ({ date, decreaseMonth, increaseMonth }) => {
  return (
    <div className="react-datepicker-custom-header">
      <div className="react-datepicker-custom-header-actions flex items-center justify-between w-full px-4">
        <Arrow direction="left" onClick={decreaseMonth} />
        <MonthLabel date={date} className="text-persianBlue text-1620 font-semibold" />
        <Arrow direction="right" onClick={increaseMonth} />
      </div>
    </div>
  );
};

export default DateHeader;
