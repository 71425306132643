export function transformAllFilters(filters?: Record<AvailableFilterKeys, string[]>): AllFilters {
  const rooms = filters?.OR || [];
  const weekday = filters?.weekday || [];
  const caseNumber = (filters?.case_number || []).map((n) => String(n));
  const modality = filters?.modality || [];
  const robotType = filters?.robot_type || [];
  const casesPerDay = filters?.cases_per_day || [];
  const sameSurgeon = filters?.same_surgeon || [];
  const multiProcedure = filters?.multi_procedure || [];
  const multiSurgeon = filters?.multi_surgeon || [];
  const multiCases = filters?.multi_cases || [];
  const allRobotic = filters?.all_robotic || [];
  const converted = filters?.converted || [];
  const turnedOver = filters?.turned_over || [];

  const surgeons: SurgeonType[] = (filters?.surgeon_id || [])
    .map((code, index) => ({
      code,
      name: (filters?.surgeon || [])[index],
    }))
    .filter((s) => s.code !== 'None');

  const procedures: ProcedureType[] = (filters?.procedure_code || [])
    .map((code, index) => ({
      code,
      name: (filters?.procedure || [])[index],
    }))
    .filter((p) => String(p.code) !== '0');

  const specialties: SpecialtyType[] = (filters?.specialty_code || [])
    .map((code, index) => ({
      code,
      name: (filters?.specialty || [])[index],
    }))
    .filter((s) => String(s.code) !== 'null');

  return {
    sameSurgeon,
    multiProcedure,
    multiSurgeon,
    multiCases,
    allRobotic,
    converted,
    turnedOver,
    casesPerDay,
    robotType,
    modality,
    caseNumber,
    weekday,
    rooms,
    surgeons,
    procedures,
    specialties,
  };
}
