import { Skeleton } from 'antd';

import { FormContainer } from '../FormContainer';

export const FormSkeleton = () => {
  return (
    <FormContainer
      left={<Skeleton.Avatar size="large" />}
      actions={
        <>
          <Skeleton.Button />
          <Skeleton.Button />
        </>
      }
    >
      <Skeleton />
    </FormContainer>
  );
};
