export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const dateSelectOptions = [
  {
    label: '60 days',
    value: '60',
  },
  {
    label: '90 days',
    value: '90',
  },
  {
    label: '180 days',
    value: '180',
  },
  {
    label: '365 days',
    value: '365',
  },
  {
    label: 'Year to Date',
    value: 'year-to-date',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];
