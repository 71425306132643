import { FC } from 'react';
import { Value } from '../../components/DashboardWidget/Value';
import { DashboardWidgetProps, SurgeryType, WidgetsType } from './types';
/* eslint-disable no-nested-ternary */

import { ReactComponent as WidgetUpIcon } from '../../assets/widget-up.svg';
import { ReactComponent as WidgetDownIcon } from '../../assets/widget-down.svg';

import operationTheaterSvg from '../../assets/open-cases.svg';
import clockSvg from '../../assets/clock.svg';
import roboticSurgerySvg from '../../assets/robotic-surgery.svg';
import lapCaseSvg from '../../assets/lap-case.svg';
import totalCasesSvg from '../../assets/total-cases.svg';
import cleaningSvg from '../../assets/cleaning.svg';
import clsx from 'clsx';
import { getHumanTimeFromSeconds } from '../../utilities/dateTime';
import { DashboardWidgetSkeleton } from '../DashboardWidgetSkeleton/DashboardWidgetSkeleton';
import { useHistory } from '../../hooks/useHistory';

const RenderIcon: FC<{ analyticsIsUp: boolean | null; isTime: boolean }> = ({
  analyticsIsUp,
  isTime,
}) => {
  if (analyticsIsUp === null) return null;

  return analyticsIsUp ? (
    <WidgetUpIcon className={clsx({ 'stroke-venetianRed': isTime, 'stroke-green': !isTime })} />
  ) : (
    <WidgetDownIcon className={clsx({ 'stroke-venetianRed': !isTime, 'stroke-green': isTime })} />
  );
};

const operationTypeIcons = {
  [WidgetsType.OPEN_CASES]: operationTheaterSvg,
  [WidgetsType.IDLE_TIME]: clockSvg,
  [WidgetsType.ROBOTIC_CASES]: roboticSurgerySvg,
  [WidgetsType.LAP_CASES]: lapCaseSvg,
  [WidgetsType.CLEANING_TIME]: cleaningSvg,
  [WidgetsType.OPEN_AVERAGE_CLEANING_TIME]: cleaningSvg,
  [WidgetsType.LAP_AVERAGE_CLEANING_TIME]: cleaningSvg,
  [WidgetsType.ROBOTIC_AVERAGE_CLEANING_TIME]: cleaningSvg,
  [WidgetsType.TOTAL_CASES]: totalCasesSvg,
  [WidgetsType.OPEN_AVERAGE_DELAY_TIME]: clockSvg,
  [WidgetsType.ROBOTIC_AVERAGE_DELAY_TIME]: clockSvg,
  [WidgetsType.TOTAL_AVERAGE_DELAY_TIME]: clockSvg,
};

const getIcon = (type: WidgetsType) => {
  if (type in operationTypeIcons) {
    return operationTypeIcons[type];
  }

  return '';
};

const getDifference = (value: string | number, prevValue: string | number) => {
  const differenceValue = +value - +prevValue;
  if (differenceValue > 0) return `+${differenceValue}`;
  return differenceValue;
};

export const DashboardWidget: FC<DashboardWidgetProps> = ({
  loading,
  title,
  value,
  prevValue,
  type,
  rangeType,
  analyticsIsUp,
  isTime,
  surgeryType,
  valueContent,
  previousPeriodContent,
}) => {
  const difference = getDifference(value, prevValue);
  const minsTime = getHumanTimeFromSeconds(+difference, Math.abs(+difference) < 60);

  const iconBG = clsx({
    'bg-lavender': surgeryType === SurgeryType.OPEN,
    'bg-selago': surgeryType === SurgeryType.ROBOTIC,
    'bg-oldLace': surgeryType === SurgeryType.LAPAROSCOPIC,
    'bg-cultured': surgeryType === SurgeryType.OVERAL,
  });

  if (loading) {
    return <DashboardWidgetSkeleton />;
  }

  return (
    <div className="widget shadow-[0px_5px_8px_rgba(12,45,202,0.05)] bg-white w-[36rem]  rounded-lg h-[14.5rem] p-5">
      <div className="flex justify-between h-full">
        <div className="left flex flex-col justify-between items-start pl-3 pt-3">
          <div>
            <div className="type opacity-70 text-black text-1220 font-lato font-semibold mb-2">
              {surgeryType}
            </div>
            <div className="title text-1620 font-semibold font-lato">{title}</div>
          </div>
          <div className="flex justify-start items-center pb-8">
            <div className="icon">
              <RenderIcon isTime={isTime} analyticsIsUp={analyticsIsUp} />
            </div>
            <div className="text-3220 font-bold font-lato px-2">
              <Value isTime={isTime} value={value} valueContent={valueContent} />
            </div>
          </div>
        </div>
        <div className="right flex flex-col justify-between items-end p-3 pb-0">
          <div className={`icon p-4 w-[5rem] h-[5rem] rounded-[0.8rem] mb-8 ${iconBG}`}>
            <img src={getIcon(type)} alt="widget-icon" />
          </div>
          {rangeType !== 'all' && (
            <div
              className={clsx(
                ' bg-opacity-20 px-[0.5rem] rounded-[0.4rem]  text-1420 font-semibold font-lato flex justify-center w-fit',
                { 'bg-green text-green': (analyticsIsUp && !isTime) || (!analyticsIsUp && isTime) },
                {
                  'bg-venetianRed text-venetianRed':
                    (analyticsIsUp && isTime) || (!analyticsIsUp && !isTime),
                },
              )}
            >
              {+difference && analyticsIsUp ? '+' : '-'}
              {isTime ? minsTime : value}
            </div>
          )}
          <div className="prev text-1020 text-black font-lato opacity-70">
            {rangeType !== 'all' && !previousPeriodContent
              ? +difference === 0
                ? 'no changes'
                : 'vs. previous period'
              : previousPeriodContent}
            {rangeType === 'all' && 'All-time period'}
          </div>
        </div>
      </div>
    </div>
  );
};

export type { DashboardWidgetProps };
