/* eslint-disable */
import { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';

import { pieChartOptions } from '../../utilities/chartUtils';
import { ChartData, ChartOptions, DefaultDataPoint } from 'chart.js';
import { BGPlugin } from '../../chartPlugins/BGPlugin';
import { DoughnutCenterPlugin } from '../../chartPlugins/DoughnutCenterPlugin';

export type ChartProps = {
  id: string;
  options?: ChartOptions<'doughnut'>;
  width?: number;
  height?: number;
  data?: ChartData<'doughnut', DefaultDataPoint<'doughnut'>, string | undefined>;
};

const defaultData = {
  datasets: [
    {
      label: 'My First Dataset',
      data: [16, 18],
      backgroundColor: ['#9797FC', '#F7B295'],
      spacing: 6,
      hoverOffset: 2,
      borderRadius: 8,
      rotation: -100,
    },
  ],
};

export const PieChart: FC<ChartProps> = ({ id, data = defaultData, options = pieChartOptions }) => {
  return (
    <div className="relative h-full w-[51rem]">
      <Doughnut id={id} data={data} plugins={[BGPlugin, DoughnutCenterPlugin]} options={options} />
    </div>
  );
};
