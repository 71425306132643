import { addHours, addMinutes, set } from 'date-fns';

import type { IRoomEvent } from './types';

const generateEvents = (date: Date, resourceId: string) => {
  const startTime = set(date, {
    hours: 7 + Math.random() * 2,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const endTime = set(date, {
    hours: 17,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const events: (IRoomEvent & { resourceId?: string })[] = [];
  for (
    let currentTime = startTime;
    currentTime < endTime;
    currentTime = addHours(currentTime, Math.random() * 3 + 3)
  ) {
    const start = set(currentTime, { minutes: Math.random() * 30 });
    events.push({
      resourceId,
      start: start,
      end: addMinutes(start, Math.random() * 30),
    });
  }

  return events;
};

export const generateDummyData = (date: Date, roomsCount?: number) => {
  const actualRoomsCount = roomsCount || 2;

  const rooms = Array(actualRoomsCount)
    .fill({})
    .map((obj, index) => ({
      resourceTitle: '',
      resourceId: `R${index + 1}`,
    }));

  const events = rooms.flatMap((r) => generateEvents(date, r.resourceId));

  return { events, rooms };
};
