import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { $ } from '../../zeus';
import { UPDATE_HOSPITAL } from './constants';

export const useMutationUpdateHospital = (
  args?: GQLArgs<CreateMutationResponse<'updateOrganization'>>,
) => {
  return useMutation(
    {
      updateOrganization: [
        {
          input: {
            name: $`name`,
            logoUrl: $`logoUrl`,
            parentOrganizationSlug: $`parentOrganizationSlug`,
            refId: $`refId`,
          },
          slug: $`slug`,
        },
        {
          name: true,
          parent: {
            name: true,
            timeZone: true,
            slug: true,
          },
          logoUrl: true,

          refId: true,
          slug: true,
          timeZone: true,
        },
      ],
    },
    { operationName: UPDATE_HOSPITAL, successText: 'Clinic has been updated' },
    args,
  );
};
