import { useMutation } from '../../hooks/useMutation';
import { GQLArgs } from '../../types';
import { $ } from '../../zeus';
import { RESYNC } from './constants';

export const useMutationResync = (args?: GQLArgs) => {
  return useMutation(
    {
      resyncAll: [
        {
          organizationSlug: $`organizationSlug`,
        },
        {
          message: true,
        },
      ],
    },
    { operationName: RESYNC },
    args,
  );
};
