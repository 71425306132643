/* eslint-disable max-len */
import clsx from 'clsx';
export const StatSignSvg = ({ classNames }: { classNames?: string[] }) => {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.73203 1.90171C8.40031 0.73665 9.64085 0.0180664 10.984 0.0180664C12.3271 0.0180664 13.5676 0.73665 14.2359 1.90171L20.6522 13.0888C20.9372 13.5854 21.087 14.1479 21.087 14.7205C21.087 16.5316 19.6187 17.9999 17.8076 17.9999H4.16041C2.34914 17.9999 0.880859 16.5316 0.880859 14.7205C0.880859 14.1479 1.03066 13.5854 1.31563 13.0888L7.73203 1.90171ZM10.984 1.70191C10.2442 1.70191 9.56074 2.09772 9.19273 2.7395L2.77599 13.9266C2.63737 14.1683 2.56453 14.4419 2.56453 14.7207C2.56453 15.6019 3.27903 16.3162 4.16023 16.3162H17.8074C18.6885 16.3162 19.403 15.6019 19.403 14.7207C19.403 14.4419 19.3301 14.1683 19.1915 13.9266L12.7748 2.7395C12.4067 2.0977 11.7233 1.70191 10.9835 1.70191H10.984Z"
        className={clsx(classNames?.[0])}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9838 5.04492C11.4488 5.04492 11.8257 5.42181 11.8257 5.88685V10.3011C11.8257 10.7661 11.4488 11.143 10.9838 11.143C10.5187 11.143 10.1418 10.7661 10.1418 10.3011V5.88685C10.1418 5.42181 10.5187 5.04492 10.9838 5.04492ZM10.9838 12.8003C11.4488 12.8003 11.8257 13.1772 11.8257 13.6422V14.0423C11.8257 14.5072 11.4488 14.8843 10.9838 14.8843C10.5187 14.8843 10.1418 14.5072 10.1418 14.0423V13.6422C10.1418 13.1772 10.5187 12.8003 10.9838 12.8003Z"
        className={clsx(classNames?.[1])}
      />
    </svg>
  );
};
