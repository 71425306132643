/* eslint-disable */
import { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarWithErrorBarsController, BarWithErrorBar } from 'chartjs-chart-error-bars';
import _ from 'lodash';

import { barChartOptions } from '../../utilities/chartUtils';
import { ChartSkeleton } from '../ChartSkeleton/ChartSkeleton';
import { Title, NoData, FilterSlot } from '../ChartSkeleton/styles';
import { ChartData, ChartOptions, DefaultDataPoint } from 'chart.js';
import { DataPointsPlugin } from '../../chartPlugins/OutliersPlugin';
import { BGPlugin } from '../../chartPlugins/BGPlugin';
import { ClickOnTickPlugin } from '../../chartPlugins/ClickOnTickPlugin';
import { CornerTitlePlugin } from '../../chartPlugins/CornerTitlePlugin';

Chart.register(BarWithErrorBarsController, BarWithErrorBar, ChartDataLabels);

export type ChartProps = {
  id: string;
  data: ChartData<'bar', DefaultDataPoint<'bar'>, string | undefined>;
  title?: string;
  colors?: string[];
  options?: ChartOptions<'bar'>;
  loading?: boolean;
  footer?: JSX.Element;
  filterSlot?: JSX.Element;
};

export const BarChart: FC<ChartProps> = ({
  id,
  data,
  title,
  options = barChartOptions,
  filterSlot,
  footer,
  loading,
}) => {
  const hasData = data.datasets.filter(Boolean).reduce((acc, { data }) => acc + data.length, 0) > 0;

  return (
    <ChartSkeleton
      id={id}
      loading={loading}
      title={
        <>
          {filterSlot && <FilterSlot>{filterSlot}</FilterSlot>}
          {title && <Title>{title}</Title>}
        </>
      }
      footer={hasData ? footer : undefined}
    >
      {!hasData && <NoData>No Data</NoData>}
      {hasData && (
        <Bar
          plugins={[BGPlugin, DataPointsPlugin, ClickOnTickPlugin, CornerTitlePlugin]}
          options={options}
          data={data}
          height={100}
        />
      )}
    </ChartSkeleton>
  );
};
