/* eslint-disable */
import { FC } from 'react';
import RChart from 'react-chartjs-2';
import { Chart } from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import _ from 'lodash';

import { bubbleChartOptions } from '../../utilities/chartUtils';
import { ChartSkeleton } from '../ChartSkeleton/ChartSkeleton';
import { Title, NoData, FilterSlot } from '../ChartSkeleton/styles';
import { ChartData, ChartOptions, DefaultDataPoint } from 'chart.js';
import { ScalableBubbleChart } from '../../chartPlugins/ScalableBubbleChart';
import { useBubbleChartProportions } from './useBubbleChartProportions';

Chart.register(/* BarWithErrorBarsController, BarWithErrorBar, */ Annotation, ScalableBubbleChart);

export type ChartProps = {
  id: string;
  data: ChartData<'scalableBubble', DefaultDataPoint<'scalableBubble'>, string | undefined>;
  title?: string;
  bubbleKey?: string;
  colors?: string[];
  options?: ChartOptions<'scalableBubble'>;
  loading?: boolean;
  footer?: JSX.Element;
  filterSlot?: JSX.Element;
};

export const BubbleChart: FC<ChartProps> = ({
  id,
  data,
  title,
  bubbleKey,
  options = bubbleChartOptions,
  filterSlot,
  footer,
  loading,
}) => {
  const hasData = data.datasets
    .filter(Boolean)
    .some((d) => d.data?.some((p) => p.y && p.x && (p as any).size));

  const [width, height] = useBubbleChartProportions();
  return (
    <ChartSkeleton
      loading={loading}
      id={id}
      title={
        <>
          {filterSlot && <FilterSlot>{filterSlot}</FilterSlot>}
          {title && <Title>{title}</Title>}
        </>
      }
      footer={hasData ? footer : undefined}
    >
      {!hasData && <NoData>No Data</NoData>}
      {hasData && (
        <div className="flex justify-center w-full">
          <RChart
            type="scalableBubble"
            key={width}
            options={options as any}
            data={data}
            redraw
            width={width}
            height={height}
          />
        </div>
      )}
    </ChartSkeleton>
  );
};
