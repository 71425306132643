import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { GET_CLINICS } from '../query/constants';
import { NEW_HOSPITAL } from './constants';
import { $ } from '../../zeus';

export const useMutationNewHospital = (
  args?: GQLArgs<CreateMutationResponse<'createOrganization'>>,
) => {
  return useMutation(
    {
      createOrganization: [
        {
          input: {
            name: $`name`,
            logoUrl: $`logoUrl`,
            parentOrganizationSlug: $`parentOrganizationSlug`,
            refId: $`refId`,
          },
        },
        {
          name: true,
          parent: {
            name: true,
            timeZone: true,
            slug: true,
          },
          logoUrl: true,
          refId: true,
          slug: true,
          timeZone: true,
        },
      ],
    },
    { operationName: NEW_HOSPITAL, successText: 'Clinic has been created' },
    {
      ...args,
      refetchQueries: [GET_CLINICS],
    },
  );
};
