import { FC, useCallback } from 'react';

import { compareOptions as compOptions } from './mocks';
import { Option, Selector } from '../Selector';
import { DateRangePicker, DateRangeSkeleton } from '../DateRangePicker/DateRangePicker';
import { useDateRangeFilter } from '../../hooks/useCalendarFilter';
import { MultiFilterReportName } from './MultiFilters.types';
import { useMultiFilterStore } from './MultiFilter.store';
import { useMe } from '../../context/useMe';

const optionToValue = (option: Option) => option.value as string;

export const today = new Date().toISOString().substr(0, 10);

type FiltersBarProps = {
  reportName: MultiFilterReportName;
  compareOptions: Option[];
  hideCompareSelectbox?: boolean;
  compareTitle?: string;
  activitiesViews?: Option[];
  leftSlot?: JSX.Element;
  rightSlot?: JSX.Element;
  alwaysGroupBy?: boolean;
};

export const FiltersBar: FC<FiltersBarProps> = ({
  reportName,
  hideCompareSelectbox,
  compareOptions = compOptions,
  compareTitle = 'Compare',
  activitiesViews,
  alwaysGroupBy,
  leftSlot,
  rightSlot,
}) => {
  const { isInited } = useMe();
  const multiFilterStore = useMultiFilterStore();
  const filtersState = multiFilterStore[reportName];
  const { variables, limitRange, setVariables, setRangeDate, setSelectType } = useDateRangeFilter();

  const setCompare = (option: Option) => {
    const value = optionToValue(option);

    multiFilterStore.setFilters(reportName, 'compare', value);
    multiFilterStore.setFilters(reportName, 'suggestedView', null);
  };
  const setActivitiesViews = (option: Option) => {
    const value = optionToValue(option);

    multiFilterStore.setFilters(reportName, 'activities', value);
  };

  const handleChangeDateRange = useCallback((range: [string, string]) => {
    setSelectType('custom');
    const rangeDate = { from: range[0] || '', to: range[1] || '' };
    setRangeDate(rangeDate);
    setVariables(rangeDate);
  }, []);

  return (
    <div className="mt-10 flex flex-wrap self-center w-full">
      <div className="mr-4">
        <div className="font-bold font-lato text-1214 ml-2 mb-4">Date Range</div>

        {!isInited && <DateRangeSkeleton />}

        {isInited && (
          <DateRangePicker
            loading={!limitRange}
            minDate={limitRange?.from}
            maxDate={limitRange?.to}
            selectType="custom"
            onApply={handleChangeDateRange}
            dates={[variables.from, variables.to]}
          />
        )}
      </div>
      {!hideCompareSelectbox && (
        <div className="mr-4">
          <div className="font-bold font-lato text-1214 ml-2 mb-4">{compareTitle}</div>
          <Selector
            onSelect={setCompare}
            options={(compareOptions as Option[]).filter((o) => {
              if (alwaysGroupBy) {
                return o.value;
              }

              return o;
            })}
            notFoundLabel="None"
            pickedData={
              alwaysGroupBy && !filtersState.compare
                ? 'procedure'
                : filtersState.compare || undefined
            }
          />
        </div>
      )}
      {!!activitiesViews && (
        <div className="mr-4">
          <div className="font-bold font-lato text-1214 mb-4">Activities</div>
          <Selector
            onSelect={setActivitiesViews}
            options={activitiesViews}
            notFoundLabel="All"
            pickedData={filtersState.activities}
          />
        </div>
      )}
      {leftSlot}
      <div className="flex items-center ml-auto mt-8">{rightSlot}</div>
    </div>
  );
};
