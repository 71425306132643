/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
import { FC } from 'react';

import { IconProps } from './types';
import clsx from 'clsx';

export const SettingsSvg: FC<IconProps> = ({ isActive }) => {
  const blue = clsx({ 'stroke-persianBlue': isActive, 'stroke-white': !isActive });

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={blue}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8074 7.62386L20.185 6.54377C19.6584 5.62985 18.4914 5.31456 17.5763 5.83896C17.1406 6.09559 16.6208 6.1684 16.1314 6.04133C15.6421 5.91426 15.2233 5.59776 14.9676 5.16162C14.803 4.88439 14.7146 4.56864 14.7113 4.24628C14.7261 3.72947 14.5311 3.22865 14.1708 2.85791C13.8104 2.48718 13.3153 2.27811 12.7982 2.27832H11.5442C11.0377 2.27832 10.552 2.48016 10.1947 2.83919C9.83742 3.19822 9.63791 3.68484 9.64034 4.19136C9.62533 5.23717 8.77321 6.07706 7.7273 6.07695C7.40494 6.0736 7.08919 5.98519 6.81197 5.82065C5.89679 5.29626 4.72985 5.61154 4.20327 6.52546L3.53508 7.62386C3.00914 8.53664 3.32013 9.70285 4.23073 10.2326C4.82263 10.5743 5.18726 11.2058 5.18726 11.8893C5.18726 12.5728 4.82263 13.2043 4.23073 13.5461C3.32129 14.0722 3.00996 15.2356 3.53508 16.1456L4.16666 17.2348C4.41338 17.68 4.82734 18.0085 5.31693 18.1477C5.80652 18.2868 6.33137 18.2251 6.77535 17.9763C7.21181 17.7216 7.73192 17.6518 8.22007 17.7824C8.70822 17.9131 9.12397 18.2333 9.3749 18.6719C9.53943 18.9491 9.62784 19.2649 9.63119 19.5872C9.63119 20.6438 10.4877 21.5003 11.5442 21.5003H12.7982C13.8512 21.5003 14.7062 20.6494 14.7113 19.5964C14.7088 19.0883 14.9096 18.6003 15.2689 18.241C15.6282 17.8817 16.1162 17.6809 16.6243 17.6834C16.9459 17.692 17.2604 17.78 17.5397 17.9396C18.4524 18.4656 19.6186 18.1546 20.1483 17.244L20.8074 16.1456C21.0625 15.7077 21.1325 15.1863 21.0019 14.6966C20.8714 14.207 20.551 13.7896 20.1117 13.5369C19.6725 13.2842 19.3521 12.8668 19.2215 12.3772C19.091 11.8875 19.161 11.3661 19.4161 10.9282C19.582 10.6386 19.8221 10.3984 20.1117 10.2326C21.0169 9.70314 21.3271 8.54374 20.8074 7.63301V7.62386Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={blue}
        d="M12.1751 14.5249C13.6309 14.5249 14.8111 13.3448 14.8111 11.8889C14.8111 10.4331 13.6309 9.25293 12.1751 9.25293C10.7192 9.25293 9.53906 10.4331 9.53906 11.8889C9.53906 13.3448 10.7192 14.5249 12.1751 14.5249Z"
        strokeWidth="1.5"
      />
    </svg>
  );
};
