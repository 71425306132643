import { FC } from 'react';
import clsx from 'clsx';

import { getHumanTimeFromSeconds } from '../../utilities/dateTime';

const EventDelay: FC<{ delay?: number | null }> = ({ delay }) => {
  if (!delay) return null;

  const hasDelay = delay > 0;
  const text = `${hasDelay ? 'delayed' : 'ahead of schedule'} ${getHumanTimeFromSeconds(delay)}`;

  return (
    <div
      title={`(${delay}s)`}
      className={clsx(
        'rbc-event-content-delay mt-2 mb-2 text-1020 font-medium w-fit text-lightPurple px-2 rounded-lg -ml-2',
        hasDelay ? 'bg-flavescent' : 'bg-teaGreen',
      )}
    >
      {text}
    </div>
  );
};

export default EventDelay;
