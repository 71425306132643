import * as H from 'history';
import {
  useQuery as useApolloQuery,
  QueryHookOptions,
  gql,
  useLazyQuery as useLazyQueryI,
  LazyQueryHookOptions,
  ApolloError,
} from '@apollo/client';

import { toast } from 'react-toastify';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { routesPath, slugRoutesPaths } from '../constants/routes';
import { ERRORS_TYPE } from '../constants/errors';
import { GraphQLTypes, InputType, ValueTypes, Zeus } from '../zeus';
import { cacheExpiration } from '../graphql/client';
import { Slug } from '../types';
import { lastUrlStorage } from '../utilities/helpers';

const callToast = (error: ApolloError, history: H.History) => {
  if (error) {
    const msg = error?.graphQLErrors?.[0]?.message;
    const is404 = msg === 'Not authorized';
    if (is404) {
      const token = localStorage.getItem('token');
      if (token && token !== 'null') {
        toast.error(msg, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
      lastUrlStorage.save();
      history.replace(routesPath.login);
      return;
    }
    toast.error((!is404 && msg) || 'Error', {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  }
};

export function useQuery<Z extends ValueTypes[O], O extends 'Query'>(
  query: Z | ValueTypes[O],
  additional?: {
    operationName?: string;
    context?: boolean;
  },
  options?: QueryHookOptions<InputType<GraphQLTypes[O], Z>>,
) {
  const history = useHistory();

  const match = useRouteMatch<Slug>(slugRoutesPaths);
  const clinicSlug = match?.params.refId || localStorage.getItem('hospital-refId');
  cacheExpiration(1);

  let queryParams = {
    ...options,
  };

  if (typeof additional?.context === 'boolean' ? additional?.context : true) {
    queryParams = {
      ...queryParams,
      context: {
        headers: {
          'X-Current-Organization-Id': clinicSlug,
        },
      },
    };
  }
  const GQL_STRING = gql(Zeus('query', query, additional?.operationName));
  const result = useApolloQuery<InputType<GraphQLTypes[O], Z>>(GQL_STRING, {
    ...queryParams,
    onError: (apolloError) => {
      if (apolloError?.graphQLErrors[0]) {
        const { code, extra } = apolloError?.graphQLErrors[0].extensions || {};
        if (code === ERRORS_TYPE.FORCE_RESET_PASSWORD) {
          localStorage.setItem('resetToken', extra.resetToken);
          history.push(routesPath.resetPassword);
        }
      }
      options?.onError?.(apolloError);
      callToast(apolloError, history);
    },
  });

  return { ...result, GQL_STRING };
}

export function useLazyQuery<Z extends ValueTypes[O], O extends 'Query'>(
  query: Z | ValueTypes[O],
  additional?: {
    operationName?: string;
    context?: boolean;
  },
  options?: LazyQueryHookOptions<InputType<GraphQLTypes[O], Z>>,
) {
  const history = useHistory();

  const match = useRouteMatch<{ refId: string }>(slugRoutesPaths);
  const clinicSlug = match?.params.refId || localStorage.getItem('hospital-refId');

  let queryParams = {
    ...options,
  };

  if (typeof additional?.context === 'boolean' ? additional?.context : true) {
    queryParams = {
      ...queryParams,
      context: {
        headers: {
          'X-Current-Organization-Id': clinicSlug,
        },
      },
    };
  }
  const GQL_STRING = gql(Zeus('query', query, additional?.operationName));

  const [fetch, data] = useLazyQueryI<InputType<GraphQLTypes[O], Z>>(GQL_STRING, {
    ...queryParams,
    onError: (apolloError) => {
      if (apolloError?.graphQLErrors[0]) {
        const { code, extra } = apolloError?.graphQLErrors[0]?.extensions || {};
        if (code === ERRORS_TYPE.FORCE_RESET_PASSWORD) {
          localStorage.setItem('resetToken', extra.resetToken);
          history.push(routesPath.resetPassword);
        }
      }
      options?.onError?.(apolloError);
      callToast(apolloError, history);
    },
  });

  return { fetch, ...data, GQL_STRING };
}
