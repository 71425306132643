import { Drawer as AntdDrawer } from 'antd';
import { DrawerProps as AntdDrawerProps } from 'antd';

type DrawerProps = AntdDrawerProps & {
  width?: number;
  isEdit?: boolean;
  icon?: React.ReactNode;
  title?: React.ReactNode;
};

const CloseIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      className="cursor-pointer"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1556_14412)">
        <rect opacity="0.7" width="18" height="18" rx="9" fill="white" />
        <path
          opacity="0.7"
          d="M12 12L6 6M12 6L6 12"
          stroke="#1C1C1F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1556_14412">
          <rect width="18" height="18" fill="white" transform="translate(18) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconContainer: React.FC = ({ children }) => {
  return (
    <div
      style={{
        width: 'calc(100% - 32px)',
      }}
      className="absolute bottom-0 h-0 flex justify-center items-center"
    >
      {children}
    </div>
  );
};

export const Drawer: React.FC<DrawerProps> = ({
  children,
  style,
  bodyStyle,
  headerStyle,
  icon,
  title,
  isEdit,
  width,
  ...rest
}) => {
  return (
    <AntdDrawer
      width={width ?? 355}
      title={icon ? <IconContainer>{icon}</IconContainer> : title ?? ' '}
      {...rest}
      mask={false}
      placement="right"
      style={{
        marginRight: 20,
        ...style,
      }}
      headerStyle={{
        background: isEdit ? '#4F5D50' : '#4E5B5F',
        ...headerStyle,
        position: 'relative',
        height: 72,
      }}
      closeIcon={<CloseIcon />}
      bodyStyle={{
        paddingTop: icon ? 70 : undefined,
        ...bodyStyle,
      }}
      contentWrapperStyle={{
        marginTop: 90,
        borderRadius: 8,
        overflow: 'hidden',
        height: 'calc(100% - 110px)',
      }}
    >
      {children}
    </AntdDrawer>
  );
};
