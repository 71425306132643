/* eslint-disable no-shadow */
export enum ERRORS_TYPE {
  FORCE_RESET_PASSWORD = 'Errors::ForceResetPassword',
  NOT_AUTHORIZED = 'Errors::NotAuthorized',
  NOT_ACTIVE = 'Errors::NotActive',
  UNPROCESSABLE = 'Errors::Unprocessable',
}

export const FORM_FIELDS = {
  refId: 'Reference ID',
};
