import { ChartOptions, TooltipItem } from 'chart.js';
import { shortEnglishHumanizer } from './dateTime';
import { Context } from 'chartjs-plugin-datalabels';

export const compLabels = {
  case_number: 'Case Number',
  national_statistics: 'National Statistic',
  OR: 'Operating Room',
  modality: 'Modality',
  surgeon_id: 'Surgeon',
  procedure: 'Procedure',
  specialty_code: 'Specialty',
  cases_per_day: 'Cases Per Day',
  weekday: 'Weekday',
  same_surgeon: 'Same Surgeon',
};

export function makeSimpleChartTooltipLabel(context: TooltipItem<'bar' | 'bubble'>) {
  const label = (context?.dataset as any)?.tooltipLabel as string;

  return `${label || ''} (${Math.ceil(context.parsed.y)})`;
}

export function displayScaleInMinutes(value: string | number) {
  let maybeNegative = '';
  if (Number(value) < 0) {
    maybeNegative = '-';
  }
  const result = shortEnglishHumanizer(Number(value) * 60 * 1000, {
    round: true,
    units: ['h', 'm'],
    spacer: '',
  })
    .split(',')
    .join('');

  if (/m/.test(result) && /h/.test(result)) {
    return maybeNegative + result.split(' ')[1];
  }

  return maybeNegative + result;
}

export const fontStyle = {
  size: 12,
  weight: 'bold',
  lineHeight: '20px',
  family: 'Lato',
};

export const scaleGridStyle = {
  display: true,
  color: '#F0F0F0',
  borderDash: [5, 5],
};

export const scaleTicksStyle = {
  padding: 12,
  font: fontStyle,
  color: '#1C1C1F',
};
export const scaleTitleStyle = {
  display: true,
  font: fontStyle,
  color: '#1C1C1F',
};

export const commonOptions: Partial<ChartOptions> = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      intersect: true,
      displayColors: false,
      callbacks: {
        title: () => '',
        label: makeSimpleChartTooltipLabel,
      },
    },
    datalabels: {
      labels: {
        value: {
          font: {
            weight: 'bold',
          },
          color: '#000',
          borderRadius: 10,
          padding: {
            left: 8,
            right: 8,
            top: 5,
            bottom: 3,
          },
          clamp: true,
          clip: false,
          offset: 7,
          anchor: 'end',
          align: 'top',
          borderColor: '#F0F0F0',
          borderWidth: 1,
          backgroundColor: (context) => {
            return '#fff';
          },
          formatter: function (value, context: any) {
            return `${context.dataset?.datalabels?.[context.dataIndex] || 0}`;
          },
        },
      },
    },
  },
  scales: {
    x: {
      grid: scaleGridStyle,
      ticks: scaleTicksStyle,
    },
    y: {
      grid: scaleGridStyle,
      title: {
        ...scaleTitleStyle,
        text: 'Duration',
      },

      ticks: {
        ...scaleTicksStyle,
      },
    },
  },
};

export const displayDataLabels = (showDataLabels: boolean) => (ctx: Context) => {
  if (ctx.dataset.type === 'scatter' || (ctx as any).dataset?.datalabels?.length === 0) {
    return false;
  }

  if (showDataLabels) {
    return true;
  }

  return false;
};
