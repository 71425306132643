import { Input } from 'antd';
import clsx from 'clsx';
import type { InputProps } from 'antd';

export const FormInput: React.FC<InputProps> = ({ value, ...props }) => {
  return (
    <Input
      {...props}
      value={value}
      className={clsx(
        value ? 'text-text-black border-text-black' : 'border-ghostBorder',
        'bg-white rounded-main border border-solid h-16',
      )}
    />
  );
};
