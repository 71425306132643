import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import classNames from 'clsx';
import { getQuery } from '../../utilities/getQuery';
import { BarChart } from '../../components/BarChart/BarChart';
import { PermissionCheck } from '../../components/PermissionCheck';
import { MultiFilter } from '../../components/MultiFilter/MultiFilter';

import { routesPath } from '../../constants/routes';
import { PageTitle } from '../../components/PageTitle';

import { useQueryTemporalActivities } from '../../rest/query/useQueryTemporalActivities';
import { ChartViewTabs } from '../../components/ChartViewTabs';
import { useMultiFilterStore } from '../../components/MultiFilter/MultiFilter.store';
import { ChartViewSwitch } from '../../components/ChartViewSwitch/ChartViewSwitch';
import { BarChartReportSkeleton } from '../../components/BarChartReportSkeleton/BarChartReportSkeleton';
import { ReportFilterSlot } from '../../components/ReportFilterSlot/ReportFilterSlot';
import { ChartReportFooterLabels } from '../../components/ChartReportFooterLabels/ChartReportFooterLabels';
import { useControls, tableId } from './useControls';
import { DownloadButton } from '../../components/DownloadButton';
import { GenesisTable, generateXLSX } from '../../components/GenesisTable/GenesisTable';
import { TemporalActivitiesLabels } from '../../constants/analyticsLabels';
import { useBarChartEngine } from '../../components/BarChart/useBarChartEngine';
import { DisplayOptionValue, displayOptions } from '../genesis-analytics/useControls';
import { useAppContext } from '../../context/appContext';
import { makeQuery } from '../../utilities/helpers';
import { capitalize, debounce } from 'lodash';
import { ChartViewButtons } from '../../components/ChartViewButtons/ChartViewButtons';
import { Chart } from 'chart.js';
import { DataPoint } from '../../components/DataPointTooltip/DataPointTooltip';
import { useQueryCase } from '../../rest/query/useQueryCase';
import { createPortal } from 'react-dom';
import { CaseTooltip } from '../../components/CaseTooltip/CaseTooltip';
import { CaseTooltipContent } from '../../components/CaseTooltipContent/CaseTooltipContent';
import { useHistory } from '../../hooks/useHistory';

const chartId = 'temporal-activities-chart';

const REPORT_NAME = 'temporalActivities';

const createRoute = (caseKey: string) => {
  return `${routesPath.caseCalendar.replace('/:refId', '').replace(':caseId', caseKey)}`;
};

export const TemporalActivities: FC = () => {
  const history = useHistory();
  const dateRanges = getQuery(history);
  const { sidebarCollapsed } = useAppContext();
  const multiFilterStore = useMultiFilterStore();
  const filtersState = multiFilterStore[REPORT_NAME];
  const [activityGroups, setActivityGroups] = useState<
    Record<ActivityGroupKeys, string[]> | undefined
  >();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openedDataPoint, setOpenedDataPoint] = useState<DataPoint | undefined>();

  const {
    displayOptionList,
    compareBy,
    downloadChart,
    setCompareBy,
    switchChartViews,
    view,
    onViewChange,
  } = useControls();

  const currentQuery = useMemo(
    () => makeQuery(dateRanges, filtersState),
    [dateRanges, filtersState],
  );

  const {
    data: sourceData,
    rawData,
    allFilters,
    loading,
  } = useQueryTemporalActivities(currentQuery);

  const caseId = openedDataPoint?.point.case_id;

  const { meta: caseData, loading: caseLoading } = useQueryCase(String(caseId), !!caseId);

  const activityGroupsOptions = useMemo(() => {
    return [
      {
        label: 'All',
        value: null,
      },
      ...Object.keys(activityGroups || {}).map((key) => ({
        label: key.split('-').map(capitalize).join(' '),
        value: key,
      })),
    ];
  }, [activityGroups]);

  const surgeons = allFilters.surgeons;

  const { filtersBar, panel, selectedFilters } = MultiFilter({
    reportName: REPORT_NAME,
    drawerOpen,
    isLoading: loading,
    allFilters,
    availableFilters: rawData?.available_filters,
    // withSwitch: true,
    activitiesViews: activityGroupsOptions,
    rightFiltersBarSlot: (
      <ChartViewTabs
        enabledList={['Bar View', 'List View']}
        defaultItem={view || 'Bar View'}
        onChange={onViewChange}
      />
    ),
    onOpenPanel: () => setDrawerOpen(true),
    onClosePanel: () => setDrawerOpen(false),
  });

  const openDataPoint = debounce((point: ChartDataPoint, chart: Chart) => {
    setOpenedDataPoint({ point, chart });
  }, 500);

  const closeDataPoint = useCallback(() => {
    setOpenedDataPoint(undefined);
  }, []);

  const { barChartOptions, getBarChartData } = useBarChartEngine<TemporalActivitiesKeys>({
    compareBy,
    displayOptionList,
    sourceData,
    transformKeys: (val, significant) =>
      TemporalActivitiesLabels[val] +
      (significant !== null && displayOptionList.includes('statisticalSignificance')
        ? ` (${significant ? 'sig' : 'insig'})`
        : ''),
    allFilters,
    extendOptions: {
      plugins: {
        dataPoints: {
          onMouseEnter: openDataPoint,
          onMouseLeave: closeDataPoint,
          onClick: (point) => {
            if (!point) return;
            const route = createRoute(point.case_id + '');
            history.push(route);
          },
        },
      },
    },
  });

  const downloadXLSX = useCallback(() => {
    generateXLSX({
      compareBy,
      allFilters,
      tableId,
      sourceData,
      labels: TemporalActivitiesLabels,
    });
  }, [sourceData, surgeons, compareBy]);

  useEffect(() => {
    setCompareBy(filtersState.compare);
  }, [filtersState.compare]);

  useEffect(() => {
    if (activityGroups || !rawData?.activity_group) return;
    setActivityGroups(rawData?.activity_group);
  }, [rawData?.activity_group, activityGroups]);

  const renderBarChart = () => (
    <BarChart
      id={chartId}
      key={sidebarCollapsed ? 'collapsed' : 'expanded'}
      data={getBarChartData() as any}
      filterSlot={
        <ReportFilterSlot<DisplayOptionValue>
          displayOptions={displayOptions.map((d) => ({
            ...d,
            label: d.value === 'dataPoints' && filtersState.excludeOutliers ? 'Outliers' : d.label,
            disabled: d.value === 'statisticalSignificance' && !compareBy,
          }))}
          hideOptions={compareBy === 'national_statistics'}
          onSwitchChartViews={switchChartViews}
          displayOptionList={displayOptionList}
          selectedFilters={selectedFilters}
          onDownloadChart={downloadChart}
        />
      }
      options={barChartOptions}
      footer={
        <ChartReportFooterLabels
          compareBy={compareBy}
          sourceData={sourceData}
          allFilters={allFilters}
        />
      }
    />
  );

  const renderList = () => (
    <div>
      <div className="flex justify-between items-center">
        <div className="p-10">{selectedFilters}</div>
        <div className={classNames('mr-10')}>
          <div className="flex justify-between">
            <ChartViewButtons
              displayOptionList={displayOptionList}
              displayOptions={displayOptions
                .map((d) => ({
                  ...d,
                  disabled: d.value === 'statisticalSignificance' && !compareBy,
                }))
                .filter((d) => ['statisticalSignificance', 'sampleSize'].includes(d.value))}
              onSwitchChartViews={switchChartViews}
            />
            <DownloadButton title="Download Excel" onClick={downloadXLSX} />
          </div>
        </div>
      </div>
      <div
        className={classNames('whitespace-nowrap overflow-auto transition-all', {
          'w-[calc(100vw-180px)]': sidebarCollapsed,
          'w-[calc(100vw-330px)]': !sidebarCollapsed,
        })}
      >
        <GenesisTable<TemporalActivitiesKeys>
          tableId={tableId}
          loading={loading}
          showSize={displayOptionList.includes('sampleSize')}
          transformKeys={(val, significant) =>
            TemporalActivitiesLabels[val] +
            (significant !== null && displayOptionList.includes('statisticalSignificance')
              ? ` (${significant ? 'sig' : 'insig'})`
              : '')
          }
          data={sourceData}
          compareBy={compareBy}
          allFilters={allFilters}
        />
      </div>
    </div>
  );

  return (
    <PermissionCheck check="readAnalytics" fallback={<div />}>
      <PageTitle
        header="Temporal Activities"
        crumbs={[{ title: 'Analytics', url: routesPath.analytics.replace('/:refId', '') }]}
      />

      <div className="relative">
        {panel}
        {filtersBar}
        <div className="bg-white shadow-sm mt-10">
          <ChartViewSwitch
            skeleton={<BarChartReportSkeleton />}
            view={view}
            defaultView="Bar View"
            loading={loading}
            bar={renderBarChart}
            list={renderList}
          />
          {createPortal(
            <div className="">
              {caseId && openedDataPoint && (
                <CaseTooltip
                  chart={openedDataPoint.chart}
                  coordinates={{
                    x: openedDataPoint.point.x,
                    y: openedDataPoint.point.y,
                  }}
                >
                  <CaseTooltipContent
                    caseId={caseId + ''}
                    caseData={caseData}
                    loading={caseLoading}
                  />
                </CaseTooltip>
              )}
            </div>,
            document.body,
          )}
        </div>
      </div>
    </PermissionCheck>
  );
};
