export const routesPath = Object.freeze({
  login: '/login',
  resetPassword: '/reset-password',
  onlyEmail: '/only-email',
  clinic: '/:refId/dashboard',
  newHospital: '/:refId/new',
  analytics: '/:refId/analytics',
  casesAnalytics: '/:refId/analytics/cases',
  nonOperativeMetrics: '/:refId/analytics/non-operative-metrics',
  temporalActivities: '/:refId/analytics/temporal-activities',
  caseView: '/:refId/analytics/case-view',
  peopleCount: '/:refId/analytics/people-count',
  calendar: '/:refId/calendar',
  dataCalendar: '/:refId/calendar/data',
  caseCalendar: '/:refId/calendar/case/:caseId',
  users: '/:refId/users',
  newUser: '/:refId/new-user',
  editUser: '/:refId/users/:email',
  settings: '/:refId/settings',
  administration: '/:refId/administration',
});

export const slugRoutesPaths = Object.values(routesPath).filter((r) => r.includes(':refId'));
