import { Sidebar } from '../SideBar';
import classNames from 'clsx';
import { TopAvatar } from '../TopAvatar';
import { TitleContainer } from '../HeaderTitle';

import { BreadcrumbsContainer } from '../Breadcrumbs/Breadcrumbs';
import { useAppContext, useUserContext } from '../../context';
import { PageHelpContainer } from '../PageHelp/PageHelp';

export const Layout: React.FC = ({ children }) => {
  const { isInited } = useUserContext();
  const { sidebarCollapsed } = useAppContext();

  return (
    <div className="flex justify-between bg-athensGrey h-screen transition-all">
      <div className="ml-4 mt-4 mb-4 min-h-[70rem]">{isInited ? <Sidebar /> : null}</div>
      <div
        className={classNames('flex flex-col', {
          'w-[calc(100vw-110px)]': sidebarCollapsed,
          'xl:w-[calc(100vw-240px)] lg:w-[calc(100vw-220px)] md:w-[calc(100vw-200px)]':
            !sidebarCollapsed,
        })}
      >
        <div className="page-head flex justify-between mt-2 mb-2 mx-12 h-[9.5rem]">
          <div className="headcontainer">
            <BreadcrumbsContainer />
            <div className="pagetitle mt-7 mb-8">
              <TitleContainer />
            </div>
          </div>
          <div className="useraction flex items-center">
            <PageHelpContainer />
            <div className="userselectbox">
              <TopAvatar />
            </div>
          </div>
        </div>
        {!!isInited && <div className="h-px bg-black bg-opacity-[0.15] mx-12"></div>}
        <div className="flex flex-col flex-grow overflow-y-auto px-12 pb-12">
          {isInited ? children : null}
        </div>
      </div>
    </div>
  );
};
