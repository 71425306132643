import clsx from 'clsx';
import { FC } from 'react';
import { GraphQLTypes } from '../../zeus';
import { ColoredSlug } from '../ColoredSlug';
import { ReactComponent as ChevronIcon } from '../../assets/chevron.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/checkmark.svg';

type ClinicItemArgs = {
  clinic: Partial<GraphQLTypes['Organization']>;
  menu?: boolean;
  active?: boolean;
  disabled?: boolean;
  showClinic?: boolean;
};

export const ClinicItem: FC<ClinicItemArgs> = ({
  clinic,
  active,
  menu,
  disabled,
  showClinic = true,
}) => {
  return (
    <div
      className={clsx(
        'flex group items-center w-full bg-opacity-20 rounded-[.8rem] p-2',
        showClinic ? 'justify-between' : 'justify-end gap-x-4',
        {
          'bg-perano bg-opacity-20 cursor-pointer': active,
          'hover:bg-white hover:bg-opacity-10 cursor-pointer': menu && !disabled,
          'hover:bg-perano hover:bg-opacity-20 cursor-pointer': !menu && !disabled,
          'opacity-30 cursor-not-allowed': disabled,
        },
      )}
    >
      <div className="flex items-center">
        <div
          className={clsx(
            'w-[3.6rem] h-[3.6rem] inline-flex justify-center items-center rounded-md',
            showClinic ? 'mr-4' : '',
          )}
        >
          <ColoredSlug withName={false} name={clinic?.name} size="medium" className="font-bold" />
        </div>
        {!!showClinic && (
          <div>
            <div
              className={clsx(
                'w-[10rem] h-[1.6rem] text-1419 font-bold font-nunito text-ellipsis overflow-hidden',
                { 'text-white': menu, 'text-black': !menu },
              )}
            >
              {clinic?.name}
            </div>
          </div>
        )}
      </div>
      {menu && (
        <div className="">
          <ChevronIcon className="w-[1rem] duration-300 stroke-perano transition group-hover:-translate-y-[1px] " />
          <ChevronIcon className="transition duration-300 mt-1 group-hover:translate-y-[1px] rotate-180 w-[1rem] stroke-perano " />
        </div>
      )}
      {!menu && active && (
        <div className="px-2">
          <CheckmarkIcon className="fill-regentGrey" />
        </div>
      )}
    </div>
  );
};
