import { Option } from '../components/Selector';

export const convertProceduresToOptions = (
  procedures: ProcedureType[],
  withAllOptions = true,
): Option[] => {
  const mappedProcedures = procedures.map((r): Option => ({ value: r.code, label: r.name }));
  if (!withAllOptions) return mappedProcedures;

  return [{ value: null, label: 'Procedures' }, ...mappedProcedures];
};
export const convertRoomsToOptions = (rooms: string[], withAllOptions = true): Option[] => {
  const mappedRooms = rooms.map((r): Option => ({ value: r, label: r }));
  if (!withAllOptions) return mappedRooms;

  return [{ value: null, label: 'All Rooms' }, ...mappedRooms];
};

export const convertSurgeonsToOptions = (
  surgeons: SurgeonType[],
  withAllOptions = true,
): Option[] => {
  const mappedSurgeons = surgeons.map((r): Option => ({ value: r.code, label: r.name || '' }));
  if (!withAllOptions) return mappedSurgeons;

  return [{ value: null, label: 'All Surgeons' }, ...mappedSurgeons];
};
