import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { RESET_PASSWORD } from './constants';
import { $ } from '../../zeus';

export const useMutationResetPassword = (
  args?: GQLArgs<CreateMutationResponse<'updatePassword'>>,
) => {
  return useMutation(
    {
      updatePassword: [
        {
          newPassword: $`newPassword`,
          resetToken: `${$`resetToken`}`,
        },
        {
          message: true,
        },
      ],
    },
    { operationName: RESET_PASSWORD },
    args,
  );
};
