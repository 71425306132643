/* eslint-disable no-console */
import { ChartOptions } from 'chart.js';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import {
  commonOptions,
  compLabels,
  displayDataLabels,
  displayScaleInMinutes,
  fontStyle,
  scaleTitleStyle,
} from '../../utilities/commonOptions';
import { bubbleChartOptions } from '../../utilities/chartUtils';
import { CompareValues } from '../MultiFilter/MultiFilters.types';
import { makeTooltipStyles } from '../ChartTooltip/ChartTooltip';
import { getEntityName } from '../../utilities/helpers';

export const makeBubbleOptions = ({
  tooltipRef,
  unit = 'minutes',
  xLimits,
  yLimits,
  compareBy,
  allFilters,
  extendOptions,
  showDataLabels,
}: {
  tooltipRef: React.MutableRefObject<HTMLDivElement>;
  xLimits: number[];
  yLimits: number[];
  unit?: string;
  compareBy: CompareValues;
  allFilters: AllFilters;
  extendOptions?: ChartOptions<'scalableBubble'>;
  showDataLabels?: boolean;
}) => {
  const maxXValue = xLimits?.[1] || 1;
  const minXValue = xLimits?.[0] || 0;

  const maxYValue = yLimits?.[1] || 1;
  const minYValue = yLimits?.[0] || 0;

  const middleX = (maxXValue - minXValue) / 2 + minXValue;
  const middleY = (maxYValue - minYValue) / 2 + minYValue;

  const annotation = {
    type: 'line',
    label: {
      display: true,
      color: '#000000',
      font: fontStyle,
      backgroundColor: 'transparent',
      position: 'end',
    },
    borderColor: 'rgb(255, 99, 132, 60%)',
    borderWidth: 2,
  };

  const options = cloneDeep({ ...bubbleChartOptions, ...commonOptions });

  return merge(
    merge(options, {
      clip: false,
      responsive: false,
      // aspectRatio: 3,
      plugins: {
        datalabels: {
          display: displayDataLabels(!!showDataLabels),
        },
        tooltip: {
          mode: 'nearest',
          callbacks: {
            label() {
              return '';
            },
            beforeBody(i) {
              const dataIndex = i[0].dataIndex;
              const datasetIndex = i[0].datasetIndex;
              // // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const general = (i[0].dataset as any)?.general;

              const set = Object.values(general)?.[datasetIndex] as any;

              const metricLabel = compLabels[compareBy as keyof typeof compLabels];

              const mean = set?.data?.[dataIndex] || 0;
              const std = set?.standardDeviation?.[dataIndex] || 0;
              const sampleSize = set?.datalabels?.[dataIndex] || 0;

              return [
                {
                  label: metricLabel,
                  value: /rest/i.test(set?.name)
                    ? 'Rest'
                    : getEntityName({ value: set?.name, allFilters, compareBy }),
                  isHead: true,
                },
                {
                  label: 'Mean',
                  value: (mean < 1 ? mean : Math.floor(mean)).toFixed(1) + ' ' + unit,
                },
                {
                  label: 'Standard Deviation',
                  value: (std < 1 ? std : Math.floor(std)).toFixed(1) + ' ' + unit,
                },
                {
                  label: 'Sample Size',
                  value: (sampleSize < 1 ? sampleSize : Math.floor(sampleSize)) + ' cases',
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ] as any;
            },
          },
          enabled: false,
          external: makeTooltipStyles({
            tooltipRef,
            isCalendarIcon: false,
          }),
        },
        annotation: {
          annotations: {
            x: merge(
              { ...cloneDeep(annotation) },
              {
                label: {
                  content: '' + middleY.toFixed(2),
                  yAdjust: -10,
                  xAdjust: 10,
                },
                yMin: middleY,
                yMax: middleY,
              },
            ),
            y: merge(
              { ...cloneDeep(annotation) },
              {
                label: {
                  content: '' + middleX.toFixed(2),
                  yAdjust: -10,
                  xAdjust: -20,
                },
                xMin: middleX,
                xMax: middleX,
              },
            ),
          },
        },
      },
      scales: {
        x: {
          // offset: true,
          ticks: {
            stepSize: unit === 'minutes' ? (maxXValue < 60 ? 15 : 30) : maxXValue > 5 ? 5 : 1,
            callback: displayScaleInMinutes,
          },
          min: minXValue,
          max: maxXValue,
          title: {
            ...scaleTitleStyle,
            text: 'Mean',
          },
        },
        y: {
          // offset: true,
          ticks: {
            stepSize: unit === 'minutes' ? (maxYValue < 60 ? 15 : 30) : maxYValue > 5 ? 5 : 1,
            callback: displayScaleInMinutes,
          },
          min: minYValue,
          max: maxYValue,
          title: {
            ...scaleTitleStyle,
            text: 'Standard Deviation',
          },
        },
      },
    } as ChartOptions<'scalableBubble'>),
    extendOptions,
  ) as ChartOptions<'scalableBubble'>;
};
