import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { $ } from '../../zeus';
import { REMOVE_PRESET } from './constants';

export const useMutationDeletePreset = (args?: GQLArgs<CreateMutationResponse<'removePreset'>>) => {
  return useMutation(
    {
      removePreset: [
        {
          name: $`name`,
        },
        {
          name: true,
          content: true,
        },
      ],
    },
    {
      operationName: REMOVE_PRESET,
      successText: 'Preset has been removed',
    },
    args,
  );
};
