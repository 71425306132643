import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'clsx';

import { ReactComponent as WidgetDownIcon } from '../../assets/widget-down.svg';

import { ReactComponent as WidgetUpIcon } from '../../assets/widget-up.svg';
import operationTheaterSvg from '../../assets/open-cases.svg';
import roboticSurgerySvg from '../../assets/robotic-surgery.svg';
import lapCaseSvg from '../../assets/lap-case.svg';
import totalCasesSvg from '../../assets/total-cases.svg';

import balloonSvg from '../../assets/balloon.svg';
import surgeonSvg from '../../assets/surgeon.svg';
import medPurseSvg from '../../assets/med-purse.svg';
import heartDisplaySvg from '../../assets/heart-display.svg';

import { SurgeryType, WidgetsType } from '../../components/DashboardWidget/types';

import { DashboardWidget } from '../../components/DashboardWidget';
import Progress from 'antd/lib/progress/progress';
import { DashboardNonOperativeMetricsResponse } from '../../rest/query/useQueryDashboardNonOperativeMetrics';
import { ChartTooltip } from '../../components/ChartTooltip/ChartTooltip';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { getEntityName } from '../../utilities/helpers';
import { CompareValues } from '../../components/MultiFilter/MultiFilters.types';

export const didGoUp = (first: number | null, second: number | null): boolean | null => {
  if (first === second) {
    return null;
  }
  if (first === null || second === null) {
    return null;
  }

  return first >= second;
};

type WidgetProps = {
  loading?: boolean;
  data: any;
  rangeType: string | null;
};

export const OpenCase: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    title="Total Cases"
    surgeryType={SurgeryType.OPEN}
    value={data.curr_open_cases || 0}
    prevValue={data.prev_open_cases || 0}
    type={WidgetsType.OPEN_CASES}
    isTime={false}
    analyticsIsUp={didGoUp(data.curr_open_cases || 0, data.prev_open_cases || 0)}
  />
);

export const OpenAveTimeClean: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    title="Average Cleaning Time"
    surgeryType={SurgeryType.OPEN}
    value={data.curr_open_av_clean_time || 0}
    prevValue={data.prev_open_av_clean_time || 0}
    type={WidgetsType.OPEN_AVERAGE_CLEANING_TIME}
    analyticsIsUp={didGoUp(data.curr_open_av_clean_time || 0, data.prev_open_av_clean_time || 0)}
    isTime
  />
);

export const OpenAveCaseDelay: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    title="Average Case Delay"
    isTime
    surgeryType={SurgeryType.OPEN}
    value={data.curr_open_cases_average_delay || 0}
    prevValue={data.prev_open_cases_average_delay || 0}
    type={WidgetsType.OPEN_AVERAGE_DELAY_TIME}
    analyticsIsUp={didGoUp(
      data.curr_open_cases_average_delay || 0,
      data.prev_open_cases_average_delay || 0,
    )}
  />
);

export const RoboCase: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    title="Total Cases"
    surgeryType={SurgeryType.ROBOTIC}
    value={data.cur_robotic_cases || 0}
    prevValue={data.prev_robotic_cases || 0}
    type={WidgetsType.ROBOTIC_CASES}
    analyticsIsUp={didGoUp(data.cur_robotic_cases || 0, data.prev_robotic_cases || 0)}
    isTime={false}
  />
);

export const RoboAveTimeClean: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    surgeryType={SurgeryType.ROBOTIC}
    title="Average Cleaning Time"
    value={data.curr_robotic_av_clean_time || 0}
    prevValue={data.prev_robotic_av_clean_time || 0}
    type={WidgetsType.ROBOTIC_AVERAGE_CLEANING_TIME}
    analyticsIsUp={didGoUp(
      data.curr_robotic_av_clean_time || 0,
      data.prev_robotic_av_clean_time || 0,
    )}
    isTime
  />
);

export const RoboAveCaseDelay: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    surgeryType={SurgeryType.ROBOTIC}
    title="Average Case Delay"
    value={data.curr_robotic_cases_average_delay || 0}
    prevValue={data.prev_robotic_cases_average_delay || 0}
    type={WidgetsType.ROBOTIC_AVERAGE_DELAY_TIME}
    analyticsIsUp={didGoUp(
      data.curr_robotic_cases_average_delay || 0,
      data.prev_robotic_cases_average_delay || 0,
    )}
    isTime
  />
);
export const LapoCase: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    title="Total Cases"
    surgeryType={SurgeryType.LAPAROSCOPIC}
    value={data.cur_lap_cases || 0}
    prevValue={data.prev_lap_cases || 0}
    type={WidgetsType.LAP_CASES}
    analyticsIsUp={didGoUp(data.cur_lap_cases || 0, data.prev_lap_cases || 0)}
    isTime={false}
  />
);

export const LapoAveTimeClean: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    surgeryType={SurgeryType.LAPAROSCOPIC}
    title="Average Cleaning Time"
    value={data.curr_lap_av_clean_time || 0}
    prevValue={data.prev_lap_av_clean_time || 0}
    type={WidgetsType.LAP_AVERAGE_CLEANING_TIME}
    analyticsIsUp={didGoUp(data.curr_lap_av_clean_time || 0, data.prev_lap_av_clean_time || 0)}
    isTime
  />
);

export const LapoAveCaseDelay: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    surgeryType={SurgeryType.LAPAROSCOPIC}
    title="Average Case Delay"
    value={data.curr_total_cases_average_delay || 0}
    prevValue={data.prev_total_cases_average_delay || 0}
    type={WidgetsType.TOTAL_AVERAGE_DELAY_TIME}
    analyticsIsUp={didGoUp(
      data.curr_total_cases_average_delay || 0,
      data.prev_total_cases_average_delay || 0,
    )}
    isTime
  />
);

export const AveRoomTimeIdle: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    surgeryType={SurgeryType.OVERAL}
    title="Average Room Idle Time"
    value={data.cur_ave_room_idle_time || 0}
    prevValue={data.prev_ave_room_idle_time || 0}
    type={WidgetsType.IDLE_TIME}
    analyticsIsUp={didGoUp(data.cur_ave_room_idle_time || 0, data.prev_ave_room_idle_time || 0)}
    isTime
  />
);

export const AveCleanTime: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    surgeryType={SurgeryType.OVERAL}
    title="Average Cleaning Time"
    value={data.cur_sum_clean_time || 0}
    prevValue={data.prev_sum_clean_time || 0}
    type={WidgetsType.CLEANING_TIME}
    analyticsIsUp={didGoUp(data.cur_sum_clean_time || 0, data.prev_sum_clean_time || 0)}
    isTime
  />
);

export const AllTotal: FC<WidgetProps> = ({ data, loading, rangeType }) => (
  <DashboardWidget
    loading={loading}
    rangeType={rangeType}
    surgeryType={SurgeryType.OVERAL}
    title="Total Cases"
    value={data.cur_total_cases || 0}
    prevValue={data.prev_total_cases || 0}
    type={WidgetsType.TOTAL_CASES}
    analyticsIsUp={didGoUp(data.cur_total_cases || 0, data.prev_total_cases || 0)}
    isTime={false}
  />
);

type MetadataWidgetRowProps = {
  type: 'ors' | 'specialties' | 'procedures' | 'surgeons';
  loading: boolean;
  allFilters: AllFilters;
  onClick?: () => void;
  options: { label: string; value: string }[];
};
export const MetadataWidgetRow: FC<MetadataWidgetRowProps> = ({
  type,
  options,
  allFilters,
  loading,
  onClick,
}) => {
  const table: Record<
    MetadataWidgetRowProps['type'],
    Record<'title' | 'icon' | 'split' | 'iconBG', string>
  > = {
    ors: {
      title: 'ORs',
      split: 'OR',
      icon: balloonSvg,
      iconBG: 'bg-cultured',
    },
    specialties: {
      title: 'Specialties',
      split: 'specialty_code',
      icon: medPurseSvg,
      iconBG: 'bg-oldLace',
    },
    procedures: {
      title: 'Procedures',
      split: 'procedure',
      icon: heartDisplaySvg,
      iconBG: 'bg-selago',
    },
    surgeons: {
      title: 'Surgeons',
      split: 'surgeon_id',
      icon: surgeonSvg,
      iconBG: 'bg-lavender',
    },
  };

  const item = table[type];

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: window.innerWidth <= 1024 ? 'top-start' : 'top',
  });

  const translatedOptions = useMemo(() => {
    return (options || []).map((o) => ({
      value: o.label,

      label:
        type !== 'ors'
          ? getEntityName({
              value: o.label,
              allFilters,
              compareBy: item.split as CompareValues,
            })
          : ' ',
      color: '',
    }));
  }, [options, allFilters]);

  const show = useCallback(() => {
    setIsOpen(true);
  }, []);
  const hide = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    referenceElement?.addEventListener('mouseenter', show);
    referenceElement?.addEventListener('mouseleave', hide);
    return () => {
      referenceElement?.removeEventListener('mouseenter', show);
      referenceElement?.removeEventListener('mouseleave', hide);
    };
  }, [referenceElement]);

  return (
    <div
      ref={setReferenceElement as any}
      className={classNames('flex justify-between items-center mb-6 relative group', {
        'cursor-pointer': !!onClick,
      })}
      onClick={onClick}
    >
      <div className={classNames('flex')}>
        <div
          className={classNames(
            'w-[4.5rem] h-[4.5rem] flex justify-center items-center rounded-main mr-[1.6rem]',
            item.iconBG,
          )}
        >
          <img src={item.icon} alt={item.title} />
        </div>
        <div className="flex flex-col">
          <div
            className={classNames('uppercase font-bold font-lato text-1620 mb-1 ', {
              'group-hover:text-persianBlue': !!onClick,
            })}
          >
            {item.title}
          </div>
          <div className="flex">
            {!loading &&
              options?.slice(0, 3).map((o) => (
                <div
                  key={o.value}
                  className="mr-4 flex items-center font-bold font-lato text-1420 text-headerTextGrey bg-peranoHover py-0.5 px-2 rounded-lg"
                >
                  <div className="">{o.value.split('/').join(' / ')}</div>
                </div>
              ))}
            {loading && (
              <>
                <div className="bg-peranoHover animate-pulse w-[3.8rem] h-[2.2rem] rounded-lg mr-4"></div>
                <div className="bg-peranoHover animate-pulse w-[3.8rem] h-[2.2rem] rounded-lg mr-4"></div>
                <div className="bg-peranoHover animate-pulse w-[3.8rem] h-[2.2rem] rounded-lg mr-4"></div>
              </>
            )}
            {!loading && options?.length > 3 && (
              <div className="flex items-center">
                <div className="flex items-center text-headerTextGrey rounded-[3.2rem] text-1214 font-lato font-semibold px-2 border border-peranoHover">
                  +{options?.length - 3}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center">
        {!loading && <div className="font-bold font-lato text-3232 ml-2">{options?.length}</div>}
        {loading && <div className="bg-ghost animate-pulse w-[4.5rem] h-[3.2rem] rounded-lg"></div>}
      </div>
      {ReactDOM.createPortal(
        <div
          ref={setPopperElement as any}
          className={classNames('z-10 text-black text-1220 font-bold', {
            hidden: !(isOpen && (options || []).length > 0),
          })}
          style={styles.popper}
          {...attributes.popper}
        >
          <ChartTooltip
            title={item.title?.toUpperCase()}
            isCalendarIcon={false}
            tooltipData={translatedOptions}
          />
        </div>,
        document.body as any,
      )}
    </div>
  );
};

type CaseMixWidgetRowProps = {
  type: 'total' | 'lap' | 'robotic' | 'open';
  value: number;
  hideTrend?: boolean;
  loading: boolean;
  prevValue: number;
};

export const CaseMixWidgetRow: FC<CaseMixWidgetRowProps> = ({
  prevValue,
  type,
  hideTrend,
  value,
  loading,
}) => {
  const isNegative = prevValue - value < 0;

  const table: Record<
    CaseMixWidgetRowProps['type'],
    Record<'title' | 'icon' | 'iconBG', string>
  > = {
    total: {
      title: 'Total Cases',
      icon: totalCasesSvg,
      iconBG: 'bg-cultured',
    },
    lap: {
      title: 'Lap',
      icon: lapCaseSvg,
      iconBG: 'bg-oldLace',
    },
    robotic: {
      title: 'Robotic',
      icon: roboticSurgerySvg,
      iconBG: 'bg-selago',
    },
    open: {
      title: 'Open',
      icon: operationTheaterSvg,
      iconBG: 'bg-lavender',
    },
  };

  const item = table[type];

  return (
    <div className="flex justify-between items-center mb-6">
      <div className="flex">
        <div
          className={classNames(
            'w-[4.5rem] h-[4.5rem] flex justify-center items-center rounded-main mr-[1.6rem]',
            item.iconBG,
          )}
        >
          <img src={item.icon} alt={item.title} />
        </div>
        <div className="flex flex-col">
          <div className="uppercase font-bold font-lato text-1620 mb-1">{item.title}</div>
          <div className="flex">
            {!hideTrend && prevValue !== 0 && (
              <div
                className={classNames('font-bold font-lato text-1420 py-0.5 px-2 rounded-lg mr-2', {
                  'text-activeText bg-activeBg': !isNegative,
                  'text-inactiveText bg-inactiveBg': isNegative,
                })}
              >
                {hideTrend || prevValue === 0 ? '' : prevValue > 0 && isNegative ? '-' : '+'}
                {prevValue}
              </div>
            )}
            {!loading && (
              <div className="font-medium font-lato text-1020 opacity-70">
                {hideTrend || prevValue === 0 ? 'no changes' : 'vs. previous period'}
              </div>
            )}
            {loading && (
              <div className="bg-ghost animate-pulse w-[7rem] h-[.8rem] rounded-lg"></div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center">
        {!hideTrend && prevValue !== 0 && isNegative && (
          <WidgetDownIcon className="stroke-inactiveText" />
        )}
        {!hideTrend && prevValue !== 0 && !isNegative && (
          <WidgetUpIcon className="stroke-activeText" />
        )}

        {!loading && <div className="font-bold font-lato text-3232 ml-2">{value}</div>}
        {loading && <div className="bg-ghost animate-pulse w-[4.5rem] h-[3.2rem] rounded-lg"></div>}
      </div>
    </div>
  );
};

type ORAScoreWidgetProps = {
  score?: number;
  onClick?: () => void;
  nationalStatistic: DashboardNonOperativeMetricsResponse['non_operative_metrics']['national_statistic'];
};

export const ORAScoreWidget: FC<ORAScoreWidgetProps> = ({ score, nationalStatistic, onClick }) => {
  // const {
  //   patientclose_patientout,
  //   patientin_firstcut,
  //   patientout_sterileprep,
  //   sterileprep_patientin,
  //   turnover,
  // } = nationalStatistic || {};

  // const top20 =
  //   patientclose_patientout['top 20%'] +
  //   patientin_firstcut['top 20%'] +
  //   patientout_sterileprep['top 20%'] +
  //   sterileprep_patientin['top 20%'] +
  //   turnover['top 20%'];

  // const hostpital =
  //   patientclose_patientout['this hospital'] +
  //   patientin_firstcut['this hospital'] +
  //   patientout_sterileprep['this hospital'] +
  //   sterileprep_patientin['this hospital'] +
  //   turnover['this hospital'];

  return (
    <div onClick={onClick} className="flex h-full justify-between cursor-pointer">
      <div className="flex flex-col justify-center items-center w-1/2">
        <div className="uppercase font-bold font-lato text-1620 mb-2">ORA Score</div>
        <div className="font-semibold font-lato text-1214 text-text-black text-opacity-70">
          Your hospital rating
        </div>
      </div>
      <div className="border-l w-1/2 flex justify-center items-center">
        <div className="">
          <Progress
            type="circle"
            percent={Number((score || 0).toFixed?.(0))}
            strokeWidth={14}
            strokeColor={'#F7B295'}
            trailColor="#F7B29544"
            format={(percent) => (
              <div className="flex justify-center items-center">
                <div className="font-lato font-bold mt-2 text-[2.5rem] mb-2">{percent}%</div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

function convert(input?: Record<'means' | 'sds' | 'counts', Record<SpecialtyCode, number>>) {
  if (!input) return [];
  const { means, sds, counts } = input;
  const keys = Object.keys(means) as SpecialtyCode[];
  return keys.map((key) => ({
    specialty: key,
    mean: means[key],
    sds: sds[key],
    count: counts[key],
  }));
}

type NonOperativeMetricWidgetProps = {
  title: string;
  nationalAvg: number;
  onSpecialtyClick: (s: SpecialtyCode) => void;
  onNationalStatisticClick: () => void;
  specialties: SpecialtyType[];
  topSpecialties: Record<'means' | 'sds' | 'counts', Record<SpecialtyCode, number>>;
  loading: boolean;
  avg: number;
  standardDeviation: number;
};

function makeVal(percent: number, min: number, max: number) {
  return (percent / 100) * (max - min) + min;
}

export const NonOperativeMetricWidget: FC<NonOperativeMetricWidgetProps> = ({
  title,
  specialties,
  nationalAvg,
  loading,
  onSpecialtyClick,
  onNationalStatisticClick,
  avg,
  standardDeviation,
  topSpecialties,
}) => {
  const specialtiesValues = useMemo(() => convert(topSpecialties), [topSpecialties]);

  const percent = useMemo(() => ((avg - nationalAvg) / nationalAvg) * 100, [avg, nationalAvg]);

  return (
    <div className="">
      <div className="border-b pb-[1.6rem] ">
        <div className="font-lato font-bold text-1620 uppercase mb-2">{title}</div>
        <div onClick={onNationalStatisticClick} className="flex items-baseline cursor-pointer">
          <div className="text-text-black text-opacity-70 font-lato font-semibold text-1220 mr-1">
            National average:
          </div>
          <div className="flex items-baseline">
            {!loading && (
              <div className="font-lato font-bold text-1620 mr-1">{nationalAvg?.toFixed?.(1)}</div>
            )}
            {loading && (
              <div className="bg-ghost animate-pulse w-[2.5rem] h-[1.8rem] rounded-lg mr-1"></div>
            )}
            <div className="font-lato font-bold text-1220 text-osloGray">min</div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-6 ">
        <div>
          <div className="flex items-baseline mb-2">
            {!loading && (
              <div
                onClick={onNationalStatisticClick}
                className={classNames('text-3232 cursor-pointer font-lato font-bold mr-1', {
                  'text-activeText': Number(percent) < -5,
                  'text-inactiveText': Number(percent) > 5,
                })}
              >
                {avg?.toFixed?.(1)}
              </div>
            )}
            {loading && (
              <div className=" bg-ghost animate-pulse rounded-lg w-[5rem] h-[3.2rem] mr-1"></div>
            )}
            <div className="text-1214 font-lato font-bold text-osloGray">min</div>
          </div>
          {!loading && (
            <div className="w-fit flex font-bold font-lato text-1420 text-headerTextGrey bg-peranoHover py-0.5 px-2 rounded-lg">
              +/- {standardDeviation?.toFixed?.(1)}
            </div>
          )}
          {loading && (
            <div className="bg-peranoHover animate-pulse rounded-lg w-[5rem] h-[2.2rem] "></div>
          )}
        </div>
        {specialtiesValues.map((specialty) => (
          <div
            key={specialty.specialty}
            onClick={() => onSpecialtyClick(specialty.specialty)}
            className="flex flex-col justify-end cursor-pointer"
          >
            <div className="font-bold font-lato text-1220">
              {specialties.find((spec) => spec.code === specialty.specialty)?.name ||
                specialty.specialty}
              :
            </div>
            <div className="flex items-center">
              <div className="flex items-baseline mr-2">
                <div className="text-2020 font-lato font-bold mr-1">
                  {specialty.mean?.toFixed?.(0)}
                </div>
                <div className="text-1214 font-lato font-bold text-osloGray">min</div>
              </div>
              <div className="">
                <div className="w-fit flex font-bold font-lato text-1420 text-headerTextGrey bg-peranoHover py-0.5 px-2 rounded-lg">
                  +/- {specialty.sds?.toFixed?.(0)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
