import { useCallback, useEffect, useState } from 'react';
import { makeDownloadChartHandler } from '../../utilities/makeDownloadChartHandler';
import { ChartViewName } from '../../components/MultiFilter/MultiFilters.types';
import { useMultiFilterStore } from '../../components/MultiFilter/MultiFilter.store';
import {
  ArrowUpSvg,
  DataPointsSvg,
  SampleSizeSvg,
  StatSignSvg,
  StdDevSvg,
} from '../../components/Icons';
import { historyPushState } from '../../utilities/helpers';

export const chartId = 'non-operative-metrics-chart';
export const tableId = 'non-operative-metrics-sheet';

export type DisplayOptionValue =
  | 'deviation'
  | 'sampleSize'
  | 'dataPoints'
  | 'min'
  | 'max'
  | 'statisticalSignificance';

export type DisplayOptionType = {
  label: string;
  disabled?: boolean;
  icon: (isActive?: boolean) => JSX.Element;
  value: DisplayOptionValue;
};

export const displayOptions: DisplayOptionType[] = [
  {
    label: 'Sample Size',
    icon: (isActive) => (
      <SampleSizeSvg classNames={[isActive ? 'fill-white' : 'group-hover:fill-white fill-black']} />
    ),
    value: 'sampleSize',
  },

  {
    label: 'Standard Deviation',
    icon: (isActive) => (
      <StdDevSvg
        classNames={[
          isActive ? 'fill-white' : 'group-hover:fill-white fill-black',
          isActive ? 'fill-white' : 'group-hover:fill-white fill-black',
          isActive ? 'fill-white' : 'group-hover:fill-white fill-black',
        ]}
      />
    ),
    value: 'deviation',
  },
  {
    label: 'Statistical Significance',
    icon: (isActive) => (
      <StatSignSvg
        classNames={[
          isActive ? 'fill-white' : 'group-hover:fill-white fill-black',
          isActive ? 'fill-white' : 'group-hover:fill-white fill-black',
        ]}
      />
    ),
    value: 'statisticalSignificance',
  },
  {
    label: 'Data Points',
    icon: (isActive) => (
      <DataPointsSvg
        classNames={[
          isActive ? 'fill-white' : 'group-hover:fill-white fill-black',
          isActive ? 'fill-white' : 'group-hover:fill-white fill-black',
          isActive ? 'fill-white' : 'group-hover:fill-white fill-black',
        ]}
      />
    ),
    value: 'dataPoints',
  },
];

export const peopleCountDisplayOptions: DisplayOptionType[] = [
  {
    label: 'Minimum Count',
    icon: (isActive) => (
      <div className="rotate-180">
        <ArrowUpSvg classNames={[isActive ? 'fill-white' : 'group-hover:fill-white fill-black']} />
      </div>
    ),
    value: 'min',
  },
  {
    label: 'Maximum Count',
    icon: (isActive) => (
      <ArrowUpSvg classNames={[isActive ? 'fill-white' : 'group-hover:fill-white fill-black']} />
    ),
    value: 'max',
  },
  ...displayOptions.filter((f) => f.value !== 'dataPoints'),
];

export const useControls = () => {
  const { nonOperativeMetrics } = useMultiFilterStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [view, setView] = useState<ChartViewName | null>(null);
  const [currentPhase, selectPhase] = useState<GenesisKeys>('sterileprep_patientin');
  const onViewChange = useCallback(
    (v: ChartViewName) => {
      setView(v);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('chartView', v);
      historyPushState(`${window.location.pathname}?${urlParams}`);
    },
    [window.location.search],
  );
  const onPhaseChange = useCallback(
    (p: GenesisKeys) => {
      selectPhase(p);
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('phase', p);
      historyPushState(`${window.location.pathname}?${urlParams}`);
    },
    [window.location.search],
  );

  const compareBy = nonOperativeMetrics.compare;

  const [displayOptionList, setDisplayOptionList] = useState<DisplayOptionValue[]>([]);
  const downloadChart = useCallback(makeDownloadChartHandler(chartId), []);

  const switchChartViews = (btn: typeof displayOptions[0]) => () => {
    setDisplayOptionList((prev) =>
      prev.includes(btn.value) ? prev.filter((f) => f !== btn.value) : [...prev, btn.value],
    );
  };
  const resetChartViews = () => {
    setDisplayOptionList([]);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const maybeChartView = urlParams.get('chartView') || '';
    const maybePhase = urlParams.get('phase') || '';

    if (maybeChartView) {
      setView(maybeChartView as ChartViewName);
    }
    if (maybePhase) {
      selectPhase(maybePhase as GenesisKeys);
    }
  }, [window.location.search]);

  return {
    compareBy,
    displayOptionList,
    view,
    currentPhase,
    downloadChart,
    switchChartViews,
    resetChartViews,
    onViewChange,
    onPhaseChange,
    currentPage,
    setCurrentPage,
  };
};
