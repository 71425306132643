import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { GET_GENESIS_METRICS } from '../../graphql/query/constants';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';
import { transformAllFilters } from '../../utilities/transformAllFilters';
import { CompareValues } from '../../components/MultiFilter/MultiFilters.types';
import { makeLogData } from '../../utilities/helpers';

const logData = makeLogData();
function transformData<K extends string>(
  data: AnalyticsGenericResponse<K>,
  isNationalStatistics = false,
): AnalyticsGenericDataFormat<K>[] {
  const counts = data.counts || {};
  const means = data.means || {};
  const sds = data.sds || {};

  const df_outliers = data.df_outliers || {};
  const dataPoints = data.dataPoints || {};
  const significance = data.significance || {};
  const bubbleChart = data.bubble_charts || {};

  const phases: K[] = [];
  const groups: string[] = [];
  Object.keys(means).forEach((key) => {
    const phaseName = key as K;
    if (!phases.includes(phaseName)) phases.push(phaseName);
    Object.keys(means[phaseName]).forEach((groupName) => {
      if (!groups.includes(groupName)) groups.push(groupName);
    });
  });
  const result = groups.map((group, gg) => {
    const context = phases.map((phase) => {
      const activitiesAmountPhase = isNationalStatistics
        ? {
            'top 20%': 0,
            average: 0,
            'this hospital': counts?.[phase]?.[0],
          }
        : counts?.[phase];
      const sdsPhase = isNationalStatistics
        ? {
            'top 20%': 0,
            average: 0,
            'this hospital': sds?.[phase]?.[0],
          }
        : sds?.[phase];

      const averageDuration = means?.[phase]?.[group] as number;
      const activitiesAmount = (activitiesAmountPhase as any)?.[group] as number;
      const standardDeviation = (sdsPhase as any)?.[group] as number;

      let radius = 0;
      const dataPointsPoints = dataPoints?.[phase]?.[group] || {};
      const bubbleData = (bubbleChart?.[phase] || {}) as BubbleChartResponse;
      const bubblePadding = (bubbleChart as any)?.padding || 0;

      const maybeIndex = bubbleData?.cens?.findIndex((item: any) => item[0] === averageDuration);
      if (maybeIndex !== -1) {
        const rad = bubbleData?.radii?.[maybeIndex] || 0;
        const newRadius = rad - bubblePadding;
        radius = newRadius <= 0 ? rad : newRadius;
      }

      const xLimits = bubbleData?.xlim || [0, 0];
      const yLimits = bubbleData?.ylim || [0, 0];
      const _dataPoints = Object.keys(dataPointsPoints).map((key) => ({
        case_id: Number(key),
        value: dataPointsPoints?.[key],
      }));

      const significanceData = significance?.[phase];

      return {
        name: phase,
        averageDuration,
        activitiesAmount,
        standardDeviation,
        radius,
        xLimits,
        yLimits,
        dataPoints: _dataPoints,
        FStatistic: significanceData?.['F-statistic'] || 0,
        pValue: significanceData?.['p-value'] || 0,
        significant: !!significanceData?.significant,
        raw: data,
      };
    });

    return { name: group === '0' ? 'all' : group, context };
  });

  return result;
}

export function makeSeqPlot<K extends string>(
  data: AnalyticsGenericResponse<K> | undefined,
  caseViewMode: 'inliers' | 'outliers' | null,
) {
  if (!data) return [];
  const means = data.means || {};

  const df = data.df || {};
  const df_outliers = data.df_outliers || {};
  const source = caseViewMode === 'outliers' ? df_outliers : df;

  const phases: K[] = Object.keys(means) as K[];

  return phases.map((phase) => {
    const plot = Object.keys(source?.case_id || {}).map((case_id_index) => {
      const case_id = source?.case_id[case_id_index];
      const data_point = source?.[phase]?.[case_id_index] || 0;
      return { case_id: case_id + '', data_point };
    });

    return {
      name: phase,
      plot,
      averageDuration:
        Object.values(means[phase]).reduce((a, b) => a + b, 0) / Object.values(means[phase]).length,
    };
  });
}

export type GenesisSeqData = ReturnType<typeof makeSeqPlot>;

async function makeQueryGenesisMetrics<K extends string>(params: Record<string, any>) {
  const { data } = await RESTClient.get<AnalyticsGenericResponse<K>>('/genesis', {
    params,
  });

  return data;
}

export function useQueryGenesisMetrics<K extends string>(
  variables: QueryAnalyticsArgs & { aspect_ratio?: number },
  caseViewMode: 'inliers' | 'outliers' | null = null,
  keepPreviousData = false,
) {
  const organization = useSLUGName();
  const params = useMemo(
    () => ({
      from: variables.from,
      to: variables.to,
      ...(organization ? { organization } : {}),
      ...(variables.aspect_ratio ? { aspect_ratio: variables.aspect_ratio } : {}),
      split: variables.split || undefined,
      ...(variables.filters || {}),
    }),
    [variables],
  );

  const processor = useCallback(() => makeQueryGenesisMetrics(params), [params]);
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery([GET_GENESIS_METRICS, params], processor, {
    keepPreviousData,
  });

  logData('/genesis(' + JSON.stringify(params) + ')', data);

  const result = useMemo(() => {
    const res = transformData(
      (data || {}) as AnalyticsGenericResponse<K>,
      (variables.split as CompareValues) === 'national_statistics',
    );
    const arr = [...res];
    const restIndex = arr.findIndex((item) => /rest/i.test(item.name));
    const restItem = arr.splice(restIndex, 1);
    arr.push(...restItem);

    return arr;
  }, [data]);

  const seqPlot = useMemo(() => {
    return makeSeqPlot((data || {}) as AnalyticsGenericResponse<K>, caseViewMode);
  }, [data, caseViewMode]);

  const allFilters = useMemo(() => transformAllFilters(data?.all_filters), [data?.all_filters]);

  return { data: result, rawData: data, seqPlot, allFilters, loading, refetch };
}
