import { FC } from 'react';
import { ReactComponent as DownloadIcon } from '../../assets/download.svg';
import { Tooltip } from '../Tooltip/Tooltip';

type DownloadButtonProps = {
  onClick: () => void;
  title?: string;
};

export const DownloadButton: FC<DownloadButtonProps> = ({ title = 'Download', onClick }) => {
  return (
    <div className="flex justify-center items-center">
      <Tooltip title={title}>
        <div
          className={`cursor-pointer border rounded-[0.8rem] group bg-white transition-all hover:bg-black p-[0.2rem]`}
          onClick={onClick}
        >
          <DownloadIcon className="transition-all fill-black group-hover:fill-white" />
        </div>
      </Tooltip>
    </div>
  );
};
