import { FC, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import administrationSvg from '../../assets/administration.svg';
import { ReactComponent as EllipsisIcon } from '../../assets/ellipsis.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { ReactComponent as ExitIcon } from '../../assets/exit.svg';
import logoutSvg from '../../assets/logout.svg';
import { useSelect } from '../../hooks/useSelect';
import { useOutsideClick, useHistory as useSlugHistory, useUserContext } from '../../hooks';
import { routesPath } from '../../constants/routes';

import { Modal } from '../Modal';
import type { ListItem } from './types';
import { ButtonTypes } from '../common';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';

const getListItems = (
  {
    goToAdministration,
    logoutNative,
  }: { goToAdministration: () => void; logoutNative: () => void },
  showAdministration: boolean,
): ListItem[] => {
  return [
    // {
    //   id: 0,
    //   title: 'My Surgeries',
    //   onPress: () => {},
    //   icon: SurgeriesSvg,
    // },
    {
      id: 1,
      title: 'Administration',
      onPress: goToAdministration,
      icon: administrationSvg,
      visible: showAdministration,
    },
    {
      id: 0,
      title: 'Log Out',
      onPress: logoutNative,
      icon: logoutSvg,
    },
  ].filter((item) => item.visible !== false);
};

const DropdownContent: FC<{ listItems: ListItem[]; onClick: () => void }> = ({
  listItems = [],
  onClick,
}) => {
  if (!listItems?.length) return <span>No actions available</span>;

  return (
    <ul className="bg-white absolute w-[22rem] mt-4 py-[1.2rem] px-[1.4rem] rounded-xl z-10">
      {listItems.map(({ title, id, onPress, icon }, i) => {
        const handleClick = () => {
          onClick?.();
          onPress();
        };

        return (
          <li
            className="flex px-4 py-4 cursor-pointer font-nunito hover:bg-perano hover:bg-opacity-10 rounded-main"
            key={id}
            onClick={handleClick}
          >
            <img className="w-[2rem]" src={icon} alt={title} />
            <span className="text-[1.2rem] leading-8 font-semibold tracking-wider px-4">
              {title}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

const generateAvatar = (fullName?: string) => {
  if (!fullName) return 'J D';

  return fullName
    .split(' ')
    .map((s) => s[0]?.toLocaleUpperCase())
    .filter((s) => !!s)
    .filter((s, index) => index <= 1)
    .join(' ');
};

export const TopAvatar = () => {
  const { logout } = useAuth0();
  const history = useHistory();
  const slugHistory = useSlugHistory();

  const { isOpen, setIsOpen } = useSelect();
  const { isOpen: isOpenLogoutModal, setIsOpen: setIsOpenLogoutModal } = useSelect();
  const menu = useRef(null);
  useOutsideClick(menu, setIsOpen(false));
  const { data, can } = useUserContext();
  if (!data) {
    return null;
  }

  const user = {
    name: data.me.fullName,
    role: data.me.role,
  };

  // const shortName = `${user.name.split('')[0]} ${user.name.split(' ')[1][0]}`;
  const logoutNative = () => {
    history.replace(routesPath.login);
    logout({ returnTo: window.location.origin });
  };
  const goToAdministration = () => {
    slugHistory.push('/administration');
  };
  const listItems = getListItems(
    {
      logoutNative: setIsOpenLogoutModal(true),
      goToAdministration,
    },
    can('superadmin'),
  );

  return (
    <>
      <div ref={menu}>
        <div className="flex cursor-pointer items-center" onClick={setIsOpen(true)}>
          <div className="bg-persianBlue font-nunito font-bold font-[1.6rem] leading-[2.2rem] mr-4 w-[32px] h-[32px] rounded-full flex justify-center items-center text-white">
            {generateAvatar(user.name)}
          </div>
          <div className="">
            <div className="text-1419 font-bold font-nunito text-black break-all">{user.name}</div>
            <div className="text-black text-1014 font-normal opacity-70 font-nunito">
              {user.role}
            </div>
          </div>
          <div className="bullets ml-4">
            <EllipsisIcon />
          </div>
        </div>
        {isOpen && <DropdownContent listItems={listItems} onClick={setIsOpen(false)} />}
      </div>
      {isOpenLogoutModal && (
        <ConfirmationModal
          onClose={setIsOpenLogoutModal(false)}
          onConfirm={logoutNative}
          confirmButtonName="Log Out"
        >
          <div className="w-[4.8rem] h-[4.8rem]">
            <ExitIcon />
          </div>
          <div className="w-[17rem] text-center text-1624 text-black font-lato font-bold mt-8 mb-4">
            Are you sure
            <br /> you want to Log Out?
          </div>
        </ConfirmationModal>
      )}
    </>
  );
};
