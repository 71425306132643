import { FC, useEffect, useMemo, useState } from 'react';
import { getQuery } from '../../utilities/getQuery';
import { BarChart } from '../../components/BarChart/BarChart';
import { PermissionCheck } from '../../components/PermissionCheck';
import { MultiFilter } from '../../components/MultiFilter/MultiFilter';

import { useQueryGenesisMetrics } from '../../rest/query/useQueryGenesisMetrics';
import { routesPath } from '../../constants/routes';
import { PageTitle } from '../../components/PageTitle';

import { chartId, useControls, viewsOptions } from './useControls';

import { ChartReportFooterLabels } from '../../components/ChartReportFooterLabels/ChartReportFooterLabels';
import { ReportFilterSlot } from '../../components/ReportFilterSlot/ReportFilterSlot';
import { ChartViewSwitch } from '../../components/ChartViewSwitch/ChartViewSwitch';

import { genesisLabels } from '../../constants/analyticsLabels';
import { useMultiFilterStore } from '../../components/MultiFilter/MultiFilter.store';
import { makeQuery } from '../../utilities/helpers';
import { useHorizontalBarChartEngine } from '../../components/BarChart/useHorizontalBarChartEngine';
import { createPortal } from 'react-dom';
import { Selector } from '../../components/Selector';
import { CaseViewModeValue } from '../../components/MultiFilter/MultiFilters.types';
import { HorizontalBarChartReportSkeleton } from '../../components/HorizontalBarChartReportSkeleton/HorizontalBarChartReportSkeleton';
import { AxisLabelPoint, Chart } from 'chart.js';
import { CaseTooltip } from '../../components/CaseTooltip/CaseTooltip';
import { debounce } from 'lodash';
import { useQueryCase } from '../../rest/query/useQueryCase';
import { CaseTooltipContent } from '../../components/CaseTooltipContent/CaseTooltipContent';
import { useHistory } from '../../hooks/useHistory';

const REPORT_NAME = 'caseView';

const itemsPerPage = 10;

const createRoute = (caseKey: string) => {
  return `${routesPath.caseCalendar.replace('/:refId', '').replace(':caseId', caseKey)}`;
};

export const CaseView: FC = () => {
  const history = useHistory();
  const dateRanges = getQuery(history);
  const multiFilterStore = useMultiFilterStore();

  const [casePoint, setCasePoint] = useState<AxisLabelPoint | undefined>();
  const filtersState = multiFilterStore[REPORT_NAME];
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [chartCtx, setChartCtx] = useState<Chart | undefined>();

  const { view, setView, downloadChart, currentPage, setCurrentPage } = useControls();

  const setTooltipData = debounce((point: AxisLabelPoint | undefined, chart: Chart | undefined) => {
    setCasePoint(point);
    setChartCtx(chart);
  }, 500);

  const currentQuery = useMemo(
    () => makeQuery(dateRanges, filtersState),
    [dateRanges, filtersState],
  );

  const maybeCaseId = casePoint?.label?.includes('Case')
    ? casePoint?.label.replace(/Case /g, '')
    : '';

  const { meta: caseData, loading: caseLoading } = useQueryCase(maybeCaseId, !!maybeCaseId);

  const { seqPlot, rawData, allFilters, loading } = useQueryGenesisMetrics<GenesisKeys>(
    currentQuery,
    filtersState.caseViewMode,
  );

  const { filtersBar, panel, selectedFilters } = MultiFilter({
    reportName: REPORT_NAME,
    drawerOpen,
    allFilters,
    isLoading: loading,
    availableFilters: rawData?.available_filters,
    leftFiltersBarSlot: (
      <div className="mr-4">
        <div className="font-bold font-lato text-1214 ml-2 mb-4">View</div>
        <Selector
          onSelect={(v) => setView(v.value as CaseViewModeValue)}
          options={viewsOptions}
          notFoundLabel="View"
          pickedData={view}
        />
      </div>
    ),
    hideFilters: ['excludeOutliers'],
    hideCompareSelectbox: true,
    onOpenPanel: () => setDrawerOpen(true),
    onClosePanel: () => setDrawerOpen(false),
  });

  const { horizontalBarChartOptions, getHorizontalBarChartData, getTotalItems } =
    useHorizontalBarChartEngine({
      compareBy: null,
      displayOptionList: [],
      sourceData: seqPlot,
      allFilters,
      itemsPerPage,
      page: currentPage,
      extendOptions: {
        plugins: {
          clickOnTick: {
            x: {
              enabled: false,
            },
            y: {
              enabled: true,
              shouldHover: (e) => !/inliers/gi.test(e?.label || ''),
              onClick: (e) => {
                if (!e || /inliers/gi.test(e.label)) return;
                const route = createRoute(e.label.replace(/Case /g, ''));
                history.push(route);
              },
              onMouseEnter: (e: AxisLabelPoint, chart, d) => {
                setTooltipData(e, chart);
              },
              onMouseLeave: () => {
                if (chartCtx) {
                  setChartCtx(undefined);
                }
                if (casePoint) {
                  setCasePoint(undefined);
                }
              },
            },
          },
        },
      },
    });

  const renderHorizontalBarChart = () => (
    <BarChart
      id={chartId}
      data={getHorizontalBarChartData() as any}
      filterSlot={
        <ReportFilterSlot
          displayOptions={[]}
          hideOptions
          paginationOptions={{
            currentPage: currentPage > Math.ceil(getTotalItems() / itemsPerPage) ? 1 : currentPage,
            onChange: setCurrentPage,
            total: getTotalItems(),
          }}
          displayOptionList={[]}
          selectedFilters={selectedFilters}
          onDownloadChart={downloadChart}
        />
      }
      options={horizontalBarChartOptions}
      footer={
        <ChartReportFooterLabels
          compareBy={null}
          sourceData={seqPlot.map((l) => ({ name: genesisLabels[l.name] }))}
          allFilters={allFilters}
        />
      }
    />
  );

  useEffect(() => {
    let caseTooltip = document.getElementById('caseTooltip');

    if (!caseTooltip) {
      caseTooltip = document.createElement('div');
      caseTooltip.id = 'caseTooltip';
      document.body.appendChild(caseTooltip);
    }
  }, []);

  return (
    <PermissionCheck check="readAnalytics" fallback={<div />}>
      <PageTitle
        header="Case View"
        crumbs={[{ title: 'Analytics', url: routesPath.analytics.replace('/:refId', '') }]}
      />

      <div className="relative">
        {panel}
        {filtersBar}
        <div className="mt-10 flex"></div>

        <div className="bg-white shadow-sm mt-10">
          <ChartViewSwitch
            skeleton={<HorizontalBarChartReportSkeleton />}
            view={'Horizontal Bar View'}
            defaultView="Horizontal Bar View"
            loading={loading}
            horizontalBar={renderHorizontalBarChart}
          />
        </div>
        {createPortal(
          <div className="">
            {maybeCaseId && casePoint && chartCtx && (
              <CaseTooltip
                position="right"
                chart={chartCtx}
                coordinates={{
                  x: casePoint.boundaries.x,
                  y: casePoint.boundaries.y,
                }}
              >
                <CaseTooltipContent
                  caseId={maybeCaseId}
                  caseData={caseData}
                  loading={caseLoading}
                />
              </CaseTooltip>
            )}
          </div>,
          document.body,
        )}
      </div>
    </PermissionCheck>
  );
};
