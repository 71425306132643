import { FC } from 'react';
import { Calendar, dateFnsLocalizer, CalendarProps } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';

import BubbleEvent from './BubbleEvent';
import SkeletonEvent from './SkeletonEvent';
import { generateDummyData } from './generateDummyData';
import { wrapWithEmptyDays } from './wrapWithEmptyDays';
import './style.scss';
import type { IBubbleEvent } from './types';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

type BubbleCalendarProps = Pick<CalendarProps, 'onNavigate' | 'onSelectEvent'> & {
  date?: Date;
  loading?: boolean;
  events: IBubbleEvent[];
};

const BubbleCalendar: FC<BubbleCalendarProps> = ({
  events,
  date,
  loading,
  onNavigate,
  onSelectEvent,
}) => {
  const EventComponent = loading ? SkeletonEvent : BubbleEvent;
  const theseEvents = loading ? generateDummyData(date!) : wrapWithEmptyDays(events, date);

  return (
    <div className="bubbleContainer">
      <Calendar
        date={date}
        onNavigate={onNavigate}
        events={theseEvents}
        defaultView="month"
        localizer={localizer}
        views={{ month: true }}
        components={{
          event: (props) => <EventComponent {...props} onClick={onSelectEvent} />,
        }}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
};

export default BubbleCalendar;
export type { IBubbleEvent } from './types';
