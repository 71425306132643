import { useMutation } from '../../hooks/useMutation';
import { GQLArgs } from '../../types';
import { UMA_LOGIN } from './constants';
import { $, GraphQLTypes } from '../../zeus';

type UMALogin = {
  createSessionWithUmaToken: Partial<GraphQLTypes['Mutation']['createSessionWithUmaToken']>;
};

export const useMutationUMALogin = (args?: GQLArgs<UMALogin>) => {
  return useMutation(
    {
      createSessionWithUmaToken: [
        {
          umaToken: $`umaToken`,
        },
        {
          token: true,
        },
      ],
    },
    { operationName: UMA_LOGIN },
    {
      context: {
        headers: {},
      },
      ...args,
    },
  );
};
