import { forwardRef } from 'react';
import classNames from 'clsx';

import { FilterArrow } from '../FilterArrow';

type FilterContainerProps = {
  open?: boolean;
  active?: boolean;
  className?: string;
  withArrow?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

// eslint-disable-next-line
export const FilterContainer = forwardRef<HTMLDivElement, FilterContainerProps>(
  // eslint-disable-next-line
  (
    { label, className, open, active, disabled, withArrow = true, onClick, children = null },
    ref,
  ) => {
    const bigtxt = label && (label as string)?.length > 15;
    return (
      <div
        className={classNames(
          `
        transition-colors
        duration-300
        select-none
        border-[0.1rem]
        border-solid
        flex
        items-center
        relative
        rounded-t-main
        rounded-b-main
    `,
          {
            'bg-persianBlue  border-persianBlue text-white': open && !active,
            'bg-black  border-black text-white': !open && active,
            'bg-white border-galery text-text-black': !open && !active,
            'opacity-30 cursor-default': disabled,
            'cursor-pointer': !disabled,
          },
          className,
        )}
        ref={ref}
      >
        <div
          className={classNames(
            `
            text-[1rem]
            md:text-[1.2rem]
            h-[2.8rem]
            md:h-[3rem]
            text-left
            flex
            items-center
            justify-start
            mulish
            not-italic
            font-semibold
            leading-[2rem]
            relative
            pl-[1.25rem]
        `,
            {
              'cursor-default': disabled,
              'cursor-pointer': !disabled,
              'min-w-[17rem] md:min-w-[20rem]': bigtxt,
              'min-w-[12rem] md:min-w-[13.2rem]': !bigtxt,
              'pr-[2.7rem]': withArrow,
              'pr-[1.25rem]': !withArrow,
            },
          )}
          onClick={onClick}
        >
          {label}
          {!!withArrow && <FilterArrow disabled={disabled} open={open} />}
        </div>
        {children}
      </div>
    );
  },
);
