import { Form } from 'antd';

import { Button } from '../Button';
import { RoleInput } from './RoleInput';
import { FormSwitch } from '../FormSwitch';
import { FormLabel } from '../../components/FormLabel';
import { FormInput } from '../../components/FormInput';
import { userRoleList } from '../../constants/userRole';
import { UserFormContainer } from './UserFormContainer';
import { FormSeparator } from '../../components/FormSeparator';
import { useMutationUpdateUser } from '../../graphql/mutation/updateUser';
import type { UserFormProps } from './types';

const rolesOptions = Object.values(userRoleList).filter((v) => v.value !== 'superadmin');

export const EditUser: React.FC<UserFormProps> = ({ data, onClose, onSuccess }) => {
  const [form] = Form.useForm();

  const { mutation, loading } = useMutationUpdateUser({
    onCompleted: () => {
      onClose?.();
      onSuccess?.();
    },
  });

  return (
    <UserFormContainer
      label="Update a User"
      actions={
        <>
          <Button type="light" onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" loading={loading} onClick={() => form.submit()}>
            Update
          </Button>
        </>
      }
    >
      <Form
        form={form}
        initialValues={{
          active: true,
          ...data,
        }}
        onFinish={(formData) =>
          mutation({
            variables: { ...data, ...formData, setDefaultPassword: formData.forceResetPassword },
          })
        }
      >
        <FormLabel>Full Name</FormLabel>
        <Form.Item
          className="mx-1"
          name="fullName"
          rules={[{ required: true, message: 'Please input Full Name' }]}
        >
          <FormInput />
        </Form.Item>
        <FormLabel>Speciality</FormLabel>
        <Form.Item className="mx-1" name="speciality">
          <FormInput />
        </Form.Item>
        <FormLabel>Reference ID</FormLabel>
        <Form.Item className="mx-1" name="refId" rules={[{ message: 'Please input Reference ID' }]}>
          <FormInput />
        </Form.Item>
        <FormLabel>User Role</FormLabel>
        <Form.Item className="mx-1 mt-3" name="role">
          <RoleInput options={rolesOptions} />
        </Form.Item>
      </Form>
    </UserFormContainer>
  );
};
