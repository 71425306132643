import { FC } from 'react';

import { FilterButton } from './FilterButton';

type AddButtonProps = {
  onClick: () => void;
  label?: string;
  prefix?: string;
};

export const AddButton: FC<AddButtonProps> = ({ onClick, label = 'Add', prefix = '+' }) => {
  return <FilterButton onClick={onClick} label={label} prefix={prefix} />;
};
