type PasswordValidation = {
  status: 'notValid' | 'valid';
  error?: string;
};

const getValidationError = (password: string): string | null => {
  if (password?.length < 6) return 'Min 6 characters';
  if (password?.length > 32) return 'Max 32 characters';
  if (!password.match('(.*[a-z].*)')) return 'At lease one lower case character';
  if (!password.match('(.*[A-Z].*)')) return 'At lease one upper case character';
  if (!password.match('(.*\\d.*)')) return 'At lease one digit';
  if (!password.replaceAll(' ', '').match('\\W')) return 'At lease one special character';

  return null;
};

export const validatePassword = (password: string): PasswordValidation => {
  const error = getValidationError(password);

  if (!error) {
    return {
      status: 'valid',
    };
  }

  return {
    status: 'notValid',
    error,
  };
};
