import { FC, useCallback, useEffect, useState } from 'react';

import { PermissionCheck } from '../../components/PermissionCheck';

import { getOldQuery, getQuery } from '../../utilities/getQuery';

import { PageTitle } from '../../components/PageTitle';
import { TotalCasesChart } from '../../components/TotalCasesChart/TotalCasesChart';
import { useUserContext } from '../../context';
import { DashboardViewSwitch } from '../../components/DashboardViewSwitch/DashboardViewSwitch';
import { DateRangeSwitch } from '../../components/DateRangeSwitch/DateRangeSwitch';
import {
  CaseMixWidgetRow,
  MetadataWidgetRow,
  NonOperativeMetricWidget,
  ORAScoreWidget,
} from './Widgets';
import { useQueryDashboardNonOperativeMetrics } from '../../rest/query/useQueryDashboardNonOperativeMetrics';
import { useQueryDashboardCaseMix } from '../../rest/query/useQueryDashboardCaseMix';
import { routesPath } from '../../constants/routes';
import { useHistory } from '../../hooks/useHistory';
import { CompareValues } from '../../components/MultiFilter/MultiFilters.types';
import { genesisLabels } from '../../constants/analyticsLabels';
import { useQueryCasesMetrics } from '../../rest/query/useQueryCasesMetrics';
import classNames from 'clsx';
import { useQueryDashboardCaseHistory } from '../../rest/query/useQueryDashboardCaseHistory';
import { ChartDatesViews } from '../../components/TotalCasesChart/SelectChartDatesView';
import { BarChartDashboardSkeleton } from '../../components/BarChartDashboardSkeleton/BarChartDashboardSkeleton';

const nomWidgetBaseStyles = [
  'bg-white',
  'border',
  'shadow-sm',
  'w-[36rem]',
  'h-[17.4rem]',
  'rounded-main',
  'p-[1.6rem]',
];
const nomWidgetStylesClass = [
  ...nomWidgetBaseStyles,
  'mb-4',
  'xl:ml-8',
  'xl:mb-16',
  'xl:mr-0',
  'lg:mr-12',
  'lg:mb-14',
  '2xl:ml-0',
  '2xl:mr-16',
  '2xl:mb-16',
];

export const Dashboard: FC = () => {
  const { data: userData } = useUserContext();
  const history = useHistory();

  const params = new URLSearchParams(window.location.search);

  const [currentChartView, setCurrentChartView] = useState(ChartDatesViews[0].value);

  const changeChartView = useCallback((v: Periods) => {
    setCurrentChartView(v);
  }, []);

  const [isChartView, setIsChartView] = useState(false);

  const [prevQuery, setPrevQuery] = useState(getOldQuery(getQuery(history)));
  const [currentQuery, setCurrentQuery] = useState(getQuery(history));
  const [rangeType, setRangeType] = useState<string>(params.get('type') || 'all');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const type = params.get('type');
    const d = userData.me.current?.organization?.availableData;

    if (!type || type === 'all') {
      setPrevQuery(
        getOldQuery({
          from: d?.from,
          to: d?.to,
        }),
      );
      setCurrentQuery({
        from: d?.from,
        to: d?.to,
      });
    }
  }, [JSON.stringify(userData.me.current?.organization?.availableData)]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setRangeType(params.get('type') || 'all');
  }, [currentQuery]);

  const isAll = rangeType === 'all';

  const { allFilters } = useQueryCasesMetrics({
    from: currentQuery.from,
    to: currentQuery.to,
  });

  const {
    nom: nomData,
    activity: activityData,
    loading: nomLoading,
  } = useQueryDashboardNonOperativeMetrics({
    from: currentQuery.from,
    to: currentQuery.to,
  });
  const { data: caseDataCurrent, loading: caseDataCurrentLoading } = useQueryDashboardCaseMix({
    from: currentQuery.from,
    to: currentQuery.to,
  });

  const { data: caseHistoryData, loading: caseHistoryLoading } = useQueryDashboardCaseHistory(
    {
      from: currentQuery.from,
      to: currentQuery.to,
      period: currentChartView,
    },
    true,
  );

  const { data: caseDataPrev, loading: caseDataPrevLoading } = useQueryDashboardCaseMix(
    {
      from: prevQuery.from,
      to: prevQuery.to,
    },
    !isAll,
  );

  const selectDates = useCallback((dates) => {
    setCurrentQuery(dates);
    setPrevQuery(dates);
  }, []);

  const openCaseAnalytics = useCallback(
    (compare: CompareValues) => () => {
      const path = routesPath.casesAnalytics.replace('/:refId', '');
      history.push(
        `${path}?from=${currentQuery.from}&to=${currentQuery.to}&compare=${compare}&skipFilters=1`,
      );
    },
    [history],
  );

  const openSpecialtiesBubbleChart = useCallback(
    (phase: GenesisKeys) => (specialtyCode: SpecialtyCode) => {
      const compare: CompareValues = 'surgeon_id';
      const path = routesPath.nonOperativeMetrics.replace('/:refId', '');
      history.push(
        `${path}?from=${currentQuery.from}&to=${currentQuery.to}&compare=${compare}&skipFilters=1&specialties=${specialtyCode}&phase=${phase}&chartView=Bubble View`,
      );
    },
    [history],
  );

  const openNationalStatistics = useCallback(() => {
    history.push(
      routesPath.nonOperativeMetrics.replace('/:refId', '') +
        '?compare=national_statistics&skipFilters=1',
    );
  }, [history, currentQuery]);

  const changeDashboardView = useCallback((name: string) => {
    const isChart = name === 'Chart View';
    setIsChartView(isChart);

    const params = new URLSearchParams(window.location.search);
    params.set('dashboardView', name === 'Chart View' ? 'chart' : 'dashboard');
    history.replace({
      search: params.toString(),
    });
  }, []);

  const caseMixLoading = (caseDataPrevLoading && !isAll) || caseDataCurrentLoading;

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const dashboardChartPeriod = params.get('dashboardChartPeriod');
    const dashboardView = params.get('dashboardView');

    let shouldChangeUrl = false;

    if (dashboardChartPeriod && ChartDatesViews.find((v) => v.value === dashboardChartPeriod)) {
      changeChartView(dashboardChartPeriod as Periods);
    } else {
      params.set('dashboardChartPeriod', currentChartView);
      shouldChangeUrl = true;
    }

    if (dashboardView === 'dashboard' || dashboardView === 'chart') {
      setIsChartView(dashboardView === 'chart');
    } else {
      params.set('dashboardView', isChartView ? 'chart' : 'dashboard');
      shouldChangeUrl = true;
    }

    if (shouldChangeUrl) {
      history.replace({
        search: params.toString(),
      });
    }
  }, [isChartView]);

  return (
    <PermissionCheck check="readAnalytics" fallback={<div />}>
      <PageTitle header="Dashboard" subHeader={`Welcome Back, ${userData?.me?.fullName}`} />
      <div className="">
        <div className="flex justify-between mt-[3.5rem]">
          <div className="flex">
            <div className="mr-4">
              <DateRangeSwitch onSelect={selectDates} loading={caseMixLoading} />
            </div>
          </div>
          <div className="switch">
            <DashboardViewSwitch
              value={isChartView ? 'Chart View' : 'Dashboard View'}
              onChange={changeDashboardView}
            />
          </div>
        </div>

        {!isChartView && (
          <div className="flex flex-col xl:flex-row">
            <div className="flex flex-col  justify-start">
              <div className="lg:mr-12 mb-8  lg:mb-12 xl:mb-0 xl:mr-0 2xl:mr-16">
                <div className="font-bold font-lato text-1620 mt-12 mb-2.5">ORA Score</div>
                <div className={classNames(nomWidgetBaseStyles, 'xl:mb-6', '2xl:mb-6')}>
                  <ORAScoreWidget
                    onClick={openNationalStatistics}
                    score={nomData?.ora_score || 0}
                    nationalStatistic={nomData?.national_statistic}
                  />
                </div>
              </div>
              <div className="flex flex-col lg:flex-row xl:flex-col justify-start">
                <div className="lg:mr-12 xl:mr-0 2xl:mr-16">
                  <div className="font-bold font-lato text-1620 mb-2.5">Case Mix</div>
                  <div
                    onClick={openCaseAnalytics('modality')}
                    className="bg-white shadow-sm mb-8 lg:mb-2 2xl:mb-8 w-[36rem] h-[26.6rem] rounded-main p-[1.6rem] cursor-pointer"
                  >
                    <CaseMixWidgetRow
                      hideTrend={isAll}
                      type="total"
                      loading={caseMixLoading}
                      value={caseDataCurrent?.case_totals || 0}
                      prevValue={caseDataPrev?.case_totals || 0}
                    />
                    <CaseMixWidgetRow
                      hideTrend={isAll}
                      type="robotic"
                      loading={caseMixLoading}
                      value={caseDataCurrent?.frequencies?.Robotic || 0}
                      prevValue={caseDataPrev?.frequencies?.Robotic || 0}
                    />
                    <CaseMixWidgetRow
                      hideTrend={isAll}
                      type="open"
                      loading={caseMixLoading}
                      value={caseDataCurrent?.frequencies?.Open || 0}
                      prevValue={caseDataPrev?.frequencies?.Open || 0}
                    />
                    <CaseMixWidgetRow
                      hideTrend={isAll}
                      type="lap"
                      loading={caseMixLoading}
                      value={caseDataCurrent?.frequencies?.Lap || 0}
                      prevValue={caseDataPrev?.frequencies?.Lap || 0}
                    />
                  </div>
                </div>
                <div className="2xl:mr-16">
                  <div className="font-bold font-lato text-1620 xl:mt-4 mb-[0.6rem]">Metadata</div>
                  <div className="bg-white shadow-sm w-[36rem] h-[26.6rem] rounded-main p-[1.6rem] xl:mt-0 ">
                    <MetadataWidgetRow
                      allFilters={allFilters}
                      type="ors"
                      onClick={openCaseAnalytics('OR')}
                      loading={caseDataCurrentLoading}
                      options={caseDataCurrent?.metadata?.ORs?.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                    <MetadataWidgetRow
                      allFilters={allFilters}
                      type="specialties"
                      onClick={openCaseAnalytics('specialty_code')}
                      loading={caseDataCurrentLoading}
                      options={caseDataCurrent?.metadata?.specialties?.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                    <MetadataWidgetRow
                      allFilters={allFilters}
                      type="procedures"
                      onClick={openCaseAnalytics('procedure')}
                      loading={caseDataCurrentLoading}
                      options={caseDataCurrent?.metadata?.procedures?.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                    <MetadataWidgetRow
                      allFilters={allFilters}
                      type="surgeons"
                      onClick={openCaseAnalytics('surgeon_id')}
                      loading={caseDataCurrentLoading}
                      options={caseDataCurrent?.metadata?.surgeons?.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-12 w-full">
              <div className="font-bold font-lato text-1620 xl:ml-8 xl:mb-2.5 2xl:mb-2.5 xl:mr-0 lg:mr-12 2xl:ml-0 mb-3.5">
                Non-Operative Metrics
              </div>
              <div className="flex xl:justify-start 2xl:justify-start flex-wrap lg:w-full 2xl:ml-0">
                <div className="nom-1-column">
                  <div
                    className={classNames(
                      nomWidgetStylesClass.filter((e) => !/mb/.test(e)),
                      'mb-4',
                      'lg:mb-16',
                    )}
                  >
                    <NonOperativeMetricWidget
                      onNationalStatisticClick={openNationalStatistics}
                      onSpecialtyClick={openSpecialtiesBubbleChart('surgery_surgery')}
                      specialties={allFilters.specialties}
                      title={genesisLabels.surgery_surgery}
                      loading={nomLoading}
                      topSpecialties={nomData?.surgery_surgery}
                      nationalAvg={nomData?.national_statistic?.surgery_surgery?.average}
                      avg={activityData?.surgery_surgery?.means?.[0]}
                      standardDeviation={activityData?.surgery_surgery?.sds?.[0]}
                    />
                  </div>

                  <div className={classNames(nomWidgetStylesClass)}>
                    <NonOperativeMetricWidget
                      onNationalStatisticClick={openNationalStatistics}
                      onSpecialtyClick={openSpecialtiesBubbleChart('sterileprep_patientin')}
                      specialties={allFilters.specialties}
                      title={genesisLabels.sterileprep_patientin}
                      topSpecialties={nomData?.sterileprep_patientin}
                      loading={nomLoading}
                      nationalAvg={nomData?.national_statistic?.sterileprep_patientin?.average}
                      avg={activityData?.sterileprep_patientin?.means?.[0]}
                      standardDeviation={activityData?.sterileprep_patientin?.sds?.[0]}
                    />
                  </div>
                  <div className={classNames(nomWidgetStylesClass)}>
                    <NonOperativeMetricWidget
                      onNationalStatisticClick={openNationalStatistics}
                      onSpecialtyClick={openSpecialtiesBubbleChart('patientin_firstcut')}
                      specialties={allFilters.specialties}
                      title={genesisLabels.patientin_firstcut}
                      loading={nomLoading}
                      topSpecialties={nomData?.patientin_firstcut}
                      nationalAvg={nomData?.national_statistic?.patientin_firstcut?.average}
                      avg={activityData?.patientin_firstcut?.means?.[0]}
                      standardDeviation={activityData?.patientin_firstcut?.sds?.[0]}
                    />
                  </div>
                </div>
                <div className="nom-2-column">
                  <div
                    className={classNames(
                      nomWidgetStylesClass.filter((e) => !/mb/.test(e)),
                      'mb-4',
                      'lg:mb-16',
                    )}
                  >
                    <NonOperativeMetricWidget
                      onNationalStatisticClick={openNationalStatistics}
                      onSpecialtyClick={openSpecialtiesBubbleChart('patientout_patientin')}
                      specialties={allFilters.specialties}
                      title={genesisLabels.patientout_patientin}
                      loading={nomLoading}
                      topSpecialties={nomData?.patientout_patientin}
                      nationalAvg={nomData?.national_statistic?.patientout_patientin?.average}
                      avg={activityData?.patientout_patientin?.means?.[0]}
                      standardDeviation={activityData?.patientout_patientin?.sds?.[0]}
                    />
                  </div>
                  <div className={classNames(nomWidgetStylesClass)}>
                    <NonOperativeMetricWidget
                      onNationalStatisticClick={openNationalStatistics}
                      onSpecialtyClick={openSpecialtiesBubbleChart('patientclose_patientout')}
                      specialties={allFilters.specialties}
                      title={genesisLabels.patientclose_patientout}
                      loading={nomLoading}
                      topSpecialties={nomData?.patientclose_patientout}
                      nationalAvg={nomData?.national_statistic?.patientclose_patientout?.average}
                      avg={activityData?.patientclose_patientout?.means?.[0]}
                      standardDeviation={activityData?.patientclose_patientout?.sds?.[0]}
                    />
                  </div>

                  <div className={classNames(nomWidgetStylesClass)}>
                    <NonOperativeMetricWidget
                      onNationalStatisticClick={openNationalStatistics}
                      onSpecialtyClick={openSpecialtiesBubbleChart('patientout_sterileprep')}
                      specialties={allFilters.specialties}
                      title={genesisLabels.patientout_sterileprep}
                      loading={nomLoading}
                      topSpecialties={nomData?.patientout_sterileprep}
                      nationalAvg={nomData?.national_statistic?.patientout_sterileprep?.average}
                      avg={activityData?.patientout_sterileprep?.means?.[0]}
                      standardDeviation={activityData?.patientout_sterileprep?.sds?.[0]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isChartView && (
          <div className="bg-white mt-12">
            {caseHistoryLoading && <BarChartDashboardSkeleton />}

            {!caseHistoryLoading && (
              <TotalCasesChart
                data={caseHistoryData}
                view={currentChartView}
                loading={caseHistoryLoading}
                onChangeView={changeChartView}
              />
            )}
          </div>
        )}
      </div>
    </PermissionCheck>
  );
};
