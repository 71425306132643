import { FC } from 'react';

import { ViewSwitch } from '../ViewSwitch';
import { ReactComponent as ChartIcon } from '../../assets/chart.svg';
import { ReactComponent as DashboardIcon } from '../../assets/dashboard2.svg';

type DashboardViewSwitchProps = {
  onChange: (name: string) => void;
  value?: string;
};

export const DashboardViewSwitch: FC<DashboardViewSwitchProps> = ({ onChange, value }) => {
  const items = [
    {
      style: 'p-[0.6rem]',
      styleActive: 'fill-white stroke-white',
      styleInactive: 'fill-black stroke-black',
      label: 'Dashboard View',
      Icon: DashboardIcon,
    },
    {
      style: 'px-[0.4rem] py-[0.4rem]',
      label: 'Chart View',
      Icon: ChartIcon,
      styleActive: 'fill-white',
      styleInactive: 'fill-black',
    },
  ];

  return <ViewSwitch defaultItem={value} items={items} onChange={onChange} />;
};
