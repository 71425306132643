import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'antd';
import clsx from 'clsx';

import loadingSvg from '../../assets/loading.svg';
import { ERRORS_TYPE } from '../../constants/errors';
import { routesPath } from '../../constants/routes';
import { useMutationLogin } from '../../graphql/mutation/login';
import { useLazyQueryClinic } from '../../graphql/query/getClinics';
import { ReactComponent as OpenEye } from '../../assets/open-eye.svg';
import { ReactComponent as ClosedEye } from '../../assets/closed-eye.svg';
import { GraphQLTypes } from '../../zeus';
import { DeepPartial } from '../../types';
import { useMultiFilterStore } from '../../components/MultiFilter/MultiFilter.store';
import { lastUrlStorage } from '../../utilities/helpers';

export const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const ShowPasswordIcon = showPassword ? OpenEye : ClosedEye;
  const {
    mutation: loginUser,
    error,
    loading,
  } = useMutationLogin({
    onCompleted: (res) => {
      if (res.createSessionWithPassword.token) {
        localStorage.setItem('token', res.createSessionWithPassword.token);
        getClinics();
      }
    },
  });

  const { fetch: getClinics } = useLazyQueryClinic({
    onCompleted: ({ organizations }) => {
      goToDashboard(organizations);
    },
    onError: (apolloError) => {
      const { code, extra } = apolloError?.graphQLErrors[0]?.extensions;
      if (code === ERRORS_TYPE.FORCE_RESET_PASSWORD) {
        localStorage.setItem('resetToken', extra.resetToken);
        history.push(routesPath.resetPassword);
      }
    },
    fetchPolicy: 'network-only',
  });

  const history = useHistory();

  useEffect(() => {
    const code = error?.graphQLErrors[0]?.extensions?.code;
    if (error && !code) {
      toast.error('Unknown Error', {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
    if (code === ERRORS_TYPE.NOT_ACTIVE) {
      toast.error(error?.graphQLErrors?.[0]?.message || 'Error', {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  }, [error]);

  const onSubmit = async (form: { email: string; password: string }) => {
    loginUser({ variables: form });
  };

  const goToDashboard = (organizations: DeepPartial<GraphQLTypes['OrganizationsList']>) => {
    const clinicRefId = localStorage.getItem('hospital-refId');
    const returnUrl = lastUrlStorage.get();
    if (!clinicRefId) {
      const refId = organizations?.nodes?.[0]?.refId + '';
      const maybeStore = localStorage.getItem(`multiFilter-storage_${refId}`);

      if (!maybeStore) {
        useMultiFilterStore.persist.setOptions({
          name: `multiFilter-storage_${refId}`,
        });
        useMultiFilterStore.persist.rehydrate();
        localStorage.removeItem(`multiFilter-storage_null`);
      }
      localStorage.setItem('hospital-refId', refId + '');

      if (returnUrl) {
        lastUrlStorage.remove();
        history.push(returnUrl);
      } else {
        history.push(`/${organizations?.nodes?.[0]?.refId}/dashboard`);
      }
    } else {
      if (returnUrl) {
        lastUrlStorage.remove();
        history.push(returnUrl);
      } else {
        history.push(`/${clinicRefId}/dashboard`);
      }
    }
  };

  return (
    <div className="flex flex-col h-full justify-center items-center min-w-[25rem] login">
      <div className="text-4064 mb-[1.5rem]">Welcome</div>
      <Form className="w-full" onFinish={onSubmit} autoComplete="off">
        <Form.Item
          name="email"
          rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
        >
          <div className="flex flex-col">
            <label
              style={{ fontSize: 10 }}
              className="ml-4 text-1020 opacity-70 font-[500]"
              htmlFor="email"
            >
              Email
            </label>
            <div
              className={clsx(
                'h-[4rem] flex items-center border border-solid border-ghost w-full rounded-[0.6rem] bg-white',
              )}
            >
              <input
                type="text"
                autoComplete="email"
                className="h-[3rem] border-none w-full tracking-wider font-semibold text-1214 font-lato placeholder:text-regentGrey placeholder:text-1214 placeholder:font-lato placeholder:tracking-wider placeholder:font-semibold px-4 focus:ring-0"
                id="email"
              />
            </div>
          </div>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <div className="flex flex-col">
            <label
              style={{ fontSize: 10 }}
              className="ml-4 text-1020 opacity-70 font-[500]"
              htmlFor="password"
            >
              Password
            </label>
            <div
              className={clsx(
                'h-[4rem] flex items-center border border-solid border-ghost w-full rounded-[0.6rem] bg-white',
              )}
            >
              <input
                type={showPassword ? 'text' : 'password'}
                autoComplete="password"
                className="h-[3rem]  border-none w-full tracking-wider font-semibold text-1214 font-lato placeholder:text-regentGrey placeholder:text-1214 placeholder:font-lato placeholder:tracking-wider placeholder:font-semibold px-4 focus:ring-0"
                id="password"
              />
              <ShowPasswordIcon
                onClick={() => setShowPassword(!showPassword)}
                className="mr-6 cursor-pointer"
              />
            </div>
          </div>
        </Form.Item>

        {error?.graphQLErrors[0]?.extensions.code === ERRORS_TYPE.NOT_AUTHORIZED && (
          <div data-testid="NOT_AUTHORIZED" className="error">
            Combination of Login and Password are incorrect
          </div>
        )}

        <button
          className={clsx(
            'text-white w-full rounded-main tracking-wider text-center text-1214 py-4 mt-14 flex justify-center items-center h-[3.2rem]',
            {
              'bg-persianBlue': loading,
              'bg-black': !loading,
            },
          )}
          type="submit"
        >
          {loading ? <img className="w-6" src={loadingSvg} alt="Loading..." /> : 'Log In'}
        </button>
      </Form>
    </div>
  );
};
