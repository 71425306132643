import { useMemo } from 'react';

import { $ } from '../../zeus';
import { GET_USERS } from '../../graphql/query/constants';
import { usePaginationItems } from '../../hooks/usePaginationItems';

const COUNT_OF_ITEMS_PER_PAGE = 20;

export const useUsers = () => {
  const {
    loading,
    searchQuery,
    items: users,
    pagination,
    refetchItems,
    onScroll,
    onSearch,
  } = usePaginationItems({
    query: {
      users: [
        {
          search: $`search`,
          pagination: $`pagination`,
        },
        {
          nodes: {
            active: true,
            email: true,
            fullName: true,
            speciality: true,
            refId: true,
            role: true,
            forceResetPassword: true,
          },
          pagination: {
            totalNodes: true,
          },
        },
      ],
    },
    entity: 'users',
    operationName: GET_USERS,
  });

  const usersList = useMemo(() => {
    return (loading ? Array(COUNT_OF_ITEMS_PER_PAGE).fill({}) : users).map(
      (item: {
        role: string;
        refId: string;
        email: string;
        active: boolean;
        fullName: string;
        speciality: string;
        forceResetPassword: boolean;
      }) => {
        return {
          role: item.role,
          email: item.email,
          refId: item.refId,
          active: item.active,
          fullName: item.fullName,
          speciality: item.speciality,
          forceResetPassword: item.forceResetPassword,
        };
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, loading]);

  return {
    loading,
    usersList,
    searchQuery,
    pagination,
    onScroll,
    onSearch,
    refetchItems,
  };
};
