import clsx from 'clsx';

type FormLabelProps = {
  className?: string;
  marginClassName?: string;
  children?: React.ReactNode;
};

export const FormLabel: React.FC<FormLabelProps> = ({
  className,
  marginClassName = 'ml-2',
  children,
}) => {
  return (
    <span
      className={clsx(
        className,
        marginClassName,
        'text-1020 text-neutralBlack opacity-70 font-medium',
      )}
    >
      {children}
    </span>
  );
};
