import { useState, useCallback } from 'react';

export const useSelect = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toogleIsOpen = () => setIsOpen(!isOpen);

  const setIsOpenReturn = useCallback(
    (status: boolean) => () => {
      setIsOpen(status);
    },
    [setIsOpen],
  );

  return { isOpen, setIsOpen: setIsOpenReturn, toogleIsOpen };
};
