import { Form } from 'antd';

import { Button } from '../Button';
import { RoleInput } from './RoleInput';
import { FormSwitch } from '../FormSwitch';
import { FormLabel } from '../../components/FormLabel';
import { FormInput } from '../../components/FormInput';
import { userRoleList } from '../../constants/userRole';
import { UserFormContainer } from './UserFormContainer';
import { FormSeparator } from '../../components/FormSeparator';
import { useMutationCreateUser } from '../../graphql/mutation/createUser';
import type { UserFormProps } from './types';

const rolesOptions = Object.values(userRoleList).filter((v) => v.value !== 'superadmin');

export const AddUser: React.FC<UserFormProps> = ({ onClose, onSuccess }) => {
  const [form] = Form.useForm();

  const { mutation, loading } = useMutationCreateUser({
    onCompleted: () => {
      onClose?.();
      onSuccess?.();
    },
  });

  return (
    <UserFormContainer
      label="Add New User"
      actions={
        <>
          <Button type="light" onClick={onClose}>
            Cancel
          </Button>
          <Button type="primary" loading={loading} onClick={() => form.submit()}>
            Add
          </Button>
        </>
      }
    >
      <Form
        form={form}
        initialValues={{
          active: true,
          role: 'surgeon',
        }}
        onFinish={(data) => mutation({ variables: data })}
      >
        <FormLabel>Full Name</FormLabel>
        <Form.Item
          className="mx-1"
          name="fullName"
          rules={[{ required: true, message: 'Please input Full Name' }]}
        >
          <FormInput />
        </Form.Item>
        <FormLabel>Email</FormLabel>
        <Form.Item
          name="email"
          className="mx-1"
          rules={[{ required: true, type: 'email', message: 'Please input Email' }]}
        >
          <FormInput />
        </Form.Item>
        <FormLabel>Speciality</FormLabel>
        <Form.Item className="mx-1" name="speciality">
          <FormInput />
        </Form.Item>
        <FormLabel>Reference ID</FormLabel>
        <Form.Item className="mx-1" name="refId" rules={[{ message: 'Please input Reference ID' }]}>
          <FormInput />
        </Form.Item>
        <FormLabel>User Role</FormLabel>
        <Form.Item className="mx-1 mt-3" name="role">
          <RoleInput options={rolesOptions} />
        </Form.Item>
      </Form>
    </UserFormContainer>
  );
};
