import { useRouteMatch } from 'react-router-dom';
import classNames from 'clsx';

import { Header } from './Header';
import { TopPanel } from './TopPanel';
import { useCaseCalendar } from './useCaseCalendar';
import CaseTimeline from '../../components/CaseTimeline';
import CalendarLayout from '../../components/CalendarLayout';
import { useState } from 'react';
import { zoomMax } from '../../components/CaseTimeline/zoom';
import { getStageLegendClassName } from '../../components/CaseTimeline/getStageClassName';

export const CaseCalendar = () => {
  const {
    params: { caseId },
  } = useRouteMatch<{ caseId: string }>();

  const { loading, events, start, end, meta } = useCaseCalendar(caseId);

  const [zoom, setZoom] = useState(zoomMax);

  return (
    <>
      <Header case={meta} date={events?.[0]?.start || new Date()} loading={loading} />
      <CalendarLayout>
        <CalendarLayout.Content
          actionsAlign="center"
          actions={
            <TopPanel
              caseId={caseId}
              loading={loading}
              zoom={zoom}
              onZoomChange={setZoom}
              end={end}
              start={start}
              meta={meta}
            />
          }
        >
          <CaseTimeline
            zoom={zoom}
            onZoomChange={setZoom}
            date={events?.[0]?.start || new Date()}
            loading={loading}
            events={events ?? []}
            onNavigate={() => 1}
          />
          <div className="flex flex-wrap">
            {loading &&
              new Array(21).fill(1)?.map((_, i) => {
                return (
                  <div
                    key={'du' + i}
                    className={classNames(
                      'animate-pulse mx-2 my-2 bg-ghost w-[10rem] h-[2.5rem] px-2 py-2 rounded-xl',
                    )}
                  ></div>
                );
              })}
            {!loading &&
              events?.map((e, i) => {
                return (
                  <div
                    key={String(e.title) + i}
                    className={classNames(
                      'mx-2 my-2 font-lato font-bold text-1214 px-2 py-2 rounded-xl',
                      getStageLegendClassName(e.stage),
                    )}
                  >{`${i + 1}. ${e.title}`}</div>
                );
              })}
          </div>
        </CalendarLayout.Content>
      </CalendarLayout>
    </>
  );
};
