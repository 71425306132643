import { FC } from 'react';
import clsx from 'clsx';
import { Skeleton } from 'antd';
import { format } from 'date-fns';
import { EventProps } from 'react-big-calendar';

import type { IBubbleEvent } from './types';

const SkeletonEvent: FC<EventProps<IBubbleEvent>> = ({ event }) => {
  return (
    <div
      className={clsx(
        'flex flex-col justify-center items-center w-full h-full relative cursor-pointer hover:bg-peranoHover',
        event.offRange ? 'rbc-off-range' : '',
      )}
    >
      <div className="rbc-date-cell show text-neutralBlack absolute right-0 top-0">
        {format(event.start ?? new Date(), 'd')}
      </div>

      <Skeleton.Avatar active shape="circle" />
    </div>
  );
};

export default SkeletonEvent;
