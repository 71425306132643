import { WatchQueryFetchPolicy } from '@apollo/client';
import { useLazyQuery, useQuery } from '../../hooks/useQuery';
import { CreateQueryResponse, GQLArgs } from '../../types';

export const useQueryClinics = () => {
  const { data, loading } = useQuery(
    {
      organizations: [
        {},
        {
          nodes: {
            logoUrl: true,
            refId: true,
            name: true,
            slug: true,
            parent: {
              refId: true,
            },
          },
        },
      ],
    },
    {
      operationName: 'GET_CLINICS',
    },
    {
      fetchPolicy: 'no-cache',
    },
  );
  return { data, loading };
};

export const useLazyQueryClinic = (
  args?: GQLArgs<CreateQueryResponse<'organizations'>> & { fetchPolicy?: WatchQueryFetchPolicy },
) => {
  return useLazyQuery(
    {
      organizations: [
        {},
        {
          nodes: {
            logoUrl: true,
            refId: true,
            name: true,
            slug: true,
            parent: {
              slug: true,
            },
          },
        },
      ],
    },
    {
      operationName: 'GET_CLINICS',
      context: false,
    },
    {
      fetchPolicy: 'cache-first',
      ...args,
    },
  );
};
