import { FieldPolicy, StoreValue } from '@apollo/client';

export const pagination: FieldPolicy<StoreValue> = {
  keyArgs: false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merge: (existing: any, incoming: any) => {
    return {
      ...incoming,
      nodes: [...(existing?.nodes || []), ...(incoming?.nodes || [])],
      pagination: {
        ...existing?.pagination,
        ...incoming?.pagination,
      },
    };
  },
};
