import { FC } from 'react';
import ReactDOM from 'react-dom';

export const BreadcrumbsContainer: FC = () => {
  return (
    <div className="my-2  h-[2.75rem]">
      <span className="font-montserrat font-semibold" id="header-breadcrumbs-container" />
    </div>
  );
};

export const BreadcrumbsContent: FC = ({ children }) => {
  const node = document.getElementById('header-breadcrumbs-container');

  return node ? ReactDOM.createPortal(children, node) : <></>;
};
