import { useState } from 'react';

export const useAddEditUser = <T = any>() => {
  const [key, setKey] = useState(1);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState<T | null>(null);

  const incrementKey = () => setKey((k) => k + 1);

  const startEdit = (data: T) => {
    incrementKey();
    setIsEdit(true);

    setData(data);
    setOpen(true);
  };

  const startAdd = () => {
    incrementKey();
    setIsEdit(false);

    setData(null);
    setOpen(true);
  };

  const stop = () => {
    setData(null);
    setOpen(false);
  };

  return {
    data,
    key,
    open,
    isEdit,
    startEdit,
    startAdd,
    stop,
  };
};
