import { FC } from 'react';
import { format } from 'date-fns';

export type MonthLabelProps = {
  date?: Date | null;
  className?: string;
};

const MonthLabel: FC<MonthLabelProps> = ({ date, className, children }) => {
  const formattedMonth = date ? format(date, 'MMMM yyyy') : 'Date is not set';

  return (
    <div className={className}>
      {children}
      {formattedMonth}
    </div>
  );
};

export default MonthLabel;
