import type { CaseStageType } from '../../types';

import './style.scss';

export const getStageClassName = (type?: CaseStageType): string => {
  switch (type) {
    case 'patient-prep':
      return 'prepStage';
    case 'main':
      return 'surgeryStage';
    case 'robotic':
      return 'postStage';
    case 'turnover':
      return 'turnoverStage';
    default:
      return 'surgeryStage';
  }
};
export const getStageLegendClassName = (type?: CaseStageType): string => {
  switch (type) {
    case 'patient-prep':
      return 'legend-prepStage';
    case 'main':
      return 'legend-surgeryStage';
    case 'robotic':
      return 'legend-postStage';
    case 'turnover':
      return 'legend-turnoverStage';
    default:
      return 'legend-surgeryStage';
  }
};

export const getResourceColor = (resourceLabel?: string) => {
  switch (resourceLabel) {
    case 'Turnover':
      return 'bg-turnover';
    case 'Patient-Prep':
      return 'bg-prep';
    case 'Main':
      return 'bg-surgery';
    case 'Robotic':
      return 'bg-post';
    default:
      return 'bg-surgery';
  }
};

export const getResourceClassName = () => {
  return 'resource';
};
