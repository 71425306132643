import { FC } from 'react';

type FilterButtonProps = {
  label: string;
  prefix?: string;
  onClick: () => void;
};

export const FilterButton: FC<FilterButtonProps> = ({ label, prefix, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`
        flex
        justify-between
        md:text-[1.6rem]
        h-[2.8rem]
        md:h-[3rem]
        text-center
        bg-persianBlue
        border
        border-[#A5BEF5]
        rounded-[2.4rem]
        items-center
        relative
        px-[1.2rem]
        cursor-pointer
        mr-6
      `}
    >
      {!!prefix && <div className="text-white text-[2rem] relative bottom-[1px] ">{prefix}</div>}
      <div className="text-1214 pl-2 tracking-wider font-lato font-[500] text-white">{label}</div>
    </div>
  );
};
