/* eslint-disable max-len */
import clsx from 'clsx';
export const ArrowUpSvg = ({ classNames }: { classNames?: string[] }) => {
  return (
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={clsx(classNames?.[0])}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.38498e-08 8L1.99999 10L8 4L14 10L16 8L8 9.5399e-08L2.38498e-08 8Z"
      />
    </svg>
  );
};
