import { FC } from 'react';
import { format } from 'date-fns';

export type DateLabelProps = {
  date?: Date | null;
};

const DateLabel: FC<DateLabelProps> = ({ date, children }) => {
  const formattedMonth = date ? format(date, 'do MMMM yyyy') : 'Date is not set';

  return (
    <div>
      {children}
      {formattedMonth}
    </div>
  );
};

export default DateLabel;
