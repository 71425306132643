import React from 'react';
import { useHistory } from 'react-router-dom';

import { useHistory as useSluggedHistory } from '../../hooks';

export interface LinkProps {
  exact?: boolean;
  path: string;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [name: string]: any;
  text: React.ReactNode;
}

export const Link: React.FC<LinkProps> = ({ text, path, exact = true, ...args }) => {
  const history = useHistory();
  const sluggedHistory = useSluggedHistory();

  const historyProvider = exact ? history : sluggedHistory;

  return (
    <div {...args} onClick={() => historyProvider.push(path)}>
      <div>{text}</div>
    </div>
  );
};
