import React, { FC, useCallback, useContext } from 'react';
import { useSidebarCollapsed } from './useSidebarCollapsed';

type AppContextType = {
  sidebarCollapsed: boolean;
  spreadSidebar: () => void;
  toggleSidebar: () => void;
  collapseSidebar: () => void;
};

const AppContext = React.createContext<AppContextType>({
  sidebarCollapsed: false,
  spreadSidebar: () => 1,
  toggleSidebar: () => 1,
  collapseSidebar: () => 1,
});

export const useAppContext = () => useContext<AppContextType>(AppContext);

const AppContextProvider: FC = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useSidebarCollapsed();

  const spreadSidebar = useCallback(() => setSidebarCollapsed(false), []);
  const collapseSidebar = useCallback(() => setSidebarCollapsed(true), []);
  const toggleSidebar = useCallback(
    () => setSidebarCollapsed(!sidebarCollapsed),
    [sidebarCollapsed],
  );

  return (
    <AppContext.Provider
      value={{
        sidebarCollapsed,
        spreadSidebar,
        toggleSidebar,
        collapseSidebar,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default React.memo(AppContextProvider) as typeof AppContextProvider;
