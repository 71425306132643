import clsx from 'clsx';

import { Menu } from './Menu';
import { useAppContext } from '../../context';
import { ReactComponent as BigLogo } from '../../assets/ora.svg';
import { ReactComponent as SmallLogo } from '../../assets/ora-small.svg';
import { ClinicsSelector } from '../ClinicsSelector/ClinicsSelector';

const SidebarDivider = () => {
  return (
    <div className="flex justify-center">
      <div
        className="w-[20rem] h-px opacity-50 rounded"
        style={{
          background:
            'linear-gradient(270deg, rgba(255, 255, 255, 0.05) 0%, #FFFFFF 50.1%, rgba(255, 255, 255, 0.05) 100%)',
        }}
      ></div>
    </div>
  );
};

export const Sidebar = () => {
  const { sidebarCollapsed } = useAppContext();

  return (
    <div
      className={clsx(
        'flex flex-col bar h-full bg-persianBlue rounded-[2rem] px-6 transition-all',
        sidebarCollapsed ? 'w-[11rem]' : 'w-[23rem]',
      )}
    >
      <div className="logo flex justify-center items-center h-[9rem]">
        {sidebarCollapsed ? <SmallLogo /> : <BigLogo />}
      </div>
      <SidebarDivider />
      <ClinicsSelector />
      <SidebarDivider />
      <Menu />
    </div>
  );
};
