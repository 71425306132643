import clsx from 'clsx';
import { FC } from 'react';

import { ReactComponent as TrashBin } from '../../assets/trashbin.svg';

type FiltersPresetsItemProps = {
  name: string;
  checked?: boolean;
  error?: boolean;
  onSelect: () => void;
  onDelete: () => void;
};

export const FiltersPresetsItem: FC<FiltersPresetsItemProps> = ({
  name,
  checked,
  error,
  onSelect,
  onDelete,
}) => {
  return (
    <div className="flex py-4 cursor-pointer">
      <label
        onClick={() => onSelect()}
        className={clsx('w-full border rounded-[1.6rem] px-[1.3rem] py-[.3rem] h-[3.1rem]', {
          'border-venetianRed': error,
        })}
      >
        <input onChange={() => 1} checked={checked} type="radio" className="mr-[1.1rem]" />
        <span className="font-[500] text-1214 tracking-wider">{name}</span>
      </label>
      <div
        onClick={onDelete}
        className="cursor-pointer flex justify-center items-center ml-4 rounded-full border hover:border-persianBlue h-[3rem] w-[3.5rem]"
      >
        <TrashBin className=" stroke-ghost hover:stroke-persianBlue" />
      </div>
    </div>
  );
};
