import { convertStringToDatetime } from '../../utilities/dateTime';

import type { IRoomEvent } from '../../components/RoomsCalendar';
import { useQueryCalendarDayResponse } from '../../rest/query/useQueryCalendarDay';

export const convertDataToEvents = (
  data: useQueryCalendarDayResponse['data'],
): null | IRoomEvent[] => {
  const events = Object.keys(data).flatMap((roomName) => {
    const cases = data[roomName];
    return cases?.map((item) => ({
      start: convertStringToDatetime(item.start_at),
      end: convertStringToDatetime(item.end_at),
      roomSlug: roomName,
      title: `${item.surgery_name ?? 'Unknown surgery'} (${item.modality}) - ${
        item.surgeon ?? 'Unknown surgeon'
      }`,
      caseKey: String(item.case_id),
      delay: Number(item.delay),
      surgeonName: item.surgeon ?? 'Unknown surgeon',
      surgeryName: item.surgery_name ?? 'Unknown surgery',
      procedureType: item.modality as IRoomEvent['procedureType'],
    }));
  });

  return events ?? [];
};

export const convertDataToEventsRooms = (data: useQueryCalendarDayResponse['data']) => {
  return Object.keys(data)
    .map((roomName) => roomName)
    .filter((r) => !!r);
};
