import { DownloadButton } from '../DownloadButton';
import { Pagination } from '../Pagination/Pagination';
import { ChartViewButtons } from '../ChartViewButtons/ChartViewButtons';

type ReportFilterSlotProps<V extends string> = {
  hideOptions?: boolean;
  selectedFilters: JSX.Element;
  paginationOptions?: {
    currentPage: number;
    total: number;
    pageSize?: number;
    onChange: (page: number) => void;
  };
  displayOptions: { label: string; icon: (isActive?: boolean) => JSX.Element; value: V }[];
  displayOptionList: V[];
  onSwitchChartViews?: (btn: {
    label: string;
    icon: (i?: boolean) => JSX.Element;
    value: V;
  }) => () => void;
  onDownloadChart: () => void;
};

export function ReportFilterSlot<V extends string = string>({
  displayOptions,
  hideOptions,
  displayOptionList,
  paginationOptions,
  onDownloadChart,
  selectedFilters,
  onSwitchChartViews,
}: ReportFilterSlotProps<V>) {
  return (
    <div data-testid="filter-slot" className="flex flex-col-reverse xl:flex-row justify-between">
      <div className="w-full inline-flex items-center">{selectedFilters}</div>
      <div className="w-fit my-2 xl:mb-2 self-end">
        <div className="flex justify-between">
          {paginationOptions && (
            <div className="w-[28rem] flex justify-end mr-4">
              <Pagination {...paginationOptions} />
            </div>
          )}
          {!hideOptions && (
            <ChartViewButtons
              displayOptionList={displayOptionList}
              displayOptions={displayOptions}
              onSwitchChartViews={onSwitchChartViews}
            />
          )}
          <DownloadButton title="Download Chart" onClick={onDownloadChart} />
        </div>
      </div>
    </div>
  );
}
