import * as H from 'history';
import { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Expand } from './Expand';
import { MenuItem } from './MenuItem';
import { useAppContext } from '../../context';
import { routesPath, slugRoutesPaths } from '../../constants/routes';
import { useUserContext } from '../../context/userContext';
import { Slug } from '../../types';
import { GraphQLTypes } from '../../zeus';
import { SubTab, Tab } from './types';
import { DashboardSvg, AnalyticsSvg, CalendarSvg, UserSvg, SettingsSvg } from '../Icons';
import clsx from 'clsx';

const getListItems = ({ history, refId }: { history: H.History; refId: string }) => {
  return {
    [routesPath.clinic]: {
      id: 0,
      icon: DashboardSvg,
      text: 'Dashboard',
      children: [routesPath.newHospital],
      onClick: () => {
        if (window.location.pathname === `/${refId}/dashboard`) {
          return;
        }
        history.push(refId ? `/${refId}/dashboard` : '/');
      },
    },
    [routesPath.analytics]: {
      id: 1,
      icon: AnalyticsSvg,
      children: [],
      text: 'Analytics',
      onClick: () => {
        history.push(`/${refId}/analytics`);
      },
    },
    [routesPath.calendar]: {
      id: 2,
      icon: CalendarSvg,
      text: 'Calendar',
      children: [],
      onClick: () => {
        history.push(`/${refId}/calendar`);
      },
      permission: 'readAnalytics',
    },
    [routesPath.users]: {
      id: 3,
      icon: UserSvg,
      text: 'Users',
      children: [routesPath.newUser],
      onClick: () => {
        history.push(`/${refId}/users`);
      },
      permission: 'readUsers',
    },
    [routesPath.settings]: {
      id: 4,
      icon: SettingsSvg,
      text: 'Settings',
      children: [],
      onClick: () => {
        history.push(`/${refId}/settings`);
      },
      permission: 'updateOrganizations',
    },
  };
};

export const Menu = () => {
  const initialClinic = {
    name: '',
    img: '',
    refId: '',
  };
  const { can } = useUserContext();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<Slug>(slugRoutesPaths);
  const [currentClinic, setCurrentClinic] =
    useState<Partial<GraphQLTypes['Organization']>>(initialClinic);
  const clinicSlug = currentClinic?.refId ? currentClinic?.refId : match?.params.refId;
  const listItemsConfig = getListItems({
    history,
    refId: clinicSlug as string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;
  const [currentTab, setCurrentTab] = useState<Tab | SubTab>(
    listItemsConfig[`${match?.path}`] || listItemsConfig[routesPath.clinic],
  );
  const listItems: Tab[] = Object.values<Tab>(listItemsConfig).filter(
    (item) => !item.permission || can(item.permission),
  );

  const { sidebarCollapsed, toggleSidebar } = useAppContext();

  useEffect(() => {
    if (match) {
      if (listItemsConfig[match.path]) {
        setCurrentTab(listItemsConfig[match.path]);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const tab: any = Object.values(listItemsConfig).find((listItem) =>
          (listItem as { children?: string[] }).children?.includes(match.path),
        );
        setCurrentTab(tab);
      }
    } else {
      setCurrentTab(listItemsConfig[routesPath.clinic]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.path]);

  return (
    <div className="menu flex justify-center flex-grow">
      <div className="w-full h-full flex flex-col">
        <div className="flex items-center mt-8 mb-4 px-12 w-[14.2rem] h-[1.4rem] text-perano text-[1rem] leading-6">
          Tools
        </div>
        {listItems.map((tab: Tab) => {
          const { id, text } = tab;
          const isActive = currentTab?.text === text;

          return (
            <Fragment key={id}>
              <MenuItem
                key={id}
                active={isActive}
                setCurrentTab={() => {
                  setCurrentTab(tab);
                  tab?.onClick?.();
                }}
                icon={<tab.icon isActive={isActive} />}
                title={!sidebarCollapsed ? tab.text : undefined}
              />
            </Fragment>
          );
        })}
        <div
          className={clsx(
            'mt-auto pb-16 inline-flex justify-end transition-all',
            sidebarCollapsed ? '' : 'pr-2',
          )}
        >
          <div
            onClick={toggleSidebar}
            className={clsx(
              sidebarCollapsed ? 'px-11 py-4' : 'p-4',
              'cursor-pointer rounded-main hover:bg-peranoHover transition-all',
            )}
          >
            <Expand direction={sidebarCollapsed ? 'right' : 'left'} />
          </div>
        </div>
      </div>
    </div>
  );
};
