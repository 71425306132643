import { useCallback, useState } from 'react';

const key = 'ora-sidebar-collapsed?';

export const useSidebarCollapsed = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(localStorage.getItem(key) === 'yes');

  const handleSetSidebarCollapsed = useCallback((newValue: boolean) => {
    localStorage.setItem(key, newValue ? 'yes' : 'no');
    setSidebarCollapsed(newValue);
  }, []);

  return [sidebarCollapsed, handleSetSidebarCollapsed] as const;
};
