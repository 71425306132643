import { useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { getQueryArrayByUrl, getQueryByUrl } from './getQueryByUrl';
import { convertDateToString, convertStringToDate } from '../utilities/dateTime';
import { dateSelectOptions } from '../constants/months';
import { getQuery, InitDateProps } from '../utilities/getQuery';
import { Value } from '../components/Selector';
import { useMe } from '../context/useMe';
import { historyPushState } from '../utilities/helpers';

const LSParamsKey = 'params';

export const LSStorage = {
  getKey() {
    const clinicSlug = localStorage.getItem('hospital-refId') || '';
    return `${LSParamsKey}-${clinicSlug}`;
  },
  get() {
    const key = this.getKey();
    return localStorage.getItem(key);
  },
  getParsed(name: string) {
    return getQueryByUrl(name, this.get() || '');
  },
  set(params: string) {
    const key = this.getKey();
    localStorage.setItem(key, params);
  },
};

type RoomSlugType = string | null | undefined;
type SurgeonNameType = string | null;
type FilterTypes = 'date' | 'room' | 'surgeon' | 'surgeons';

const generateQueryString = (
  currentSearch: string,
  fieldName: FilterTypes,
  fieldValue?: string | null,
) => {
  const params = new URLSearchParams(currentSearch);

  fieldValue ? params.set(fieldName, encodeURIComponent(fieldValue)) : params.delete(fieldName);

  return params.toString();
};

const generateQueryArrayString = (
  currentSearch: string,
  fieldName: FilterTypes,
  fieldValue?: (string | null | undefined)[],
) => {
  const params = new URLSearchParams(currentSearch);
  params.delete(fieldName);
  fieldValue?.forEach((v) => {
    v ? params.append(fieldName, encodeURIComponent(v)) : params.delete(fieldName);
  });

  return params.toString();
};

export const useRoomFilter = () => {
  const location = useLocation();
  const history = useHistory();
  const params = LSStorage.get();
  const roomFilterFromLS = getQueryByUrl('room', params || '');
  const roomFilterFromUrl = getQueryByUrl('room', location.search);
  useEffect(() => {
    if (!location.search && params) {
      history.replace({
        search: params,
      });
    }
  }, [history, params]);

  const [room, setRoom] = useState<RoomSlugType>(roomFilterFromUrl || roomFilterFromLS || null);

  const handleRoomChange = useCallback(
    (newRoom: RoomSlugType) => {
      setRoom(newRoom);
      const search = generateQueryString(location.search, 'room', newRoom);
      LSStorage.set(search);
      history.replace({ search });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search],
  );

  return {
    room,
    handleRoomChange,
  };
};

export const useSurgeonFilter = () => {
  const location = useLocation();
  const history = useHistory();
  const params = LSStorage.get();
  const surgeonFilterFromLS = getQueryByUrl('surgeon', params || '');
  const surgeonFilterFromUrl = getQueryByUrl('surgeon', location.search);
  useEffect(() => {
    if (!location.search && params) {
      history.replace({
        search: params,
      });
    }
  }, [history, params]);

  const [surgeon, setSurgeon] = useState<SurgeonNameType | undefined>(
    surgeonFilterFromUrl || surgeonFilterFromLS || null,
  );

  const handleSurgeonChange = useCallback(
    (newSurgeon: RoomSlugType) => {
      setSurgeon(newSurgeon);
      const search = generateQueryString(location.search, 'surgeon', newSurgeon);
      LSStorage.set(search);

      history.replace({ search });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search],
  );

  return {
    surgeon,
    handleSurgeonChange,
  };
};

export const useSurgeonsFilter = () => {
  const location = useLocation();
  const history = useHistory();
  const params = LSStorage.get();
  const surgeonsFilterFromLS = getQueryArrayByUrl('surgeons', params || '');
  const surgeonsFilterFromUrl = getQueryArrayByUrl('surgeons', location.search);
  useEffect(() => {
    if (!location.search && params) {
      history.replace({
        search: params,
      });
    }
  }, [history, params]);

  const [surgeons, setSurgeons] = useState<string[]>(
    surgeonsFilterFromLS.length > 0 ? surgeonsFilterFromLS : surgeonsFilterFromUrl || [],
  );

  const handleSurgeonsChange = useCallback(
    (newSurgeons: string[]) => {
      setSurgeons(newSurgeons);
      const search = generateQueryArrayString(location.search, 'surgeons', newSurgeons);
      LSStorage.set(search);
      history.replace({ search });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search],
  );

  return {
    surgeons,
    handleSurgeonsChange,
  };
};

const getInitialDate = (params: string | null, search: string) => {
  const dateFilterFromLS = getQueryByUrl('date', params || '');
  const dateFilterFromUrl = getQueryByUrl('date', search);

  if (dateFilterFromUrl || dateFilterFromLS) {
    return convertStringToDate(dateFilterFromUrl || dateFilterFromLS);
  }

  return undefined;
};

export const useDateFilter = () => {
  const location = useLocation();
  const history = useHistory();
  const params = LSStorage.get();

  const initDate = getInitialDate(params, location.search) ?? new Date();

  const [date, setDate] = useState(initDate);
  const dateFilterFromLS = getQueryByUrl('date', params || '');
  const dateFilterFromUrl = getQueryByUrl('date', location.search);

  useEffect(() => {
    if (dateFilterFromUrl || dateFilterFromLS) {
      setDate(convertStringToDate(dateFilterFromUrl || dateFilterFromLS));
    }
  }, [dateFilterFromUrl, dateFilterFromLS]);

  useEffect(() => {
    if (!location.search && params) {
      history.replace({
        search: params,
      });
    }
  }, [history, params]);

  const handleDateChange = useCallback(
    (newDate: Date) => {
      setDate(newDate);
      const search = generateQueryString(location.search, 'date', convertDateToString(newDate));
      LSStorage.set(search);
      history.replace({ search });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search],
  );

  return {
    date,
    handleDateChange,
  };
};

export const useDateRangeFilter = () => {
  const history = useHistory();
  const { data, isInited } = useMe();
  const availableData = data?.me?.current?.organization?.availableData || {};

  const getSelectValue = (): string => {
    const URLParams = new URLSearchParams(history.location.search);
    const LSParams = new URLSearchParams(LSStorage.get() || '');
    const paramsType = URLParams.get('type') || LSParams.get('type') || 'all';
    return paramsType;
  };

  const [isView, setIsView] = useState(false);
  const [variables, setVariables] = useState(getQuery(history));
  const [limitRange, setLimitRange] = useState<{ from: string; to: string } | null>(null);
  const [rangeDate, setRangeDate] = useState({ from: '', to: '' });
  const [selectType, setSelectType] = useState(getSelectValue());
  const [pickedData] = useState<Value>(getSelectValue() ?? dateSelectOptions[0]?.value);

  useEffect(() => {
    const URLParams = new URLSearchParams(history.location.search);
    const LSParams = new URLSearchParams(LSStorage.get() || '');

    Object.entries(variables).forEach((key: string[]) => URLParams.set(key[0], key[1]));

    if (URLParams.toString() === window.location.search.slice(1)) return;

    URLParams.set('type', selectType);

    if (data) {
      historyPushState(window.location.pathname + '?' + URLParams.toString(), history);
    }

    ['type', 'from', 'to'].forEach((k) => {
      const val = URLParams.get(k);

      if (val) {
        LSParams.set(k, val);
      }
    });

    // LSStorage.set(LSParams.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(variables), data]);

  useEffect(() => {
    if (isInited) {
      if (availableData.from && availableData.to) {
        if (selectType === 'all' || !selectType) {
          updateQuery({
            from: availableData.from,
            to: availableData.to,
          });
        }
        setLimitRange({
          from: availableData?.from,
          to: availableData.to,
        });
      } else {
        setLimitRange(getQuery(history));
      }
    }
  }, [isInited, availableData, selectType]);

  // useEffect(() => {
  //   const URLParams = new URLSearchParams(window.location.search);
  //   const from = URLParams.get('from') || '';
  //   const to = URLParams.get('to') || '';
  //   const type = URLParams.get('type') || '';
  //   if (from && to) {
  //     updateQuery({
  //       from,
  //       to,
  //     });

  //     if (type) {
  //       setSelectType(type);
  //     }
  //   }
  // }, [window.location.search]);

  const updateQuery = useCallback((newQuery: InitDateProps) => {
    setVariables(newQuery);
  }, []);

  return {
    isInited,
    pickedData,
    isView,
    rangeDate,
    selectType,
    variables,
    limitRange,
    getSelectValue,
    setIsView,
    setVariables,
    setRangeDate,
    updateQuery,
    setSelectType,
  };
};

export const useCalendarFilter = () => {
  const { room, handleRoomChange } = useRoomFilter();
  const { surgeon, handleSurgeonChange } = useSurgeonFilter();
  const { date, handleDateChange } = useDateFilter();

  return {
    date,
    room,
    surgeon,
    handleDateChange,
    handleRoomChange,
    handleSurgeonChange,
  };
};
