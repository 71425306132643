import { useMemo } from 'react';
import { startOfDay, endOfDay } from 'date-fns';

import { useHistory } from '../../hooks';
import { routesPath } from '../../constants/routes';
import { convertDateToString } from '../../utilities/dateTime';
import { useCalendarFilter } from '../../hooks/useCalendarFilter';
import { convertDataToEvents, convertDataToEventsRooms } from './converter';
import type { CalendarProps } from './types';
import { useQueryCalendarDay } from '../../rest/query/useQueryCalendarDay';

type RoomSlugType = CalendarProps['room'];
type SurgeonNameType = CalendarProps['surgeon'];
type OnSelectEventType = CalendarProps['onSelectEvent'];

const getVariables = (date: Date, room: RoomSlugType, surgeon: SurgeonNameType) => {
  return {
    rooms: room ? [room] : null,
    from: convertDateToString(startOfDay(date)),
    to: convertDateToString(endOfDay(date)),
    surgeon,
  };
};

const createRoute = (caseKey: string) => {
  return `${routesPath.caseCalendar.replace('/:refId', '').replace(':caseId', caseKey)}`;
};

export const useRoomsCalendar = (surgeons: SurgeonType[]) => {
  const { date, room, surgeon, handleDateChange, handleRoomChange, handleSurgeonChange } =
    useCalendarFilter();

  const { data, loading, refetch } = useQueryCalendarDay({
    date: convertDateToString(date),
  });

  const history = useHistory();

  const events = useMemo(() => convertDataToEvents(data), [data]);
  const eventsRooms = useMemo(() => convertDataToEventsRooms(data), [data]);

  const onDateChange = (newDate: Date) => {
    handleDateChange(newDate);
  };

  const onRoomChange = (newRoom: RoomSlugType) => {
    handleRoomChange(newRoom);
  };

  const onSurgeonChange = (newSurgeon: SurgeonNameType) => {
    handleSurgeonChange(newSurgeon);
  };

  const onSelectEvent: OnSelectEventType = (event) => {
    history.push(createRoute(event.caseKey!), {
      name: event.title,
    });
  };

  return {
    events,
    eventsRooms,
    loading,
    date,
    onDateChange,
    room,
    onRoomChange,
    surgeon,
    onSurgeonChange,
    onSelectEvent,
  };
};
