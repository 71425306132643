import { FC, useState } from 'react';
import { format } from 'date-fns';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import Trigger from './Trigger';
import DateHeader from './DateHeader';
import MonthHeader from './MonthHeader';
import { FilterOverlay } from '../FilterOverlay';

import './style.scss';
import 'react-datepicker/dist/react-datepicker.css';

export type CalendarDatePickerProps = Omit<
  ReactDatePickerProps,
  'selected' | 'renderCustomHeader' | 'showMonthYearPicker'
> & {
  date?: ReactDatePickerProps['selected'];
  mode?: 'date' | 'month';
  dayFormat?: string;
};

const getStatus = ({
  date,
  mode,
  dayFormat,
}: Pick<CalendarDatePickerProps, 'date' | 'mode' | 'dayFormat'>) => {
  if (mode === 'month') {
    const isSelected = format(new Date(), 'MMMM yyyy') !== format(date!, 'MMMM yyyy');
    const label = !isSelected ? 'Month' : format(date!, 'MMMM yyyy');

    return { isSelected, label };
  }

  const isSelected = format(new Date(), dayFormat!) !== format(date!, dayFormat!);
  const label = !isSelected ? 'Day' : format(date!, dayFormat!);

  return { isSelected, label };
};

const CalendarDatePicker: FC<CalendarDatePickerProps> = ({
  date: selected,
  onChange,
  mode = 'date',
  customInput,
  dayFormat = 'MMMM dd',
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const isMonthMode = mode === 'month';
  const HeaderComponent = isMonthMode ? MonthHeader : DateHeader;
  const className = isMonthMode ? 'monthpickerContainer' : 'datepickerContainer';
  const { label, isSelected } = getStatus({ date: selected, dayFormat, mode });
  const thisCustomInput = customInput ?? <Trigger label={label} open={open} active={isSelected} />;

  return (
    <div className={className}>
      {open && <FilterOverlay />}
      <DatePicker
        open={open}
        maxDate={new Date()}
        popperPlacement="bottom-start"
        {...rest}
        selected={selected}
        onChange={onChange}
        // https://github.com/Hacker0x01/react-datepicker/issues/730#issuecomment-289418619
        // somehow this is not like that by default
        onSelect={() => setOpen(false)}
        onClickOutside={() => setOpen(false)}
        onInputClick={() => setOpen((o) => !o)}
        customInput={
          <span className="react-datepicker-ignore-onclickoutside">{thisCustomInput}</span>
        }
        showFourColumnMonthYearPicker
        showMonthYearPicker={isMonthMode}
        renderCustomHeader={(customHeaderProps) => <HeaderComponent {...customHeaderProps} />}
      />
    </div>
  );
};

export default CalendarDatePicker;
