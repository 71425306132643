import { BubbleController } from 'chart.js';
export class ScalableBubbleChart extends BubbleController {
  resolveDataElementOptions(index, mode) {
    const parsed = this.getParsed(index);
    const { iScale, vScale } = this._cachedMeta;

    let values = super.resolveDataElementOptions(index, mode);

    const xLen = iScale.max - iScale.min;
    const width = iScale.width;
    const height = vScale.height;
    const aspectRatioX = width / xLen;

    // In case values were cached (and thus frozen), we need to clone the values
    if (values.$shared) {
      values = Object.assign({}, values, { $shared: false });
    }

    // Custom radius resolution
    const radius = parsed ? parsed._custom * aspectRatioX : 0;

    if (mode !== 'active') {
      values.radius = 0;
    }
    values.radius = radius < 1 || isNaN(radius) ? 1 : radius;

    return values;
  }
}
ScalableBubbleChart.id = 'scalableBubble';
ScalableBubbleChart.defaults = BubbleController.defaults;
