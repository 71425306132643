import { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

export const AnalyticsSvg: FC<IconProps> = ({ isActive }) => {
  const lavander = clsx({ 'stroke-persianBlue opacity-50': isActive, 'stroke-white': !isActive });
  const blue = clsx({ 'stroke-persianBlue': isActive, 'stroke-white': !isActive });

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={lavander}
        d="M7.24414 14.781L10.2371 10.891L13.6511 13.573L16.5801 9.79297"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={blue}
        d="M19.9943 6.12232C21.0558 6.12232 21.9163 5.26181 21.9163 4.20032C21.9163 3.13883 21.0558 2.27832 19.9943 2.27832C18.9328 2.27832 18.0723 3.13883 18.0723 4.20032C18.0723 5.26181 18.9328 6.12232 19.9943 6.12232Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={blue}
        d="M14.9238 3.12012H7.65606C4.64462 3.12012 2.77734 5.25284 2.77734 8.26428V16.3467C2.77734 19.3581 4.60801 21.4817 7.65606 21.4817H16.2602C19.2716 21.4817 21.1389 19.3581 21.1389 16.3467V9.30776"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
