import clsx from 'clsx';
import { Tooltip } from 'antd';

type ChartViewButtonsProps<V extends string> = {
  displayOptions: {
    disabled?: boolean;
    label: string;
    icon: (isActive?: boolean) => JSX.Element;
    value: V;
  }[];
  displayOptionList: V[];
  onSwitchChartViews?: (btn: {
    label: string;
    icon: (i?: boolean) => JSX.Element;
    value: V;
  }) => () => void;
};

export function ChartViewButtons<V extends string = string>({
  displayOptionList,
  onSwitchChartViews,
  displayOptions,
}: ChartViewButtonsProps<V>) {
  return (
    <div className="flex rounded-[0.8rem] border h-[3.2rem] mr-4 my-1 content-center">
      {displayOptions.map((btn, i) => (
        <Tooltip title={btn.label} key={btn.label}>
          <div
            key={btn.label}
            onClick={() => !btn.disabled && onSwitchChartViews?.(btn)()}
            className={clsx(
              'w-[3.2rem]  flex font-bold font-lato text-1214 justify-center content-center',
              {
                'bg-black text-white': displayOptionList.includes(btn.value),
                'rounded-l-[0.8rem]': i === 0,
                'opacity-40': btn.disabled,
                'cursor-pointer': !btn.disabled,
                'rounded-r-[0.8rem]': i === displayOptions.length - 1,
              },
            )}
          >
            <div
              className={clsx('w-full flex justify-center py-1 h-fit self-center', {
                'border-l':
                  (i > 0 && !displayOptionList.includes(btn.value)) ||
                  (i > 0 &&
                    displayOptionList.includes(btn.value) &&
                    displayOptionList.includes(displayOptions[i - 1]?.value)),
              })}
            >
              {btn.icon(displayOptionList.includes(btn.value))}
            </div>
          </div>
        </Tooltip>
      ))}
    </div>
  );
}
