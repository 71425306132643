import { Button as ButtonAnt } from 'antd';
import clsx from 'clsx';

import type { ButtonProps, ButtonType } from './types';

const typeClassNames: { [x in ButtonType]: string } = {
  light:
    'bg-white border-ghostBorder hover:border-ghostBorder active:border-ghostBorder focus:border-ghostBorder text-text-black hover:bg-ghost hover:bg-opacity-30 focus:bg-ghost active:bg-ghost hover:text-text-black focus:text-text-black active:text-text-black',
  primary:
    'bg-persianBlue text-white hover:bg-persianBlue focus:bg-persianBlue active:bg-persianBlue hover:text-white focus:text-white active:text-white',
  dark: 'bg-text-black text-white hover:bg-persianBlue focus:bg-text-black active:bg-text-black hover:text-white focus:text-white active:text-white',
  disabled: 'bg-[#7E7E80] text-white cursor-default',
};

export const Button: React.FC<ButtonProps> = ({
  type = 'primary',
  children,
  onClick,
  icon,
  className,
  ...rest
}) => {
  return (
    <ButtonAnt
      {...rest}
      className={clsx(
        `flex
        p-0
        position-unset
        items-center
        justify-center
        h-[3.2rem]
        rounded-[0.8rem]
        text-1214
        font-bold`,
        className,
        icon ? 'w-[3.2rem]' : 'w-80',
        typeClassNames[type],
      )}
      icon={icon}
      disabled={type === 'disabled'}
      onClick={onClick}
    >
      {children}
    </ButtonAnt>
  );
};
