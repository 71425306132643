/* eslint-disable react/no-array-index-key */

import { Skeleton } from 'antd';
import clsx from 'clsx';

import './style.scss';

type ColumnType<T> = {
  header: (columnIndex: number) => React.ReactNode;
  headerStyle?: React.CSSProperties;
  headerClassName?: string;
  getValue: (row: T) => React.ReactNode;
  cellStyle?: React.CSSProperties;
  cellClassName?: string;
};

export type ConfigType<T> = {
  columns: ColumnType<T>[];
};

type TableProps<T = any> = {
  id?: string;
  data: T[];
  loading?: boolean;
  className?: string;
  withBorder?: boolean;
  withRounded?: boolean;
  loadingCellContent?: React.ReactNode;
  config: ConfigType<T>;
  alignToRight?: boolean;
};

const defaultSkeleton = (
  <Skeleton
    active
    className="m-0"
    title={{
      style: { margin: 0 },
    }}
    paragraph={{
      rows: 0,
      style: { margin: 0 },
    }}
  />
);

const TableHeader = <T,>({
  config,
  alignToRight,
}: Pick<TableProps<T>, 'config' | 'alignToRight'>) => {
  return (
    <thead className="rounded-main text-headerTextGrey">
      <tr>
        {config.columns.map((column, index) => {
          const isFirstColumn = index === 0;
          const isLastColumn = index === config.columns.length - 1;

          return (
            <th
              key={index}
              style={column.headerStyle}
              className={clsx(
                column.headerClassName,
                'bg-headerGrey',
                isFirstColumn ? 'px-8' : 'px-12',
                isLastColumn || alignToRight ? 'pr-6 text-right' : 'text-left',
                'text-left text-1214 font-bold uppercase py-6',
              )}
            >
              {column.header(index)}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

const TableRows = <T,>({
  data,
  config,
  loading,
  alignToRight,
  loadingCellContent,
}: Pick<TableProps<T>, 'data' | 'config' | 'loading' | 'loadingCellContent' | 'alignToRight'>) => {
  return (
    <>
      {data?.map((row, rowIndex) => {
        const className = rowIndex % 2 !== 0 ? 'bg-tableEvenRow' : 'bg-white';

        return (
          <tr key={rowIndex} className={clsx('hover:bg-transparent')}>
            {config.columns.map((column, index) => {
              const isFirstColumn = index === 0;
              const isLastColumn = index === config.columns.length - 1;

              return (
                <td
                  key={index}
                  style={loading ? {} : column.cellStyle}
                  className={clsx(className, loading ? '' : column.cellClassName)}
                >
                  {/* same height for every table for now, so we support skeletons */}
                  <div
                    className={clsx(
                      isFirstColumn ? 'px-8' : 'px-12',
                      isLastColumn || alignToRight ? 'text-right pr-6' : 'text-left',
                      'ora-table-td h-[7.4rem] text-1420',
                    )}
                  >
                    <div className="inline-flex items-center h-full">
                      {loading ? loadingCellContent : column.getValue(row)}
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

export const Table = <T,>({
  data = [],
  id,
  config,
  loading,
  className,
  withBorder = true,
  withRounded = true,
  alignToRight = false,
  loadingCellContent = defaultSkeleton,
}: TableProps<T>) => {
  return (
    <table
      id={id}
      className={clsx(
        className,
        'ora-table w-full',
        withRounded ? 'rounded-main overflow-hidden' : '',
        withBorder ? 'border border-solid border-galery' : '',
      )}
    >
      <TableHeader config={config} alignToRight={alignToRight} />
      <tbody className="bg-white">
        <TableRows
          data={data}
          config={config}
          loading={loading}
          alignToRight={alignToRight}
          loadingCellContent={loadingCellContent}
        />
      </tbody>
    </table>
  );
};
