import { useMemo } from 'react';
import { orderBy } from 'lodash';

import { useQuery } from '../../hooks/useQuery';

import { useMutationResync } from '../mutation/resync';
import { useMutationUpdateOrganization } from '../mutation/updateOrganization';
import { GET_ADMINISTRATION } from './constants';

const convertDataToHospitals = (adminStat: any[]) => {
  return orderBy(
    adminStat.map((hospital) => hospital) ?? [],
    (hospital) => hospital.casesAmount,
    'desc',
  );
};

export const useQueryAdministration = () => {
  const { mutation: performResync } = useMutationResync({
    // onCompleted: () => refetch(),
  });

  const { mutation: performUpdate } = useMutationUpdateOrganization({
    // onCompleted: () => refetch(),
  });

  const hospitals = useMemo(() => convertDataToHospitals([]), []);

  const resync = (organizationSlug?: string) => {
    performResync({ variables: { organizationSlug } });
  };

  const updateOrganization = (slug: string, active: boolean) => {
    performUpdate({ variables: { organizationSlug: slug, organizationActive: active } });
  };

  return { hospitals, loading: false, resync, updateOrganization };
};
