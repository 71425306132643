import { FC, useCallback, useState } from 'react';
import checkSvg from '../../assets/Check-dropdown.svg';
import classNames from 'clsx';
import { Option } from '../Selector/types';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { FilterContainer } from '../FilterContainer';
import { FilterOverlay } from '../FilterOverlay';
import { Menu } from 'antd';
import './MultiLevelSelectbox.scss';

interface MenuInfo {
  key: string;
  keyPath: string[];
  selectedKeys: string[];
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

export interface MenuOption<C extends string = string> extends Option {
  nested?: MenuOption<C>[];
  disabled?: boolean;
}
export interface FullMenuOption<C extends string = string> extends MenuOption {
  category?: C;
  disabled?: boolean;
  subLabel?: string;
  id?: string;
  nested?: FullMenuOption<C>[];
}

export function makeMenu(m: MenuOption[], maybeLabel?: string): FullMenuOption[] {
  return m.map((el) => ({
    ...el,
    category: maybeLabel,
    subLabel: el.label,
    id: maybeLabel ? btoa(maybeLabel + '_' + el.label) : btoa(el.label),
    nested: el.nested ? makeMenu(el.nested, el.label) : undefined,
  }));
}

type MultiLevelSelectboxProps<C extends string = string> = {
  options: FullMenuOption<C>[];
  selectedItem?: FullMenuOption<C>;
  shouldHaveNotNestedItems?: boolean;
  shouldHaveNone?: boolean;
  disabled?: boolean;
  loading: boolean;
  header: string;
  convertSelectedLabel?: (i: FullMenuOption<C>) => string;
  makeTopLabel?: (val: string) => string;
  onSelectOption: (o?: FullMenuOption<C>, isRemove?: boolean) => void;
};

export const Arrow: React.FC<{ open?: boolean; topClass?: string }> = ({
  open,
  topClass = 'top-[1rem] md:top-[1.4rem]',
}) => {
  return (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        topClass,
        open ? 'rotate-270' : '-rotate-90',
        'arrow absolute right-[0.8rem] md:right-[1rem] lg:right-[0.6rem] cursor-pointer',
      )}
    >
      <path
        d="M1.04927 0.5L4.04927 3.5L6.95117 0.598365"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export function MultiLevelSelectbox<C extends string = string>({
  options,
  selectedItem,
  shouldHaveNotNestedItems,
  disabled,
  shouldHaveNone,
  loading,
  header,
  makeTopLabel = (val: string) => val,
  convertSelectedLabel,
  onSelectOption,
}: MultiLevelSelectboxProps<C>) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [open, setOpen] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 7],
        },
      },
    ],
  });

  const handleSelect = useCallback(
    (el: MenuInfo) => {
      if (el.key === 'none') {
        onSelectOption(undefined, true);
        setOpen(false);
        return;
      }
      let item: FullMenuOption<C> | undefined = undefined;

      options.forEach((o) => {
        o.nested?.forEach((n) => {
          if (n.id === el.key) {
            item = n;
          }
        });
        if (!item && o.id === el.key) {
          item = o;
        }
      });

      onSelectOption(item);

      setOpen(false);
    },
    [options],
  );

  if (!options || options.length === 0) return null;

  return (
    <div className="mr-4">
      <div
        className={classNames('font-bold font-lato text-1214 ml-2 mb-4', {
          'opacity-50': disabled,
        })}
      >
        {header}
      </div>
      <FilterContainer
        disabled={disabled}
        ref={setReferenceElement as any}
        label={
          loading
            ? 'Loading...'
            : selectedItem
            ? convertSelectedLabel?.(selectedItem) || selectedItem.label
            : disabled
            ? 'None'
            : 'Select View'
        }
        open={open}
        active={!!selectedItem}
        onClick={() => !disabled && setOpen(!open)}
      >
        {open && (
          <>
            <FilterOverlay onClick={() => setOpen(false)} />
            {ReactDOM.createPortal(
              <div
                ref={setPopperElement as any}
                className={classNames('MultiLevelSelectbox z-10 text-black text-1220 font-bold')}
                style={styles.popper}
                {...attributes.popper}
              >
                <Menu onSelect={handleSelect} style={{ width: 256 }} mode="vertical">
                  {!!selectedItem && shouldHaveNone && <Menu.Item key="none">None</Menu.Item>}

                  {options
                    .filter(
                      (o) =>
                        (o?.nested && o?.nested?.length > 0) ||
                        ((o?.nested?.length === 0 || !o?.nested) && shouldHaveNotNestedItems),
                    )
                    .map((o) => {
                      if (o?.nested?.length === 0 || !o?.nested)
                        return (
                          <Menu.Item
                            disabled={o.disabled}
                            className={classNames({
                              selected: selectedItem?.id === o.id,
                            })}
                            key={btoa(o.label)}
                          >
                            <div className="flex justify-between w-full">
                              <div className=""> {o.subLabel || o.label}</div>
                              {selectedItem?.id === o.id && (
                                <img
                                  className="inline absolute right-2 top-5"
                                  src={checkSvg}
                                  alt="logo"
                                />
                              )}
                            </div>
                          </Menu.Item>
                        );
                      return (
                        <Menu.SubMenu
                          disabled={o.disabled}
                          className={classNames({
                            selected: selectedItem?.category === o.label,
                          })}
                          popupClassName="MultiLevelSelectbox"
                          key={o.label}
                          title={
                            <div className="flex justify-between w-full">
                              <div className="">{makeTopLabel(o.label)}</div>
                              <div className="">
                                <Arrow />
                              </div>
                            </div>
                          }
                        >
                          {o?.nested?.map((n) => (
                            <Menu.Item
                              className={classNames({
                                selected: selectedItem?.id === n.id,
                              })}
                              key={btoa(o.label + '_' + n.label)}
                            >
                              <div className="flex justify-between w-full">
                                <div className=""> {n.subLabel || n.label}</div>
                                {selectedItem?.id === n.id && (
                                  <img
                                    className="inline absolute right-2 top-5"
                                    src={checkSvg}
                                    alt="logo"
                                  />
                                )}
                              </div>
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      );
                    })}
                </Menu>
              </div>,
              document.body,
            )}
          </>
        )}
      </FilterContainer>
    </div>
  );
}
