import { min, max, startOfDay, endOfDay, set, isValid } from 'date-fns';
import type { IRoomEvent } from './types';

export const getLowerBound = (events: IRoomEvent[], currentDate?: Date | string) => {
  const thisDate = currentDate ? new Date(currentDate) : new Date();

  const todayEventsTimes = events.map((e) => e.start || 0).filter((d) => isValid(d));
  const minEventTime = todayEventsTimes.length
    ? min(todayEventsTimes)
    : set(thisDate, {
        hours: 7,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

  // in case operation was done between days
  const startOfThisDay = startOfDay(thisDate);

  return max([minEventTime, startOfThisDay]);
};

export const getUpperBound = (roomsEvents: IRoomEvent[], currentDate?: Date | string) => {
  const thisDate = currentDate ? new Date(currentDate) : new Date();
  const defaultLastWorkingHour = set(thisDate, {
    hours: 17,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const todayEventsTimes = roomsEvents
    .map((e) => e.end || 0)
    .filter((d) => !!d)
    // so we don't get upperBound less than 17:00
    .concat([defaultLastWorkingHour]);

  const maxEventTime = max(todayEventsTimes);
  // in case operation was done between days
  const endOfThisDay = endOfDay(thisDate);

  return min([maxEventTime, endOfThisDay]);
};
