import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { API_BASE_URL } from '../env';

import { pagination } from './cache';

const httpLink = createHttpLink({
  uri: API_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  const returnHeaders = {
    ...headers,
  };

  if (token && token !== 'null') {
    returnHeaders.authorization = token ? `Bearer ${token}` : '';
    returnHeaders['x-current-company-id'] = localStorage.getItem('hospital-refId') || '';
  }

  return {
    headers: {
      ...returnHeaders,
    },
  };
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const token = context.response.headers.get('x-next-authorization-bearer');

    if (token) {
      localStorage.setItem('token', token);
    }

    return response;
  });
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          organizations: pagination,
          users: pagination,
        },
      },
    },
  }),
  name: 'deeep-web-client',
  link: from([afterwareLink, authLink, httpLink]),
  version: '1.3',
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export const cacheExpiration = (minutes: number) => {
  const maybeExpiration = localStorage.getItem('expiration');
  const saveExp = () => {
    const expiration = new Date();
    expiration.setMinutes(expiration.getMinutes() + minutes);
    localStorage.setItem('expiration', expiration.toISOString());
  };
  if (!maybeExpiration) {
    saveExp();
    return;
  }

  const now = new Date();

  if (maybeExpiration <= now.toISOString()) {
    client.cache.gc();
    saveExp();
  }
};
