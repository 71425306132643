import { compareOptions } from '../MultiFilter/mocks';
import { CompareValues } from '../MultiFilter/MultiFilters.types';

import { ConfigType, Table } from '../Table';
import { getHumanTimeFromSeconds } from '../../utilities/dateTime';
import { generateArray, getEntityName } from '../../utilities/helpers';

type CasesTableProps = {
  tableId: string;
  data: number[];
  labels: string[];
  allFilters: AllFilters;
  loading: boolean;
  compareBy: CompareValues;
};

type Opt = { label: string; value: number };

const LoadingPlaceholder: Opt[] = generateArray(3).map((_, i) => ({
  value: i,
  label: String(i),
}));

const showSeconds = (seconds?: number) => {
  if (!seconds) return 'N/A';

  return getHumanTimeFromSeconds(Number(seconds) * 60, true);
};

const showLabel = (seconds?: number, sds?: number, size?: number) => {
  if (!seconds) return 'N/A';
  return `${showSeconds(seconds)} ${
    Number(sds) ? '± ' + showSeconds(Number(seconds) - Number(sds)) : ''
  }  (${size})`;
};

export function CasesTable({
  loading,
  allFilters,
  tableId,
  data: initialData,
  labels,
  compareBy,
}: CasesTableProps) {
  const hasData = !!initialData?.length;

  const data = labels.map((label, index) => ({
    label,
    value: initialData[index],
  }));

  const total = data.reduce((acc, { value }) => acc + value, 0);

  const groupByHeader =
    compareOptions.find((option) => option.value === compareBy)?.label || 'Compare';

  if (!hasData && !loading) {
    return (
      <div className="p-10 flex justify-start uppercase text-silver text-[1.5rem] md:text-[2rem] self-center md:self-start">
        NO DATA
      </div>
    );
  }

  const columns: ConfigType<Opt>['columns'] = [
    {
      header: () => (groupByHeader !== 'None' ? groupByHeader : 'Compare'),
      headerClassName: 'sticky left-0',
      cellClassName: 'sticky left-0 font-bold',
      getValue: (el: Opt) => {
        return getEntityName({ value: el.label, allFilters, compareBy });
      },
    },
    {
      header: () => 'Count',
      getValue: (el: Opt) => {
        return el.value;
      },
    },
    {
      header: () => 'Percentage',
      getValue: (el: Opt) => {
        return ((el.value / total) * 100).toFixed(0) + '%';
      },
    },
  ];

  return (
    <Table<Opt>
      id={tableId}
      withBorder={false}
      withRounded={false}
      config={{ columns }}
      loading={loading}
      data={loading ? LoadingPlaceholder : data || []}
    />
  );
}
