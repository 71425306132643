import { useState } from 'react';

import { Table } from '../../components/Table';
import { Button } from '../../components/Button';
import { routesPath } from '../../constants/routes';
import { SearchBar } from '../../components/SearchBar';
import { RefreshIcon } from '../../components/RefreshIcon';
import { ColoredSlug } from '../../components/ColoredSlug';
import { useHistory, useDebouncedValue } from '../../hooks';
import { PermissionCheck } from '../../components/PermissionCheck';
import { useQueryAdministration } from '../../graphql/query/getAdministration';
import { formatToUserTimeZone, convertStringToDateTZ } from '../../utilities/dateTime';
import type { GraphQLTypes } from '../../zeus';
import clsx from 'clsx';

const filterHospitals = (hospitals?: any[], search?: string) => {
  if (!hospitals) return hospitals;
  if (!search) return hospitals;

  const processedSearch = search.toLocaleLowerCase().trim();
  return hospitals?.filter((h) => h.orgName.toLocaleLowerCase().trim().includes(processedSearch));
};

export const Administration = () => {
  const history = useHistory();

  const handleUpdateOrganization = (orgSlug: string, checked: boolean) => {
    updateOrganization(orgSlug, checked);
  };

  const handleStartAddNewHospital = () => {
    history.push(routesPath.newHospital.replace('/:refId', ''));
  };
  const { hospitals, loading, resync, updateOrganization } = useQueryAdministration();
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebouncedValue(search);

  const filteredHospitals = filterHospitals(hospitals, debouncedSearch);

  return (
    <div className="pt-12">
      <div className="flex flex-row items-center justify-between mb-12">
        <div className="flex gap-x-8 w-1/2">
          <SearchBar placeholder="Search Hospitals..." value={search} onChange={setSearch} />
        </div>
        <div className="ml-4 flex gap-x-4">
          <PermissionCheck check="updateIndex">
            <Button type="light" onClick={() => resync(undefined)} icon={<RefreshIcon />} />
          </PermissionCheck>
          <PermissionCheck check="createOrganizations">
            <Button onClick={handleStartAddNewHospital} type="dark">
              Add New Hospital
            </Button>
          </PermissionCheck>
        </div>
      </div>
      <Table
        loading={loading}
        className="text-text-black"
        data={filteredHospitals ?? []}
        config={{
          columns: [
            {
              header: () => 'Sync',
              getValue: ({ orgSlug, orgActive }) => (
                <input
                  onChange={(e) => handleUpdateOrganization(orgSlug, e.target.checked)}
                  checked={orgActive}
                  className={clsx(
                    'checked:bg-persianBlue mb-1 cursor-pointer checked:border-persianBlue  w-[1.2rem] h-[1.2rem] ring-0 border-black bg-transparent',
                    { 'rounded-[.2rem]': true },
                  )}
                  type={'checkbox'}
                />
              ),
            },
            {
              header: () => 'Hospitals',
              getValue: ({ orgName }) => (
                <ColoredSlug name={orgName} size="medium" className="font-bold" />
              ),
            },
            { header: () => 'Users', getValue: ({ usersAmount }) => usersAmount },
            { header: () => 'Rooms', getValue: ({ roomsAmount }) => roomsAmount },
            { header: () => 'Cases', getValue: ({ casesAmount }) => casesAmount },
            {
              header: () => 'Resync',
              getValue: ({ orgSlug, currentSync }) =>
                currentSync === false ? (
                  <PermissionCheck check="updateIndex">
                    <Button type="light" onClick={() => resync(orgSlug)} icon={<RefreshIcon />} />
                  </PermissionCheck>
                ) : null,
            },
            {
              header: () => 'Last Sync',
              getValue: ({ lastSyncAt }) =>
                lastSyncAt ? formatToUserTimeZone(convertStringToDateTZ(lastSyncAt)) : '',
            },
          ],
        }}
      />
    </div>
  );
};
