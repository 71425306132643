import clsx from 'clsx';
import arrow from '../../assets/Check.svg';

type ArrowProps = {
  disabled?: boolean;
  onClick: () => void;
  className?: string;
  direction: 'left' | 'right';
};

export const Arrow: React.FC<ArrowProps> = ({ className, disabled, direction, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        'hover:bg-perano hover:bg-opacity-20 h-10 w-10 inline-flex items-center justify-center rounded-xl',
        direction === 'left' ? '-ml-4' : '-mr-4',
        className,
        disabled ? 'text-galery pointer-events-none' : 'cursor-pointer',
      )}
    >
      <img
        alt="img"
        src={arrow}
        className={clsx(direction === 'left' ? 'rotate-90' : '-rotate-90')}
      />
    </div>
  );
};
