import { MultiFilterStateContextType } from './MultiFilters.types';

export const initState: MultiFilterStateContextType = {
  dateRange: '',
  activities: '',
  rooms: [],
  surgeons: [],
  suggestedView: null,
  vrest: [],
  weekDay: [],
  compare: null,
  modalities: [],
  cases: [],
  specialties: [],
  procedures: [],
  caseViewMode: null,
  allRoboticDay: [],
  casesPerDay: [],
  sameSurgeon: [],
  multiCases: false,
  excludeOutliers: false,
};

export function extractProperFilters(filters: Record<string, any>) {
  return Object.keys(initState).reduce((acc, propName) => {
    const propTypeValue = initState[propName as keyof MultiFilterStateContextType];
    if (filters[propName]) {
      acc[propName] =
        Array.isArray(propTypeValue) && !Array.isArray(filters[propName])
          ? [filters[propName]]
          : filters[propName];
    }
    return acc;
  }, {} as any) as Partial<MultiFilterStateContextType>;
}
