// eslint-disable-next-line no-shadow
export enum AuthType {
  LOGIN = 'LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  ONLY_EMAIL = 'ONLY_EMAIL',
}

export interface AuthProps {
  type: AuthType;
}
