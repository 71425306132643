// eslint-disable-next-line operator-linebreak
export const {
  REACT_APP_ENV,
  REACT_APP_API_BASE_URL,
  REACT_APP_LOGIN_TYPE,
  REACT_APP_ANALYTICS_API_BASE_URL,
  REACT_APP_PROXY_ANALYTICS_API_BASE_URL,
  NODE_ENV,
  PUBLIC_URL,
} = process.env;
export const API_BASE_URL = REACT_APP_API_BASE_URL || 'http://localhost:3001';
export const LOGIN_TYPE = REACT_APP_LOGIN_TYPE || 'SSO';
export const ANALYTICS_API_BASE_URL = REACT_APP_ANALYTICS_API_BASE_URL;
export const PROXY_ANALYTICS_API_BASE_URL = REACT_APP_PROXY_ANALYTICS_API_BASE_URL;
