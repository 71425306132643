import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { GET_MONTH_CASES } from '../../graphql/query/constants';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';
import { makeLogData } from '../../utilities/helpers';
import { transformAllFilters } from '../../utilities/transformAllFilters';

const logData = makeLogData();
export type useQueryCalendarMonthResponse = {
  data: Record<
    string,
    {
      count: number;
      modalities: ('Lap' | 'Open' | 'Robotic')[];
    }
  >;
  all_filters: AnalyticsGenericResponse<string>['all_filters'];
};

export type CalendarMonthArgs = {
  from: string;
  to: string;
  rooms?: string[] | null;
};

const makeQueryCalendarMonth = async (params: Record<string, any>) => {
  const { data } = await RESTClient.get<useQueryCalendarMonthResponse>('/calendar/monthly', {
    params,
  });

  return data;
};

export const useQueryCalendarMonth = (variables: CalendarMonthArgs, isActive?: boolean) => {
  const organization = useSLUGName();
  const params = useMemo(
    () => ({
      from: variables.from,
      to: variables.to,
      OR: variables.rooms || [],
      ...(organization ? { organization } : {}),
    }),
    [variables],
  );

  const processor = useCallback(() => makeQueryCalendarMonth(params), [params]);
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery([GET_MONTH_CASES, params], processor, {
    enabled: isActive || isActive === undefined,
  });

  logData('/calendar/monthly(' + JSON.stringify(params) + ')', data);

  const result = data?.data || ({} as useQueryCalendarMonthResponse['data']);
  const allFilters = useMemo(() => transformAllFilters(data?.all_filters), [data?.all_filters]);

  return { data: result, allFilters, rawData: data, loading, refetch };
};
