import { useHistory as useRouterHistory, useRouteMatch } from 'react-router-dom';

import { slugRoutesPaths } from '../constants/routes';
import { Slug } from '../types';

export const useSLUGName = () => {
  const match = useRouteMatch<Slug>(slugRoutesPaths);
  const slug = match?.params.refId || localStorage.getItem('hospital-refId');
  return slug;
};

export const useHistory = () => {
  const history = useRouterHistory();
  const slug = useSLUGName();

  if (!slug) return history;

  return {
    ...history,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    push: (location: string | Record<string, string>, ...rest: any) => {
      if (typeof location === 'string' && location.startsWith('/')) {
        return history.push(`/${slug}${location}`, ...rest);
      }

      return history.push(location);
    },
  };
};
