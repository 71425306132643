import { FC, useCallback, useEffect, useMemo } from 'react';
import flatten from 'lodash/flatten';
import { FiltersOptions } from '../FiltersPanel/types';
import { Option } from '../Selector';
import { MultiFilterStateContextType, SelectedFilterKeys } from '../MultiFilter/MultiFilters.types';
import { SelectedFilterItem } from './SelectedFilterItem';
import { humanDate } from '../../utilities/dateTime';

export type DeleteSelectedFilter = (name: SelectedFilterKeys, val: string) => void;

type SelectedFiltersProps = {
  filtersState: MultiFilterStateContextType;
  limit?: number;
  hiddenOptions?: SelectedFilterKeys[];
  notdeleteableOptions?: SelectedFilterKeys[];
  options: FiltersOptions;
  onDeleteSelectedFilter: DeleteSelectedFilter;
  onChangeFiltersQty?: (qty: number) => void;
};

const makeOptionExtractor = (selected: string | string[] | boolean) => (options: Option[]) =>
  options.find((option) => option.value === selected);

const getSelectedOptions =
  (name: SelectedFilterKeys, selected: string | string[] | boolean) =>
  ({
    roomsOptions,
    surgeonsOptions,
    modalitiesOptions,
    proceduresOptions,
    specialtiesOptions,
    casesPerDayOptions,
    casesOptions,
    weekOptions,
    compareOptions,
    sameSurgeonOptions,
    allRoboticDayOptions,
  }: FiltersOptions): Option | undefined => {
    const extractOption = makeOptionExtractor(selected);
    const options: Record<SelectedFilterKeys, Option | undefined> = {
      dateRange: {
        label: (typeof selected === 'string' ? selected : '').split(';').map(humanDate).join(' - '),
        value: typeof selected === 'string' ? selected : '',
      },

      rooms: extractOption(roomsOptions || []),
      surgeons: extractOption(surgeonsOptions || []),
      modalities: extractOption(modalitiesOptions || []),
      procedures: extractOption(proceduresOptions || []),
      specialties: extractOption(specialtiesOptions || []),
      casesPerDay: extractOption(casesPerDayOptions || []),
      cases: extractOption(casesOptions || []),
      weekDay: extractOption(weekOptions || []),
      compare: extractOption(compareOptions || []),
      sameSurgeon: extractOption(sameSurgeonOptions || []),
      allRoboticDay: extractOption(allRoboticDayOptions || []),
      multiCases: {
        label: 'Exclude Multi-Procedure/Surgeon Cases',
        value: 'multiCases',
      },
      excludeOutliers: {
        label: 'Exclude Outliers',
        value: 'excludeOutliers',
      },
    };
    return options[name];
  };

export const SelectedFilters: FC<SelectedFiltersProps> = ({
  filtersState,
  limit,
  options,
  hiddenOptions = [],
  notdeleteableOptions = [],
  onDeleteSelectedFilter,
  onChangeFiltersQty,
}) => {
  const filterOptions = useCallback(
    (name: SelectedFilterKeys) => {
      if (hiddenOptions.includes(name)) {
        return false;
      }
      return Boolean(filtersState[name]);
    },
    [filtersState],
  );

  const filters = useMemo(
    () =>
      (Object.keys(filtersState) as SelectedFilterKeys[])
        .filter(filterOptions)
        .map((name: SelectedFilterKeys) => {
          const filterValue = filtersState[name];

          if (Array.isArray(filterValue)) {
            return filterValue.map((val) => {
              const opt = getSelectedOptions(name, val)(options);

              if (!opt) {
                return null;
              }
              return (
                <SelectedFilterItem
                  key={opt.label + opt.value}
                  option={opt}
                  onDelete={
                    notdeleteableOptions.includes(name)
                      ? undefined
                      : () => onDeleteSelectedFilter(name, val)
                  }
                />
              );
            });
          }
          if (!filterValue) {
            return null;
          }
          const opt = getSelectedOptions(name, filterValue)(options);
          if (!opt) {
            return null;
          }
          return (
            <SelectedFilterItem
              key={opt.label + opt.value}
              option={opt}
              onDelete={
                notdeleteableOptions.includes(name)
                  ? undefined
                  : () => onDeleteSelectedFilter(name, filterValue as string)
              }
            />
          );
        }),
    [filterOptions, filtersState, onDeleteSelectedFilter, options, notdeleteableOptions],
  );

  const filtersDom = useMemo(() => flatten(filters), [filters]);

  useEffect(() => {
    onChangeFiltersQty?.(filtersDom.filter(Boolean).length);
  }, [filtersDom]);

  return (
    <div className="selected-filter flex flex-wrap">
      {limit && filtersDom.length - limit > 0
        ? [
            ...filtersDom.slice(0, limit),
            <SelectedFilterItem
              key={'more'}
              option={{ label: `Other ${filtersDom.length - limit} ...`, value: 'other' }}
            />,
          ]
        : filtersDom}
    </div>
  );
};
