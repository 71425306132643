import { FC } from 'react';

export const Wrapper: FC = ({ children }) => <div className="p-10 box-border">{children}</div>;

export const Container: FC = ({ children }) => (
  <div className=" w-full flex flex-col justify-center items-center lg:flex-row lg:items-stretch lg:justify-start mt-5 min-h-[20rem] xl:min-h-[40rem]">
    {children}
  </div>
);

export const FilterSlot: FC = ({ children }) => <div className="">{children}</div>;

export const Title: FC = ({ children }) => (
  <div className="lato font-bold text-[2rem] leading-10 text-black lg:mb-14 mb-14  text-center md:text-left non-italic md:mb-8">
    {children}
  </div>
);

export const Labels: FC = ({ children }) => (
  <div className="grid grid-cols-2 md:grid-cols-3">{children}</div>
);

export const NoData: FC = ({ children }) => (
  <div className="flex justify-center uppercase text-silver text-[1.5rem] md:text-[2rem] self-center md:self-start">
    {children}
  </div>
);

export const Label: FC<{ $color: string }> = ({ children, $color }) => (
  <div
    className="flex mb-4 lato non-italic font-bold text-[1.2rem] leading-[1.6rem] items-center mr-[4.7rem] last:mr-0 capitalize"
    style={{ color: $color }}
  >
    <div
      style={$color ? { backgroundColor: $color } : {}}
      className="flex w-[8px] md:w-[20px] h-[8px] rounded-md mr-[8px] transition-all"
    ></div>
    {children}
  </div>
);
