import { routesPath } from '../constants/routes';
import { Dashboard } from '../page/dashboard/Dashboard';
import { NewHospital } from '../page/newHospital';
import { MonthCalendar } from '../page/monthCalendar';
import { DailyCalendar } from '../page/dailyCalendar';
import { CaseCalendar } from '../page/caseCalendar';
import { Users } from '../page/users';
import { EditUser } from '../page/editUser';
import { Settings } from '../page/settings';
import { Administration } from '../page/administration';
import { IRoutes } from '../types';
import { CasesAnalytics } from '../page/cases-analytics/CasesAnalytics';
import { CaseView } from '../page/case-view/CaseView';
import { GenesisAnalytics } from '../page/genesis-analytics/GenesisAnalytics';
import { TemporalActivities } from '../page/temporal-activities/TemporalActivities';
import { Analytics } from '../page/analytics/Analytics';
import { PeopleCount } from '../page/peopleCount/PeopleCount';

export const ROUTES: IRoutes[] = [
  {
    path: routesPath.clinic,
    exact: true,
    component: Dashboard,
    private: true,
  },
  {
    path: routesPath.newHospital,
    exact: true,
    component: NewHospital,
    private: true,
    permission: 'createOrganizations',
  },
  {
    path: routesPath.nonOperativeMetrics,
    exact: true,
    component: GenesisAnalytics,
    private: true,
    permission: 'readAnalytics',
  },
  {
    path: routesPath.temporalActivities,
    exact: true,
    component: TemporalActivities,
    private: true,
    permission: 'readAnalytics',
  },
  {
    path: routesPath.casesAnalytics,
    exact: true,
    component: CasesAnalytics,
    private: true,
    permission: 'readAnalytics',
  },
  {
    path: routesPath.caseView,
    exact: true,
    component: CaseView,
    private: true,
    permission: 'readAnalytics',
  },
  {
    path: routesPath.peopleCount,
    exact: true,
    component: PeopleCount,
    private: true,
    permission: 'readAnalytics',
  },
  {
    pageTitle: 'Analytics',
    path: routesPath.analytics,
    exact: true,
    component: Analytics,
    private: true,
    permission: 'readAnalytics',
  },
  {
    path: routesPath.calendar,
    exact: true,
    component: MonthCalendar,
    private: true,
    permission: 'readAnalytics',
  },
  {
    path: routesPath.dataCalendar,
    exact: true,
    component: DailyCalendar,
    private: true,
    permission: 'readAnalytics',
  },
  {
    path: routesPath.caseCalendar,
    exact: true,
    component: CaseCalendar,
    private: true,
    permission: 'readAnalytics',
  },
  {
    pageTitle: 'Users',
    path: routesPath.users,
    exact: true,
    component: Users,
    private: true,
    permission: 'readUsers',
  },
  {
    pageTitle: 'User',
    path: routesPath.editUser,
    exact: true,
    component: EditUser,
    private: true,
    permission: 'updateUsers',
  },
  {
    pageTitle: 'Settings',
    path: routesPath.settings,
    exact: true,
    component: Settings,
    private: true,
    permission: 'updateOrganizations',
  },
  {
    pageTitle: 'Administration',
    path: routesPath.administration,
    exact: true,
    component: Administration,
    private: true,
    permission: 'superadmin',
  },
];
