import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { SEND_EMAIL_FOR_RESET_PASSWORD } from './constants';
import { $ } from '../../zeus';

export const useMutationSendMailForResetPassword = (
  args?: GQLArgs<CreateMutationResponse<'resetPassword'>>,
) => {
  return useMutation(
    {
      resetPassword: [
        {
          baseUrl: $`baseUrl`,
          email: $`email`,
        },
        {
          message: true,
        },
      ],
    },
    { operationName: SEND_EMAIL_FOR_RESET_PASSWORD },
    args,
  );
};
