import { FC } from 'react';

import { useUserContext } from '../../hooks';
import { Permission } from '../../types';

type PermissionCheckProps = {
  check: Permission | Permission[];
  fallback?: React.ReactNode;
  loader?: React.ReactNode;
};

export const PermissionCheck: FC<PermissionCheckProps> = ({
  children,
  check,
  fallback,
  loader,
}) => {
  const { can, isInited } = useUserContext();
  if (!isInited) return <>{loader ?? null}</>;
  if (!can(check)) return <>{fallback ?? null}</>;

  return <>{children}</>;
};
