import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { $ } from '../../zeus';
import { REMOVE_USER } from './constants';

export const useMutationRemoveUser = (args?: GQLArgs<CreateMutationResponse<'removeUser'>>) => {
  return useMutation(
    {
      removeUser: [
        {
          email: $`email`,
        },
        {
          message: true,
        },
      ],
    },
    {
      operationName: REMOVE_USER,
      successText: 'User has been removed',
    },
    args,
  );
};
