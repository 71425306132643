import { FC, useCallback } from 'react';
import clsx from 'clsx';

import searchSvg from '../../assets/search.svg';

type SearchBarProps = {
  value?: string;
  className?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
};

export const SearchBar: FC<SearchBarProps> = ({
  value = '',
  className,
  placeholder = 'Search...',
  onChange,
}) => {
  return (
    <div
      className={clsx(
        'flex relative h-[3.2rem] border border-solid border-galery rounded-main w-full max-w-[40rem]',
        className,
      )}
    >
      <div className="absolute left-0 h-full inline-flex items-center justify-center">
        <div className="ml-[0.9rem]">
          <img src={searchSvg} />
        </div>
      </div>
      <input
        value={value}
        placeholder={placeholder}
        className="w-full font-semibold text-1214 rounded-main pl-[3.2rem] py-[0.6rem] border-0"
        onChange={useCallback(({ target: { value } }) => onChange?.(value), [onChange])}
      />
    </div>
  );
};
