import { FC } from 'react';
import { Skeleton } from 'antd';
import { EventProps } from 'react-big-calendar';

import type { IRoomEvent } from './types';

const standardSize = '1.4rem';

const SkeletonEvent: FC<EventProps<IRoomEvent>> = () => {
  return (
    <div className="h-36 pointer-events-none">
      <Skeleton.Input className="w-3/5 leading-6" style={{ height: standardSize }} />
      <Skeleton.Input active className="w-3/5 leading-6" style={{ height: standardSize }} />
      <div className="flex gap-x-4 mt-6 w-full">
        <Skeleton.Input
          className="leading-6"
          style={{ height: standardSize, width: standardSize }}
        />
        <Skeleton.Input className="w-2/5 leading-6" style={{ height: standardSize }} />
        <Skeleton.Input className="w-1/5 leading-6 ml-auto" style={{ height: standardSize }} />
      </div>
    </div>
  );
};

export default SkeletonEvent;
