import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { $ } from '../../zeus';
import { CREATE_PRESET } from './constants';

export const useMutationCreatePreset = (args?: GQLArgs<CreateMutationResponse<'createPreset'>>) => {
  return useMutation(
    {
      createPreset: [
        {
          input: {
            name: $`name`,
            content: $`content`,
          },
        },
        {
          name: true,
          content: true,
        },
      ],
    },
    {
      operationName: CREATE_PRESET,
      successText: 'Preset has been created',
    },
    args,
  );
};
