import Dropdown from 'antd/lib/dropdown';
import { ReactComponent as ChevronIcon } from '../../assets/chevron.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/checkmark.svg';

import { FC, useState, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';

export const ChartDatesViews: Option<Periods>[] = [
  {
    label: 'Monthly View',
    value: 'months',
  },
  {
    label: 'Weekly View',
    value: 'weeks',
  },
];

type ItemProps = {
  item: Option<Periods>;
  isActive: boolean;
  onSelect: (item: Option<Periods>) => void;
};

const Item: FC<ItemProps> = ({ item, isActive, onSelect }) => {
  return (
    <div
      onClick={() => onSelect(item)}
      className={clsx(
        'flex justify-between rounded-main mb-2 py-[1rem] pl-[1rem] pr-[.8rem] transition-all hover:bg-perano hover:bg-opacity-10',
        {
          'bg-perano bg-opacity-20': isActive,
        },
      )}
    >
      <div className="text-1214 self-center font-semibold font-lato tracking-wider">
        {item.label}
      </div>
      {isActive && (
        <div className="self-center">
          <CheckmarkIcon className="fill-regentGrey " />
        </div>
      )}
    </div>
  );
};

type SelectChartDatesViewProps = {
  onChange: (value: Option<Periods>['value']) => void;
  value: Option<Periods>['value'];
};

export const SelectChartDatesView: FC<SelectChartDatesViewProps> = ({ onChange, value }) => {
  const [open, setOpen] = useState(false);
  const [selectedView, setSelectedView] = useState(ChartDatesViews[0]);
  const popupRef = useRef<HTMLElement | null>(null);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, []);

  const selectView = useCallback((item: Option<Periods>) => {
    onChange(item.value);
    setSelectedView(item);
    setOpen(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setSelectedView(ChartDatesViews.find((item) => item.value === value) || ChartDatesViews[0]);
  }, [value]);
  return (
    <Dropdown
      placement="bottomCenter"
      trigger={['click']}
      getPopupContainer={(el) => {
        popupRef.current = el;
        return el;
      }}
      align={{
        offset: [0, -3],
      }}
      visible={open}
      onVisibleChange={toggleOpen}
      overlay={
        <div className="bg-white cursor-pointer w-[13.2rem] border border-t-0 border-galery rounded-main rounded-t-none">
          <div className="py-2">
            <div className="h-px bg-galery"></div>
          </div>
          <ul className="px-2 pb-2">
            {ChartDatesViews.map((item) => (
              <li key={item.value} className="">
                <Item
                  item={item}
                  isActive={item.value === selectedView.value}
                  onSelect={selectView}
                />
              </li>
            ))}
          </ul>
        </div>
      }
    >
      <div
        className={clsx(
          'bg-white cursor-pointer flex w-[13.2rem] h-[3.2rem] transition-all justify-between border border-galery py-[.6rem] pl-[1.5rem] pr-[1.2rem] rounded-main ',
          { 'hover:bg-perano hover:bg-opacity-20': !open },
        )}
      >
        <div className="text-1214 self-center font-semibold font-lato tracking-wider transition-all">
          {selectedView.label}
        </div>
        <div className="w-4 h-2 self-center">
          <ChevronIcon
            className={clsx('stroke-black transition-all', {
              'rotate-180': !open,
              'rotate-0': open,
            })}
          />
        </div>
      </div>
    </Dropdown>
  );
};
