import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { GET_DASHBOARD_CASE_HISTORY } from '../../graphql/query/constants';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';
import { ANALYTICS_API_BASE_URL } from '../../env';
import { transformAllFilters } from '../../utilities/transformAllFilters';
import { makeLogData } from '../../utilities/helpers';

const logData = makeLogData();
type useQueryDashboardCaseMixResponse = {
  case_mix: {
    case_totals: number;
    frequencies: Record<'Lap' | 'Open' | 'Robotic', number>;
    metadata: Record<'ORs' | 'surgeons' | 'procedures' | 'specialties', string[]>;
  };
  all_filters: AnalyticsGenericResponse<string>['all_filters'];
};

export type DashboardCaseMixArgs = {
  from: string;
  to: string;
};

const makeQueryDashboardCaseMix = async (params: Record<string, any>) => {
  const { data } = await RESTClient.request<useQueryDashboardCaseMixResponse>({
    baseURL: ANALYTICS_API_BASE_URL + '/dashboard/v1/case_mix',
    params,
  });

  return data;
};

export const useQueryDashboardCaseMix = (variables: DashboardCaseMixArgs, isActive?: boolean) => {
  const organization = useSLUGName();
  const params = useMemo(
    () => ({
      from: variables.from,
      to: variables.to,
      ...(organization ? { organization } : {}),
    }),
    [variables],
  );

  const processor = useCallback(() => makeQueryDashboardCaseMix(params), [params]);
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery([GET_DASHBOARD_CASE_HISTORY, params], processor, {
    enabled: isActive || isActive === undefined,
  });

  logData('/dashboard-case-mix(' + JSON.stringify(params) + ')', data);

  const result = data?.case_mix || ({} as useQueryDashboardCaseMixResponse['case_mix']);
  const allFilters = useMemo(() => transformAllFilters(data?.all_filters), [data?.all_filters]);

  return { data: result, loading, allFilters, refetch };
};
