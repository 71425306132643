import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';

import loadingSvg from '../../assets/loading.svg';

import { ERRORS_TYPE } from '../../constants/errors';
import { routesPath } from '../../constants/routes';
import { useMutationUMALogin } from '../../graphql/mutation/umaLogin';
import { useLazyQueryClinic } from '../../graphql/query/getClinics';
import { DeepPartial } from '../../types';
import { GraphQLTypes } from '../../zeus';
import clsx from 'clsx';

export const LoginSSO = () => {
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { mutation: loginUser, error } = useMutationUMALogin({
    onCompleted: (res) => {
      if (res.createSessionWithUmaToken.token) {
        localStorage.setItem('token', res.createSessionWithUmaToken.token);
        getClinics();
      }
    },
  });

  const { fetch: getClinics } = useLazyQueryClinic({
    onCompleted: ({ organizations }) => {
      goToDashboard(organizations);
    },
    onError: (apolloError) => {
      const { code, extra } = apolloError?.graphQLErrors[0].extensions;
      if (code === ERRORS_TYPE.FORCE_RESET_PASSWORD) {
        localStorage.setItem('resetToken', extra.resetToken);
        history.push(routesPath.resetPassword);
      }
    },
  });
  const history = useHistory();

  useEffect(() => {
    if (error?.graphQLErrors[0].extensions.code === ERRORS_TYPE.NOT_ACTIVE) {
      toast.error(error?.graphQLErrors?.[0]?.message || 'Error', {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  }, [error]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently({
            scope: 'openid profile email',
          });
          const umaToken = token;
          loginUser({ variables: { umaToken } });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, isAuthenticated]);

  const goToDashboard = (organizations: DeepPartial<GraphQLTypes['OrganizationsList']>) => {
    const hospitalRefId = localStorage.getItem('hospital-refId');

    if (!hospitalRefId) {
      localStorage.setItem('hospital-refId', organizations?.nodes?.[0]?.refId + '');
      history.push(`/${organizations?.nodes?.[0]?.refId}/dashboard`);
    } else {
      history.push(`/${hospitalRefId}/dashboard`);
    }
  };
  return (
    <>
      <div className="flex flex-col items-center min-w-[25rem] h-full justify-center">
        <div className="text-4064">Welcome</div>
        <Form className="w-full" onFinish={() => loginWithRedirect()} autoComplete="off">
          <button
            className={clsx(
              'text-white w-full rounded-main tracking-wider text-center text-1214 py-4 mt-14 flex justify-center',
              {
                'bg-persianBlue': isAuthenticated,
                'bg-black': !isAuthenticated,
              },
            )}
            type="submit"
          >
            {isAuthenticated ? (
              <img className="w-6" src={loadingSvg} alt="Loading..." />
            ) : (
              'Login with SSO'
            )}
          </button>
        </Form>
      </div>
      <div className="text-1020 opacity-70 font-[500] mb-8"></div>
    </>
  );
};
