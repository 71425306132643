import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { GET_MONTH_CASES } from '../../graphql/query/constants';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';
import { makeLogData } from '../../utilities/helpers';

const logData = makeLogData();

export type useQueryCalendarDayResponse = {
  data: Record<
    string,
    {
      case_id: string;
      delay: number | null;
      end_at: string;
      modality: string;
      start_at: string;
      surgeon: string;
      surgery_name: string;
    }[]
  >;
  all_filters: AnalyticsGenericResponse<string>['all_filters'];
};

export type CalendarDayArgs = {
  date: string;
  rooms?: string[] | null;
};

const makeQueryCalendarDay = async (params: Record<string, any>) => {
  const { data } = await RESTClient.get<useQueryCalendarDayResponse>('/calendar/daily', {
    params,
  });

  return data;
};

export const useQueryCalendarDay = (variables: CalendarDayArgs, isActive?: boolean) => {
  const organization = useSLUGName();
  const params = useMemo(
    () => ({
      date: variables.date,
      OR: variables.rooms || [],
      ...(organization ? { organization } : {}),
    }),
    [variables],
  );

  const processor = useCallback(() => makeQueryCalendarDay(params), [params]);
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery([GET_MONTH_CASES, params], processor, {
    enabled: isActive || isActive === undefined,
  });

  logData('/calendar/data(' + JSON.stringify(params) + ')', data);

  const result = data?.data || ({} as useQueryCalendarDayResponse['data']);

  return { data: result, rawData: data, loading, refetch };
};
