import { FC } from 'react';

import classNames from 'clsx';

import { SuggestedViewCategory } from '../../rest/query/useQuerySuggestedViews';
import { MenuOption, MultiLevelSelectbox } from '../MultiLevelSelectbox/MultiLevelSelectbox';

type SuggestedViewsSelectboxProps = {
  options: MenuOption<SuggestedViewCategory>[];
  view?: MenuOption<SuggestedViewCategory>;
  loading: boolean;
  makeTopLabel?: (val: string) => string;
  onSelectView: (o?: MenuOption<SuggestedViewCategory>, isRemove?: boolean) => void;
};

export const Arrow: React.FC<{ open?: boolean; topClass?: string }> = ({
  open,
  topClass = 'top-[1rem] md:top-[1.4rem]',
}) => {
  return (
    <svg
      width="8"
      height="4"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(
        topClass,
        open ? 'rotate-270' : '-rotate-90',
        'arrow absolute right-[0.8rem] md:right-[1rem] lg:right-[0.6rem] cursor-pointer',
      )}
    >
      <path
        d="M1.04927 0.5L4.04927 3.5L6.95117 0.598365"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const phrases = {
  'Specialties in OR': 'Specialties in',
  'Procedures in OR': 'Procedures in',
  'Procedures by Surgeon': 'Procedures by',
  'Compare ORs for Surgeon': 'Compare ORs for',
  'Compare Surgeons for Procedure': 'Compare Surgeons for',
  'Compare ORs for Procedure': 'Compare ORs for',
  'Compare ORs for Specialty': 'Compare ORs for',
};

export const SuggestedViewsSelectbox: FC<SuggestedViewsSelectboxProps> = ({
  options,
  view,
  loading,
  makeTopLabel = (val: string) => val,
  onSelectView,
}) => {
  return (
    <MultiLevelSelectbox<SuggestedViewCategory>
      header="Suggested Views"
      options={options}
      shouldHaveNone
      disabled={!options.some((o) => (o.nested || []).length > 0)}
      makeTopLabel={makeTopLabel}
      selectedItem={view}
      convertSelectedLabel={(i) =>
        `${phrases[i.category as keyof typeof phrases] || i.category} ${i.subLabel || i.label}`
      }
      loading={loading}
      onSelectOption={onSelectView}
    />
  );
};
