/* eslint-disable no-console */
import { FC, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Switch } from 'antd';
import { CreateForm, FormErrors } from '../../components/CreateForm';
import { FormSelect } from '../../components/FormSelect';
import { Option } from '../../components/FormSelect/types';
import { userRoleList } from '../../constants/userRole';

import { SInput, SFormItem } from '../newHospital/styled';
import { ButtonTypes } from '../../components/common';
import { PermissionCheck } from '../../components/PermissionCheck';
import { useQueryUser } from '../../graphql/query/getUser';
import { useMutationUpdateUser } from '../../graphql/mutation/updateUser';
import { omit } from 'lodash';
import { RoleEnum } from '../../zeus';

export const EditUser: FC = () => {
  const [role, setRole] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors[]>([]);

  const history = useHistory();

  const {
    params: { slug, email },
  } = useRouteMatch<{ slug: string; email: string }>();
  const { data, loading: isLoading } = useQueryUser(email);
  const { mutation, loading } = useMutationUpdateUser({
    onCompleted() {
      history.push(`/${slug}/users`);
    },

    onUIError: setErrors,
  });

  if (isLoading || !data) {
    return <div>Loading ...</div>;
  }

  const onSubmit = async (form: typeof data.user) => {
    await mutation({
      variables: {
        input: omit(form, ['role', 'email']),
        email: data.user.email,
        role: role?.length ? role : userRoleList[data.user?.role || RoleEnum.org_admin].value,
      },
    });
  };

  return (
    <>
      <CreateForm
        initialValues={data.user}
        onSubmit={onSubmit}
        loading={loading}
        buttonText="Update User"
        errors={errors}
        buttonType={ButtonTypes.PRIMARY}
      >
        <>
          <PermissionCheck check="superadmin">
            <div>
              <SFormItem name="setDefaultPassword">
                <Switch defaultChecked={false} />
              </SFormItem>
              <span>Set default password</span>
            </div>
          </PermissionCheck>
          <div>
            <SFormItem name="active">
              <Switch defaultChecked={data.user.active} />
            </SFormItem>
            <span>Active</span>
          </div>

          <div>
            <SFormItem name="forceResetPassword">
              <Switch defaultChecked={data.user.forceResetPassword} />
            </SFormItem>
            <span> Reset password </span>
          </div>

          <span>Full Name</span>
          <SFormItem
            name="fullName"
            rules={[{ required: true, message: 'Please input Full Name' }]}
          >
            <SInput />
          </SFormItem>
          {/* <span>Email</span>
          <SFormItem
            name="email"
            rules={[{ required: true, type: 'email', message: 'Please input Email' }]}
          >
            <SInput />
          </SFormItem> */}
          <span>Speciality</span>
          <SFormItem name="speciality">
            <SInput />
          </SFormItem>
          <span>Reference ID</span>
          <SFormItem name="refId" rules={[{ message: 'Please input Reference ID' }]}>
            <SInput />
          </SFormItem>
          <SFormItem name="role" rules={[{ message: 'Please input role' }]}>
            <div>
              <div>
                <span>User Role</span>
                <FormSelect
                  options={Object.values(userRoleList)}
                  onChange={setRole}
                  value={userRoleList[data.user?.role || 'org_admin']?.value}
                />
              </div>
            </div>
          </SFormItem>
        </>
      </CreateForm>
    </>
  );
};
