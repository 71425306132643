/* eslint-disable no-console */
import axios, { AxiosError } from 'axios';
import { ANALYTICS_API_BASE_URL } from '../env';

import { toast } from 'react-toastify';
import { routesPath } from '../constants/routes';
import { lastUrlStorage } from '../utilities/helpers';

const callToast = (error: AxiosError) => {
  const history = window.history;
  if (error.response) {
    const token = localStorage.getItem('token');
    if (token && token !== 'null' && error.response.status === 401) {
      localStorage.removeItem('token');
      lastUrlStorage.save();
      history.replaceState(null, '', routesPath.login);
    }
  } else if (error.request) {
    console.error(error.request);
  } else {
    console.error('Error', error.message);
  }
  console.log(error.config);

  if (error) {
    let message = error?.message || 'Error';

    if (/doctype/.test(message)) {
      message = 'Oops! Something went wrong!';
    }
    toast.error(message, {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
    if (error?.message === 'Not authorized') history.replaceState(null, '', routesPath.login);
  }

  return Promise.reject(error);
};

export const RESTClient = axios.create({
  baseURL: ANALYTICS_API_BASE_URL + '/api/v1',

  transformResponse: [
    (data) => {
      if (typeof data === 'string') {
        return JSON.parse(data.replace(/(-?)Infinity|NaN/g, 'null'));
      }
      return data;
    },
  ],
});

RESTClient.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (config.method === 'get') {
    config.params = {
      ...config.params,
      source: 'sql',
    };
  }
  return config;
});

RESTClient.interceptors.response.use((response) => response, callToast);
