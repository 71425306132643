import { format } from 'date-fns';

import CalendarComponent from './CalendarComponent';
import { routesPath } from '../../constants/routes';
import { useRoomsCalendar } from './useRoomsCalendar';
import { PageTitle } from '../../components/PageTitle';
import type { IRoomEvent } from '../../components/RoomsCalendar';

export const DailyCalendar = () => {
  const surgeons = [] as any[];
  const rooms = [] as any[];

  const {
    events,
    eventsRooms,
    loading,
    date,
    onDateChange,
    room,
    onRoomChange,
    surgeon,
    onSurgeonChange,
    onSelectEvent,
  } = useRoomsCalendar(surgeons);

  return (
    <div>
      <PageTitle
        header={format(date, 'EEEE, MMMM d, yyyy')}
        crumbs={[{ title: 'Calendar', url: routesPath.calendar.replace('/:refId', '') }]}
      />
      <CalendarComponent
        loading={loading}
        events={events as IRoomEvent[]}
        eventsRooms={eventsRooms}
        date={date}
        room={room}
        rooms={rooms}
        surgeon={surgeon}
        surgeons={surgeons}
        onDateChange={onDateChange}
        onRoomChange={onRoomChange}
        onSurgeonChange={onSurgeonChange}
        onSelectEvent={onSelectEvent}
      />
    </div>
  );
};
