import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  MultiFilterReportName,
  MultiFilterStateContextType,
  MultiFilterStore,
} from './MultiFilters.types';
import { initState } from './MultiFilter.helpers';
import _ from 'lodash';
import { adjustUrlState, historyPushState } from '../../utilities/helpers';

const setUrlState = (state: Partial<MultiFilterStateContextType>, excludeParams: string[] = []) => {
  const params = adjustUrlState(new URLSearchParams(window.location.search), state, excludeParams);

  if (params.toString() === window.location.search.slice(1)) return;

  historyPushState(`${window.location.pathname}?${params.toString()}`);
};

const excludeUrlParams = {
  cases: ['activities', 'type'],
  caseView: ['activities', 'type'],
  nonOperativeMetrics: ['activities', 'type'],
  peopleCount: ['activities', 'type'],
  temporalActivities: [],
};

export const useMultiFilterStore = create<MultiFilterStore>()(
  devtools(
    immer(
      persist(
        (set, get) => ({
          cases: initState,
          caseView: initState,
          nonOperativeMetrics: initState,
          peopleCount: initState,
          temporalActivities: initState,
          setFilters(key, name, value) {
            const newState = { ...get()[key], [name]: value };
            set({ [key]: newState });
            setUrlState(_.omit(newState, 'dateRange'), excludeUrlParams[key]);
          },
          setBulkFilters(key, filters) {
            const newState = { ...get()[key], ...filters };
            set({ [key]: newState });
            setUrlState(_.omit(newState, 'dateRange'), excludeUrlParams[key]);
          },
          deleteSelectedFilter(key, name, value) {
            const someFilterType = get()[key][name];
            const setFilters = get().setFilters;

            if (someFilterType instanceof Array) {
              const newVal = someFilterType.filter((filterVal) => filterVal !== value);
              setFilters(key, name, newVal);
              return;
            }

            if (someFilterType === value) {
              setFilters(key, name, null);
              return;
            }
            if (_.isBoolean(someFilterType)) {
              setFilters(key, name, false);
            }
          },
          reset(key: MultiFilterReportName, shouldKeepCompare) {
            const s = {
              ...initState,
              compare: shouldKeepCompare ? get()[key].compare : null,
              activities: get()[key].activities,
            };
            set(() => ({ [key]: s }));
            setUrlState(_.omit(s, 'dateRange'), excludeUrlParams[key]);
          },
          resetAll() {
            set(() => ({
              case: initState,
              nonOperativeMetrics: initState,
              temporalActivities: initState,
            }));
            setUrlState(_.omit(initState, 'dateRange'));
          },
        }),
        {
          name: 'multiFilter-storage_' + localStorage.getItem('hospital-refId'),
        },
      ),
    ),
  ),
);
