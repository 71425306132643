import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Tooltip } from '../Tooltip/Tooltip';

export type ViewSwitchItem<V extends string> = {
  style?: string;
  disabled?: boolean;
  styleActive?: string;
  styleInactive?: string;

  label: V;
  Icon: React.ComponentType<{ className?: string }>;
};

type ViewSwitchProps<V extends string> = {
  className?: string;
  defaultItem?: string;
  items: ViewSwitchItem<V>[];
  onChange: (name: V) => void;
};

export function ViewSwitch<V extends string>({
  className,
  items,
  defaultItem,
  onChange,
}: ViewSwitchProps<V>) {
  const [activeItem, setActive] = useState(
    defaultItem ?? items?.filter((i) => !i.disabled)?.[0]?.label,
  );
  const change = (item: typeof items[0]) => {
    if (item.disabled) return;
    setActive(item.label);
    onChange(item.label);
  };

  useEffect(() => {
    if (defaultItem) {
      setActive(defaultItem);
    }
  }, [defaultItem]);

  return (
    <div className={clsx('flex gap-x-4 justify-between', className)}>
      {items.map((item) => {
        const active = activeItem === item.label;
        return (
          <Tooltip title={item.label} key={item.label}>
            <div
              onClick={() => change(item)}
              className={clsx(' rounded-[0.8rem]', item.style, {
                'bg-black text-white': active,
                'bg-white text-black ': !active,
                'hover:bg-perano hover:bg-opacity-20 ': !active && !item.disabled,
                'opacity-30': item.disabled,
                'cursor-pointer': !item.disabled,
              })}
            >
              <item.Icon
                className={clsx({
                  [item.styleActive ?? '']: active,
                  [item.styleInactive ?? '']: !active,
                })}
              />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}
