/* eslint-disable */
import clsx from 'clsx';
import { FC, forwardRef } from 'react';

import { Selector } from '../Selector';
import { FilterArrow } from '../FilterArrow';
import type { FormSelectProps } from './types';

type TriggerProps = {
  open?: boolean;
  active?: string;
  onClick?: () => void;
  label?: React.ReactNode;
  children?: React.ReactNode;
};

const Trigger = forwardRef<HTMLDivElement, TriggerProps>(
  // eslint-disable-next-line
  ({ children = null, onClick, label, active, open }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          active ? 'border-text-black' : '',
          open ? 'bg-persianBlue border-persianBlue text-white' : '',
          `transition-colors duration-300 select-none border-[0.1rem] border-solid flex items-center relative cursor-pointer rounded-t-main rounded-b-main`,
        )}
      >
        <div
          className={clsx(
            `h-16 w-full text-[1rem] cursor-pointer text-left flex items-center justify-start mulish not-italic font-semibold leading-[2rem] relative pl-[1.25rem] pr-[2.7rem]`,
          )}
          onClick={onClick}
        >
          {label}
          {<FilterArrow open={open} topClass="top-[1.8rem]" />}
        </div>
        {children}
      </div>
    );
  },
);

export const FormSelect: FC<FormSelectProps> = ({ value, options, onChange, search = true }) => {
  return (
    <Selector
      stretch
      notFoundLabel=""
      pickedData={value}
      options={options}
      withSearch={search}
      container={Trigger}
      onSelect={(o) => onChange?.(o.value!)}
    />
  );
};
