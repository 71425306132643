import { useCallback, useEffect, useMemo, useState } from 'react';

import { allRoboticDayOptions, compareOptions, sameSurgeonOptions } from './mocks';
import {
  convertRoomsToOptions,
  convertSurgeonsToOptions,
  convertProceduresToOptions,
} from '../../utilities/optionsConvertors';
// import { useMultiFilterActions, useMultiFilterState } from './MultiFilter.context';
import {
  ChartViewName,
  MultiFilterReportName,
  MultiFilterStateContextType,
  SelectedFilterKeys,
} from './MultiFilters.types';
import { Option } from '../Selector';
import { FiltersPanel } from '../FiltersPanel/FiltersPanel';
import { AddButton } from './AddButton';
import { FiltersBar } from './FiltersBar';
import { RemoveButton } from './RemoveButton';
import { SelectedFilters } from '../SelectedFilters/SelectedfFilters';
import { getQuery } from '../../utilities/getQuery';
import { useHistory } from '../../hooks/useHistory';
import { useDateRangeFilter } from '../../hooks/useCalendarFilter';
import { useMultiFilterStore } from './MultiFilter.store';
import { extractProperFilters } from './MultiFilter.helpers';
import { convertURLParamsToObject } from '../../utilities/helpers';

type MultiFilterProps = {
  reportName: MultiFilterReportName;
  limitSelectedFilters?: number;
  drawerOpen: boolean;
  hideCompareSelectbox?: boolean;
  compareTitle?: string;
  view?: ChartViewName | null;
  isLoading?: boolean;
  availableFilters?: Record<AvailableFilterKeys, string[]>;
  allFilters: AllFilters;
  hideFilters?: SelectedFilterKeys[];
  activitiesViews?: Option[];
  leftFiltersBarSlot?: JSX.Element;
  rightFiltersBarSlot?: JSX.Element;
  includeSplitOptions?: Option[];
  alwaysGroupBy?: boolean;
  onOpenPanel: () => void;
  onClosePanel: () => void;
};

export const today = new Date().toISOString().substr(0, 10);

function toOptions<T>(arr: T[]) {
  return arr.map((w) => ({ label: w, value: w }));
}

function makeAllFiltersOptions(
  allFilters: AllFilters,
  availableFilters?: Record<AvailableFilterKeys, string[]>,
) {
  const proceduresOptions = convertProceduresToOptions(allFilters.procedures, false);
  const roomsOptions = convertRoomsToOptions(allFilters.rooms, false);
  const surgeonsOptions = convertSurgeonsToOptions(allFilters.surgeons, false);
  const modalitiesOptions = toOptions(allFilters.modality);
  const casesOptions = allFilters.caseNumber.map((w) => ({ label: `Case ${w}`, value: w }));
  const weekOptions = toOptions(allFilters.weekday);
  const specialtiesOptions = allFilters.specialties.map((w) => ({ label: w.name, value: w.code }));
  const casesPerDayOptions = allFilters.casesPerDay.map((w) => ({
    label: `${w} ${/1/g.test(w) ? 'case' : 'cases'} per day`,
    value: w,
  }));

  return {
    proceduresOptions,
    roomsOptions,
    surgeonsOptions,
    modalitiesOptions,
    casesOptions,
    weekOptions,
    specialtiesOptions,
    casesPerDayOptions,
  };
}

export const MultiFilter = ({
  reportName,
  limitSelectedFilters = 5,
  hideFilters,
  availableFilters,
  isLoading,
  allFilters,
  drawerOpen,
  activitiesViews,
  alwaysGroupBy,
  view,
  hideCompareSelectbox,
  leftFiltersBarSlot,
  rightFiltersBarSlot,
  compareTitle,
  onOpenPanel,
  onClosePanel,
  includeSplitOptions,
}: MultiFilterProps) => {
  const history = useHistory();
  const dateRanges = getQuery(history);
  const { updateQuery } = useDateRangeFilter();
  const [qtyFilter, setQtyFilter] = useState(0);

  const {
    proceduresOptions,
    roomsOptions,
    surgeonsOptions,
    modalitiesOptions,
    casesOptions,
    weekOptions,
    specialtiesOptions,
    casesPerDayOptions,
  } = useMemo(
    () => makeAllFiltersOptions(allFilters, availableFilters),
    [allFilters, availableFilters],
  );

  const filtersState = useMultiFilterStore((s) => s[reportName]);
  const [setBulkFilters, setFilters, reset, deleteSelectedFilter] = useMultiFilterStore((s) => [
    s.setBulkFilters,
    s.setFilters,
    s.reset,
    s.deleteSelectedFilter,
  ]);

  const onChangeFiltersQty = useCallback((qty: number) => {
    setQtyFilter(qty);
  }, []);

  const applyFilters = useCallback((filters: MultiFilterStateContextType) => {
    if (filters.dateRange) {
      const [from, to] = filters.dateRange?.split(';');

      updateQuery({ from, to });
    }
    setBulkFilters(reportName, { ...filters, suggestedView: null });
  }, []);

  function getFiltersFromURL() {
    const urlSelectedFilters = new URLSearchParams(window.location.search);

    const urlState = convertURLParamsToObject(urlSelectedFilters);
    const urlFilters = extractProperFilters(urlState);

    if (alwaysGroupBy && !filtersState.compare) {
      urlFilters.compare = 'procedure';
    }
    const isSkipFilters = urlSelectedFilters.get('skipFilters');

    if (isSkipFilters) {
      reset(reportName);
    }
    setBulkFilters(reportName, urlFilters);
  }

  useEffect(() => {
    getFiltersFromURL();
  }, [alwaysGroupBy, history.location.search, view]);

  const dateRange = `${dateRanges.from};${dateRanges.to}`;

  useEffect(() => {
    setFilters(reportName, 'dateRange', dateRange);
  }, [dateRange]);

  const filtersBar = (
    <FiltersBar
      reportName={reportName}
      compareTitle={compareTitle}
      hideCompareSelectbox={hideCompareSelectbox}
      activitiesViews={activitiesViews}
      leftSlot={leftFiltersBarSlot}
      rightSlot={rightFiltersBarSlot}
      compareOptions={[...compareOptions, ...(includeSplitOptions || [])]}
      alwaysGroupBy={alwaysGroupBy}
    />
  );

  const panel = (
    <FiltersPanel
      isLoading={isLoading}
      reportName={reportName}
      drawerOpen={drawerOpen}
      alwaysGroupBy={alwaysGroupBy}
      availableFilters={availableFilters}
      applyFilters={applyFilters}
      onClosePanel={onClosePanel}
      hideFilters={hideFilters}
      filtersState={filtersState}
      filters={{
        roomsOptions,
        surgeonsOptions,
        compareOptions: [...compareOptions, ...(includeSplitOptions || [])],
        modalitiesOptions,
        specialtiesOptions,
        proceduresOptions,
        allRoboticDayOptions,
        sameSurgeonOptions,
        casesPerDayOptions,
        casesOptions,
        weekOptions,
      }}
    />
  );
  const selectedFilters = (
    <div className="w-full flex items-center min-h-12 shrink-0">
      <div className="text-1216 h-[2.3rem] font-bold mr-8 inline-flex items-center">Filters:</div>
      <div className="">
        <SelectedFilters
          filtersState={filtersState}
          onChangeFiltersQty={onChangeFiltersQty}
          hiddenOptions={['compare', 'dateRange', ...(hideFilters || [])]}
          limit={limitSelectedFilters}
          onDeleteSelectedFilter={(name, val) => {
            deleteSelectedFilter(reportName, name, val);
            setFilters(reportName, 'suggestedView', null);
          }}
          options={{
            roomsOptions,
            surgeonsOptions,
            specialtiesOptions,
            compareOptions: [...compareOptions, ...(includeSplitOptions || [])],
            casesOptions,
            casesPerDayOptions,
            allRoboticDayOptions,
            sameSurgeonOptions,
            modalitiesOptions,
            proceduresOptions,
            weekOptions,
          }}
        />
      </div>
      <div className="self-start min-w-[28rem] flex my-[.75rem]">
        <AddButton
          prefix={qtyFilter >= limitSelectedFilters ? `` : '+'}
          label={qtyFilter >= limitSelectedFilters ? `Manage other filters` : 'Add'}
          onClick={onOpenPanel}
        />
        {qtyFilter >= 1 && <RemoveButton onClick={() => reset(reportName, true)} />}
      </div>
    </div>
  );
  return { filtersBar, selectedFilters, panel };
};
