import { $, GraphQLTypes } from '../../zeus';
import { usePaginationItems } from '../../hooks/usePaginationItems';
import { GET_PRESETS } from './constants';

export function useQueryPresets(perPage?: number) {
  const res = usePaginationItems({
    query: {
      presets: [
        {
          pagination: $`pagination`,
        },
        {
          pagination: {
            lastPage: true,
            currentPage: true,
            perPage: true,
            nextPage: true,
            totalNodes: true,
            totalPages: true,
          },
          nodes: {
            content: true,
            name: true,
          },
        },
      ],
    },
    operationName: GET_PRESETS,
    entity: 'presets',
    perPage,
  });

  return { ...res, items: res.items as GraphQLTypes['Preset'][] };
}
