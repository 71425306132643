type ExpandProps = {
  direction?: 'left' | 'right';
};

export const Expand: React.FC<ExpandProps> = ({ direction = 'left' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={direction === 'left' ? 'transition-all' : 'transition-all rotate-180'}
    >
      <g clipPath="url(#clip0_1998_5133)">
        <path
          d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
          stroke="#A5BEF5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.166 15.3346L12.8327 12.0013L16.166 8.66797"
          stroke="#A5BEF5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.334 15.3346L7.00065 12.0013L10.334 8.66797"
          stroke="#A5BEF5"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1998_5133">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
