import { FC } from 'react';
import { format } from 'date-fns';

export type YearLabelProps = {
  date?: Date | null;
  className?: string;
};

const YearLabel: FC<YearLabelProps> = ({ date, className }) => {
  const formattedYear = date ? format(date, 'yyyy') : 'Date is not set';

  return <span className={className}>{formattedYear}</span>;
};

export default YearLabel;
