import { FC, useMemo } from 'react';

import { Selector } from '../../components/Selector';
import RoomsCalendar from '../../components/RoomsCalendar';
import CalendarLayout from '../../components/CalendarLayout';
import { PermissionCheck } from '../../components/PermissionCheck';
import CalendarDatePicker from '../../components/CalendarDatePicker';
import CalendarDateSlider from '../../components/CalendarDateSlider';
import { convertRoomsToOptions, convertSurgeonsToOptions } from '../../utilities/optionsConvertors';

import type { CalendarProps } from './types';

const CalendarComponent: FC<CalendarProps> = ({
  loading,
  events,
  eventsRooms,
  date,
  onDateChange,
  room,
  rooms,
  onRoomChange,
  surgeon,
  surgeons,
  onSurgeonChange,
  onSelectEvent,
}) => {
  // if we aren't able to get rooms list for some reason - at least use the ones we got from request
  const theseRooms = rooms.length ? rooms : eventsRooms;
  const roomOptions = useMemo(() => convertRoomsToOptions(theseRooms), [theseRooms]);
  const surgeonsOptions = useMemo(() => convertSurgeonsToOptions(surgeons), [surgeons]);

  const showSurgeonsFilter = !!surgeons.length;
  const selectedRoom = theseRooms.find((r) => r === room);

  return (
    <CalendarLayout>
      <CalendarLayout.Filters>
        <CalendarDatePicker date={date} mode="date" dayFormat="MMMM yyyy" onChange={onDateChange} />
        <PermissionCheck check="readRooms">
          <Selector
            pickedData={room}
            options={roomOptions}
            onSelect={(newRoom) => onRoomChange?.(newRoom?.value)}
          />
        </PermissionCheck>
        {showSurgeonsFilter && (
          <Selector
            withSearch
            pickedData={surgeon}
            options={surgeonsOptions}
            onSelect={(newSurgeon) => onSurgeonChange?.(newSurgeon?.value)}
          />
        )}
      </CalendarLayout.Filters>
      <CalendarLayout.Content
        actionsAlign="center"
        actions={<CalendarDateSlider date={date} onChange={onDateChange} mode="date" />}
      >
        <RoomsCalendar
          date={date}
          loading={loading!}
          onNavigate={() => 1}
          events={events ?? []}
          onSelectEvent={onSelectEvent}
          rooms={selectedRoom ? [selectedRoom] : theseRooms}
          singleColumn={false}
        />
      </CalendarLayout.Content>
    </CalendarLayout>
  );
};

export default CalendarComponent;
