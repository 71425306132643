import { useState } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Form } from 'antd';

import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { SUCCESS_RES } from '../../static/const';
// eslint-disable-next-line max-len
import { useMutationSendMailForResetPassword } from '../../graphql/mutation/sendEmailForResetPassword';

export const OnlyEmail = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { mutation: resetPass, error } = useMutationSendMailForResetPassword({
    onCompleted(res) {
      if (res.resetPassword.message === SUCCESS_RES) {
        setIsSuccess(true);
      }
    },
  });

  const onSubmit = (form: { email: string }) => {
    resetPass({ variables: { email: form.email, baseUrl: window.location.origin } });
  };

  return (
    <>
      <div className="title title_black">Reset password</div>
      <Form
        validateTrigger="onSubmit"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
        >
          <Input placeholder="Email Address" prefix={<MailOutlined />} />
        </Form.Item>

        {error && <div className="error">Something went wrong</div>}
        {isSuccess && <div className="success">Check your email</div>}
        <Button htmlType="submit">Reset</Button>
      </Form>
    </>
  );
};
