import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { CompareValues } from '../../components/MultiFilter/MultiFilters.types';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';
import { transformAllFilters } from '../../utilities/transformAllFilters';
import { makeLogData } from '../../utilities/helpers';

const logData = makeLogData();

export type GenesisFilter = {
  OR?: string[];
  surgeon_id?: string[];
  procedure?: string[];
  weekday?: string[];
  surgical_pose?: string[];
  surgery_code?: string[];
  cutoff?: string;
  hybrid?: boolean;
  all_robotic?: boolean;
  hospital_code?: string[];
  case_number?: string[];
  case_id?: string[];
  exclude_outliers?: boolean;
  turned_over?: boolean;
  add_scatter?: boolean;
};

export type QueryGenesisMetricsArgs = {
  from: string;
  to: string;
  split?: CompareValues;
  filters?: GenesisFilter;
};

const GET_CASES_METRICS = 'GET_CASES_METRICS';

type CasesResponse = {
  frequencies: Record<string, number>;
  all_filters: Record<AvailableFilterKeys, string[]>;
  available_filters: Record<AvailableFilterKeys, string[]>;
};

const makeQueryCasesMetrics = async (params: Record<string, any>) => {
  const { data } = await RESTClient.get<CasesResponse>('/cases', { params });

  return data;
};

export const useQueryCasesMetrics = (variables: QueryGenesisMetricsArgs) => {
  const clinickName = useSLUGName();
  const params = useMemo(
    () => ({
      from: variables.from,
      to: variables.to,
      ...(clinickName ? { organization: clinickName } : {}),
      metadata: variables.split || undefined,
      ...(variables.filters || {}),
    }),
    [variables],
  );

  const processor = useCallback(() => makeQueryCasesMetrics(params), [params]);
  const { data, isLoading: loading, refetch } = useQuery([GET_CASES_METRICS, params], processor);
  logData('/case-mix(' + JSON.stringify(params) + ')', data);

  const allFilters = useMemo(() => transformAllFilters(data?.all_filters), [data?.all_filters]);

  return { data, loading, allFilters, refetch };
};
