import { useEffect, useMemo, useState } from 'react';
import { useHistory, useCalendarFilter } from '../../hooks';
import { routesPath } from '../../constants/routes';
import { convertDateToString } from '../../utilities/dateTime';
import { convertDataToEvents, getBounds, getLastDataMonth } from './utilities';

import type { UseCalendarParamsType, CalendarProps } from './types';
import { getInitDate } from '../../utilities/getQuery';
import { useQueryCalendarMonth } from '../../rest/query/useQueryCalendarMonth';
import { useMe } from '../../context/useMe';

type RoomSlugType = CalendarProps['room'];
type SurgeonNameType = CalendarProps['surgeon'];

const createRoute = (room: RoomSlugType, surgeon?: SurgeonNameType, date?: Date) => {
  const params = new URLSearchParams({
    date: convertDateToString(date),
  });

  if (room) {
    params.set('room', room);
  }

  if (surgeon) {
    params.set('surgeon', surgeon);
  }

  return `${routesPath.dataCalendar.replace('/:refId', '')}?${params.toString()}`;
};

export const useCalendar = ({ applySmartSizing }: UseCalendarParamsType) => {
  const { date, room, surgeon, handleDateChange, handleRoomChange, handleSurgeonChange } =
    useCalendarFilter();
  const { data: me, isInited } = useMe();

  const lastDateFromRange = me?.me.current?.organization?.availableData.to;

  const history = useHistory();

  const params = new URLSearchParams(history.location.search);

  const [init, setInit] = useState(false);

  const variables = getBounds(date);

  const { data, allFilters, loading } = useQueryCalendarMonth(
    {
      ...variables,
      rooms: room ? [room] : [],
    },
    init,
  );

  const events = useMemo(() => {
    return convertDataToEvents(data, applySmartSizing);
  }, [data, applySmartSizing]);

  useEffect(() => {
    if (!init && isInited) {
      const date = params.get('date');
      const lastDataMonth = date || lastDateFromRange || getInitDate().to;

      setInit(true);

      handleDateChange(new Date(lastDataMonth));
    }
  }, [events, init, isInited]);

  const handleRefetch = () => {
    // refetch(getVariables(date, room, surgeon));
  };

  const onDateChange = (newDate: Date) => {
    handleDateChange(newDate);
    handleRefetch();
  };

  const onRoomChange = (newRoom: RoomSlugType) => {
    handleRoomChange(newRoom);
    handleRefetch();
  };

  const onSurgeonChange = (newSurgeon: SurgeonNameType) => {
    handleSurgeonChange(newSurgeon);
    handleRefetch();
  };

  const onSelectEvent: CalendarProps['onSelectEvent'] = (event) => {
    handleDateChange(new Date(event.start || ''));
    history.push(createRoute(room, surgeon, event.start));
  };

  return {
    loading,
    events,
    date,
    onDateChange,
    room,
    onRoomChange,
    surgeon,
    rooms: allFilters.rooms,
    onSurgeonChange,
    onSelectEvent,
  };
};
