import { CompareValues } from './MultiFilters.types';

export const compareOptions: {
  label: string;
  value: CompareValues;
}[] = [
  {
    label: 'None',
    value: null,
  },
  {
    label: 'Specialties',
    value: 'specialty_code',
  },
  {
    label: 'Procedures',
    value: 'procedure',
  },
  {
    label: 'Surgeons',
    value: 'surgeon_id',
  },
  {
    label: 'Rooms',
    value: 'OR',
  },
  {
    label: 'Modalities',
    value: 'modality',
  },
  {
    label: 'Week Days',
    value: 'weekday',
  },
  {
    label: 'Cases Numbers',
    value: 'case_number',
  },

  {
    label: 'Case Counts per Day',
    value: 'cases_per_day',
  },
  {
    label: 'A-A vs A-B Turnovers',
    value: 'same_surgeon',
  },
];

export const casesOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
];

export const weekOptions = [
  // { label: 'Sun', value: 'Sun' },
  { label: 'Mon', value: 'Mon' },
  { label: 'Tue', value: 'Tue' },
  { label: 'Wed', value: 'Wed' },
  { label: 'Thu', value: 'Thu' },
  { label: 'Fri', value: 'Fri' },
  // { label: 'Sat', value: 'Sat' },
];

export const modalitiesOptions = [
  {
    label: 'Open',
    value: 'Open',
  },
  {
    label: 'Lap',
    value: 'Lap',
  },
  {
    label: 'Robotic',
    value: 'Robotic',
  },
];

export const additionalOptions = [
  {
    label: 'Exclude outliers',
    value: 'excludeOutliers',
  },
  {
    label: 'Exclude Multi-Procedure/Surgeon Cases',
    value: 'multiCases',
  },
];

export const allRoboticDayOptions = [
  {
    label: 'Restrict to All Robotic Days',
    value: 'include',
  },

  {
    label: 'Exclude All Robotic Days',
    value: 'exclude',
  },
];
export const sameSurgeonOptions = [
  {
    label: 'Restrict to Same Surgeon Turnovers',
    value: 'include',
  },

  {
    label: 'Exclude Same Surgeon Turnovers',
    value: 'exclude',
  },
];
