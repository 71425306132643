import type { DataType } from './types';
import type { ICaseEvent } from '../../components/CaseTimeline';
import { convertStringToDatetime } from '../../utilities/dateTime';
import { useQueryCaseResponse } from '../../rest/query/useQueryCase';
import { CaseStageType } from '../../types';

export const convertDataToEvents = (_case: useQueryCaseResponse['data']): ICaseEvent[] => {
  return (
    Object.keys(_case).flatMap((key) => {
      const activities = _case[key as ActivityGroupKeys];

      return (
        activities?.map((activity) => {
          const start = convertStringToDatetime(activity.start_time ?? '');
          const end = convertStringToDatetime(activity.end_time ?? '');
          start.setSeconds(0, 0);
          end.setSeconds(0, 1);

          return {
            title: activity.label,
            stage: key as CaseStageType,
            end,
            start,
          };
        }) ?? []
      );
    }) ?? []
  );
};
