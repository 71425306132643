import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Dropdown from 'antd/lib/dropdown';
import { ClinicItem } from './ClinicItem';
import { useClinics } from './useClinics';
import { ClinicsDropDown } from './ClinicsDropDown';
import { useAppContext, useUserContext } from '../../context';

export const ClinicsSelector = () => {
  const history = useHistory();
  const { currentOrg, onOrgChange } = useUserContext();
  const [dropDownKey, setDropDownKey] = useState('dropDownKey0');
  const { sidebarCollapsed } = useAppContext();
  const clinicRefId = currentOrg?.refId ?? '';

  const handleChangeClinic = (newRefId?: string) => {
    setDropDownKey(`dropDownKey${Math.random()}`);
    history.push(`/${newRefId}/dashboard`);
    onOrgChange(newRefId!);
  };

  const addNewHospital = () => {
    setDropDownKey(`dropDownKey${Math.random()}`);
    history.replace(`/${clinicRefId}/new`);
  };

  const {
    pagination,
    searchQuery,
    items: clinics,
    onScroll,
    onSearch,
    refetchItems,
    setSearchQuery,
  } = useClinics();

  return (
    <Dropdown
      key={dropDownKey}
      overlay={
        <ClinicsDropDown
          addHospital={addNewHospital}
          hasMorePages={!!pagination?.nextPage}
          onScroll={onScroll}
          handleChangeClinic={handleChangeClinic}
          searchQuery={searchQuery}
          setSearchQuery={(q: string) => onSearch(q)}
          currentClinic={currentOrg as any}
          clinics={clinics}
        />
      }
      trigger={['click']}
      align={{
        offset: [250, -70],
      }}
      placement="bottomRight"
    >
      <div
        onClick={async () => {
          setSearchQuery('');

          await refetchItems({
            search: '',
            pagination: {
              page: 1,
              perPage: 5,
            },
          });
        }}
        className="flex justify-center items-center h-[7.2rem] w-full"
      >
        <ClinicItem
          menu
          clinic={currentOrg as any}
          showClinic={!sidebarCollapsed}
          key={currentOrg?.refId ?? 'no_clinic'}
        />
      </div>
    </Dropdown>
  );
};
