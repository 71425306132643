import { FC } from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';

import { useUserContext } from '../../hooks';
import { PageTitle } from '../../components/PageTitle';
import { routesPath, slugRoutesPaths } from '../../constants/routes';
import type { PrivateRouteProps } from '../../types';
import { lastUrlStorage } from '../../utilities/helpers';

type ComponentWrapperProps = Pick<PrivateRouteProps, 'routeComponent' | 'permission' | 'pageTitle'>;

export const ComponentWrapper: FC<ComponentWrapperProps> = ({
  pageTitle,
  permission,
  routeComponent: Component,
}) => {
  const { can, isInited } = useUserContext();
  const permitted = !permission || can(permission);
  const noPermission = isInited && !permitted;

  if (noPermission) {
    const clinicSlug = localStorage.getItem('hospital-refId');

    return <Redirect to={`/${clinicSlug}/dashboard`} />;
  }

  if (!permitted) return <></>;

  return (
    <>
      {!!pageTitle && <PageTitle header={pageTitle} subHeader={false} />}
      <Component />
    </>
  );
};

export const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const match = useRouteMatch(slugRoutesPaths);
  const token = localStorage.getItem('token');
  const clinicRefId = localStorage.getItem('hospital-refId');
  const auth = token ?? false;

  if (!auth) {
    lastUrlStorage.save();
    return <Redirect to={routesPath.login} />;
  }
  if (!match) {
    return <Redirect to={`/${clinicRefId}/dashboard`} />;
  }

  return (
    <Route {...props}>
      <ComponentWrapper {...props} />
    </Route>
  );
};
