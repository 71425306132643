import { ChartOptions } from 'chart.js';
import { cloneDeep, merge } from 'lodash';
import { pieChartOptions } from '../../utilities/chartUtils';
import { makeTooltipStyles } from '../../components/ChartTooltip/ChartTooltip';
import { CompareValues } from '../../components/MultiFilter/MultiFilters.types';
import { compLabels } from '../../utilities/commonOptions';

export const fontStyle = {
  size: 12,
  weight: 'bold',
  lineHeight: '20px',
  family: 'Lato',
};

export const scaleGridStyle = {
  display: true,
  color: '#F0F0F0',
  borderDash: [5, 5],
};

export const scaleTicksStyle = {
  padding: 12,
  font: fontStyle,
  color: '#1C1C1F',
};
export const scaleTitleStyle = {
  display: true,
  font: fontStyle,
  color: '#1C1C1F',
};

export const makeDoughnutOptions = ({
  tooltipRef,
  compareBy,
  sum,
}: {
  tooltipRef: React.MutableRefObject<HTMLDivElement>;
  compareBy: CompareValues;
  sum: number;
}) => {
  const options = cloneDeep({ ...pieChartOptions });

  return merge(options, {
    responsive: true,
    layout: {
      padding: {
        left: 50,
        right: 50,
      },
    },
    plugins: {
      datalabels: {
        font: fontStyle,
        color: '#fff',
      },
      doughnutCenter: {
        text: sum + '',
      },
      tooltip: {
        mode: 'nearest',
        callbacks: {
          beforeBody(i) {
            const dataIndex = i[0].dataIndex;
            // // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const general = (i[0].dataset as any)?.general;
            const labels = general.labels;
            const data = general.data;

            const metricLabel = compLabels[compareBy as keyof typeof compLabels];
            const item = labels[dataIndex];
            const value = data[dataIndex];

            const total = data.reduce((a: number, b: number) => a + b, 0);
            const percent = ((value / total) * 100).toFixed(0);
            return [
              {
                label: metricLabel,
                value: item,
                isHead: true,
              },
              {
                label: 'Frequency',
                value: value || 0,
              },
              {
                label: 'Percentage',
                value: percent + '%',
              },

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any;
          },
        },
        enabled: false,
        external: makeTooltipStyles({
          tooltipRef,
          isCalendarIcon: false,
        }),
      },
    },
  } as ChartOptions<'doughnut'>) as ChartOptions<'doughnut'>;
};
