import html2canvas from 'html2canvas';
import { convertDatetimeToString } from './dateTime';

export const makeDownloadChartHandler = (id: string) => () => {
  html2canvas(document.getElementById(id) as HTMLCanvasElement).then((canvas) => {
    const link = document.createElement('a');
    link.download = `${id}-${convertDatetimeToString(new Date())}.png`;
    link.href = canvas.toDataURL('image/png');
    link.click();
  });
};
