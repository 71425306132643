import React, { FC } from 'react';
import classNames from 'clsx';
import { Pagination as APagination } from 'antd';
import { ReactComponent as ChevronIcon } from '../../assets/chevron.svg';
import './Pagination.scss';

type PaginationProps = {
  currentPage: number;
  total: number;
  pageSize?: number;
  onChange: (page: number) => void;
};

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  onChange,
  total,
  pageSize = 10,
}) => {
  return (
    <APagination
      className="ora-pagination"
      current={currentPage}
      total={total}
      showSizeChanger={false}
      showLessItems
      hideOnSinglePage
      itemRender={(page, type, originalElement) => {
        if (type === 'prev') {
          return (
            <ChevronIcon
              className={classNames('stroke-black w-6 -rotate-90', {
                'opacity-50': currentPage === 1,
              })}
            />
          );
        }
        if (type === 'next') {
          return (
            <ChevronIcon
              className={classNames('stroke-black w-6 rotate-90', {
                'opacity-50': currentPage === Math.ceil(total / pageSize),
              })}
            />
          );
        }
        return originalElement;
      }}
      pageSize={pageSize}
      onChange={(page, pageSize) => {
        onChange(page);
      }}
    />
  );
};
