import { Tooltip } from 'antd';

type ActionContainerProps = {
  icon: string;
  title: string;
  onClick?: () => void;
};

export const ActionContainer: React.FC<ActionContainerProps> = ({ icon, title, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="inline-flex items-center justify-center w-[3.2rem] h-[3.2rem] rounded-full bg-white cursor-pointer"
    >
      <Tooltip placement="top" title={title}>
        <img className="w-8 h-8" src={icon} alt={title} />
      </Tooltip>
    </div>
  );
};
