import { FC, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import ReactDOM from 'react-dom';
import { Modal } from '../Modal';

export const PageHelpContainer: FC = () => {
  return <div id="header-help-container" />;
};

export const PageHelpOutlet: FC = ({ children }) => {
  const node = document.getElementById('header-help-container');

  return node ? ReactDOM.createPortal(children, node) : <></>;
};

type PageHelpProps = {
  title?: string;
};

export const PageHelp: FC<PageHelpProps> = ({ title = '', children }) => {
  const [open, setOpen] = useState(false);
  return (
    <PageHelpOutlet>
      <div className="notification w-[5rem]">
        <div
          onClick={() => setOpen(true)}
          className="bell hover:bg-persianBlue hover:text-white cursor-pointer flex font-nunito font-bold font-[1.8rem] leading-[2.2rem] items-center justify-center bg-white w-[36px] h-[36px] p-2 rounded-full"
        >
          ?
        </div>
      </div>
      {open && (
        <Modal onCancel={() => setOpen(false)}>
          <div className="flex flex-col justify-between w-full h-full">
            <div className="flex flex-col justify-between">
              <div className="flex justify-between items-center">
                <div className="uppercase text-1820 font-lato font-bold">{title}</div>
                <div onClick={() => setOpen(false)} className="close cursor-pointer self-end">
                  <CloseIcon />
                </div>
              </div>
              <div className="text-[1.5rem] w-full flex flex-col justify-center items-center mt-8">
                {children}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </PageHelpOutlet>
  );
};
