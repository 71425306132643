import { FC } from 'react';

import { routesPath } from '../../constants/routes';
import { PermissionCheck } from '../../components/PermissionCheck';
import { ReactComponent as StopwatchIcon } from '../../assets/stopwatch.svg';
import { ReactComponent as TriangleCirclesIcon } from '../../assets/triangle-circles.svg';
import { ReactComponent as BarsIcon } from '../../assets/bars.svg';
import { ReactComponent as HorizontalBarsIcon } from '../../assets/horizontal-bars.svg';
import { ReactComponent as PeopleIcon } from '../../assets/people.svg';
import { ReactComponent as PeoplePatternIcon } from '../../assets/people-count-pattern.svg';
import { ReactComponent as CaseViewPatternIcon } from '../../assets/case-view-pattern.svg';
import { ReactComponent as CaseMixPatternIcon } from '../../assets/case-mix-pattern.svg';
import { ReactComponent as NomPatternIcon } from '../../assets/nom-pattern.svg';
import { ReactComponent as TempActPatternIcon } from '../../assets/temp-act-pattern.svg';
import { AnalyticsWidgets } from '../../components/AnalyticsWidget/AnalyticsWidgets';
import './animations.scss';

export const Analytics: FC = () => {
  return (
    <PermissionCheck check="readAnalytics" fallback={<div />}>
      <div className="flex flex-wrap gap-x-12 gap-y-12 mt-12">
        <AnalyticsWidgets
          title="Case Mix"
          className="triangle-circles-container"
          icon={<TriangleCirclesIcon />}
          pattern={<CaseMixPatternIcon />}
          url={routesPath.casesAnalytics.replace('/:refId', '')}
        />
        <AnalyticsWidgets
          title="Non-operative Metrics"
          className="bars-container"
          icon={<BarsIcon />}
          pattern={<NomPatternIcon />}
          url={routesPath.nonOperativeMetrics.replace('/:refId', '')}
        />
        <AnalyticsWidgets
          title="Temporal Activities"
          className="stopwatch-container"
          icon={<StopwatchIcon />}
          pattern={<TempActPatternIcon />}
          url={routesPath.temporalActivities.replace('/:refId', '')}
        />
        <AnalyticsWidgets
          title="Case View"
          className="horizontal-bars-container"
          icon={<HorizontalBarsIcon />}
          pattern={<CaseViewPatternIcon />}
          url={routesPath.caseView.replace('/:refId', '')}
        />
        <AnalyticsWidgets
          title="People Count"
          className="people-container"
          icon={<PeopleIcon />}
          pattern={<PeoplePatternIcon />}
          url={routesPath.peopleCount.replace('/:refId', '')}
        />
      </div>
    </PermissionCheck>
  );
};
