import { useState, useLayoutEffect, useCallback, useEffect } from 'react';
import { useQueryMe } from '../graphql/query/getMe';
import { Permission } from '../types';
import { GraphQLTypes } from '../zeus';
import { useMultiFilterStore } from '../components/MultiFilter/MultiFilter.store';

type OrganizationType = { name: string; refId?: string } | null | undefined;

export type UseMeValueType = {
  isInited: boolean;
  currentOrg: OrganizationType;
  data: { me: GraphQLTypes['Me'] };
  onOrgChange: (refId: string) => void;
  can: (permission: Permission | Permission[]) => boolean;
};

export const useMe = () => {
  const [isInited, setIsInited] = useState(false);
  const { data, loading } = useQueryMe();
  const [currentOrg, setCurrentOrg] = useState<OrganizationType>(null);

  const can = useCallback(
    (permission: Permission | Permission[]) => {
      if (data?.me?.role === 'superadmin') return true;

      const toCheck = Array.isArray(permission) ? permission : [permission];
      return toCheck.every((p) => !!data?.me?.current?.permissions?.includes(p));
    },
    [data],
  );

  const handleSetCurrentOrg = useCallback((refId?: string) => {
    localStorage.setItem('hospital-refId', refId ?? '');
    useMultiFilterStore.persist.setOptions({
      name: `multiFilter-storage_${refId}`,
    });
    useMultiFilterStore.persist.rehydrate();
  }, []);

  useEffect(() => {
    setCurrentOrg(data?.me?.current?.organization);
  }, [data?.me?.current?.organization]);

  useLayoutEffect(() => {
    if (loading || isInited) return;

    setIsInited(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return {
    isInited,
    currentOrg,
    data,
    can,
    onOrgChange: handleSetCurrentOrg,
  };
};
