import React, { FC, useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import triangleSvg from '../../assets/triangle.svg';
import loadingSVG from '../../assets/loading.svg';
import { ReactComponent as CloseSVG } from '../../assets/close.svg';

export interface DataPoint {
  point: ChartDataPoint;
  chart: Chart;
}
export interface DataPointTooltipProps extends DataPoint {
  onClose: (p: ChartDataPoint) => void;
}

export const DataPointTooltip: FC<DataPointTooltipProps> = ({ chart, point, onClose }) => {
  const initStyles: React.CSSProperties = {
    transition: '0.4s',
    position: 'absolute',
    opacity: 0,
  };
  const position = chart?.canvas?.getBoundingClientRect();
  const elRef = useRef<HTMLDivElement>(null);

  const [styles, setStyles] = useState(initStyles);

  useEffect(() => {
    if (!elRef.current) return;
    const height = elRef.current.offsetHeight;
    const top = position.top + window.pageYOffset + point?.y - height - 8;
    const left = `${position.left + window.pageXOffset + point?.x}px`;

    setStyles({
      ...initStyles,
      top: `${top}px`,
      left: left,
      opacity: '1',
    });
  }, [elRef.current?.style.height]);
  return (
    <div ref={elRef} style={styles}>
      <div className="w-full shadow-[0_15px_15px_#5252523c] bg-white border border-galery rounded-main relative p-4 -left-1/2">
        <CloseSVG
          onClick={() => onClose(point)}
          className="cursor-pointer w-5 h-5 absolute top-[.5rem] right-[.5rem]"
        />
        <div className="flex text-1214 font-semibold font-lato mb-2 p-2 pr-4">
          <span className="self-center">Case ID: {point.case_id}</span>
        </div>
        <div className="border-t flex justify-center">
          <img className="w-[5rem] h-[5rem]" src={loadingSVG} alt={'loading'} />
        </div>
        <img
          className="absolute bottom-[-8px] z-[11] m-auto left-0 right-0 w-4 h-4"
          alt="triangle"
          src={triangleSvg}
        ></img>
      </div>
    </div>
  );
};
