/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	CreateUserInput:{
		fullName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		speciality:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		refId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		forceResetPassword:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	ISO8601DateTime: "String",
	Mutation:{
		createOrganization:{
			input:{
				type:"OrganizationInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createPreset:{
			input:{
				type:"PresetInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createSessionWithPassword:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			password:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createSessionWithUmaToken:{
			umaToken:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createUser:{
			input:{
				type:"CreateUserInput",
				array:false,
				arrayRequired:false,
				required:true
			},
			role:{
				type:"RoleEnum",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removePreset:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		removeUser:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		resetPassword:{
			baseUrl:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		resyncAll:{
			organizationSlug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateOrganization:{
			slug:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			input:{
				type:"OrganizationInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updatePassword:{
			resetToken:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			newPassword:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		updateUser:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			input:{
				type:"UpdateUserInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			role:{
				type:"RoleEnum",
				array:false,
				arrayRequired:false,
				required:false
			},
			setDefaultPassword:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	NaiveDate: "String",
	OrganizationInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		parentOrganizationSlug:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		refId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		logoUrl:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PaginationInput:{
		page:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		perPage:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PresetInput:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	Query:{
		organizations:{
			pagination:{
				type:"PaginationInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			search:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		presets:{
			pagination:{
				type:"PaginationInput",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		user:{
			email:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		users:{
			pagination:{
				type:"PaginationInput",
				array:false,
				arrayRequired:false,
				required:false
			},
			search:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	RoleEnum: "enum",
	UpdateUserInput:{
		fullName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		},
		speciality:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		refId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		forceResetPassword:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	AvailableData:{
		from:"NaiveDate",
		to:"NaiveDate"
	},
	Current:{
		organization:"Organization",
		permissions:"String"
	},
	Me:{
		active:"Boolean",
		current:"Current",
		email:"String",
		forceResetPassword:"Boolean",
		fullName:"String",
		refId:"String",
		role:"RoleEnum",
		speciality:"String"
	},
	Mutation:{
		createOrganization:"Organization",
		createPreset:"Preset",
		createSessionWithPassword:"Session",
		createSessionWithUmaToken:"Session",
		createUser:"User",
		removePreset:"Preset",
		removeSession:"Result",
		removeUser:"Result",
		resetPassword:"Result",
		resyncAll:"Result",
		updateOrganization:"Organization",
		updatePassword:"Result",
		updateUser:"User"
	},
	Organization:{
		active:"Boolean",
		availableData:"AvailableData",
		logoUrl:"String",
		name:"String",
		parent:"Organization",
		refId:"String",
		slug:"String",
		timeZone:"String"
	},
	OrganizationsList:{
		nodes:"Organization",
		pagination:"PaginationPayload"
	},
	PaginationPayload:{
		currentPage:"Int",
		lastPage:"Int",
		nextPage:"Int",
		perPage:"Int",
		totalNodes:"Int",
		totalPages:"Int"
	},
	Preset:{
		content:"String",
		name:"String"
	},
	PresetsList:{
		nodes:"Preset",
		pagination:"PaginationPayload"
	},
	Query:{
		me:"Me",
		organizations:"OrganizationsList",
		presets:"PresetsList",
		user:"User",
		users:"UsersList"
	},
	Result:{
		message:"String"
	},
	Session:{
		expiresAt:"ISO8601DateTime",
		token:"String"
	},
	User:{
		active:"Boolean",
		email:"String",
		forceResetPassword:"Boolean",
		fullName:"String",
		refId:"String",
		role:"RoleEnum",
		speciality:"String"
	},
	UsersList:{
		nodes:"User",
		pagination:"PaginationPayload"
	}
}