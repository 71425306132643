import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { GET_GENESIS_METRICS } from '../../graphql/query/constants';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';
import { transformAllFilters } from '../../utilities/transformAllFilters';
import { makeLogData } from '../../utilities/helpers';

const logData = makeLogData();
const transformData = (data: AnalyticsGenericResponse<TemporalActivitiesKeys>) => {
  const counts = data.counts || {}; // activitiesAmount
  const means = data.means || {};
  const sds = data.sds || {};

  const df_outliers = data.df_outliers || {};
  const dataPoints = data.dataPoints || {};
  const significance = data.significance || {};
  const bubbleChart = data.bubble_charts || {};

  const phases: TemporalActivitiesKeys[] = [];
  const groups: string[] = [];
  Object.keys(means).forEach((key) => {
    const phaseName = key as TemporalActivitiesKeys;

    if (!phases.includes(phaseName)) phases.push(phaseName);
    Object.keys(means[phaseName]).forEach((groupName) => {
      if (!groups.includes(groupName)) groups.push(groupName);
    });
  });

  phases.sort();

  const result = groups.map((group, gg) => {
    const context = phases.map((phase) => {
      const averageDuration = means?.[phase]?.[group] as number;
      const activitiesAmount = counts?.[phase]?.[group] as number;
      const standardDeviation = sds?.[phase]?.[group] as number;
      const dataPointsPoints = dataPoints?.[phase]?.[group] || {};
      const _dataPoints = Object.keys(dataPointsPoints).map((key) => ({
        case_id: Number(key),
        value: dataPointsPoints?.[key],
      }));

      const significanceData = significance?.[phase] || {};
      let radius = 1;
      const bubbleData = (bubbleChart?.[phase] || {}) as BubbleChartResponse;

      const maybeIndex = (bubbleData?.cens || [])?.findIndex(
        (item: any) => item[0] === averageDuration,
      );
      if (maybeIndex !== -1) {
        radius = bubbleData?.radii?.[maybeIndex] || 1;
      }
      const xLimits = bubbleData?.xlim || [0, 0];
      const yLimits = bubbleData?.ylim || [0, 0];

      return {
        name: phase,
        averageDuration,
        activitiesAmount,
        standardDeviation,
        radius,
        xLimits,
        yLimits,
        dataPoints: _dataPoints,
        FStatistic: significanceData['F-statistic'],
        pValue: significanceData['p-value'],
        significant: significanceData.significant,
        raw: data,
      };
    });

    return { name: group === '0' ? 'all' : group, context };
  });

  return result;
};

export type TemporalActivitiesSourceData = ReturnType<typeof transformData>;

const makeQueryTemporalActivities = async (params: Record<string, any>) => {
  const { data } = await RESTClient.get<AnalyticsGenericResponse<TemporalActivitiesKeys>>(
    '/temporal-activities',
    { params },
  );

  return data;
};

export const useQueryTemporalActivities = (variables: QueryAnalyticsArgs) => {
  const organization = useSLUGName();
  const params = useMemo(
    () => ({
      from: variables.from,
      to: variables.to,
      ...(organization ? { organization } : {}),
      split: variables.split || undefined,
      ...(variables.filters || {}),
    }),
    [variables],
  );

  const processor = useCallback(() => makeQueryTemporalActivities(params), [params]);
  const { data, isLoading: loading, refetch } = useQuery([GET_GENESIS_METRICS, params], processor);
  logData('/temporal-activities(' + JSON.stringify(params) + ')', data);

  const result = useMemo(() => {
    const res = transformData((data || {}) as AnalyticsGenericResponse<TemporalActivitiesKeys>);
    const arr = [...res];
    const restIndex = arr.findIndex((item) => /rest/i.test(item.name));
    const restItem = arr.splice(restIndex, 1);
    arr.push(...restItem);

    return arr;
  }, [data]);

  const allFilters = useMemo(() => transformAllFilters(data?.all_filters), [data?.all_filters]);

  return { data: result, rawData: data, allFilters, loading, refetch };
};
