import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FiltersPanelTabs, FiltersPanelTabsButtons } from './FiltersPanelTabs';
import { additionalOptions } from '../MultiFilter/mocks';
import { PanelFilter } from './FiltersPanelSection';
import {
  MultiFilterReportName,
  MultiFilterStateContextType,
  SelectedFilterKeys,
} from '../MultiFilter/MultiFilters.types';
import { FiltersPanelHeader } from './FiltersPanelHeader';
import { Drawer } from '../Drawer';
import { FiltersPanelAllFilters } from './FiltersPanelAllFilters';
import { FiltersPanelSavedFilters, optionsKeys } from './FiltersPanelSavedFilters';
import { FiltersOptions, OptionsKeys } from './types';
import _, { debounce } from 'lodash';

export type FiltersPanelProps = {
  reportName: MultiFilterReportName;
  isLoading?: boolean;
  hideFilters?: SelectedFilterKeys[];
  availableFilters?: Record<AvailableFilterKeys, string[]>;
  onClosePanel: () => void;
  alwaysGroupBy?: boolean;
  applyFilters: (filters: MultiFilterStateContextType) => void;
  drawerOpen: boolean;
  filtersState: MultiFilterStateContextType;
  filters: FiltersOptions;
};

const makeAdditionalFilters = (
  filtersState?: MultiFilterStateContextType,
  hideFilters?: SelectedFilterKeys[],
) => {
  return additionalOptions
    .filter((f) => {
      if (hideFilters?.includes(f.value as SelectedFilterKeys)) {
        return false;
      }
      return true;
    })
    .map((a) => ({
      label: a.label,
      value: a.value,
      checked: (filtersState as any)[a.value],
    }));
};

const mapper: Record<OptionsKeys, AvailableFilterKeys> = {
  casesOptions: 'case_number',
  modalitiesOptions: 'modality',
  weekOptions: 'weekday',
  roomsOptions: 'OR',
  surgeonsOptions: 'surgeon_id',
  specialtiesOptions: 'specialty_code',
  proceduresOptions: 'procedure_code',
  compareOptions: 'surgery_name',
  dateRange: 'surgery_name',
  allRoboticDayOptions: 'all_robotic',
  casesPerDayOptions: 'cases_per_day',
  sameSurgeonOptions: 'same_surgeon',
};

const makeFiltersList = (
  filters: FiltersOptions,
  state: MultiFilterStateContextType,
  availableFilters: Record<AvailableFilterKeys, string[]>,
  selectedFilterCategories: OptionsKeys[],
  availableFiltersHistory: Record<AvailableFilterKeys, string[]>[],
) => {
  const res = Object.keys(filters).reduce((r, key) => {
    const k = key as OptionsKeys;
    const stateKey = optionsKeys[key as OptionsKeys] as keyof MultiFilterStateContextType;
    if (!stateKey) {
      // eslint-disable-next-line no-console
      console.error('stateKey - is not recognized', key);
    }
    const savedFilter = state[stateKey || 'rooms'];

    const filterListBool = (filters[k] || []).map((f) => {
      const categoryArray =
        key === selectedFilterCategories[selectedFilterCategories.length - 1]
          ? availableFiltersHistory[availableFiltersHistory.length - 1]?.[mapper[k]]
          : availableFilters?.[mapper[k]];

      const val = f.value === 'include' ? 'true' : f.value === 'exclude' ? 'false' : f.value;

      const containValue = categoryArray?.map((e) => String(e))?.includes(val as string);
      const disabled = !containValue;

      const isArrayAndInclude =
        Array.isArray(savedFilter) && savedFilter.includes(f.value as any) && !disabled;
      const isNotNullAndEq = savedFilter !== null && savedFilter === f.value && !disabled;
      return {
        label: f.label,
        value: f.value,
        disabled: disabled && !['sameSurgeon', 'allRoboticDay'].includes(stateKey),
        checked: isArrayAndInclude || isNotNullAndEq || false,
      };
    });

    return { ...r, [key]: filterListBool };
  }, {}) as Record<OptionsKeys, PanelFilter[]>;

  res.dateRange = [
    {
      checked: true,
      label: 'Date Range',
      value: Array.isArray(state.dateRange) ? state.dateRange[0] : state.dateRange,
    },
  ];

  return res;
};

export const FiltersPanel: FC<FiltersPanelProps> = ({
  reportName,
  filters,
  isLoading,
  availableFilters,
  hideFilters,
  filtersState,
  drawerOpen,
  alwaysGroupBy,
  onClosePanel,
  applyFilters,
}) => {
  const additionalFilters = useMemo(
    () => makeAdditionalFilters(filtersState, hideFilters),
    [filtersState, hideFilters],
  );

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [selectedFilterCategories, setSelectedFilterCategories] = useState<OptionsKeys[]>([]);
  const [availableFiltersHistory, setAvailableFiltersHistory] = useState<
    Record<AvailableFilterKeys, string[]>[]
  >([]);
  const [currentFiltersState, setCurrentFiltersState] = useState(filtersState);
  const [currentAdditionalFiltersState, setCurrentAdditionalFiltersState] =
    useState<PanelFilter[]>(additionalFilters);

  const filtersList = useMemo(() => {
    return makeFiltersList(
      filters,
      currentFiltersState,
      availableFilters || ({} as any),
      selectedFilterCategories,
      availableFiltersHistory,
    );
  }, [
    filters,
    currentFiltersState,
    availableFilters,
    availableFiltersHistory,
    selectedFilterCategories,
  ]);

  const onResetFilters = useCallback(() => {
    const newState = Object.keys(filtersState).reduce((r, key) => {
      const k = key as keyof MultiFilterStateContextType;

      if (k === 'compare' && alwaysGroupBy) {
        return {
          ...r,
          [k]: filtersState[k],
        };
      }
      return { ...r, [k]: Array.isArray(filtersState[k]) ? [] : null };
    }, {}) as MultiFilterStateContextType;
    setCurrentFiltersState(newState);
    applyFilters(newState);

    setCurrentAdditionalFiltersState(additionalFilters.map((a) => ({ ...a, checked: false })));

    setSelectedFilterCategories([]);
  }, [additionalFilters, alwaysGroupBy, applyFilters, filtersState]);

  const onCancel = useCallback(() => {
    setCurrentFiltersState(filtersState);
    setCurrentAdditionalFiltersState(additionalFilters);
    onClosePanel();
  }, [additionalFilters, filtersState, onClosePanel]);

  const applyPreset = useCallback(
    (filters: MultiFilterStateContextType) => {
      const newAdditionalFilters = additionalFilters.map((a) => ({
        ...a,
        checked: (filters as any)[a.value],
      }));
      setCurrentAdditionalFiltersState(newAdditionalFilters);

      let _filters = filters;

      if (alwaysGroupBy) {
        _filters = {
          ...filters,
          compare: filtersState.compare,
        };
      }

      if ((_filters.dateRange as any) instanceof Array) {
        _filters = {
          ..._filters,
          dateRange: _filters.dateRange[0],
        };
      }

      setCurrentFiltersState(_filters);
      applyFilters(_filters);
    },
    [additionalFilters, alwaysGroupBy, applyFilters, filtersState],
  );

  const deleteSelectedFilter = useCallback(
    (name: keyof MultiFilterStateContextType, val: string) => {
      const filters = currentFiltersState[name];
      if (Array.isArray(filters)) {
        setCurrentFiltersState({
          ...currentFiltersState,
          [name]: filters.filter((f) => f !== val),
        });
      } else {
        setCurrentFiltersState({
          ...currentFiltersState,
          [name]: null,
        });
      }

      setCurrentAdditionalFiltersState((prevState) =>
        prevState.map((a) => {
          if (a.value === name) {
            return { ...a, checked: false };
          }
          return a;
        }),
      );
    },
    [currentFiltersState],
  );

  const updateFilters = useCallback(
    (name: keyof MultiFilterStateContextType) => (arr: PanelFilter[]) => {
      const k = _.invert(optionsKeys)[name] as OptionsKeys;

      if (!selectedFilterCategories.includes(k) && arr.some((v) => v.checked)) {
        setSelectedFilterCategories((prev) => [...prev, k]);
        if (availableFilters) {
          setAvailableFiltersHistory((prev) => [...prev, availableFilters]);
        }
      } else if (!arr.some((v) => v.checked)) {
        setSelectedFilterCategories((prev) => prev.filter((v) => v !== k));

        if (availableFiltersHistory.length > 0) {
          setAvailableFiltersHistory((prev) => prev.slice(0, prev.length - 1));
        }
      }

      const _state = {
        ...currentFiltersState,
        [name]: arr.filter((v) => v.checked).map((v) => v.value),
      };

      const newState = {
        ..._state,
        dateRange: Array.isArray(_state?.dateRange) ? _state.dateRange[0] : _state.dateRange || '',
        ...currentAdditionalFiltersState.reduce(
          (acc, opt) => ({
            ...acc,
            [opt.value as string]: opt.checked,
          }),
          {},
        ),
      };

      setCurrentFiltersState({
        ...newState,
      });

      const hasSelectedFilters = Object.keys(newState).some((key) => {
        if (key === 'dateRange' || key === 'compare') {
          return false;
        }

        const category = newState[key as keyof MultiFilterStateContextType];

        return Array.isArray(category) ? category.length > 0 : !!category;
      });

      if (!hasSelectedFilters) {
        setSelectedFilterCategories([]);
      }

      applyFilters(newState);
    },
    [
      applyFilters,
      availableFilters,
      availableFiltersHistory.length,
      currentAdditionalFiltersState,
      currentFiltersState,
      selectedFilterCategories,
    ],
  );

  const updateCurrentAdditionalFiltersState = useCallback(
    (vars: PanelFilter[]) => {
      const _state = {
        ...currentFiltersState,
      };

      const newState = {
        ..._state,
        dateRange: Array.isArray(_state?.dateRange) ? _state.dateRange[0] : _state.dateRange || '',
        ...vars.reduce(
          (acc, opt) => ({
            ...acc,
            [opt.value as string]: opt.checked,
          }),
          {},
        ),
      };

      setCurrentFiltersState({
        ...newState,
      });

      setCurrentAdditionalFiltersState(vars);

      applyFilters(newState);
    },
    [applyFilters, currentFiltersState],
  );

  useEffect(() => {
    const additionalFilters = makeAdditionalFilters(filtersState, hideFilters);

    setCurrentAdditionalFiltersState(additionalFilters);
  }, [filtersState, hideFilters]);

  useEffect(() => {
    setCurrentFiltersState(filtersState);
  }, [filtersState]);

  return (
    <Drawer
      width={550}
      destroyOnClose
      title={<FiltersPanelHeader onResetFilters={onResetFilters} />}
      visible={drawerOpen}
      onClose={onCancel}
      bodyStyle={{
        padding: 0,
        opacity: isLoading ? 0.3 : 1,
        paddingLeft: '2rem',
        paddingRight: '2rem',
      }}
      headerStyle={{ background: '#a5bef533', borderTop: '8px solid #A5BEF5' }}
    >
      {isLoading && <div className="bg-error w-full h-full absolute z-20 opacity-0"></div>}
      <div className="flex flex-col h-full">
        <FiltersPanelTabs
          tabs={FiltersPanelTabsButtons}
          activeIndex={currentTabIndex}
          onChange={setCurrentTabIndex}
        >
          <FiltersPanelAllFilters
            filtersList={filtersList}
            updateFilters={updateFilters}
            additionalFilters={currentAdditionalFiltersState}
            onAdditionalFiltersChange={(data) => {
              updateCurrentAdditionalFiltersState(data);
            }}
          />
          <FiltersPanelSavedFilters
            reportName={reportName}
            onApplyPreset={applyPreset}
            shouldHaveCompare={alwaysGroupBy}
            additionalFilters={currentAdditionalFiltersState}
            onDeleteSelectedFilter={deleteSelectedFilter}
            onAddClick={() => setCurrentTabIndex(0)}
            filtersList={filtersList}
          />
        </FiltersPanelTabs>
      </div>
    </Drawer>
  );
};
