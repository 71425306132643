import { useEffect, useState } from 'react';

export const ASPECT_RATIO = {
  '1024': 2.462,
  '1280': 2.322,
  '1440': 2.241,
  '1920': 2.192,
} as const;

export const useAspectRatioForBubbles = () => {
  const [aspectRation, setAspectRation] = useState<number>(ASPECT_RATIO[1440]);

  function getAspectRatio() {
    let ratio: number = ASPECT_RATIO['1440'];
    if (window.innerWidth >= 1920) {
      ratio = ASPECT_RATIO['1920'];
    } else if (window.innerWidth <= 1440 && window.innerWidth > 1280) {
      ratio = ASPECT_RATIO['1440'];
    } else if (window.innerWidth <= 1280 && window.innerWidth > 1024) {
      ratio = ASPECT_RATIO['1280'];
    } else {
      ratio = ASPECT_RATIO['1024'];
    }

    setAspectRation(ratio);
  }

  useEffect(() => {
    window.addEventListener('resize', getAspectRatio);

    return () => {
      window.removeEventListener('resize', getAspectRatio);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    getAspectRatio();
  }, []);

  return aspectRation;
};
