import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'clsx';
import { Chart } from 'chart.js';
import triangleSvg from '../../assets/triangle.svg';

export interface CaseTooltipProps {
  coordinates: {
    x: number;
    y: number;
  };
  chart: Chart;
  position?: 'top' | 'right';
}

const initStyles: React.CSSProperties = {
  transition: '0.4s',
  position: 'absolute',
  opacity: 0,
};

export const CaseTooltip: FC<CaseTooltipProps> = ({
  chart,
  coordinates,
  children,
  position = 'top',
}) => {
  const chartPosition = chart?.canvas?.getBoundingClientRect();
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [styles, setStyles] = useState(initStyles);

  useEffect(() => {
    if (!tooltipRef.current) return;
    const height = tooltipRef.current.offsetHeight;
    const width = tooltipRef.current.offsetWidth;
    let top = 0;
    let left = '0px';

    if (position === 'top') {
      top =
        chartPosition.top +
        document.body.getBoundingClientRect().top +
        Number(coordinates?.y) -
        height -
        8;
      left = `${
        chartPosition.left + document.body.getBoundingClientRect().left + Number(coordinates?.x)
      }px`;
    }

    if (position === 'right') {
      top =
        chartPosition.top +
        document.body.getBoundingClientRect().top +
        Number(coordinates?.y) -
        height / 2;
      left = `${
        chartPosition.left +
        document.body.getBoundingClientRect().left +
        Number(coordinates?.x) +
        width / 2 +
        30
      }px`;
    }
    setStyles({
      ...initStyles,
      top: top,
      left: left,
      opacity: '1',
    });
  }, [
    tooltipRef.current?.style.height,
    chartPosition.left,
    chartPosition.top,
    position,
    coordinates,
  ]);
  /*

  */

  return (
    <div ref={tooltipRef} style={styles}>
      <div className="w-full min-w-[23rem] min-h-[18rem] shadow-[0_15px_15px_#5252523c] bg-white border border-galery rounded-main relative  p-4 -left-1/2">
        <div className="flex text-1214 tracking-wider font-semibold font-lato mb-2">
          <span className="self-center"></span>
        </div>
        {children}
        <img
          className={classNames('absolute  z-[11] m-auto  w-4 h-4', {
            'left-0 right-0 bottom-[-8px]': position === 'top',
            'top-0 bottom-0 rotate-90 left-[-8px]': position === 'right',
          })}
          alt="triangle"
          src={triangleSvg}
        ></img>
      </div>
    </div>
  );
};
