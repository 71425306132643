import { Skeleton } from 'antd';
import React, { useState, FC, useMemo } from 'react';

import { Value, Option } from './types';
import { FilterOverlay } from '../FilterOverlay';
import { useSelect } from '../../hooks/useSelect';
import { FilterContainer } from '../FilterContainer';
import checkSvg from '../../assets/Check-dropdown.svg';
import { Input, List, ListItem, DropdownContainer } from './styled';

type SelectorViewProps = {
  active: boolean;
  isOpen: boolean;
  stretch?: boolean;
  label: React.ReactNode;
  searchText: string;
  showSearch: boolean;
  shownOptions: Option[];
  container?: React.ComponentType;
  setIsOpen: (isOpen: boolean) => () => void;
  calcIsActive: (option: Value) => boolean;
  setSearchText: (searchText: string) => void;
  onChange: (option: Option) => void;
};

const SelectorView: FC<SelectorViewProps> = ({
  active,
  isOpen,
  setIsOpen,
  label,
  stretch,
  searchText,
  showSearch,
  shownOptions,
  container: Container = FilterContainer,
  calcIsActive,
  setSearchText,
  onChange,
}) => {
  return (
    <Container label={label} open={isOpen} active={active} onClick={setIsOpen(!isOpen)}>
      {isOpen && (
        <>
          <FilterOverlay onClick={setIsOpen(false)} />
          <DropdownContainer stretch={stretch}>
            {!!showSearch && (
              <Input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                allowClear
              />
            )}
            <List stretch={stretch}>
              {shownOptions.map(({ label, value: optionValue }) => {
                const onClick = () => onChange({ label, value: optionValue });

                const isActive = calcIsActive(optionValue);

                return (
                  <ListItem
                    key={optionValue}
                    onClick={onClick}
                    isBigText={label.length > 15}
                    $isActive={isActive}
                  >
                    {label}
                    <img
                      className={`${isActive ? 'inline' : 'hidden'} absolute right-4 top-4`}
                      src={checkSvg}
                      alt="logo"
                    />
                  </ListItem>
                );
              })}
            </List>
          </DropdownContainer>
        </>
      )}
    </Container>
  );
};

export const Selector: FC<{
  loading?: boolean;
  options: Option[];
  onSelect: (option: Option) => void;
  pickedData: Value;
  notFoundLabel?: React.ReactNode;
  stretch?: boolean;
  withSearch?: boolean;
  container?: React.ComponentType;
}> = ({
  pickedData,
  loading,
  options = [],
  onSelect,
  notFoundLabel = 'EMPTY',
  stretch = false,
  withSearch = false,
  container,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const { isOpen, setIsOpen } = useSelect();

  const showSearch = withSearch && options.length > 5;

  const pickedOption = useMemo(() => {
    return options.find((o) => o.value === pickedData);
  }, [options, pickedData]);

  const shownOptions = useMemo(() => {
    if (!showSearch || !searchText) return options;

    // case insensitive compare
    const regex = new RegExp(searchText, 'i');
    // o.value === null stands for "All Options"
    return options.filter((o) => regex.test(o.label) || o.value === null);
  }, [showSearch, searchText, options]);

  const onChange = (option: Option) => {
    onSelect(option);
    setIsOpen(false)();
  };

  if (loading) {
    return <Skeleton.Input className="skeleton" active />;
  }

  return (
    <SelectorView
      isOpen={isOpen}
      active={!!pickedData}
      label={pickedOption?.label ?? notFoundLabel ?? ''}
      searchText={searchText}
      setIsOpen={setIsOpen}
      setSearchText={setSearchText}
      shownOptions={shownOptions}
      calcIsActive={(option: Value) => option === pickedData}
      onChange={onChange}
      showSearch={showSearch}
      container={container}
      stretch={stretch}
    />
  );
};

export const SelectorMulti: FC<{
  loading?: boolean;
  options: Option[];
  onSelect: (option: Option[]) => void;
  pickedData: Value[];
  limit?: number;
  notSelectedLabel?: React.ReactNode;
  notFoundLabel?: React.ReactNode;
  showOptionLabel?: boolean;
  withSearch?: boolean;
}> = ({
  pickedData,
  loading,
  options = [],
  onSelect,
  showOptionLabel,
  limit = 999,
  notSelectedLabel = 'SELECT FEW OPTIONS',
  withSearch = false,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const { isOpen, setIsOpen } = useSelect();

  const showSearch = withSearch && options.length > 5;

  const shownOptions = useMemo(() => {
    if (!showSearch || !searchText) return options.filter((o) => o.value);

    // case insensitive compare
    const regex = new RegExp(searchText, 'i');
    // o.value === null stands for "All Options"
    return options.filter((o) => regex.test(o.label) || o.value === null);
  }, [showSearch, searchText, options]);

  const onChange = (option: Option) => {
    if (option.value === null) {
      onSelect([]);
      setIsOpen(false)();
      return;
    }
    let newPickedData: Value[] = [];
    if (pickedData.includes(option.value)) {
      newPickedData = pickedData.filter((o) => o !== option.value);
    } else {
      if (pickedData.length < limit) {
        newPickedData = [...pickedData, option.value];
      } else {
        const p = [...pickedData];
        p.pop();
        newPickedData = [...p, option.value];
      }
    }
    const opts = options.filter((o) => newPickedData.includes(o.value));
    onSelect(opts);
    if (opts.length === limit) {
      setIsOpen(false)();
    }
  };

  if (loading) {
    return <Skeleton.Input className="skeleton" active />;
  }

  const pickedOption = (pickedData || []).map(
    (o) => options.find((oo) => oo.value === o) || { label: 'notFoundLabel' ?? '' },
  );

  const label =
    pickedData?.length > 0
      ? showOptionLabel
        ? pickedOption[0].label + '...'
        : `Selected ${pickedData?.length} options`
      : notSelectedLabel;

  return (
    <SelectorView
      active={!!pickedData?.length}
      isOpen={isOpen}
      label={label}
      searchText={searchText}
      setIsOpen={setIsOpen}
      setSearchText={setSearchText}
      shownOptions={shownOptions}
      calcIsActive={(optionValue: Value) => pickedData.includes(optionValue)}
      onChange={onChange}
      showSearch={showSearch}
    />
  );
};

export type { Option, Value } from './types';
