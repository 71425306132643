import { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { routesPath } from '../constants/routes';
import { Auth } from '../page/auth';
import { AuthType } from '../page/auth/types';
import { PrivateRoute } from './privateRoute';
import { UserContextProvider } from '../context';
import { ROUTES } from './routes';
import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';

const RoutesWithAutorizedUser = () => {
  return (
    <UserContextProvider>
      <Layout>
        <ErrorBoundary>
          {ROUTES.map((item) => {
            return (
              <PrivateRoute
                path={item.path}
                exact={item.exact}
                key={item.path}
                pageTitle={item.pageTitle}
                routeComponent={item.component}
                permission={item.permission}
              />
            );
          })}
        </ErrorBoundary>
      </Layout>
    </UserContextProvider>
  );
};

export const Routes: FC = () => {
  return (
    <Switch>
      <Route path={routesPath.login} exact>
        <Auth type={AuthType.LOGIN} />
      </Route>
      <Route path={routesPath.resetPassword} exact>
        <Auth type={AuthType.RESET_PASSWORD} />
      </Route>
      <RoutesWithAutorizedUser />
    </Switch>
  );
};
