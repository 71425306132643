import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'clsx';
import { getQuery } from '../../utilities/getQuery';
import { BarChart } from '../../components/BarChart/BarChart';
import { PermissionCheck } from '../../components/PermissionCheck';
import { MultiFilter } from '../../components/MultiFilter/MultiFilter';

import { useQueryGenesisMetrics } from '../../rest/query/useQueryGenesisMetrics';
import { routesPath } from '../../constants/routes';
import { PageTitle } from '../../components/PageTitle';
import { GenesisTable, generateXLSX } from '../../components/GenesisTable/GenesisTable';
import { DownloadButton } from '../../components/DownloadButton';
import { BubbleChart } from '../../components/BubbleChart/BubbleChart';
import { chartId, displayOptions, useControls, tableId } from './useControls';
import { useBarChartEngine } from '../../components/BarChart/useBarChartEngine';
import { ChartReportFooterLabels } from '../../components/ChartReportFooterLabels/ChartReportFooterLabels';
import { ReportFilterSlot } from '../../components/ReportFilterSlot/ReportFilterSlot';
import { ChartViewSwitch } from '../../components/ChartViewSwitch/ChartViewSwitch';
import { useBubbleChartEngine } from '../../components/BubbleChart/useBubbleChartEngine';
import { ChartViewTabs } from '../../components/ChartViewTabs';
import { genesisLabels } from '../../constants/analyticsLabels';
import { useMultiFilterStore } from '../../components/MultiFilter/MultiFilter.store';
import { BarChartReportSkeleton } from '../../components/BarChartReportSkeleton/BarChartReportSkeleton';
import { makeQuery } from '../../utilities/helpers';
import { capitalize, debounce } from 'lodash';
import { DataPoint } from '../../components/DataPointTooltip/DataPointTooltip';
import { createPortal } from 'react-dom';
import { Chart } from 'chart.js';

import { PhaseSelectbox } from '../../components/PhaseSelectbox/PhaseSelectbox';
import { SuggestedViewsSelectbox } from './../../components/SuggestedViewsSelectbox/SuggestedViewsSelectbox';
import { useSuggestedView } from '../../rest/query/useQuerySuggestedViews';
import { ChartViewButtons } from '../../components/ChartViewButtons/ChartViewButtons';
import { PageHelp } from '../../components/PageHelp/PageHelp';
import { HelpDescription } from './HelpDescription';
import { useQueryCase } from '../../rest/query/useQueryCase';
import { CaseTooltip } from '../../components/CaseTooltip/CaseTooltip';
import { CaseTooltipContent } from '../../components/CaseTooltipContent/CaseTooltipContent';
import { useHistory } from '../../hooks/useHistory';
import { useAppContext } from '../../context/appContext';
import { useAspectRatioForBubbles } from '../../hooks/useAspectRatioForBubbles';

const REPORT_NAME = 'nonOperativeMetrics';

const createRoute = (caseKey: string) => {
  return `${routesPath.caseCalendar.replace('/:refId', '').replace(':caseId', caseKey)}`;
};

export const GenesisAnalytics: FC = () => {
  const history = useHistory();
  const { sidebarCollapsed } = useAppContext();
  const aspect_ratio = useAspectRatioForBubbles();
  const dateRanges = getQuery(history);
  const multiFilterStore = useMultiFilterStore();
  const filtersState = multiFilterStore[REPORT_NAME];
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openedDataPoint, setOpenedDataPoint] = useState<DataPoint | undefined>();

  const compareBy = filtersState.compare;

  const {
    displayOptionList,
    switchChartViews,
    resetChartViews,
    downloadChart,
    view,
    onViewChange,
    onPhaseChange,
    currentPhase,
  } = useControls();

  const currentQuery = useMemo(
    () => makeQuery(dateRanges, filtersState),
    [dateRanges, filtersState],
  );

  const {
    data: sourceData,
    rawData,
    allFilters,
    loading,
  } = useQueryGenesisMetrics<GenesisKeys>({ ...currentQuery, aspect_ratio }, null);

  const bubble_sourceData =
    view === 'Bubble View'
      ? sourceData.filter((d) => d.context.filter((c) => c.name === currentPhase)[0].radius > 0)
      : sourceData;

  const caseId = openedDataPoint?.point.case_id;

  const { meta: caseData, loading: caseLoading } = useQueryCase(String(caseId), !!caseId);

  const { setSuggestedView, suggestedViews, suggestedView } = useSuggestedView(
    REPORT_NAME,
    view,
    allFilters.procedures,
    allFilters.specialties,
  );

  const phaseOptions = useMemo(() => {
    return sourceData?.[0]?.context?.map((s) => ({
      label: capitalize(genesisLabels[s.name]),
      value: s.name,
    }));
  }, [sourceData]);

  const { filtersBar, panel, selectedFilters } = MultiFilter({
    reportName: REPORT_NAME,
    drawerOpen,
    isLoading: loading,
    view,
    allFilters,
    availableFilters: rawData?.available_filters,
    leftFiltersBarSlot: (
      <div className="flex">
        <SuggestedViewsSelectbox
          loading={loading}
          options={suggestedViews}
          view={suggestedView}
          onSelectView={setSuggestedView}
        />
        {view !== 'Bubble View' ? undefined : (
          <PhaseSelectbox
            options={phaseOptions}
            phase={currentPhase}
            onSelectPhase={onPhaseChange}
          />
        )}
      </div>
    ),
    rightFiltersBarSlot: (
      <ChartViewTabs
        enabledList={['Bar View', 'Bubble View', 'List View']}
        disabledOptions={!compareBy || compareBy === 'national_statistics' ? ['Bubble View'] : []}
        defaultItem={view || 'Bar View'}
        onChange={onViewChange}
      />
    ),
    alwaysGroupBy: view === 'Bubble View',
    onOpenPanel: () => setDrawerOpen(true),
    onClosePanel: () => setDrawerOpen(false),
    includeSplitOptions:
      view === 'Bubble View'
        ? []
        : [
            {
              label: 'National Statistics',
              value: 'national_statistics',
            },
          ],
  });

  const downloadXLSX = useCallback(() => {
    generateXLSX({
      compareBy,
      allFilters,
      tableId,
      sourceData,
      labels: genesisLabels,
    });
  }, [sourceData, allFilters, compareBy]);

  const openDataPoint = debounce((point: ChartDataPoint, chart: Chart) => {
    setOpenedDataPoint({ point, chart });
  }, 500);

  const closeDataPoint = useCallback(() => {
    setOpenedDataPoint(undefined);
  }, []);

  const { barChartOptions, getBarChartData } = useBarChartEngine({
    transformKeys: (val, significant) =>
      genesisLabels[val] +
      (significant !== null && displayOptionList.includes('statisticalSignificance')
        ? ` (${significant ? 'sig' : 'insig'})`
        : ''),
    compareBy,
    displayOptionList,
    sourceData,
    shouldSkipEmptyValues: false,
    allFilters,
    extendOptions: {
      plugins: {
        dataPoints: {
          onMouseEnter: openDataPoint,
          onMouseLeave: closeDataPoint,
          onClick: (point) => {
            if (!point) return;
            const route = createRoute(point.case_id + '');
            history.push(route);
          },
        },
        clickOnTick: {
          x: {
            enabled: true,
            shouldHover() {
              return !(!compareBy || compareBy === 'national_statistics');
            },
            onClick: (label, b, datasets: any[]) => {
              if (!compareBy || compareBy === 'national_statistics') {
                return;
              }
              const dataset = datasets?.[0];

              const x = dataset?.phaseNames?.[label?.index as number];
              if (label?.scaleId === 'x' && x) {
                onViewChange('Bubble View');
                onPhaseChange(x);
              }
            },
          },
          y: {
            enabled: false,
          },
        },
      },
    },
  });
  const { bubbleChartOptions, getBubbleData } = useBubbleChartEngine({
    currentPhase,
    compareBy,
    displayOptionList,
    sourceData: bubble_sourceData,
    allFilters,
  });

  useEffect(() => {
    if (view === 'Bubble View' || view === 'Horizontal Bar View') {
      resetChartViews();
    }
  }, [view]);

  useEffect(() => {
    if (!displayOptionList.includes('dataPoints')) {
      setOpenedDataPoint(undefined);
    }
  }, [displayOptionList]);

  const footer = (
    <ChartReportFooterLabels
      compareBy={compareBy}
      sourceData={bubble_sourceData}
      allFilters={allFilters}
    />
  );

  const renderBubbleChart = () => (
    <BubbleChart
      id={chartId}
      // bubbleKey={currentPhase}
      data={getBubbleData() as any}
      filterSlot={
        <ReportFilterSlot
          displayOptions={displayOptions}
          hideOptions
          onSwitchChartViews={switchChartViews}
          displayOptionList={displayOptionList}
          selectedFilters={selectedFilters}
          onDownloadChart={downloadChart}
        />
      }
      options={bubbleChartOptions}
      footer={footer}
    />
  );

  const renderBarChart = () => (
    <BarChart
      id={chartId}
      data={getBarChartData() as any}
      filterSlot={
        <ReportFilterSlot
          displayOptions={displayOptions
            .map((d) => ({
              ...d,
              label:
                d.value === 'dataPoints' && filtersState.excludeOutliers ? 'Outliers' : d.label,
              disabled: d.value === 'statisticalSignificance' && !compareBy,
            }))
            .filter(
              (d) =>
                (compareBy === 'national_statistics' &&
                  ['sampleSize', 'deviation'].includes(d.value)) ||
                compareBy !== 'national_statistics',
            )}
          onSwitchChartViews={switchChartViews}
          displayOptionList={displayOptionList}
          selectedFilters={selectedFilters}
          onDownloadChart={downloadChart}
        />
      }
      options={barChartOptions}
      footer={footer}
    />
  );

  const renderList = () => (
    <div>
      <div className="flex justify-between items-center">
        <div className="p-10">{selectedFilters}</div>
        <div className={classNames('mr-10')}>
          <div className="flex justify-between">
            <ChartViewButtons
              displayOptionList={displayOptionList}
              displayOptions={displayOptions
                .map((d) => ({
                  ...d,
                  disabled: d.value === 'statisticalSignificance' && !compareBy,
                }))
                .filter((d) => ['statisticalSignificance', 'sampleSize'].includes(d.value))}
              onSwitchChartViews={switchChartViews}
            />
            <DownloadButton title="Download Excel" onClick={downloadXLSX} />
          </div>
        </div>
      </div>
      <div
        className={classNames('whitespace-nowrap overflow-auto transition-all', {
          'w-[calc(100vw-200px)]': sidebarCollapsed,
          'w-[calc(100vw-320px)]': !sidebarCollapsed,
        })}
      >
        <GenesisTable<GenesisKeys>
          tableId={tableId}
          loading={loading}
          showSize={displayOptionList.includes('sampleSize')}
          transformKeys={(val, significant) =>
            genesisLabels[val] +
            (significant !== null && displayOptionList.includes('statisticalSignificance')
              ? ` (${significant ? 'sig' : 'insig'})`
              : '')
          }
          data={sourceData}
          compareBy={compareBy}
          allFilters={allFilters}
        />
      </div>
    </div>
  );
  return (
    <PermissionCheck check="readAnalytics" fallback={<div />}>
      <PageTitle
        header="Non-Operative Metrics"
        crumbs={[{ title: 'Analytics', url: routesPath.analytics.replace('/:refId', '') }]}
      />
      <PageHelp title="Non-Operative Metrics">
        <HelpDescription />
      </PageHelp>

      <div className="relative">
        {panel}
        {filtersBar}
        <div className="mt-10 flex"></div>

        <div className="bg-white shadow-sm mt-10">
          <ChartViewSwitch
            skeleton={<BarChartReportSkeleton />}
            view={view}
            defaultView="Bar View"
            loading={loading}
            bubble={renderBubbleChart}
            bar={renderBarChart}
            list={renderList}
          />
        </div>
        {createPortal(
          <div className="">
            {caseId && openedDataPoint && (
              <CaseTooltip
                chart={openedDataPoint.chart}
                coordinates={{
                  x: openedDataPoint.point.x,
                  y: openedDataPoint.point.y,
                }}
              >
                <CaseTooltipContent
                  caseId={caseId + ''}
                  caseData={caseData}
                  loading={caseLoading}
                />
              </CaseTooltip>
            )}
          </div>,
          document.body,
        )}
      </div>
    </PermissionCheck>
  );
};
