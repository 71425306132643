import { useQuery } from '../../hooks/useQuery';
import { $ } from '../../zeus';
import { GET_USER } from './constants';

export const useQueryUser = (email: string) => {
  const { data, loading: isLoading } = useQuery(
    {
      user: [
        { email: $`email` },
        {
          email: true,
          role: true,
          active: true,
          fullName: true,
          refId: true,
          speciality: true,
          forceResetPassword: true,
        },
      ],
    },
    {
      operationName: GET_USER,
    },
    {
      variables: {
        email,
      },
    },
  );

  return { data, loading: isLoading };
};
