import { addDays, isEqual } from 'date-fns';

import { isOffRange } from './isOffRange';
import type { IBubbleEvent } from './types';

/**
 * function allows us to paint event on every day, even if it's empty
 * @param events
 */
export const wrapWithEmptyDays = (events: IBubbleEvent[], date?: Date | string) => {
  const selectedDate = new Date(date as any) ?? new Date();
  selectedDate.setHours(0, 0, 0, 0);

  const startDate = addDays(selectedDate, -40);
  const endDate = addDays(selectedDate, 40);

  const res: IBubbleEvent[] = [];
  for (let currentDate = startDate; currentDate < endDate; currentDate = addDays(currentDate, 1)) {
    const event: IBubbleEvent = events.find((e) => isEqual(e.start!, currentDate)) ?? {
      start: currentDate,
      end: currentDate,
      empty: true,
    };

    const offRange = isOffRange(event.start!, selectedDate);

    res.push({ ...event, offRange, empty: event.empty ?? offRange });
  }

  return res;
};
