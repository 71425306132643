import { FC } from 'react';

import { Arrow } from '../Arrow';
import DateLabel from '../DateLabel';

export type DateHeaderProps = {
  date?: Date | null;
  decrease: () => void;
  increase: () => void;
  prevButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
};

const DateHeader: FC<DateHeaderProps> = ({ date, decrease, increase }) => {
  return (
    <div className="w-[17.5rem] inline-flex justify-between items-center">
      <Arrow direction="left" onClick={decrease} />
      <DateLabel date={date} />
      <Arrow direction="right" onClick={increase} />
    </div>
  );
};

export default DateHeader;
