/* eslint-disable operator-linebreak */
import { FC } from 'react';
import { Form } from 'antd';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { Button } from '../../components/Button';
import { FormSlug } from '../../components/FormSlug';
import { FormInput } from '../../components/FormInput';
import { FormLabel } from '../../components/FormLabel';
import { FormSelect } from '../../components/FormSelect';
import { FormSkeleton } from '../../components/FormSkeleton';
import { FormContainer } from '../../components/FormContainer';
import { PermissionCheck } from '../../components/PermissionCheck';

import { Slug } from '../../types';
import { GET_CLINICS } from '../../graphql/query/constants';
import { useQueryClinics } from '../../graphql/query/getClinics';
import { useMutationUpdateHospital } from '../../graphql/mutation/updateHospital';

export const Settings: FC = () => {
  const {
    params: { refId },
  } = useRouteMatch<Slug>();

  const { data, loading } = useQueryClinics();
  const clinicData = data?.organizations.nodes.find((clinic) => clinic.refId === refId);
  const { mutation, loading: mutationLoading } = useMutationUpdateHospital({});
  const history = useHistory();
  const cancel = () => history.goBack();

  const clinicsList =
    data?.organizations.nodes.map(({ slug: value, name: label }) => ({
      label,
      value,
    })) || [];

  const defaultClinic = clinicsList.find(({ value }) => {
    return value === clinicData?.parent?.refId;
  });

  const [form] = Form.useForm();

  if (loading) {
    return <FormSkeleton />;
  }

  return (
    <PermissionCheck check="updateOrganizations">
      <Form
        form={form}
        initialValues={{
          parentOrganizationSlug: defaultClinic?.value,
          ...clinicData,
        }}
        onFinish={(data) => {
          mutation({
            variables: {
              ...data,
              refId,
            },
            refetchQueries: [GET_CLINICS],
          });
        }}
      >
        <FormContainer
          left={
            <Form.Item name="name">
              <FormSlug label="Hospital logo" />
            </Form.Item>
          }
          actions={
            <>
              <Button type="light" loading={loading} onClick={cancel}>
                Cancel
              </Button>
              <Button type="primary" loading={loading} onClick={() => form.submit()}>
                Update
              </Button>
            </>
          }
        >
          <FormLabel>Full Hospital Name</FormLabel>
          <Form.Item name="name" rules={[{ required: true, message: 'Please input name' }]}>
            <FormInput disabled={mutationLoading} />
          </Form.Item>

          <FormLabel>Reference ID</FormLabel>
          <Form.Item name="refId" rules={[{ message: 'Please input ID' }]}>
            <FormInput disabled={mutationLoading} />
          </Form.Item>

          <FormLabel>Parent Organization</FormLabel>
          <Form.Item name="parentOrganizationSlug">
            <FormSelect options={clinicsList} />
          </Form.Item>
        </FormContainer>
      </Form>
    </PermissionCheck>
  );
};
