import { useMutation } from '../../hooks/useMutation';
import { CreateMutationResponse, GQLArgs } from '../../types';
import { LOGIN } from './constants';
import { $ } from '../../zeus';

export const useMutationLogin = (
  args?: GQLArgs<CreateMutationResponse<'createSessionWithPassword'>>,
) => {
  return useMutation(
    {
      createSessionWithPassword: [
        {
          email: $`email`,
          password: $`password`,
        },
        {
          token: true,
        },
      ],
    },
    { operationName: LOGIN },
    {
      context: {
        headers: {},
      },
      ...args,
    },
  );
};
