import { FC } from 'react';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom';

import { Button } from '../../components/Button';
import { FormSlug } from '../../components/FormSlug';
import { FormInput } from '../../components/FormInput';
import { FormLabel } from '../../components/FormLabel';
import { PageTitle } from '../../components/PageTitle';
import { FormSelect } from '../../components/FormSelect';
import { FormSkeleton } from '../../components/FormSkeleton';
import { FormContainer } from '../../components/FormContainer';
import { PermissionCheck } from '../../components/PermissionCheck';

import { routesPath } from '../../constants/routes';
import { useQueryClinics } from '../../graphql/query/getClinics';
import { useMutationNewHospital } from '../../graphql/mutation/newHospital';

export const NewHospital: FC = () => {
  const { data, loading } = useQueryClinics();

  const history = useHistory();
  const cancel = () => history.goBack();
  const { mutation, loading: mutationLoading } = useMutationNewHospital({
    onCompleted() {
      history.push('/');
    },
  });

  const [form] = Form.useForm();

  const clinicsList = data?.organizations?.nodes.map(({ slug: value, name: label }) => ({
    label,
    value,
  }));

  return (
    <>
      <PageTitle
        header="Add New Hospital"
        crumbs={[
          { title: 'Administration', url: routesPath.administration.replace('/:refId', '') },
        ]}
      />
      {loading ? (
        <FormSkeleton />
      ) : (
        <PermissionCheck check="createOrganizations">
          <Form
            form={form}
            initialValues={{}}
            onFinish={(data) => {
              mutation({
                variables: data,
              });
            }}
          >
            <FormContainer
              left={
                <Form.Item name="name">
                  <FormSlug label="Hospital logo" />
                </Form.Item>
              }
              actions={
                <>
                  <Button type="light" loading={loading} onClick={cancel}>
                    Cancel
                  </Button>
                  <Button type="primary" loading={loading} onClick={() => form.submit()}>
                    Add
                  </Button>
                </>
              }
            >
              <FormLabel>Full Hospital Name</FormLabel>
              <Form.Item name="name" rules={[{ required: true, message: 'Please input name' }]}>
                <FormInput disabled={mutationLoading} />
              </Form.Item>

              <FormLabel>Reference ID</FormLabel>
              <Form.Item name="refId" rules={[{ message: 'Please input ID' }]}>
                <FormInput disabled={mutationLoading} />
              </Form.Item>

              <FormLabel>Parent Organization</FormLabel>
              <Form.Item name="parentOrganizationSlug">
                <FormSelect options={clinicsList ?? []} />
              </Form.Item>
            </FormContainer>
          </Form>
        </PermissionCheck>
      )}
    </>
  );
};
