import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';

import { useMultiFilterStore } from '../../components/MultiFilter/MultiFilter.store';
import { FullMenuOption } from '../../components/MultiLevelSelectbox/MultiLevelSelectbox';
import { ChartViewName } from '../../components/MultiFilter/MultiFilters.types';

export type SuggestedViewsType = 'case_mix' | 'non_operative_metrics';
export type SuggestedViewCategory =
  // case-mix
  | 'Procedures in OR'
  | 'Specialties in OR'
  | 'Procedures by Surgeon'
  // non-operative-metrics
  | 'ORs for Procedure'
  | 'ORs for Surgeon'
  | 'ORs for Specialty'
  | 'Surgeons for Procedure';

const phrases = {
  'Specialties in OR': 'Specialties in OR',
  'Procedures in OR': 'Procedures in OR',
  'Procedures by Surgeon': 'Procedures by Surgeon',
  'ORs for Surgeon': 'Compare ORs for Surgeon',
  'Surgeons for Procedure': 'Compare Surgeons for Procedure',
  'ORs for Procedure': 'Compare ORs for Procedure',
  'ORs for Specialty': 'Compare ORs for Specialty',
};

export type QuerySuggestedViewsArgs = {
  type: SuggestedViewsType;
};

type SuggestedViewsItem = {
  label: string;
  id: string;
};

export type SuggestedViewsResponse = {
  suggestions: Record<string, SuggestedViewsItem[]>;
  status: string;
};

const GET_SUGGESTED_VIEWS = 'GET_SUGGESTED_VIEWS';
const makeQuerySuggestedViews = async (params: Record<string, any>) => {
  const { data } = await RESTClient.get<SuggestedViewsResponse>('/suggestion-views', { params });

  return data;
};

export const useQuerySuggestedViews = (variables: QuerySuggestedViewsArgs) => {
  const clinickName = useSLUGName();
  const params = useMemo(
    () => ({
      type: variables.type,
      ...(clinickName ? { organization: clinickName } : {}),
    }),
    [variables],
  );

  const processor = useCallback(() => makeQuerySuggestedViews(params), [params]);
  const { data, isLoading: loading, refetch } = useQuery([GET_SUGGESTED_VIEWS, params], processor);

  const suggestions = data?.suggestions || {};

  return { suggestions, loading, refetch };
};

export const useSuggestedView = (
  REPORT_NAME: 'cases' | 'nonOperativeMetrics',
  view: ChartViewName | null,
  procedures: ProcedureType[],
  specialties: SpecialtyType[],
) => {
  const mapmap: Record<string, SuggestedViewsType> = {
    cases: 'case_mix',
    nonOperativeMetrics: 'non_operative_metrics',
  };
  const [setBulkFilters, filtersState] = useMultiFilterStore((s) => [
    s.setBulkFilters,
    s[REPORT_NAME],
  ]);
  const { suggestions } = useQuerySuggestedViews({
    type: mapmap[REPORT_NAME],
  });

  const shouldKeepCompare = view === 'Bubble View' || REPORT_NAME !== 'nonOperativeMetrics';

  const getResetSuggestedViews = useCallback(() => {
    return {
      ...(!shouldKeepCompare ? { compare: null } : {}),
      procedures: [],
      surgeons: [],
      cases: [],
      modalities: [],
      weekDay: [],
      specialties: [],
      allRoboticsDay: false,
      rooms: [],
      frequencyCutoff: null,
      excludeHybridCases: false,
      multiCases: false,
    };
  }, [shouldKeepCompare]);

  const suggestedViews: FullMenuOption<SuggestedViewCategory>[] = useMemo(() => {
    return Object.keys(suggestions).map((key) => ({
      label: phrases[key as SuggestedViewCategory],
      value: phrases[key as SuggestedViewCategory],
      nested: suggestions[key].map((item) => ({
        label: item.label,
        subLabel:
          procedures.find((s) => s.code === item.label)?.name ||
          specialties.find((s) => s.code === item.label)?.name ||
          '',
        value: item.id,
        category: phrases[key as SuggestedViewCategory] as SuggestedViewCategory,
        id: btoa(`${phrases[key as SuggestedViewCategory]}_${item.label}`),
      })),
    }));
  }, [suggestions, procedures]);

  const suggestedView = useMemo(() => {
    let item: FullMenuOption<SuggestedViewCategory> | undefined = undefined;

    suggestedViews.forEach((o) => {
      o.nested?.forEach((n) => {
        if (n.id === filtersState.suggestedView) {
          item = n;
        }
      });
    });

    return item as FullMenuOption<SuggestedViewCategory> | undefined;
  }, [suggestedViews, filtersState.suggestedView]);

  const setSuggestedView = useCallback(
    (el?: FullMenuOption, isRemove?: boolean) => {
      const resetItems = getResetSuggestedViews();
      setBulkFilters(REPORT_NAME, {
        ...(isRemove ? resetItems : {}),
        suggestedView: el ? el?.id || null : null,
      });
    },
    [REPORT_NAME, getResetSuggestedViews],
  );

  useEffect(() => {
    if (!suggestedView) return;

    if ((suggestedView as any)?.category === 'Procedures by Surgeon') {
      const resetItems = getResetSuggestedViews();
      setBulkFilters(REPORT_NAME, {
        ...resetItems,
        compare: 'procedure',
        surgeons: [String(suggestedView?.value) as string],
      });
    }

    if ((suggestedView as any)?.category === 'Procedures in OR') {
      const resetItems = getResetSuggestedViews();
      setBulkFilters(REPORT_NAME, {
        ...resetItems,
        compare: 'procedure',
        rooms: [String(suggestedView?.value) as string],
      });
    }

    if ((suggestedView as any)?.category === 'Compare ORs for Procedure') {
      const resetItems = getResetSuggestedViews();
      setBulkFilters(REPORT_NAME, {
        ...resetItems,
        compare: 'OR',
        procedures: [String(suggestedView?.value) as string],
      });
    }

    if ((suggestedView as any)?.category === 'Compare ORs for Surgeon') {
      const resetItems = getResetSuggestedViews();
      setBulkFilters(REPORT_NAME, {
        ...resetItems,
        compare: 'OR',
        surgeons: ([String(suggestedView?.value)] || []) as string[],
      });
    }

    if ((suggestedView as any)?.category === 'Compare Surgeons for Procedure') {
      const resetItems = getResetSuggestedViews();
      setBulkFilters(REPORT_NAME, {
        ...resetItems,
        compare: 'surgeon_id',
        procedures: [suggestedView.label],
        surgeons: (suggestedView?.value || []) as string[],
      });
    }

    if ((suggestedView as any)?.category === 'Compare ORs for Specialty') {
      const resetItems = getResetSuggestedViews();
      setBulkFilters(REPORT_NAME, {
        ...resetItems,
        compare: 'OR',
        specialties: [String(suggestedView?.value) as string],
      });
    }

    if ((suggestedView as any)?.category === 'Specialties in OR') {
      const resetItems = getResetSuggestedViews();
      setBulkFilters(REPORT_NAME, {
        ...resetItems,
        compare: 'specialty_code',
        rooms: [String(suggestedView?.value) as string],
      });
    }
  }, [suggestedView, getResetSuggestedViews, REPORT_NAME, setBulkFilters]);

  return {
    suggestedViews,
    suggestedView,
    setSuggestedView,
  };
};
