import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { GET_DASHBOARD_CASE_MIX } from '../../graphql/query/constants';
import { useSLUGName } from '../../hooks/useHistory';
import { RESTClient } from '../client';
import { ANALYTICS_API_BASE_URL } from '../../env';
import { makeLogData } from '../../utilities/helpers';
import { transformAllFilters } from '../../utilities/transformAllFilters';
import { ModalityType } from '../../types';

const logData = makeLogData();

type useQueryDashboardCaseHistoryResponse = {
  data: ({ modality: Record<ModalityType, number> } & Record<string, number>)[];
  all_filters: AnalyticsGenericResponse<string>['all_filters'];
};

export type DashboardCaseHistoryArgs = {
  from: string;
  to: string;
  period: Periods;
};

const makeQueryDashboardCaseHistory = async (params: Record<string, any>) => {
  const { data } = await RESTClient.request<useQueryDashboardCaseHistoryResponse>({
    baseURL: ANALYTICS_API_BASE_URL + '/dashboard/v1/case_history',
    params,
  });

  return data;
};

export const useQueryDashboardCaseHistory = (
  variables: DashboardCaseHistoryArgs,
  isActive?: boolean,
) => {
  const organization = useSLUGName();
  const params = useMemo(
    () => ({
      from: variables.from,
      to: variables.to,
      period: variables.period,
      ...(organization ? { organization } : {}),
    }),
    [variables],
  );

  const processor = useCallback(() => makeQueryDashboardCaseHistory(params), [params]);
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery([GET_DASHBOARD_CASE_MIX, params], processor, {
    enabled: isActive || isActive === undefined,
  });

  logData('/dashboard/case_history(' + JSON.stringify(params) + ')', data);

  const result = (data?.data || []).map((v) => {
    const keys = Object.keys(v).filter((k) => k !== 'modality');
    return { date: keys[0], count: keys.map((k) => v[k])[0], modality: v.modality };
  });
  const allFilters = useMemo(() => transformAllFilters(data?.all_filters), [data?.all_filters]);

  return { data: result, loading, allFilters, refetch };
};
